import React from 'react';
import SvgObj from './HersheyObj';

export const HersheyObj = SvgObj;
export class HersheySVG extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      output:this.props.input,
      font:this.props.font||'gothiceng',
      scale:this.props.scale||1,
      height:18,
      offsetX:this.props.left||0,
      offsetY:this.props.top||0
    }
    this.textToSVG = this.textToSVG.bind(this)
  }
  textToSVG(input) {
    const fonts = Object.keys(SvgObj());
    const font = this.state.font;
    const obj = SvgObj()[font];
    let svgArr = [];
    let offsetX = this.state.offsetX/this.state.scale;
    let offsetY = this.state.offsetY/this.state.scale;
    let lineLimit = 500/this.state.scale;
    let appHeight = this.state.height;
    if(input){
      const lines = input.split('\n');
      for(let x=0;x<lines.length;x++){
        const words = lines[x].split(' ');
        for(let y=0;y<words.length;y++){
          const word = words[y];
          let wordLength = 0;
          for(let i=0;i<word.length;i++){
            const char = word[i].charCodeAt() - 33;
            wordLength += parseInt(obj.chars[char].o+8,10);
          }
          if(offsetX+wordLength>lineLimit){
            offsetX = this.state.offsetX/this.state.scale;
            offsetY += 45;
            if(this.props.multiline){appHeight += 44*this.state.scale;}
          }
          for(let i=0;i<word.length;i++){
            const char = word[i].charCodeAt() - 33;
            const letter = obj.chars[char].d;
            if(letter){
              const glyph = <g transform={'scale('+this.state.scale+') translate('+offsetX+','+offsetY+')'}>
                  <path d={letter} style={{stroke:'#000000',fill:'none'}}/>
                </g>;
              svgArr.push(glyph);
              offsetX += parseInt(obj.chars[char].o+8,10);
            }
          }
          offsetX += 10;
        }
        offsetX = this.state.offsetX/this.state.scale;
        offsetY += 45;
        if(this.props.multiline&&x>0){appHeight += 44*this.state.scale;}
      }
    }
    this.setState({height:appHeight,svgArr:svgArr});
  }
  componentDidMount() {
    this.textToSVG(this.state.output);
  }
  render() {
    return(
      <React.Fragment>
        {this.state.svgArr}
      </React.Fragment>
    ); 
  }
}