import React from 'react';

function SvgObj() {
    const fonts = {
    "astrology": {
        "name": "Astrology",
        "chars": [
            {
                "d": "M4,3 L8,5 10,7 11,10 11,13 10,16 8,18 4,20 M4,3 L7,4 9,5 11,7 12,10 M12,13 L11,16 9,18 7,19 4,20 M20,3 L17,4 15,5 13,7 12,10 M12,13 L13,16 15,18 17,19 20,20 M20,3 L16,5 14,7 13,10 13,13 14,16 16,18 20,20 M3,11 L21,11 M3,12 L21,12",
                "o": 12
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8 M14,1 L13,2 13,8 M14,2 L13,8 M14,1 L15,2 13,8",
                "o": 9
            },
            {
                "d": "M12,1 L9,2 6,4 4,7 3,10 3,13 4,16 6,19 9,21 12,22 15,22 18,21 21,19 23,16 24,13 24,10 23,7 21,4 18,2 15,1 12,1 M13,10 L12,11 12,12 13,13 14,13 15,12 15,11 14,10 13,10 M13,11 L13,12 14,12 14,11 13,11",
                "o": 14
            },
            {
                "d": "M6,1 L4,2 5,4 7,5 M6,1 L5,2 5,4 M11,1 L13,2 12,4 10,5 M11,1 L12,2 12,4 M7,5 L5,6 4,7 3,9 3,12 4,14 5,15 7,16 10,16 12,15 13,14 14,12 14,9 13,7 12,6 10,5 7,5 M8,16 L8,22 M9,16 L9,22 M4,19 L13,19",
                "o": 9
            },
            {
                "d": "M9,1 L6,2 4,4 3,7 3,8 4,11 6,13 9,14 10,14 13,13 15,11 16,8 16,7 15,4 13,2 10,1 9,1 M9,14 L9,22 M10,14 L10,22 M5,18 L14,18",
                "o": 10
            },
            {
                "d": "M12,1 L9,2 6,4 4,7 3,10 3,14 4,17 6,20 9,22 12,23 16,23 19,22 22,20 24,17 25,14 25,10 24,7 22,4 19,2 16,1 12,1 M14,1 L14,23 M3,12 L25,12",
                "o": 14
            },
            {
                "d": "M9,8 L6,9 4,11 3,14 3,15 4,18 6,20 9,21 10,21 13,20 15,18 16,15 16,14 15,11 13,9 10,8 9,8 M22,2 L16,2 20,3 14,9 M22,2 L22,8 21,4 15,10 M21,3 L15,9",
                "o": 14
            },
            {
                "d": "M11,-3 L9,-1 7,2 5,6 4,11 4,15 5,20 7,24 9,27 11,29 M9,-1 L7,3 6,6 5,11 5,15 6,20 7,23 9,27",
                "o": 7
            },
            {
                "d": "M3,-3 L5,-1 7,2 9,6 10,11 10,15 9,20 7,24 5,27 3,29 M5,-1 L7,3 8,6 9,11 9,15 8,20 7,23 5,27",
                "o": 7
            },
            {
                "d": "M3,4 L4,2 6,1 9,1 11,2 12,4 12,7 11,10 10,12 8,14 5,16 M9,1 L10,2 11,4 11,8 10,11 8,14 M16,1 L14,22 M17,1 L13,22 M5,16 L19,16",
                "o": 10
            },
            {
                "d": "M4,1 L4,16 M5,1 L4,12 M4,12 L5,10 6,9 8,8 11,8 14,9 15,11 15,13 14,15 12,17 M11,8 L13,9 14,11 14,13 11,19 11,21 12,22 14,22 16,20 M2,1 L5,1",
                "o": 10
            },
            {
                "d": "M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M9,9 L6,10 4,12 3,15 3,16 4,19 6,21 9,22 10,22 13,21 15,19 16,16 16,15 15,12 13,10 10,9 9,9 M9,3 L5,5 9,1 9,9 M10,3 L14,5 10,1 10,9 M9,14 L8,15 8,16 9,17 10,17 11,16 11,15 10,14 9,14 M9,15 L9,16 10,16 10,15 9,15",
                "o": 10
            },
            {
                "d": "M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M10,3 L11,1 11,22 M13,3 L12,1 12,22 M3,3 L4,1 4,8 5,11 7,13 10,14 11,14 M6,3 L5,1 5,9 6,12 M20,3 L19,1 19,8 18,11 16,13 13,14 12,14 M17,3 L18,1 18,9 17,12 M7,18 L16,18",
                "o": 12
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 9,22 12,21 14,19 16,16 17,13 18,9 18,6 17,3 16,2 14,1 12,1 M9,3 L7,5 6,7 5,10 4,14 4,18 5,20 M12,20 L14,18 15,16 16,13 17,9 17,5 16,3 M12,1 L10,2 8,5 7,7 6,10 5,14 5,19 6,21 7,22 M9,22 L11,21 13,18 14,16 15,13 16,9 16,4 15,2 14,1",
                "o": 11
            },
            {
                "d": "M12,5 L7,22 9,22 M15,1 L13,5 8,22 M15,1 L9,22 M15,1 L12,4 9,6 7,7 M12,5 L10,6 7,7",
                "o": 11
            },
            {
                "d": "M7,6 L7,5 8,5 8,7 6,7 6,5 7,3 8,2 11,1 14,1 17,2 18,4 18,6 17,8 15,10 5,16 3,18 1,22 M16,2 L17,4 17,6 16,8 14,10 11,12 M14,1 L15,2 16,4 16,6 15,8 13,10 5,16 M2,20 L3,19 5,19 10,20 15,20 16,19 M5,19 L10,21 15,21 M5,19 L10,22 13,22 15,21 16,19 16,18",
                "o": 11
            },
            {
                "d": "M7,6 L7,5 8,5 8,7 6,7 6,5 7,3 8,2 11,1 14,1 17,2 18,4 18,6 17,8 16,9 14,10 11,11 M16,2 L17,4 17,6 16,8 15,9 M14,1 L15,2 16,4 16,6 15,8 13,10 11,11 M9,11 L11,11 14,12 15,13 16,15 16,18 15,20 13,21 10,22 7,22 4,21 3,20 2,18 2,16 4,16 4,18 3,18 3,17 M14,13 L15,15 15,18 14,20 M11,11 L13,12 14,14 14,18 13,20 12,21 10,22",
                "o": 11
            },
            {
                "d": "M15,5 L10,22 12,22 M18,1 L16,5 11,22 M18,1 L12,22 M18,1 L2,16 18,16",
                "o": 11
            },
            {
                "d": "M9,1 L4,11 M9,1 L19,1 M9,2 L17,2 M8,3 L13,3 17,2 19,1 M4,11 L5,10 8,9 11,9 14,10 15,11 16,13 16,16 15,19 13,21 9,22 6,22 4,21 3,20 2,18 2,16 4,16 4,18 3,18 3,17 M14,11 L15,13 15,16 14,19 12,21 M11,9 L13,10 14,12 14,16 13,19 11,21 9,22",
                "o": 11
            },
            {
                "d": "M17,5 L17,4 16,4 16,6 18,6 18,4 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 10,22 13,21 15,19 16,17 16,14 15,12 14,11 12,10 9,10 7,11 6,12 5,14 M8,4 L6,7 5,10 4,14 4,18 5,20 M14,19 L15,17 15,14 14,12 M12,1 L10,2 8,5 7,7 6,10 5,14 5,19 6,21 7,22 M10,22 L12,21 13,20 14,17 14,13 13,11 12,10",
                "o": 11
            },
            {
                "d": "M6,1 L4,7 M19,1 L18,4 16,7 12,12 10,15 9,18 8,22 M10,14 L8,18 7,22 M16,7 L10,13 8,16 7,18 6,22 8,22 M5,4 L8,1 10,1 15,4 M7,2 L10,2 15,4 M5,4 L7,3 10,3 15,4 17,4 18,3 19,1",
                "o": 11
            },
            {
                "d": "M11,1 L8,2 7,3 6,5 6,8 7,10 9,11 12,11 15,10 17,9 18,7 18,4 17,2 15,1 11,1 M13,1 L8,2 M8,3 L7,5 7,9 8,10 M7,10 L10,11 M11,11 L15,10 M16,9 L17,7 17,4 16,2 M17,2 L13,1 M11,1 L9,3 8,5 8,9 9,11 M12,11 L14,10 15,9 16,7 16,3 15,1 M9,11 L5,12 3,14 2,16 2,19 3,21 6,22 10,22 14,21 15,20 16,18 16,15 15,13 14,12 12,11 M10,11 L5,12 M6,12 L4,14 3,16 3,19 4,21 M3,21 L8,22 14,21 M14,20 L15,18 15,15 14,13 M14,12 L11,11 M9,11 L7,12 5,14 4,16 4,19 5,21 6,22 M10,22 L12,21 13,20 14,18 14,14 13,12 12,11",
                "o": 11
            },
            {
                "d": "M16,9 L15,11 14,12 12,13 9,13 7,12 6,11 5,9 5,6 6,4 8,2 11,1 14,1 16,2 17,3 18,6 18,9 17,13 16,16 14,19 12,21 9,22 6,22 4,21 3,19 3,17 5,17 5,19 4,19 4,18 M7,11 L6,9 6,6 7,4 M16,3 L17,5 17,9 16,13 15,16 13,19 M9,13 L8,12 7,10 7,6 8,3 9,2 11,1 M14,1 L15,2 16,4 16,9 15,13 14,16 13,18 11,21 9,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L14,1 17,2 18,3 19,5 19,8 18,10 17,11 14,12 6,12 M14,1 L16,2 17,3 18,5 18,8 17,10 16,11 14,12 M2,22 L18,22 18,17 17,22",
                "o": 11
            },
            {
                "d": "M17,2 L13,2 9,3 6,5 4,8 3,11 3,14 4,17 6,20 9,22 13,23 17,23 M17,2 L14,3 11,5 9,8 8,11 8,14 9,17 11,20 14,22 17,23",
                "o": 9
            },
            {
                "d": "M9,12 L7,12 5,13 4,14 3,16 3,18 4,20 5,21 7,22 9,22 11,21 12,20 13,18 13,16 12,14 11,13 9,12 M13,3 L10,12 M20,5 L12,13 M22,12 L13,15",
                "o": 13
            },
            {
                "d": "M7,6 L13,20 M13,6 L7,20 M3,10 L17,16 M17,10 L3,16",
                "o": 10
            },
            {
                "d": "M8,17 L6,16 5,16 3,17 2,19 2,20 3,22 5,23 6,23 8,22 9,20 9,19 8,17 5,13 4,10 4,8 5,5 7,3 10,2 14,2 17,3 19,5 20,8 20,10 19,13 16,17 15,19 15,20 16,22 18,23 19,23 21,22 22,20 22,19 21,17 19,16 18,16 16,17 M4,8 L5,6 7,4 10,3 14,3 17,4 19,6 20,8",
                "o": 12
            },
            {
                "d": "M8,8 L6,9 5,9 3,8 2,6 2,5 3,3 5,2 6,2 8,3 9,5 9,6 8,8 5,12 4,15 4,17 5,20 7,22 10,23 14,23 17,22 19,20 20,17 20,15 19,12 16,8 15,6 15,5 16,3 18,2 19,2 21,3 22,5 22,6 21,8 19,9 18,9 16,8 M4,17 L5,19 7,21 10,22 14,22 17,21 19,19 20,17",
                "o": 12
            },
            {
                "d": "M4,8 L3,7 3,5 4,3 6,2 8,2 10,3 11,4 12,6 13,11 M3,5 L5,3 7,3 9,4 10,5 11,7 12,11 12,22 M21,8 L22,7 22,5 21,3 19,2 17,2 15,3 14,4 13,6 12,11 M22,5 L20,3 18,3 16,4 15,5 14,7 13,11 13,22",
                "o": 13
            },
            {
                "d": "M10,1 L3,21 M9,4 L15,22 M10,4 L16,22 M10,1 L17,22 M5,16 L14,16 M1,22 L7,22 M12,22 L19,22 M3,21 L2,22 M3,21 L5,22 M15,21 L13,22 M15,20 L14,22 M16,20 L18,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L14,1 17,2 18,3 19,5 19,7 18,9 17,10 14,11 M17,3 L18,5 18,7 17,9 M14,1 L16,2 17,4 17,8 16,10 14,11 M7,11 L14,11 17,12 18,13 19,15 19,18 18,20 17,21 14,22 2,22 M17,13 L18,15 18,18 17,20 M14,11 L16,12 17,14 17,19 16,21 14,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 11
            },
            {
                "d": "M17,4 L18,1 18,7 17,4 15,2 13,1 10,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 10,22 13,22 15,21 17,19 18,17 M6,4 L5,6 4,9 4,14 5,17 6,19 M10,1 L8,2 6,5 5,9 5,14 6,18 8,21 10,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L12,1 15,2 17,4 18,6 19,9 19,14 18,17 17,19 15,21 12,22 2,22 M16,4 L17,6 18,9 18,14 17,17 16,19 M12,1 L14,2 16,5 17,9 17,14 16,18 14,21 12,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L18,1 18,7 M7,11 L13,11 M13,7 L13,15 M2,22 L18,22 18,16 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M13,1 L18,2 M15,1 L18,3 M16,1 L18,4 M17,1 L18,7 M13,7 L12,11 13,15 M13,9 L11,11 13,13 M13,10 L9,11 13,12 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M13,22 L18,21 M15,22 L18,20 M16,22 L18,19 M17,22 L18,16",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L18,1 18,7 M7,11 L13,11 M13,7 L13,15 M2,22 L10,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M13,1 L18,2 M15,1 L18,3 M16,1 L18,4 M17,1 L18,7 M13,7 L12,11 13,15 M13,9 L11,11 13,13 M13,10 L9,11 13,12 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 9
            },
            {
                "d": "M17,4 L18,1 18,7 17,4 15,2 13,1 10,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 10,22 13,22 15,21 17,21 18,22 18,14 M6,4 L5,6 4,9 4,14 5,17 6,19 M10,1 L8,2 6,5 5,9 5,14 6,18 8,21 10,22 M17,15 L17,20 M16,14 L16,20 15,21 M13,14 L21,14 M14,14 L16,15 M15,14 L16,16 M19,14 L18,16 M20,14 L18,15",
                "o": 12
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M17,1 L17,22 M18,2 L18,21 M19,1 L19,22 M2,1 L10,1 M14,1 L22,1 M7,11 L17,11 M2,22 L10,22 M14,22 L22,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M15,1 L17,2 M16,1 L17,3 M20,1 L19,3 M21,1 L19,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M17,21 L15,22 M17,20 L16,22 M19,20 L20,22 M19,21 L21,22",
                "o": 12
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L10,1 M2,22 L10,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 6
            },
            {
                "d": "M9,1 L9,18 8,21 7,22 M10,2 L10,18 9,21 M11,1 L11,18 10,21 7,22 5,22 3,21 2,19 2,17 3,16 4,16 5,17 5,18 4,19 3,19 M3,17 L3,18 4,18 4,17 3,17 M6,1 L14,1 M7,1 L9,2 M8,1 L9,3 M12,1 L11,3 M13,1 L11,2",
                "o": 8
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M18,2 L7,13 M10,11 L17,22 M11,11 L18,22 M11,9 L19,22 M2,1 L10,1 M15,1 L21,1 M2,22 L10,22 M14,22 L21,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M17,1 L18,2 M20,1 L18,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M17,20 L15,22 M17,20 L20,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L10,1 M2,22 L17,22 17,16 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M12,22 L17,21 M14,22 L17,20 M15,22 L17,19 M16,22 L17,16",
                "o": 9
            },
            {
                "d": "M5,1 L5,21 M5,1 L12,22 M6,1 L12,19 M7,1 L13,19 M19,1 L12,22 M19,1 L19,22 M20,2 L20,21 M21,1 L21,22 M2,1 L7,1 M19,1 L24,1 M2,22 L8,22 M16,22 L24,22 M3,1 L5,2 M22,1 L21,3 M23,1 L21,2 M5,21 L3,22 M5,21 L7,22 M19,21 L17,22 M19,20 L18,22 M21,20 L22,22 M21,21 L23,22",
                "o": 13
            },
            {
                "d": "M5,1 L5,21 M5,1 L19,22 M6,1 L18,19 M7,1 L19,19 M19,2 L19,22 M2,1 L7,1 M16,1 L22,1 M2,22 L8,22 M3,1 L5,2 M17,1 L19,2 M21,1 L19,2 M5,21 L3,22 M5,21 L7,22",
                "o": 12
            },
            {
                "d": "M10,1 L7,2 5,4 4,6 3,10 3,13 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 19,13 19,10 18,6 17,4 15,2 12,1 10,1 M6,4 L5,6 4,9 4,14 5,17 6,19 M16,19 L17,17 18,14 18,9 17,6 16,4 M10,1 L8,2 6,5 5,9 5,14 6,18 8,21 10,22 M12,22 L14,21 16,18 17,14 17,9 16,5 14,2 12,1",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L14,1 17,2 18,3 19,5 19,8 18,10 17,11 14,12 7,12 M17,3 L18,5 18,8 17,10 M14,1 L16,2 17,4 17,9 16,11 14,12 M2,22 L10,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 11
            },
            {
                "d": "M10,1 L7,2 5,4 4,6 3,10 3,13 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 19,13 19,10 18,6 17,4 15,2 12,1 10,1 M6,4 L5,6 4,9 4,14 5,17 6,19 M16,19 L17,17 18,14 18,9 17,6 16,4 M10,1 L8,2 6,5 5,9 5,14 6,18 8,21 10,22 M12,22 L14,21 16,18 17,14 17,9 16,5 14,2 12,1 M7,19 L8,17 10,16 11,16 13,17 14,19 15,25 16,27 18,27 19,25 19,23 M15,23 L16,25 17,26 18,26 M14,19 L16,24 17,25 18,25 19,24",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L14,1 17,2 18,3 19,5 19,7 18,9 17,10 14,11 7,11 M17,3 L18,5 18,7 17,9 M14,1 L16,2 17,4 17,8 16,10 14,11 M11,11 L13,12 14,14 16,20 17,22 19,22 20,20 20,18 M16,18 L17,20 18,21 19,21 M13,12 L14,13 17,19 18,20 19,20 20,19 M2,22 L10,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 11
            },
            {
                "d": "M16,4 L17,1 17,7 16,4 14,2 11,1 8,1 5,2 3,4 3,7 4,9 7,11 13,13 15,14 16,16 16,19 15,21 M4,7 L5,9 7,10 13,12 15,13 16,15 M5,2 L4,4 4,6 5,8 7,9 13,11 16,13 17,15 17,18 16,20 15,21 12,22 9,22 6,21 4,19 3,16 3,22 4,19",
                "o": 10
            },
            {
                "d": "M2,1 L2,7 M9,1 L9,22 M10,2 L10,21 M11,1 L11,22 M18,1 L18,7 M2,1 L18,1 M6,22 L14,22 M3,1 L2,7 M4,1 L2,4 M5,1 L2,3 M7,1 L2,2 M13,1 L18,2 M15,1 L18,3 M16,1 L18,4 M17,1 L18,7 M9,21 L7,22 M9,20 L8,22 M11,20 L12,22 M11,21 L13,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,16 6,19 8,21 11,22 13,22 16,21 18,19 19,16 19,2 M6,2 L6,17 7,19 M7,1 L7,17 8,20 9,21 11,22 M2,1 L10,1 M16,1 L22,1 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M17,1 L19,2 M21,1 L19,2",
                "o": 12
            },
            {
                "d": "M3,1 L10,22 M4,1 L10,19 10,22 M5,1 L11,19 M17,2 L10,22 M1,1 L8,1 M13,1 L19,1 M2,1 L4,3 M6,1 L5,3 M7,1 L5,2 M15,1 L17,2 M18,1 L17,2",
                "o": 10
            },
            {
                "d": "M4,1 L8,22 M5,1 L8,17 8,22 M6,1 L9,17 M12,1 L9,17 8,22 M12,1 L16,22 M13,1 L16,17 16,22 M14,1 L17,17 M20,2 L17,17 16,22 M1,1 L9,1 M12,1 L14,1 M17,1 L23,1 M2,1 L5,2 M3,1 L5,3 M7,1 L6,3 M8,1 L6,2 M18,1 L20,2 M22,1 L20,2",
                "o": 12
            },
            {
                "d": "M3,1 L15,22 M4,1 L16,22 M5,1 L17,22 M16,2 L4,21 M1,1 L8,1 M13,1 L19,1 M1,22 L7,22 M12,22 L19,22 M2,1 L5,3 M6,1 L5,3 M7,1 L5,2 M14,1 L16,2 M18,1 L16,2 M4,21 L2,22 M4,21 L6,22 M15,21 L13,22 M15,20 L14,22 M15,20 L18,22",
                "o": 10
            },
            {
                "d": "M3,1 L10,12 10,22 M4,1 L11,12 11,21 M5,1 L12,12 12,22 M18,2 L12,12 M1,1 L8,1 M15,1 L21,1 M7,22 L15,22 M2,1 L4,2 M7,1 L5,2 M16,1 L18,2 M20,1 L18,2 M10,21 L8,22 M10,20 L9,22 M12,20 L13,22 M12,21 L14,22",
                "o": 11
            },
            {
                "d": "M17,1 L3,1 3,7 M15,1 L3,22 M16,1 L4,22 M17,1 L5,22 M3,22 L17,22 17,16 M4,1 L3,7 M5,1 L3,4 M6,1 L3,3 M8,1 L3,2 M12,22 L17,21 M14,22 L17,20 M15,22 L17,19 M16,22 L17,16",
                "o": 10
            },
            {
                "d": "M3,2 L4,6 5,8 7,10 10,11 14,11 17,10 19,8 20,6 21,2 M3,2 L4,5 5,7 7,9 10,10 14,10 17,9 19,7 20,5 21,2 M10,10 L8,11 7,12 6,14 6,17 7,19 9,21 11,22 13,22 15,21 17,19 18,17 18,14 17,12 16,11 14,10 M10,11 L8,12 7,14 7,17 8,20 M16,20 L17,17 17,14 16,12 14,11",
                "o": 12
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M7,5 L7,17 M8,6 L8,16 M16,6 L16,16 M17,5 L17,17 M3,2 L5,4 7,5 10,6 14,6 17,5 19,4 21,2 M3,20 L5,18 7,17 10,16 14,16 17,17 19,18 21,20",
                "o": 12
            },
            {
                "d": "M21,4 L6,4 4,5 3,7 3,9 4,11 6,12 8,12 10,11 11,9 11,7 10,5 21,5 M3,8 L4,10 5,11 7,12 M11,8 L10,6 9,5 7,4 M3,19 L18,19 20,18 21,16 21,14 20,12 18,11 16,11 14,12 13,14 13,16 14,18 3,18 M21,15 L20,13 19,12 17,11 M13,15 L14,17 15,18 17,19",
                "o": 12
            },
            {
                "d": "M9,16 L7,15 6,15 4,16 3,18 3,19 4,21 6,22 7,22 9,21 10,19 10,18 9,16 4,11 3,9 3,6 4,4 6,3 9,2 13,2 17,3 19,5 20,7 20,10 19,13 16,16 15,18 15,20 16,22 18,22 19,21 20,19 M7,14 L5,11 4,9 4,6 5,4 6,3 M13,2 L16,3 18,5 19,7 19,10 18,13 16,16",
                "o": 11
            },
            {
                "d": "M1,6 L4,3 6,6 6,17 M3,4 L5,7 5,17 M6,6 L9,3 11,6 11,16 M8,4 L10,7 10,16 M11,6 L14,3 16,6 16,22 M13,4 L15,7 15,22 M16,6 L19,3 20,5 21,8 21,11 20,14 19,16 17,18 14,20 9,22 M18,4 L19,5 20,8 20,11 19,14 18,16 16,18 13,20 9,22",
                "o": 13
            },
            {
                "d": "M16,8 L14,15 14,19 15,21 16,22 18,22 20,20 21,18 M17,8 L15,15 15,21 M16,8 L18,8 16,15 15,19 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 11,21 12,20 13,18 14,15 M7,9 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22",
                "o": 11
            },
            {
                "d": "M7,1 L5,8 4,14 4,18 5,20 6,21 8,22 10,22 13,21 15,18 16,15 16,13 15,10 14,9 12,8 10,8 8,9 7,10 6,12 5,15 M8,1 L6,8 5,12 5,18 6,21 M13,20 L14,18 15,15 15,12 14,10 M4,1 L9,1 7,8 5,15 M10,22 L12,20 13,18 14,15 14,12 13,9 12,8 M5,1 L8,2 M6,1 L7,3",
                "o": 10
            },
            {
                "d": "M14,12 L14,11 13,11 13,13 15,13 15,11 14,9 12,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 12,21 14,18 M6,10 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22",
                "o": 9
            },
            {
                "d": "M18,1 L15,12 14,16 14,19 15,21 16,22 18,22 20,20 21,18 M19,1 L16,12 15,16 15,21 M15,1 L20,1 16,15 15,19 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 11,21 12,20 13,18 14,15 M6,10 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22 M16,1 L19,2 M17,1 L18,3",
                "o": 11
            },
            {
                "d": "M4,17 L8,16 11,15 14,13 15,11 14,9 12,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 12,21 14,19 M6,10 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22",
                "o": 9
            },
            {
                "d": "M16,3 L16,2 15,2 15,4 17,4 17,2 16,1 14,1 12,2 10,4 9,6 8,9 7,13 5,22 4,25 3,27 1,29 M10,5 L9,8 8,13 6,22 5,25 M14,1 L12,3 11,5 10,8 9,13 7,21 6,24 5,26 3,28 1,29 -1,29 -2,28 -2,26 0,26 0,28 -1,28 -1,27 M4,8 L15,8",
                "o": 8
            },
            {
                "d": "M16,8 L12,22 11,25 9,28 7,29 M17,8 L13,22 11,26 M16,8 L18,8 14,22 12,26 10,28 7,29 4,29 2,28 1,27 1,25 3,25 3,27 2,27 2,26 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 11,21 12,20 13,18 14,15 M6,10 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22",
                "o": 11
            },
            {
                "d": "M8,1 L2,22 4,22 M9,1 L3,22 M5,1 L10,1 4,22 M6,15 L8,11 10,9 12,8 14,8 16,9 17,11 17,14 15,19 M16,9 L16,13 15,17 15,21 M16,11 L14,16 14,19 15,21 16,22 18,22 20,20 21,18 M6,1 L9,2 M7,1 L8,3",
                "o": 11
            },
            {
                "d": "M8,1 L8,3 10,3 10,1 8,1 M9,1 L9,3 M8,2 L10,2 M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,19 M7,9 L7,13 6,17 6,21 M7,11 L5,16 5,19 6,21 7,22 9,22 11,20 12,18",
                "o": 6
            },
            {
                "d": "M10,1 L10,3 12,3 12,1 10,1 M11,1 L11,3 M10,2 L12,2 M2,12 L3,10 5,8 7,8 8,9 9,11 9,14 7,21 6,24 5,26 3,28 1,29 -1,29 -2,28 -2,26 0,26 0,28 -1,28 -1,27 M8,9 L8,14 6,21 5,24 4,26 M8,11 L7,15 5,22 4,25 3,27 1,29",
                "o": 6
            },
            {
                "d": "M8,1 L2,22 4,22 M9,1 L3,22 M5,1 L10,1 4,22 M18,10 L18,9 17,9 17,11 19,11 19,9 18,8 16,8 14,9 10,13 8,14 M6,14 L8,14 10,15 11,16 13,20 14,21 16,21 M10,16 L12,20 13,21 M8,14 L9,15 11,21 12,22 14,22 16,21 18,18 M6,1 L9,2 M7,1 L8,3",
                "o": 11
            },
            {
                "d": "M8,1 L5,12 4,16 4,19 5,21 6,22 8,22 10,20 11,18 M9,1 L6,12 5,16 5,21 M5,1 L10,1 6,15 5,19 M6,1 L9,2 M7,1 L8,3",
                "o": 6
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,22 M7,9 L7,14 5,22 M7,11 L6,15 4,22 6,22 M8,14 L10,11 12,9 14,8 16,8 18,9 19,11 19,14 17,22 M18,9 L18,14 16,22 M18,11 L17,15 15,22 17,22 M19,14 L21,11 23,9 25,8 27,8 29,9 30,11 30,14 28,19 M29,9 L29,13 28,17 28,21 M29,11 L27,16 27,19 28,21 29,22 31,22 33,20 34,18",
                "o": 17
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,22 M7,9 L7,14 5,22 M7,11 L6,15 4,22 6,22 M8,14 L10,11 12,9 14,8 16,8 18,9 19,11 19,14 17,19 M18,9 L18,13 17,17 17,21 M18,11 L16,16 16,19 17,21 18,22 20,22 22,20 23,18",
                "o": 12
            },
            {
                "d": "M9,8 L6,9 4,12 3,15 3,17 4,20 5,21 8,22 11,22 14,21 16,18 17,15 17,13 16,10 15,9 12,8 9,8 M6,10 L5,12 4,15 4,18 5,20 M14,20 L15,18 16,15 16,12 15,10 M9,8 L7,10 6,12 5,15 5,18 6,21 8,22 M11,22 L13,20 14,18 15,15 15,12 14,9 12,8",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 7,18 4,29 M7,9 L7,14 6,18 3,29 M7,11 L6,15 2,29 M8,15 L9,12 10,10 11,9 13,8 15,8 17,9 18,10 19,13 19,15 18,18 16,21 13,22 11,22 9,21 8,18 8,15 M17,10 L18,12 18,15 17,18 16,20 M15,8 L16,9 17,12 17,15 16,18 15,20 13,22 M-1,29 L7,29 M3,28 L0,29 M3,27 L1,29 M4,27 L5,29 M3,28 L6,29",
                "o": 11
            },
            {
                "d": "M16,8 L10,29 M17,8 L11,29 M16,8 L18,8 12,29 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 11,21 12,20 13,18 14,15 M6,10 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22 M7,29 L15,29 M11,28 L8,29 M11,27 L9,29 M12,27 L13,29 M11,28 L14,29",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,15 6,22 M7,9 L7,15 5,22 M7,11 L6,15 4,22 6,22 M16,10 L16,9 15,9 15,11 17,11 17,9 16,8 14,8 12,9 10,11 8,15",
                "o": 9
            },
            {
                "d": "M14,11 L14,10 13,10 13,12 15,12 15,10 14,9 11,8 8,8 5,9 4,10 4,12 5,14 7,15 10,16 12,17 13,19 M5,9 L4,12 M5,13 L7,14 10,15 12,16 M13,17 L12,21 M4,10 L5,12 7,13 10,14 12,15 13,17 13,19 12,21 9,22 6,22 3,21 2,20 2,18 4,18 4,20 3,20 3,19",
                "o": 9
            },
            {
                "d": "M9,1 L6,12 5,16 5,19 6,21 7,22 9,22 11,20 12,18 M10,1 L7,12 6,16 6,21 M9,1 L11,1 7,15 6,19 M3,8 L13,8",
                "o": 7
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,19 M7,9 L7,13 6,17 6,21 M7,11 L5,16 5,19 6,21 8,22 10,22 12,21 14,19 16,16 M18,8 L16,16 16,19 17,21 18,22 20,22 22,20 23,18 M19,8 L17,16 17,21 M18,8 L20,8 18,15 17,19",
                "o": 12
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,19 M7,9 L7,13 6,17 6,21 M7,11 L5,16 5,19 6,21 8,22 10,22 12,21 14,19 16,16 17,12 17,8 16,8 16,9 17,11",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,19 M7,9 L7,13 6,17 6,21 M7,11 L5,16 5,19 6,21 8,22 10,22 12,21 14,19 15,16 M17,8 L15,16 15,19 16,21 18,22 20,22 22,21 24,19 26,16 27,12 27,8 26,8 26,9 27,11 M18,8 L16,16 16,21 M17,8 L19,8 17,15 16,19",
                "o": 15
            },
            {
                "d": "M3,12 L5,9 7,8 9,8 11,9 12,11 12,13 M9,8 L10,9 10,13 9,17 8,19 6,21 4,22 2,22 1,21 1,19 3,19 3,21 2,21 2,20 M11,10 L11,13 10,17 10,20 M19,10 L19,9 18,9 18,11 20,11 20,9 19,8 17,8 15,9 13,11 12,13 11,17 11,21 12,22 M9,17 L9,19 10,21 12,22 14,22 16,21 18,18",
                "o": 11
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,19 M7,9 L7,13 6,17 6,21 M7,11 L5,16 5,19 6,21 8,22 10,22 12,21 14,19 16,15 M18,8 L14,22 13,25 11,28 9,29 M19,8 L15,22 13,26 M18,8 L20,8 16,22 14,26 12,28 9,29 6,29 4,28 3,27 3,25 5,25 5,27 4,27 4,26",
                "o": 11
            },
            {
                "d": "M17,8 L16,10 14,12 6,18 4,20 3,22 M16,10 L7,10 5,11 4,13 M14,10 L10,9 7,9 6,10 M14,10 L10,8 7,8 5,10 4,13 M4,20 L13,20 15,19 16,17 M6,20 L10,21 13,21 14,20 M6,20 L10,22 13,22 15,20 16,17",
                "o": 10
            },
            {
                "d": "M1,7 L4,4 7,7 7,19 M3,5 L6,8 6,19 M7,7 L10,4 13,7 13,19 M9,5 L12,8 12,19 M13,7 L16,4 19,7 19,18 21,20 M15,5 L18,8 18,19 20,21 23,18",
                "o": 13
            },
            {
                "d": "M19,4 L3,20 M19,4 L16,5 10,5 M17,6 L14,6 10,5 M19,4 L18,7 18,13 M17,6 L17,9 18,13 M10,13 L3,13 M9,14 L6,14 3,13 M10,13 L10,20 M9,14 L9,17 10,20",
                "o": 11
            },
            {
                "d": "M2,10 L4,6 9,16 M4,8 L9,18 12,11 17,11 20,10 21,8 21,6 20,4 18,3 17,3 15,4 14,6 14,8 15,11 16,13 17,16 17,19 15,21 M17,3 L16,4 15,6 15,8 17,12 18,15 18,18 17,20 15,21",
                "o": 12
            },
            {
                "d": "M3,10 L6,7 10,9 M5,8 L9,10 12,7 15,9 M11,8 L14,10 17,7 19,9 M16,8 L18,10 21,7 M3,16 L6,13 10,15 M5,14 L9,16 12,13 15,15 M11,14 L14,16 17,13 19,15 M16,14 L18,16 21,13",
                "o": 12
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            }
        ]
    },
    "cursive": {
        "name": "Script 1-stroke (alt)",
        "chars": [
            {
                "d": "M5,1 L5,15 M5,20 L4,21 5,22 6,21 5,20",
                "o": 5
            },
            {
                "d": "M4,1 L4,8 M12,1 L12,8",
                "o": 8
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M8,-3 L8,26 M12,-3 L12,26 M17,4 L15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 16,14 17,16 17,19 15,21 12,22 8,22 5,21 3,19",
                "o": 10
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M23,10 L23,9 22,8 21,8 20,9 19,11 17,16 15,19 13,21 11,22 7,22 5,21 4,20 3,18 3,16 4,14 5,13 12,9 13,8 14,6 14,4 13,2 11,1 9,2 8,4 8,6 9,9 11,12 16,19 18,21 20,22 22,22 23,21 23,20",
                "o": 13
            },
            {
                "d": "M2,8 L2,12",
                "o": 2
            },
            {
                "d": "M11,-3 L9,-1 7,2 5,6 4,11 4,15 5,20 7,24 9,27 11,29",
                "o": 7
            },
            {
                "d": "M3,-3 L5,-1 7,2 9,6 10,11 10,15 9,20 7,24 5,27 3,29",
                "o": 7
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M20,-3 L2,29",
                "o": 11
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22",
                "o": 10
            },
            {
                "d": "M4,6 L4,5 5,3 6,2 8,1 12,1 14,2 15,3 16,5 16,7 15,9 13,12 3,22 17,22",
                "o": 10
            },
            {
                "d": "M5,1 L16,1 10,9 13,9 15,10 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M13,1 L3,15 18,15 M13,1 L13,22",
                "o": 10
            },
            {
                "d": "M15,1 L5,1 4,10 5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M16,4 L15,2 12,1 10,1 7,2 5,5 4,10 4,15 5,19 7,21 10,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 4,15",
                "o": 10
            },
            {
                "d": "M17,1 L7,22 M3,1 L17,1",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,6 5,8 7,9 11,10 14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,15 4,13 6,11 9,10 13,9 15,8 16,6 16,4 15,2 12,1 8,1",
                "o": 10
            },
            {
                "d": "M16,8 L15,11 13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 10,1 13,2 15,4 16,8 16,13 15,18 13,21 10,22 8,22 5,21 4,19",
                "o": 10
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M3,6 L3,5 4,3 5,2 7,1 11,1 13,2 14,3 15,5 15,7 14,9 13,10 9,12 9,15 M9,20 L8,21 9,22 10,21 9,20",
                "o": 9
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M0,22 L2,21 5,18 8,14 12,7 15,1 15,22 14,19 12,16 10,14 7,12 5,12 4,13 4,15 5,17 7,19 10,21 13,22 18,22",
                "o": 9
            },
            {
                "d": "M13,3 L14,4 14,7 13,11 12,14 11,16 9,19 7,21 5,22 4,22 3,21 3,18 4,13 5,10 6,8 8,5 10,3 12,2 15,1 18,1 20,2 21,4 21,6 20,8 19,9 17,10 14,11 M13,11 L14,11 17,12 18,13 19,15 19,18 18,20 17,21 15,22 12,22 10,21 9,19",
                "o": 11
            },
            {
                "d": "M12,7 L12,8 13,9 15,9 17,8 18,6 18,4 17,2 15,1 12,1 9,2 7,4 5,7 4,9 3,13 3,17 4,20 5,21 7,22 9,22 12,21 14,19 15,17",
                "o": 10
            },
            {
                "d": "M13,1 L11,2 10,4 9,8 8,14 7,17 6,19 4,21 2,22 0,22 -1,21 -1,19 0,18 2,18 4,19 6,21 9,22 12,22 15,21 17,19 19,15 20,10 20,6 19,3 18,2 16,1 13,1 11,3 11,5 12,8 14,11 16,13 19,15 21,16",
                "o": 12
            },
            {
                "d": "M14,5 L14,6 15,7 17,7 18,6 18,4 17,2 14,1 10,1 7,2 6,4 6,7 7,9 8,10 11,11 8,11 5,12 4,13 3,15 3,18 4,20 5,21 8,22 11,22 14,21 16,19 17,17",
                "o": 10
            },
            {
                "d": "M10,7 L8,7 6,6 5,4 6,2 9,1 12,1 16,2 19,2 21,1 M16,2 L14,9 12,15 10,19 8,21 6,22 4,22 2,21 1,19 1,17 2,16 4,16 6,17 M9,11 L18,11",
                "o": 10
            },
            {
                "d": "M0,22 L2,21 6,17 9,12 10,9 11,5 11,2 10,1 9,1 8,2 7,4 7,7 8,9 10,10 14,10 17,9 18,8 19,6 19,12 18,17 17,19 15,21 12,22 8,22 5,21 3,19 2,17 2,15",
                "o": 12
            },
            {
                "d": "M7,8 L5,7 4,5 4,4 5,2 7,1 8,1 10,2 11,4 11,6 10,10 8,16 6,20 4,22 2,22 1,21 1,19 M7,13 L16,10 18,9 21,7 23,5 24,3 24,2 23,1 22,1 20,3 18,7 16,13 15,18 15,21 16,22 17,22 19,21 20,20 22,17",
                "o": 12
            },
            {
                "d": "M14,17 L12,15 10,12 9,10 8,7 8,4 9,2 10,1 12,1 13,2 14,4 14,7 13,12 11,17 10,19 8,21 6,22 4,22 2,21 1,19 1,17 2,16 4,16 6,17",
                "o": 8
            },
            {
                "d": "M10,25 L8,22 6,17 5,11 5,5 6,2 8,1 10,1 11,2 12,5 12,8 11,13 8,22 6,28 5,31 4,33 2,34 1,33 1,31 2,28 4,25 6,23 9,21 13,19",
                "o": 7
            },
            {
                "d": "M7,8 L5,7 4,5 4,4 5,2 7,1 8,1 10,2 11,4 11,6 10,10 8,16 6,20 4,22 2,22 1,21 1,19 M24,4 L24,2 23,1 22,1 20,2 18,4 16,7 14,9 12,10 10,10 M12,10 L13,12 13,19 14,21 15,22 16,22 18,21 19,20 21,17",
                "o": 12
            },
            {
                "d": "M4,13 L6,13 10,12 13,10 15,8 16,6 16,3 15,1 13,1 12,2 11,4 10,9 9,14 8,17 7,19 5,21 3,22 1,22 0,21 0,19 1,18 3,18 5,19 8,21 11,22 13,22 16,21 18,19",
                "o": 10
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,6 8,11 7,15 5,22 M7,15 L10,7 12,3 13,2 15,1 16,1 18,2 19,4 19,6 18,11 17,15 15,22 M17,15 L20,7 22,3 23,2 25,1 26,1 28,2 29,4 29,6 28,11 26,18 26,21 27,22 28,22 30,21 31,20 33,17",
                "o": 15
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,6 8,11 7,15 5,22 M7,15 L10,7 12,3 13,2 15,1 17,1 19,2 20,4 20,6 19,11 17,18 17,21 18,22 19,22 21,21 22,20 24,17",
                "o": 11
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,9 3,13 3,17 4,20 5,21 7,22 9,22 12,21 14,19 16,16 17,14 18,10 18,6 17,3 16,2 14,1 12,1 10,3 10,6 11,9 13,12 15,14 18,16 20,17",
                "o": 11
            },
            {
                "d": "M13,3 L14,4 14,7 13,11 12,14 11,16 9,19 7,21 5,22 4,22 3,21 3,18 4,13 5,10 6,8 8,5 10,3 12,2 15,1 20,1 22,2 23,3 24,5 24,8 23,10 22,11 20,12 17,12 15,11 14,10",
                "o": 13
            },
            {
                "d": "M13,7 L12,9 11,10 9,11 7,11 6,9 6,7 7,4 9,2 12,1 15,1 17,2 18,4 18,8 17,11 15,14 11,18 8,20 6,21 3,22 1,22 0,21 0,19 1,18 3,18 5,19 8,21 11,22 14,22 17,21 19,19",
                "o": 12
            },
            {
                "d": "M13,3 L14,4 14,7 13,11 12,14 11,16 9,19 7,21 5,22 4,22 3,21 3,18 4,13 5,10 6,8 8,5 10,3 12,2 15,1 19,1 21,2 22,3 23,5 23,8 22,10 21,11 19,12 16,12 13,11 14,12 15,14 15,19 16,21 18,22 20,21 21,20 23,17",
                "o": 13
            },
            {
                "d": "M0,22 L2,21 4,19 7,15 9,12 11,8 12,5 12,2 11,1 10,1 9,2 8,4 8,6 9,8 11,10 14,12 16,14 17,16 17,18 16,20 15,21 12,22 8,22 5,21 3,19 2,17 2,15",
                "o": 10
            },
            {
                "d": "M10,7 L8,7 6,6 5,4 6,2 9,1 12,1 16,2 19,2 21,1 M16,2 L14,9 12,15 10,19 8,21 6,22 4,22 2,21 1,19 1,17 2,16 4,16 6,17",
                "o": 9
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,6 8,10 7,13 6,17 6,19 7,21 9,22 11,22 13,21 14,20 16,16 19,8 21,1 M19,8 L18,12 17,18 17,21 18,22 19,22 21,21 22,20 24,17",
                "o": 11
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,6 8,10 7,13 6,17 6,20 7,22 9,22 11,21 14,18 16,15 18,11 19,8 20,4 20,2 19,1 18,1 17,2 16,4 16,6 17,9 19,11 21,12",
                "o": 11
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,7 8,22 M18,1 L8,22 M18,1 L16,22 M30,1 L28,2 25,5 22,9 19,15 16,22",
                "o": 13
            },
            {
                "d": "M8,7 L6,7 5,6 5,4 6,2 8,1 10,1 12,2 13,4 13,7 11,16 11,19 12,21 14,22 16,22 18,21 19,19 19,17 18,16 16,16 M23,4 L23,2 22,1 20,1 18,2 16,4 14,7 10,16 8,19 6,21 4,22 2,22 1,21 1,19",
                "o": 12
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,6 8,10 7,13 6,17 6,19 7,21 8,22 10,22 12,21 14,19 16,16 17,14 19,8 M21,1 L19,8 16,18 14,24 12,29 10,33 8,34 7,33 7,31 8,28 10,25 13,22 16,20 21,17",
                "o": 11
            },
            {
                "d": "M13,7 L12,9 11,10 9,11 7,11 6,9 6,7 7,4 9,2 12,1 15,1 17,2 18,4 18,8 17,11 15,15 12,18 8,21 6,22 3,22 2,21 2,19 3,18 6,18 8,19 9,20 10,22 10,25 9,28 8,30 6,33 4,34 3,33 3,31 4,28 6,25 9,22 12,20 18,17",
                "o": 11
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M8,-1 L0,13 M8,-1 L16,13",
                "o": 8
            },
            {
                "d": "M0,29 L20,29",
                "o": 9
            },
            {
                "d": "M5,6 L3,8 3,10 4,11 5,10 4,9 3,10",
                "o": 4
            },
            {
                "d": "M9,16 L8,14 6,13 4,13 2,14 1,15 0,17 0,19 1,21 3,22 5,22 7,21 8,19 10,13 9,18 9,21 10,22 11,22 13,21 14,20 16,17",
                "o": 10
            },
            {
                "d": "M0,17 L2,14 5,9 6,7 7,4 7,2 6,1 4,2 3,4 2,8 1,15 1,21 2,22 3,22 5,21 7,19 8,16 8,13 9,17 10,18 12,18 14,17",
                "o": 9
            },
            {
                "d": "M7,15 L7,14 6,13 4,13 2,14 1,15 0,17 0,19 1,21 3,22 6,22 9,20 11,17",
                "o": 6
            },
            {
                "d": "M9,16 L8,14 6,13 4,13 2,14 1,15 0,17 0,19 1,21 3,22 5,22 7,21 8,19 14,1 M10,13 L9,18 9,21 10,22 11,22 13,21 14,20 16,17",
                "o": 10
            },
            {
                "d": "M1,20 L3,19 4,18 5,16 5,14 4,13 3,13 1,14 0,16 0,19 1,21 3,22 5,22 7,21 8,20 10,17",
                "o": 6
            },
            {
                "d": "M0,17 L4,12 6,9 7,7 8,4 8,2 7,1 5,2 4,4 2,12 -1,21 -4,28 -5,31 -5,33 -4,34 -2,33 -1,30 0,21 1,22 3,22 5,21 6,20 8,17",
                "o": 5
            },
            {
                "d": "M9,16 L8,14 6,13 4,13 2,14 1,15 0,17 0,19 1,21 3,22 5,22 7,21 8,20 M10,13 L8,20 4,31 3,33 1,34 0,33 0,31 1,28 4,25 7,23 9,22 12,20 15,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 5,9 6,7 7,4 7,2 6,1 4,2 3,4 2,8 1,14 0,22 M0,22 L1,19 2,17 4,14 6,13 8,13 9,14 9,16 8,19 8,21 9,22 10,22 12,21 13,20 15,17",
                "o": 10
            },
            {
                "d": "M3,8 L3,9 4,9 4,8 3,8 M0,17 L2,13 0,19 0,21 1,22 2,22 4,21 5,20 7,17",
                "o": 5
            },
            {
                "d": "M3,8 L3,9 4,9 4,8 3,8 M0,17 L2,13 -4,31 -5,33 -7,34 -8,33 -8,31 -7,28 -4,25 -1,23 1,22 4,20 7,17",
                "o": 5
            },
            {
                "d": "M0,17 L2,14 5,9 6,7 7,4 7,2 6,1 4,2 3,4 2,8 1,14 0,22 M0,22 L1,19 2,17 4,14 6,13 8,13 9,14 9,16 7,17 4,17 M4,17 L6,18 7,21 8,22 9,22 11,21 12,20 14,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 5,9 6,7 7,4 7,2 6,1 4,2 3,4 2,8 1,15 1,21 2,22 3,22 5,21 6,20 8,17",
                "o": 5
            },
            {
                "d": "M0,17 L2,14 4,13 5,14 5,15 4,19 3,22 M4,19 L5,17 7,14 9,13 11,13 12,14 12,15 11,19 10,22 M11,19 L12,17 14,14 16,13 18,13 19,14 19,16 18,19 18,21 19,22 20,22 22,21 23,20 25,17",
                "o": 12
            },
            {
                "d": "M0,17 L2,14 4,13 5,14 5,15 4,19 3,22 M4,19 L5,17 7,14 9,13 11,13 12,14 12,16 11,19 11,21 12,22 13,22 15,21 16,20 18,17",
                "o": 10
            },
            {
                "d": "M6,13 L4,13 2,14 1,15 0,17 0,19 1,21 3,22 5,22 7,21 8,20 9,18 9,16 8,14 6,13 5,14 5,16 6,18 8,19 11,19 13,18 14,17",
                "o": 8
            },
            {
                "d": "M0,17 L2,14 3,12 2,16 -4,34 M2,16 L3,14 5,13 7,13 9,14 10,16 10,18 9,20 8,21 6,22 M2,21 L4,22 7,22 10,21 12,20 15,17",
                "o": 8
            },
            {
                "d": "M9,16 L8,14 6,13 4,13 2,14 1,15 0,17 0,19 1,21 3,22 5,22 7,21 M10,13 L9,16 7,21 4,28 3,31 3,33 4,34 6,33 7,30 7,23 9,22 12,20 15,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 3,12 3,14 6,14 7,15 7,17 6,20 6,21 7,22 8,22 10,21 11,20 13,17",
                "o": 8
            },
            {
                "d": "M0,17 L2,14 3,12 3,14 5,17 6,19 6,21 4,22 M0,21 L2,22 6,22 8,21 9,20 11,17",
                "o": 7
            },
            {
                "d": "M0,17 L2,14 4,10 M7,1 L1,19 1,21 2,22 4,22 6,21 7,20 9,17 M1,9 L8,9",
                "o": 6
            },
            {
                "d": "M0,17 L2,13 0,19 0,21 1,22 3,22 5,21 7,19 9,16 M10,13 L8,19 8,21 9,22 10,22 12,21 13,20 15,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,13 1,18 1,21 2,22 3,22 6,21 8,19 9,16 9,13 M9,13 L10,17 11,18 13,18 15,17",
                "o": 9
            },
            {
                "d": "M3,13 L1,15 0,18 0,20 1,22 3,22 5,21 7,19 M9,13 L7,19 7,21 8,22 10,22 12,21 14,19 15,16 15,13 M15,13 L16,17 17,18 19,18 21,17",
                "o": 12
            },
            {
                "d": "M0,17 L2,14 4,13 6,13 7,14 7,21 8,22 11,22 14,20 16,17 M13,14 L12,13 10,13 9,14 5,21 4,22 2,22 1,21",
                "o": 8
            },
            {
                "d": "M0,17 L2,13 0,19 0,21 1,22 3,22 5,21 7,19 9,16 M10,13 L4,31 3,33 1,34 0,33 0,31 1,28 4,25 7,23 9,22 12,20 15,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 4,13 6,13 8,15 8,17 7,19 5,21 2,22 4,23 5,25 5,28 4,31 3,33 1,34 0,33 0,31 1,28 4,25 7,23 11,20 14,17",
                "o": 8
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "cyrillic": {
        "name": "Cyrillic",
        "chars": [
            {
                "d": "M5,1 L4,3 5,15 6,3 5,1 M5,3 L5,9 M5,20 L4,21 5,22 6,21 5,20",
                "o": 5
            },
            {
                "d": "M4,1 L3,8 M5,1 L3,8 M12,1 L11,8 M13,1 L11,8",
                "o": 8
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L9,1 M6,11 L13,11 16,12 17,13 18,15 18,18 17,20 16,21 13,22 2,22 M13,11 L15,12 16,13 17,15 17,18 16,20 15,21 13,22 M24,1 L24,22 M25,1 L25,22 M21,1 L28,1 M21,22 L28,22",
                "o": 15
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M16,8 L16,22 M17,8 L17,22 M2,8 L9,8 M13,8 L20,8 M2,22 L20,22 20,27 19,22",
                "o": 11
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M2,8 L9,8 M6,15 L10,15 13,16 14,18 14,19 13,21 10,22 2,22 M10,15 L12,16 13,18 13,19 12,21 10,22 M20,8 L20,22 M21,8 L21,22 M17,8 L24,8 M17,22 L24,22",
                "o": 13
            },
            {
                "d": "M4,1 L3,8 M5,1 L3,8",
                "o": 4
            },
            {
                "d": "M10,-3 L8,-1 6,2 4,6 3,11 3,15 4,20 6,24 8,27 10,29 11,29 M10,-3 L11,-3 9,-1 7,2 5,6 4,11 4,15 5,20 7,24 9,27 11,29",
                "o": 7
            },
            {
                "d": "M3,-3 L5,-1 7,2 9,6 10,11 10,15 9,20 7,24 5,27 3,29 4,29 M3,-3 L4,-3 6,-1 8,2 10,6 11,11 11,15 10,20 8,24 6,27 4,29",
                "o": 7
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M20,-3 L2,29",
                "o": 11
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1 M9,1 L7,2 6,3 5,5 4,10 4,13 5,18 6,20 7,21 9,22 M11,22 L13,21 14,20 15,18 16,13 16,10 15,5 14,3 13,2 11,1",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22 M10,2 L10,22 M6,22 L15,22",
                "o": 10
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 8,1 12,1 15,2 16,3 17,5 17,7 16,9 13,11 8,13 6,14 4,16 3,19 3,22 M12,1 L14,2 15,3 16,5 16,7 15,9 12,11 8,13 M3,20 L4,19 6,19 11,21 14,21 16,20 17,19 M6,19 L11,22 15,22 16,21 17,19 17,17",
                "o": 10
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 8,1 12,1 15,2 16,4 16,7 15,9 12,10 9,10 M12,1 L14,2 15,4 15,7 14,9 12,10 M12,10 L14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M15,12 L16,15 16,18 15,20 14,21 12,22",
                "o": 10
            },
            {
                "d": "M12,3 L12,22 M13,1 L13,22 M13,1 L2,16 18,16 M9,22 L16,22",
                "o": 10
            },
            {
                "d": "M5,1 L3,11 M3,11 L5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M11,8 L13,9 15,11 16,14 16,16 15,19 13,21 11,22 M5,1 L15,1 M5,2 L10,2 15,1",
                "o": 10
            },
            {
                "d": "M15,4 L14,5 15,6 16,5 16,4 15,2 13,1 10,1 7,2 5,4 4,6 3,10 3,16 4,19 6,21 9,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 4,15 M10,1 L8,2 6,4 5,6 4,10 4,16 5,19 7,21 9,22 M11,22 L13,21 15,19 16,16 16,15 15,12 13,10 11,9",
                "o": 10
            },
            {
                "d": "M3,1 L3,7 M3,5 L4,3 6,1 8,1 13,4 15,4 16,3 17,1 M4,3 L6,2 8,2 13,4 M17,1 L17,4 16,7 12,12 11,14 10,17 10,22 M16,7 L11,12 10,14 9,17 9,22",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,7 5,9 8,10 12,10 15,9 16,7 16,4 15,2 12,1 8,1 M8,1 L6,2 5,4 5,7 6,9 8,10 M12,10 L14,9 15,7 15,4 14,2 12,1 M8,10 L5,11 4,12 3,14 3,18 4,20 5,21 8,22 12,22 15,21 16,20 17,18 17,14 16,12 15,11 12,10 M8,10 L6,11 5,12 4,14 4,18 5,20 6,21 8,22 M12,22 L14,21 15,20 16,18 16,14 15,12 14,11 12,10",
                "o": 10
            },
            {
                "d": "M16,8 L15,11 13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 11,1 14,2 16,4 17,7 17,13 16,17 15,19 13,21 10,22 7,22 5,21 4,19 4,18 5,17 6,18 5,19 M9,14 L7,13 5,11 4,8 4,7 5,4 7,2 9,1 M11,1 L13,2 15,4 16,7 16,13 15,17 14,19 12,21 10,22",
                "o": 10
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 7,1 10,1 13,2 14,3 15,5 15,7 14,9 13,10 9,12 9,15 M10,1 L12,2 13,3 14,5 14,7 13,9 11,11 M9,20 L8,21 9,22 10,21 9,20",
                "o": 9
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M10,1 L3,22 M10,1 L17,22 M10,4 L16,22 M5,16 L14,16 M1,22 L7,22 M13,22 L19,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L18,1 18,7 17,1 M6,11 L14,11 17,12 18,13 19,15 19,18 18,20 17,21 14,22 2,22 M14,11 L16,12 17,13 18,15 18,18 17,20 16,21 14,22",
                "o": 11
            },
            {
                "d": "M4,4 L3,1 3,7 4,4 6,2 9,1 11,1 14,2 16,4 17,6 18,9 18,14 17,17 16,19 14,21 11,22 8,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M11,1 L13,2 15,4 16,6 17,9 17,14 16,17 15,19 13,21 11,22 M8,11 L17,11",
                "o": 11
            },
            {
                "d": "M8,1 L8,7 7,15 6,19 5,21 4,22 M18,1 L18,22 M19,1 L19,22 M5,1 L22,1 M1,22 L22,22 M1,22 L1,29 M2,22 L1,29 M21,22 L22,29 M22,22 L22,29",
                "o": 12
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M18,1 L18,22 M19,1 L19,22 M2,1 L9,1 M15,1 L22,1 M18,3 L6,20 M2,22 L9,22 M15,22 L22,22",
                "o": 12
            },
            {
                "d": "M12,1 L12,22 M13,1 L13,22 M9,1 L16,1 M10,4 L6,5 4,7 3,10 3,13 4,16 6,18 10,19 15,19 19,18 21,16 22,13 22,10 21,7 19,5 15,4 10,4 M10,4 L7,5 5,7 4,10 4,13 5,16 7,18 10,19 M15,19 L18,18 20,16 21,13 21,10 20,7 18,5 15,4 M9,22 L16,22",
                "o": 13
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L17,1 17,7 16,1 M2,22 L9,22",
                "o": 9
            },
            {
                "d": "M15,1 L15,22 M16,1 L16,22 M12,1 L19,1 M4,2 L5,3 4,4 3,3 3,2 4,1 5,1 6,2 7,4 8,8 9,10 11,11 20,11 22,10 23,8 24,4 25,2 26,1 27,1 28,2 28,3 27,4 26,3 27,2 M11,11 L9,12 8,14 7,19 6,21 5,22 M11,11 L10,12 9,14 8,19 7,21 6,22 4,22 3,21 2,19 M20,11 L22,12 23,14 24,19 25,21 26,22 M20,11 L21,12 22,14 23,19 24,21 25,22 27,22 28,21 29,19 M12,22 L19,22",
                "o": 16
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M18,1 L18,22 M19,1 L19,22 M2,1 L9,1 M15,1 L22,1 M18,3 L6,20 M2,22 L9,22 M15,22 L22,22",
                "o": 12
            },
            {
                "d": "M5,1 L5,12 6,14 9,15 12,15 15,14 17,12 M6,1 L6,12 7,14 9,15 M17,1 L17,22 M18,1 L18,22 M2,1 L9,1 M14,1 L21,1 M14,22 L21,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L9,1 M6,11 L13,11 15,10 16,8 17,4 18,2 19,1 20,1 21,2 21,3 20,4 19,3 20,2 M13,11 L15,12 16,14 17,19 18,21 19,22 M13,11 L14,12 15,14 16,19 17,21 18,22 20,22 21,21 22,19 M2,22 L9,22",
                "o": 12
            },
            {
                "d": "M8,1 L8,7 7,15 6,19 5,21 4,22 3,22 2,21 2,20 3,19 4,20 3,21 M19,1 L19,22 M20,1 L20,22 M5,1 L23,1 M16,22 L23,22",
                "o": 12
            },
            {
                "d": "M5,1 L5,22 M6,1 L12,19 M5,1 L12,22 M19,1 L12,22 M19,1 L19,22 M20,1 L20,22 M2,1 L6,1 M19,1 L23,1 M2,22 L8,22 M16,22 L23,22",
                "o": 13
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M18,1 L18,22 M19,1 L19,22 M2,1 L9,1 M15,1 L22,1 M6,11 L18,11 M2,22 L9,22 M15,22 L22,22",
                "o": 12
            },
            {
                "d": "M10,1 L7,2 5,4 4,6 3,10 3,13 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 19,13 19,10 18,6 17,4 15,2 12,1 10,1 M10,1 L8,2 6,4 5,6 4,10 4,13 5,17 6,19 8,21 10,22 M12,22 L14,21 16,19 17,17 18,13 18,10 17,6 16,4 14,2 12,1",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M18,1 L18,22 M19,1 L19,22 M2,1 L22,1 M2,22 L9,22 M15,22 L22,22",
                "o": 12
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M16,1 L16,22 M17,1 L17,22 M27,1 L27,22 M28,1 L28,22 M2,1 L9,1 M13,1 L20,1 M24,1 L31,1 M2,22 L31,22",
                "o": 17
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L14,1 17,2 18,3 19,5 19,8 18,10 17,11 14,12 6,12 M14,1 L16,2 17,3 18,5 18,8 17,10 16,11 14,12 M2,22 L9,22",
                "o": 11
            },
            {
                "d": "M17,4 L18,7 18,1 17,4 15,2 12,1 10,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 M10,1 L8,2 6,4 5,6 4,9 4,14 5,17 6,19 8,21 10,22",
                "o": 10
            },
            {
                "d": "M9,1 L9,22 M10,1 L10,22 M3,1 L2,7 2,1 17,1 17,7 16,1 M6,22 L13,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L9,1 M2,22 L9,22 M19,1 L16,2 14,4 13,6 12,10 12,13 13,17 14,19 16,21 19,22 21,22 24,21 26,19 27,17 28,13 28,10 27,6 26,4 24,2 21,1 19,1 M19,1 L17,2 15,4 14,6 13,10 13,13 14,17 15,19 17,21 19,22 M21,22 L23,21 25,19 26,17 27,13 27,10 26,6 25,4 23,2 21,1 M6,11 L12,11",
                "o": 16
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L14,1 17,2 18,3 19,5 19,7 18,9 17,10 14,11 M14,1 L16,2 17,3 18,5 18,7 17,9 16,10 14,11 M6,11 L14,11 17,12 18,13 19,15 19,18 18,20 17,21 14,22 2,22 M14,11 L16,12 17,13 18,15 18,18 17,20 16,21 14,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M16,1 L16,22 M17,1 L17,22 M27,1 L27,22 M28,1 L28,22 M2,1 L9,1 M13,1 L20,1 M24,1 L31,1 M2,22 L31,22 M30,22 L31,29 M31,22 L31,29",
                "o": 17
            },
            {
                "d": "M3,1 L16,22 M4,1 L17,22 M17,1 L3,22 M1,1 L7,1 M13,1 L19,1 M1,22 L7,22 M13,22 L19,22",
                "o": 10
            },
            {
                "d": "M3,1 L10,17 M4,1 L11,17 M18,1 L11,17 9,20 8,21 6,22 5,22 4,21 4,20 5,19 6,20 5,21 M1,1 L7,1 M14,1 L20,1",
                "o": 11
            },
            {
                "d": "M4,4 L3,1 3,7 4,4 6,2 8,1 12,1 15,2 16,4 16,7 15,9 12,10 9,10 M12,1 L14,2 15,4 15,7 14,9 12,10 M12,10 L14,11 16,13 17,15 17,18 16,20 15,21 12,22 7,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M15,12 L16,15 16,18 15,20 14,21 12,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M12,7 L12,15 M2,1 L18,1 18,7 17,1 M6,11 L12,11 M2,22 L18,22 18,16 17,22",
                "o": 10
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M10,1 L10,22 M11,1 L11,22 M3,1 L2,7 2,1 14,1 M11,11 L18,11 21,12 22,13 23,15 23,18 22,20 21,21 18,22 7,22 M18,11 L20,12 21,13 22,15 22,18 21,20 20,21 18,22",
                "o": 14
            },
            {
                "d": "M16,1 L16,22 M17,1 L17,22 M20,1 L8,1 5,2 4,3 3,5 3,7 4,9 5,10 8,11 16,11 M8,1 L6,2 5,3 4,5 4,7 5,9 6,10 8,11 M11,11 L9,12 8,13 5,20 4,21 3,21 2,20 M9,12 L8,14 6,21 5,22 3,22 2,20 2,19 M13,22 L20,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L9,1 M6,11 L13,11 16,12 17,13 18,15 18,18 17,20 16,21 13,22 2,22 M13,11 L15,12 16,13 17,15 17,18 16,20 15,21 13,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M18,1 L18,22 M19,1 L19,22 M2,1 L9,1 M15,1 L22,1 M2,22 L22,22 M21,22 L22,29 M22,22 L22,29",
                "o": 12
            },
            {
                "d": "M5,10 L5,11 4,11 4,10 5,9 7,8 11,8 13,9 14,10 15,12 15,19 16,21 17,22 M14,10 L14,19 15,21 17,22 18,22 M14,12 L13,13 7,14 4,15 3,17 3,19 4,21 7,22 10,22 12,21 14,19 M7,14 L5,15 4,17 4,19 5,21 7,22",
                "o": 11
            },
            {
                "d": "M16,1 L15,2 9,4 6,6 4,9 3,12 3,16 4,19 6,21 9,22 11,22 14,21 16,19 17,16 17,14 16,11 14,9 11,8 9,8 6,9 4,11 3,14 M16,1 L15,3 13,4 9,5 6,7 4,9 M9,8 L7,9 5,11 4,14 4,16 5,19 7,21 9,22 M11,22 L13,21 15,19 16,16 16,14 15,11 13,9 11,8",
                "o": 10
            },
            {
                "d": "M4,4 L3,1 3,7 4,4 6,2 9,1 11,1 14,2 16,4 17,6 18,9 18,14 17,17 16,19 14,21 11,22 8,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M11,1 L13,2 15,4 16,6 17,9 17,14 16,17 15,19 13,21 11,22 M8,11 L17,11",
                "o": 11
            },
            {
                "d": "M8,8 L8,12 7,18 6,21 5,22 M17,8 L17,22 M18,8 L18,22 M5,8 L21,8 M3,22 L2,27 2,22 21,22 21,27 20,22",
                "o": 11
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M16,8 L16,22 M17,8 L17,22 M2,8 L9,8 M13,8 L20,8 M2,22 L9,22 M13,22 L20,22 M16,9 L6,21 M8,2 L8,1 7,1 7,2 8,4 10,5 12,5 14,4 15,2",
                "o": 11
            },
            {
                "d": "M10,1 L10,29 M11,1 L11,29 M7,1 L11,1 M10,11 L9,9 8,8 6,8 4,9 3,12 3,18 4,21 6,22 8,22 9,21 10,19 M6,8 L5,9 4,12 4,18 5,21 6,22 M15,8 L16,9 17,12 17,18 16,21 15,22 M11,11 L12,9 13,8 15,8 17,9 18,12 18,18 17,21 15,22 13,22 12,21 11,19 M7,29 L14,29",
                "o": 11
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M2,8 L16,8 16,13 15,8 M2,22 L9,22",
                "o": 8
            },
            {
                "d": "M13,8 L13,22 M14,8 L14,22 M10,8 L17,8 M5,9 L4,10 3,9 4,8 5,8 6,9 8,13 9,14 11,15 16,15 18,14 19,13 21,9 22,8 23,8 24,9 23,10 22,9 M11,15 L9,16 8,17 6,21 5,22 M11,15 L9,17 7,21 6,22 4,22 3,21 2,19 M16,15 L18,16 19,17 21,21 22,22 M16,15 L18,17 20,21 21,22 23,22 24,21 25,19 M10,22 L17,22",
                "o": 14
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M16,8 L16,22 M17,8 L17,22 M2,8 L9,8 M13,8 L20,8 M2,22 L9,22 M13,22 L20,22 M16,9 L6,21",
                "o": 11
            },
            {
                "d": "M5,8 L5,15 6,17 9,18 11,18 14,17 16,15 M6,8 L6,15 7,17 9,18 M16,8 L16,22 M17,8 L17,22 M2,8 L9,8 M13,8 L20,8 M13,22 L20,22",
                "o": 11
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M2,8 L9,8 M6,15 L8,15 11,14 12,13 14,9 15,8 16,8 17,9 16,10 15,9 M8,15 L11,16 12,17 14,21 15,22 M8,15 L10,16 11,17 13,21 14,22 16,22 17,21 18,19 M2,22 L9,22",
                "o": 10
            },
            {
                "d": "M7,8 L7,12 6,18 5,21 4,22 3,22 2,21 3,20 4,21 M16,8 L16,22 M17,8 L17,22 M4,8 L20,8 M13,22 L20,22",
                "o": 11
            },
            {
                "d": "M5,8 L5,22 M5,8 L11,22 M6,8 L11,20 M17,8 L11,22 M17,8 L17,22 M18,8 L18,22 M2,8 L6,8 M17,8 L21,8 M2,22 L8,22 M14,22 L21,22",
                "o": 12
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M16,8 L16,22 M17,8 L17,22 M2,8 L9,8 M13,8 L20,8 M6,15 L16,15 M2,22 L9,22 M13,22 L20,22",
                "o": 11
            },
            {
                "d": "M9,8 L6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 14,21 16,19 17,16 17,14 16,11 14,9 11,8 9,8 M9,8 L7,9 5,11 4,14 4,16 5,19 7,21 9,22 M11,22 L13,21 15,19 16,16 16,14 15,11 13,9 11,8",
                "o": 10
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M16,8 L16,22 M17,8 L17,22 M2,8 L20,8 M2,22 L9,22 M13,22 L20,22",
                "o": 11
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M15,8 L15,22 M16,8 L16,22 M25,8 L25,22 M26,8 L26,22 M2,8 L9,8 M12,8 L19,8 M22,8 L29,8 M2,22 L29,22",
                "o": 16
            },
            {
                "d": "M5,8 L5,29 M6,8 L6,29 M6,11 L8,9 10,8 12,8 15,9 17,11 18,14 18,16 17,19 15,21 12,22 10,22 8,21 6,19 M12,8 L14,9 16,11 17,14 17,16 16,19 14,21 12,22 M2,8 L6,8 M2,29 L9,29",
                "o": 10
            },
            {
                "d": "M15,11 L14,12 15,13 16,12 16,11 14,9 12,8 9,8 6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 14,21 16,19 M9,8 L7,9 5,11 4,14 4,16 5,19 7,21 9,22",
                "o": 9
            },
            {
                "d": "M9,8 L9,22 M10,8 L10,22 M4,8 L3,13 3,8 16,8 16,13 15,8 M6,22 L13,22",
                "o": 10
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M2,8 L9,8 M2,22 L9,22 M18,8 L15,9 13,11 12,14 12,16 13,19 15,21 18,22 20,22 23,21 25,19 26,16 26,14 25,11 23,9 20,8 18,8 M18,8 L16,9 14,11 13,14 13,16 14,19 16,21 18,22 M20,22 L22,21 24,19 25,16 25,14 24,11 22,9 20,8 M6,15 L12,15",
                "o": 15
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M2,8 L13,8 16,9 17,11 17,12 16,14 13,15 M13,8 L15,9 16,11 16,12 15,14 13,15 M6,15 L13,15 16,16 17,18 17,19 16,21 13,22 2,22 M13,15 L15,16 16,18 16,19 15,21 13,22",
                "o": 10
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M15,8 L15,22 M16,8 L16,22 M25,8 L25,22 M26,8 L26,22 M2,8 L9,8 M12,8 L19,8 M22,8 L29,8 M2,22 L29,22 29,27 28,22",
                "o": 16
            },
            {
                "d": "M4,8 L15,22 M5,8 L16,22 M16,8 L4,22 M2,8 L8,8 M12,8 L18,8 M2,22 L8,22 M12,22 L18,22",
                "o": 10
            },
            {
                "d": "M3,8 L9,22 M4,8 L9,20 M15,8 L9,22 7,26 5,28 3,29 2,29 1,28 2,27 3,28 M1,8 L7,8 M11,8 L17,8",
                "o": 9
            },
            {
                "d": "M4,10 L3,8 3,12 4,10 5,9 7,8 11,8 14,9 15,11 15,12 14,14 11,15 M11,8 L13,9 14,11 14,12 13,14 11,15 M8,15 L11,15 14,16 15,18 15,19 14,21 11,22 7,22 4,21 3,19 3,18 4,17 5,18 4,19 M11,15 L13,16 14,18 14,19 13,21 11,22",
                "o": 9
            },
            {
                "d": "M4,14 L16,14 16,12 15,10 14,9 12,8 9,8 6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 14,21 16,19 M15,14 L15,11 14,9 M9,8 L7,9 5,11 4,14 4,16 5,19 7,21 9,22",
                "o": 9
            },
            {
                "d": "M9,8 L9,22 M10,8 L10,22 M4,8 L3,13 3,8 13,8 M10,15 L14,15 17,16 18,18 18,19 17,21 14,22 6,22 M14,15 L16,16 17,18 17,19 16,21 14,22",
                "o": 11
            },
            {
                "d": "M15,8 L15,22 M16,8 L16,22 M19,8 L8,8 5,9 4,11 4,12 5,14 8,15 15,15 M8,8 L6,9 5,11 5,12 6,14 8,15 M13,15 L10,16 9,17 7,21 6,22 M13,15 L11,16 10,17 8,21 7,22 5,22 4,21 3,19 M12,22 L19,22",
                "o": 10
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M2,8 L9,8 M6,15 L10,15 13,16 14,18 14,19 13,21 10,22 2,22 M10,15 L12,16 13,18 13,19 12,21 10,22",
                "o": 9
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            }
        ]
    },
    "futural": {
        "name": "Sans 1-stroke",
        "chars": [
            {
                "d": "M5,1 L5,15 M5,20 L4,21 5,22 6,21 5,20",
                "o": 5
            },
            {
                "d": "M4,1 L4,8 M12,1 L12,8",
                "o": 8
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M8,-3 L8,26 M12,-3 L12,26 M17,4 L15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 16,14 17,16 17,19 15,21 12,22 8,22 5,21 3,19",
                "o": 10
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M23,10 L23,9 22,8 21,8 20,9 19,11 17,16 15,19 13,21 11,22 7,22 5,21 4,20 3,18 3,16 4,14 5,13 12,9 13,8 14,6 14,4 13,2 11,1 9,2 8,4 8,6 9,9 11,12 16,19 18,21 20,22 22,22 23,21 23,20",
                "o": 13
            },
            {
                "d": "M5,3 L4,2 5,1 6,2 6,4 5,6 4,7",
                "o": 5
            },
            {
                "d": "M11,-3 L9,-1 7,2 5,6 4,11 4,15 5,20 7,24 9,27 11,29",
                "o": 7
            },
            {
                "d": "M3,-3 L5,-1 7,2 9,6 10,11 10,15 9,20 7,24 5,27 3,29",
                "o": 7
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M20,-3 L2,29",
                "o": 11
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22",
                "o": 10
            },
            {
                "d": "M4,6 L4,5 5,3 6,2 8,1 12,1 14,2 15,3 16,5 16,7 15,9 13,12 3,22 17,22",
                "o": 10
            },
            {
                "d": "M5,1 L16,1 10,9 13,9 15,10 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M13,1 L3,15 18,15 M13,1 L13,22",
                "o": 10
            },
            {
                "d": "M15,1 L5,1 4,10 5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M16,4 L15,2 12,1 10,1 7,2 5,5 4,10 4,15 5,19 7,21 10,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 4,15",
                "o": 10
            },
            {
                "d": "M17,1 L7,22 M3,1 L17,1",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,6 5,8 7,9 11,10 14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,15 4,13 6,11 9,10 13,9 15,8 16,6 16,4 15,2 12,1 8,1",
                "o": 10
            },
            {
                "d": "M16,8 L15,11 13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 10,1 13,2 15,4 16,8 16,13 15,18 13,21 10,22 8,22 5,21 4,19",
                "o": 10
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M3,6 L3,5 4,3 5,2 7,1 11,1 13,2 14,3 15,5 15,7 14,9 13,10 9,12 9,15 M9,20 L8,21 9,22 10,21 9,20",
                "o": 9
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M9,1 L1,22 M9,1 L17,22 M4,15 L14,15",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,7 17,9 16,10 13,11 M4,11 L13,11 16,12 17,13 18,15 18,18 17,20 16,21 13,22 4,22",
                "o": 10
            },
            {
                "d": "M18,6 L17,4 15,2 13,1 9,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L11,1 14,2 16,4 17,6 18,9 18,14 17,17 16,19 14,21 11,22 4,22",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,1 L17,1 M4,11 L12,11 M4,22 L17,22",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 M4,1 L17,1 M4,11 L12,11",
                "o": 8
            },
            {
                "d": "M18,6 L17,4 15,2 13,1 9,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 18,14 M13,14 L18,14",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M18,1 L18,22 M4,11 L18,11",
                "o": 11
            },
            {
                "d": "M4,1 L4,22",
                "o": 4
            },
            {
                "d": "M12,1 L12,17 11,20 10,21 8,22 6,22 4,21 3,20 2,17 2,15",
                "o": 8
            },
            {
                "d": "M4,1 L4,22 M18,1 L4,15 M9,10 L18,22",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,22 L16,22",
                "o": 7
            },
            {
                "d": "M4,1 L4,22 M4,1 L12,22 M20,1 L12,22 M20,1 L20,22",
                "o": 12
            },
            {
                "d": "M4,1 L4,22 M4,1 L18,22 M18,1 L18,22",
                "o": 11
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 19,14 19,9 18,6 17,4 15,2 13,1 9,1",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,8 17,10 16,11 13,12 4,12",
                "o": 10
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 19,14 19,9 18,6 17,4 15,2 13,1 9,1 M12,18 L18,24",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,7 17,9 16,10 13,11 4,11 M11,11 L18,22",
                "o": 10
            },
            {
                "d": "M17,4 L15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 16,14 17,16 17,19 15,21 12,22 8,22 5,21 3,19",
                "o": 10
            },
            {
                "d": "M8,1 L8,22 M1,1 L15,1",
                "o": 8
            },
            {
                "d": "M4,1 L4,16 5,19 7,21 10,22 12,22 15,21 17,19 18,16 18,1",
                "o": 11
            },
            {
                "d": "M1,1 L9,22 M17,1 L9,22",
                "o": 9
            },
            {
                "d": "M2,1 L7,22 M12,1 L7,22 M12,1 L17,22 M22,1 L17,22",
                "o": 12
            },
            {
                "d": "M3,1 L17,22 M17,1 L3,22",
                "o": 10
            },
            {
                "d": "M1,1 L9,11 9,22 M17,1 L9,11",
                "o": 9
            },
            {
                "d": "M17,1 L3,22 M3,1 L17,1 M3,22 L17,22",
                "o": 10
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M8,-1 L0,13 M8,-1 L16,13",
                "o": 8
            },
            {
                "d": "M0,29 L18,29",
                "o": 9
            },
            {
                "d": "M5,6 L3,8 3,10 4,11 5,10 4,9 3,10",
                "o": 4
            },
            {
                "d": "M15,8 L15,22 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,11 L6,9 8,8 11,8 13,9 15,11 16,14 16,16 15,19 13,21 11,22 8,22 6,21 4,19",
                "o": 9
            },
            {
                "d": "M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 9
            },
            {
                "d": "M15,1 L15,22 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M3,14 L15,14 15,12 14,10 13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 9
            },
            {
                "d": "M10,1 L8,1 6,2 5,5 5,22 M2,8 L9,8",
                "o": 7
            },
            {
                "d": "M15,8 L15,24 14,27 13,28 11,29 8,29 6,28 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,12 L7,9 9,8 12,8 14,9 15,12 15,22",
                "o": 10
            },
            {
                "d": "M3,1 L4,2 5,1 4,0 3,1 M4,8 L4,22",
                "o": 4
            },
            {
                "d": "M5,1 L6,2 7,1 6,0 5,1 M6,8 L6,25 5,28 3,29 1,29",
                "o": 5
            },
            {
                "d": "M4,1 L4,22 M14,8 L4,18 M8,14 L15,22",
                "o": 8
            },
            {
                "d": "M4,1 L4,22",
                "o": 4
            },
            {
                "d": "M4,8 L4,22 M4,12 L7,9 9,8 12,8 14,9 15,12 15,22 M15,12 L18,9 20,8 23,8 25,9 26,12 26,22",
                "o": 15
            },
            {
                "d": "M4,8 L4,22 M4,12 L7,9 9,8 12,8 14,9 15,12 15,22",
                "o": 10
            },
            {
                "d": "M8,8 L6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19 16,16 16,14 15,11 13,9 11,8 8,8",
                "o": 10
            },
            {
                "d": "M4,8 L4,29 M4,11 L6,9 8,8 11,8 13,9 15,11 16,14 16,16 15,19 13,21 11,22 8,22 6,21 4,19",
                "o": 9
            },
            {
                "d": "M15,8 L15,29 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M4,8 L4,22 M4,14 L5,11 7,9 9,8 12,8",
                "o": 6
            },
            {
                "d": "M14,11 L13,9 10,8 7,8 4,9 3,11 4,13 6,14 11,15 13,16 14,18 14,19 13,21 10,22 7,22 4,21 3,19",
                "o": 9
            },
            {
                "d": "M5,1 L5,18 6,21 8,22 10,22 M2,8 L9,8",
                "o": 7
            },
            {
                "d": "M4,8 L4,18 5,21 7,22 10,22 12,21 15,18 M15,8 L15,22",
                "o": 10
            },
            {
                "d": "M2,8 L8,22 M14,8 L8,22",
                "o": 8
            },
            {
                "d": "M3,8 L7,22 M11,8 L7,22 M11,8 L15,22 M19,8 L15,22",
                "o": 11
            },
            {
                "d": "M3,8 L14,22 M14,8 L3,22",
                "o": 9
            },
            {
                "d": "M2,8 L8,22 M14,8 L8,22 6,26 4,28 2,29 1,29",
                "o": 8
            },
            {
                "d": "M14,8 L3,22 M3,8 L14,8 M3,22 L14,22",
                "o": 9
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "futuram": {
        "name": "Sans bold",
        "chars": [
            {
                "d": "M5,1 L5,15 6,15 M5,1 L6,1 6,15 M5,19 L4,20 4,21 5,22 6,22 7,21 7,20 6,19 5,19 M5,20 L5,21 6,21 6,20 5,20",
                "o": 6
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8 M14,1 L13,2 13,8 M14,2 L13,8 M14,1 L15,2 13,8",
                "o": 9
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M9,-3 L9,26 10,26 M9,-3 L10,-3 10,26 M14,4 L16,4 14,2 11,1 8,1 5,2 3,4 3,6 4,8 5,9 13,13 14,14 15,16 15,18 14,20 11,21 8,21 6,20 5,19 M14,4 L13,3 11,2 8,2 5,3 4,4 4,6 5,8 13,12 15,14 16,16 16,18 15,20 14,21 11,22 8,22 5,21 3,19 5,19 M15,19 L12,21",
                "o": 10
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M21,9 L20,10 21,11 22,10 22,9 21,8 20,8 19,9 18,11 16,16 14,19 12,21 10,22 7,22 4,21 3,19 3,16 4,14 10,10 12,8 13,6 13,4 12,2 10,1 8,2 7,4 7,6 8,9 10,12 15,19 17,21 20,22 21,22 22,21 22,20 M7,22 L5,21 4,19 4,16 5,14 7,12 M7,6 L8,8 16,19 18,21 20,22",
                "o": 13
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8",
                "o": 5
            },
            {
                "d": "M11,-3 L9,-1 7,2 5,6 4,11 4,15 5,20 7,24 9,27 11,29 M9,-1 L7,3 6,6 5,11 5,15 6,20 7,23 9,27",
                "o": 7
            },
            {
                "d": "M3,-3 L5,-1 7,2 9,6 10,11 10,15 9,20 7,24 5,27 3,29 M5,-1 L7,3 8,6 9,11 9,15 8,20 7,23 5,27",
                "o": 7
            },
            {
                "d": "M8,1 L7,2 9,12 8,13 M8,1 L8,13 M8,1 L9,2 7,12 8,13 M3,4 L4,4 12,10 13,10 M3,4 L13,10 M3,4 L3,5 13,9 13,10 M13,4 L12,4 4,10 3,10 M13,4 L3,10 M13,4 L13,5 3,9 3,10",
                "o": 8
            },
            {
                "d": "M12,4 L12,21 13,21 M12,4 L13,4 13,21 M4,12 L21,12 21,13 M4,12 L4,13 21,13",
                "o": 13
            },
            {
                "d": "M7,21 L6,22 5,22 4,21 4,20 5,19 6,19 7,20 7,23 6,25 4,26 M5,20 L5,21 6,21 6,20 5,20 M6,22 L7,23 M7,21 L6,25",
                "o": 6
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,19 L4,20 4,21 5,22 6,22 7,21 7,20 6,19 5,19 M5,20 L5,21 6,21 6,20 5,20",
                "o": 6
            },
            {
                "d": "M20,-3 L2,29 3,29 M20,-3 L21,-3 3,29",
                "o": 12
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1 M7,2 L5,5 4,10 4,13 5,18 7,21 M6,20 L9,21 11,21 14,20 M13,21 L15,18 16,13 16,10 15,5 13,2 M14,3 L11,2 9,2 6,3",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22 M6,5 L6,6 8,5 10,3 10,22 11,22",
                "o": 10
            },
            {
                "d": "M4,6 L4,5 5,3 6,2 8,1 12,1 14,2 15,3 16,5 16,7 15,9 13,12 4,22 M4,6 L5,6 5,5 6,3 8,2 12,2 14,3 15,5 15,7 14,9 12,12 3,22 M4,21 L17,21 17,22 M3,22 L17,22",
                "o": 10
            },
            {
                "d": "M5,1 L16,1 9,10 M5,1 L5,2 15,2 M15,1 L8,10 M9,9 L11,9 14,10 16,12 17,15 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18 4,18 M8,10 L11,10 14,11 16,14 M12,10 L15,12 16,15 16,16 15,19 12,21 M16,17 L14,20 11,21 8,21 5,20 4,18 M7,21 L4,19",
                "o": 10
            },
            {
                "d": "M13,4 L13,22 14,22 M14,1 L14,22 M14,1 L3,17 18,17 M13,4 L4,17 M4,16 L18,16 18,17",
                "o": 10
            },
            {
                "d": "M5,1 L4,10 M6,2 L5,9 M5,1 L15,1 15,2 M6,2 L15,2 M5,9 L8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18 4,18 M4,10 L5,10 7,9 11,9 14,10 16,13 M12,9 L15,11 16,14 16,16 15,19 12,21 M16,17 L14,20 11,21 8,21 5,20 4,18 M7,21 L4,19",
                "o": 10
            },
            {
                "d": "M14,2 L15,4 16,4 15,2 12,1 10,1 7,2 5,5 4,10 4,15 5,19 7,21 10,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 M15,3 L12,2 10,2 7,3 M8,2 L6,5 5,10 5,15 6,19 9,21 M5,17 L7,20 10,21 11,21 14,20 16,17 M12,21 L15,19 16,16 16,15 15,12 12,10 M16,14 L14,11 11,10 10,10 7,11 5,14 M9,10 L6,12 5,15",
                "o": 10
            },
            {
                "d": "M3,1 L17,1 7,22 M3,1 L3,2 16,2 M16,1 L6,22 7,22",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,6 5,8 6,9 8,10 12,11 14,12 15,13 16,15 16,18 15,20 12,21 8,21 5,20 4,18 4,15 5,13 6,12 8,11 12,10 14,9 15,8 16,6 16,4 15,2 12,1 8,1 M6,2 L5,4 5,6 6,8 8,9 12,10 14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,15 4,13 6,11 8,10 12,9 14,8 15,6 15,4 14,2 M15,3 L12,2 8,2 5,3 M4,19 L7,21 M13,21 L16,19",
                "o": 10
            },
            {
                "d": "M15,11 L13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 10,1 13,2 15,4 16,8 16,13 15,18 13,21 10,22 8,22 5,21 4,19 5,19 6,21 M15,8 L14,11 11,13 M15,9 L13,12 10,13 9,13 6,12 4,9 M8,13 L5,11 4,8 4,7 5,4 8,2 M4,6 L6,3 9,2 10,2 13,3 15,6 M11,2 L14,4 15,8 15,13 14,18 12,21 M13,20 L10,21 8,21 5,20",
                "o": 10
            },
            {
                "d": "M5,8 L4,9 4,10 5,11 6,11 7,10 7,9 6,8 5,8 M5,9 L5,10 6,10 6,9 5,9 M5,19 L4,20 4,21 5,22 6,22 7,21 7,20 6,19 5,19 M5,20 L5,21 6,21 6,20 5,20",
                "o": 6
            },
            {
                "d": "M5,8 L4,9 4,10 5,11 6,11 7,10 7,9 6,8 5,8 M5,9 L5,10 6,10 6,9 5,9 M7,21 L6,22 5,22 4,21 4,20 5,19 6,19 7,20 7,23 6,25 4,26 M5,20 L5,21 6,21 6,20 5,20 M6,22 L7,23 M7,21 L6,25",
                "o": 6
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,8 L21,8 21,9 M4,8 L4,9 21,9 M4,16 L21,16 21,17 M4,16 L4,17 21,17",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M3,6 L3,5 4,3 5,2 8,1 11,1 14,2 15,3 16,5 16,7 15,9 14,10 12,11 9,12 M3,6 L4,6 4,5 5,3 8,2 11,2 14,3 15,5 15,7 14,9 12,10 9,11 M4,4 L7,2 M12,2 L15,4 M15,8 L11,11 M9,11 L9,15 10,15 10,11 M9,19 L8,20 8,21 9,22 10,22 11,21 11,20 10,19 9,19 M9,20 L9,21 10,21 10,20 9,20",
                "o": 10
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M10,1 L2,22 M10,4 L3,22 2,22 M10,4 L17,22 18,22 M10,1 L18,22 M5,16 L15,16 M4,17 L16,17",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M5,2 L5,21 M4,1 L12,1 15,2 16,3 17,5 17,8 16,10 15,11 12,12 M5,2 L12,2 15,3 16,5 16,8 15,10 12,11 M5,11 L12,11 15,12 16,13 17,15 17,18 16,20 15,21 12,22 4,22 M5,12 L12,12 15,13 16,15 16,18 15,20 12,21 5,21",
                "o": 10
            },
            {
                "d": "M18,6 L17,4 15,2 13,1 9,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 M18,6 L17,6 16,4 15,3 13,2 9,2 7,3 5,6 4,9 4,14 5,17 7,20 9,21 13,21 15,20 16,19 17,17 18,17",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M5,2 L5,21 M4,1 L11,1 14,2 16,4 17,6 18,9 18,14 17,17 16,19 14,21 11,22 4,22 M5,2 L11,2 14,3 15,4 16,6 17,9 17,14 16,17 15,19 14,20 11,21 5,21",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M5,2 L5,21 M4,1 L16,1 M5,2 L16,2 16,1 M5,11 L11,11 11,12 M5,12 L11,12 M5,21 L16,21 16,22 M4,22 L16,22",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M5,2 L5,22 4,22 M4,1 L16,1 M5,2 L16,2 16,1 M5,11 L11,11 11,12 M5,12 L11,12",
                "o": 9
            },
            {
                "d": "M18,6 L17,4 15,2 13,1 9,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 18,13 13,13 M18,6 L17,6 16,4 15,3 13,2 9,2 7,3 6,4 5,6 4,9 4,14 5,17 6,19 7,20 9,21 13,21 15,20 16,19 17,17 17,14 13,14 13,13",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L5,1 5,22 4,22 M18,1 L17,1 17,22 18,22 M18,1 L18,22 M5,11 L17,11 M5,12 L17,12",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 5,22 M4,1 L5,1 5,22",
                "o": 5
            },
            {
                "d": "M12,1 L12,17 11,20 9,21 7,21 5,20 4,17 3,17 M12,1 L13,1 13,17 12,20 11,21 9,22 7,22 5,21 4,20 3,17",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 5,22 M4,1 L5,1 5,22 M18,1 L17,1 5,13 M18,1 L5,14 M8,10 L17,22 18,22 M9,10 L18,22",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L5,1 5,21 M5,21 L16,21 16,22 M4,22 L16,22",
                "o": 8
            },
            {
                "d": "M4,1 L4,22 M5,6 L5,22 4,22 M5,6 L12,22 M4,1 L12,19 M20,1 L12,19 M19,6 L12,22 M19,6 L19,22 20,22 M20,1 L20,22",
                "o": 12
            },
            {
                "d": "M4,1 L4,22 M5,4 L5,22 4,22 M5,4 L18,22 M4,1 L17,19 M17,1 L17,19 M17,1 L18,1 18,22",
                "o": 11
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 19,14 19,9 18,6 17,4 15,2 13,1 9,1 M10,2 L7,3 5,6 4,9 4,14 5,17 7,20 10,21 12,21 15,20 17,17 18,14 18,9 17,6 15,3 12,2 10,2",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M5,2 L5,22 4,22 M4,1 L13,1 15,2 16,3 17,5 17,8 16,10 15,11 13,12 5,12 M5,2 L13,2 15,3 16,5 16,8 15,10 13,11 5,11",
                "o": 10
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 19,14 19,9 18,6 17,4 15,2 13,1 9,1 M10,2 L7,3 5,6 4,9 4,14 5,17 7,20 10,21 12,21 15,20 17,17 18,14 18,9 17,6 15,3 12,2 10,2 M12,19 L17,24 18,24 M12,19 L13,19 18,24",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M5,2 L5,22 4,22 M4,1 L12,1 15,2 16,3 17,5 17,8 16,10 15,11 12,12 5,12 M5,2 L12,2 15,3 16,5 16,8 15,10 12,11 5,11 M10,12 L16,22 17,22 M11,12 L17,22",
                "o": 10
            },
            {
                "d": "M17,4 L15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 12,12 14,13 15,14 16,16 16,19 15,20 12,21 8,21 6,20 5,19 3,19 M17,4 L15,4 14,3 12,2 8,2 5,3 4,4 4,6 5,8 7,9 12,11 14,12 16,14 17,16 17,19 15,21 12,22 8,22 5,21 3,19",
                "o": 10
            },
            {
                "d": "M8,2 L8,22 M9,2 L9,22 8,22 M2,1 L15,1 15,2 M2,1 L2,2 15,2",
                "o": 9
            },
            {
                "d": "M4,1 L4,16 5,19 7,21 10,22 12,22 15,21 17,19 18,16 18,1 M4,1 L5,1 5,16 6,19 7,20 10,21 12,21 15,20 16,19 17,16 17,1 18,1",
                "o": 11
            },
            {
                "d": "M2,1 L10,22 M2,1 L3,1 10,19 M18,1 L17,1 10,19 M18,1 L10,22",
                "o": 10
            },
            {
                "d": "M2,1 L8,22 M2,1 L3,1 8,19 M13,1 L8,19 M13,4 L8,22 M13,4 L18,22 M13,1 L18,19 M24,1 L23,1 18,19 M24,1 L18,22",
                "o": 13
            },
            {
                "d": "M3,1 L16,22 17,22 M3,1 L4,1 17,22 M17,1 L16,1 3,22 M17,1 L4,22 3,22",
                "o": 10
            },
            {
                "d": "M2,1 L9,11 9,22 10,22 M2,1 L3,1 10,11 M17,1 L16,1 9,11 M17,1 L10,11 10,22",
                "o": 10
            },
            {
                "d": "M16,1 L3,22 M17,1 L4,22 M3,1 L17,1 M3,1 L3,2 16,2 M4,21 L17,21 17,22 M3,22 L17,22",
                "o": 10
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M3,15 L11,10 19,15 M3,15 L11,11 19,15",
                "o": 11
            },
            {
                "d": "M0,29 L20,29",
                "o": 10
            },
            {
                "d": "M4,1 L9,7 M4,1 L3,2 9,7",
                "o": 6
            },
            {
                "d": "M15,8 L15,22 16,22 M15,8 L16,8 16,22 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19 M15,11 L11,9 8,9 6,10 5,11 4,14 4,16 5,19 6,20 8,21 11,21 15,19",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 5,22 M4,1 L5,1 5,22 M5,11 L7,9 9,8 12,8 14,9 16,11 17,14 17,16 16,19 14,21 12,22 9,22 7,21 5,19 M5,11 L9,9 12,9 14,10 15,11 16,14 16,16 15,19 14,20 12,21 9,21 5,19",
                "o": 10
            },
            {
                "d": "M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19 M15,11 L14,12 13,10 11,9 8,9 6,10 5,11 4,14 4,16 5,19 6,20 8,21 11,21 13,20 14,18 15,19",
                "o": 9
            },
            {
                "d": "M15,1 L15,22 16,22 M15,1 L16,1 16,22 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19 M15,11 L11,9 8,9 6,10 5,11 4,14 4,16 5,19 6,20 8,21 11,21 15,19",
                "o": 10
            },
            {
                "d": "M4,15 L15,15 15,12 14,10 13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19 M4,14 L14,14 14,12 13,10 11,9 8,9 6,10 5,11 4,14 4,16 5,19 6,20 8,21 11,21 13,20 14,18 15,19",
                "o": 9
            },
            {
                "d": "M11,1 L9,1 7,2 6,5 6,22 7,22 M11,1 L11,2 9,2 7,3 M8,2 L7,5 7,22 M3,8 L10,8 10,9 M3,8 L3,9 10,9",
                "o": 8
            },
            {
                "d": "M16,8 L15,8 15,23 14,26 13,27 11,28 9,28 7,27 6,26 4,26 M16,8 L16,23 15,26 13,28 11,29 8,29 6,28 4,26 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19 M15,11 L11,9 8,9 6,10 5,11 4,14 4,16 5,19 6,20 8,21 11,21 15,19",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 5,22 M4,1 L5,1 5,22 M5,12 L8,9 10,8 13,8 15,9 16,12 16,22 M5,12 L8,10 10,9 12,9 14,10 15,12 15,22 16,22",
                "o": 10
            },
            {
                "d": "M4,1 L3,2 3,3 4,4 5,4 6,3 6,2 5,1 4,1 M4,2 L4,3 5,3 5,2 4,2 M4,8 L4,22 5,22 M4,8 L5,8 5,22",
                "o": 5
            },
            {
                "d": "M4,1 L3,2 3,3 4,4 5,4 6,3 6,2 5,1 4,1 M4,2 L4,3 5,3 5,2 4,2 M4,8 L4,29 5,29 M4,8 L5,8 5,29",
                "o": 5
            },
            {
                "d": "M4,1 L4,22 5,22 M4,1 L5,1 5,22 M16,8 L15,8 5,18 M16,8 L5,19 M8,15 L14,22 16,22 M9,14 L16,22",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 5,22 M4,1 L5,1 5,22",
                "o": 5
            },
            {
                "d": "M4,8 L4,22 5,22 M4,8 L5,8 5,22 M5,12 L8,9 10,8 13,8 15,9 16,12 16,22 M5,12 L8,10 10,9 12,9 14,10 15,12 15,22 16,22 M16,12 L19,9 21,8 24,8 26,9 27,12 27,22 M16,12 L19,10 21,9 23,9 25,10 26,12 26,22 27,22",
                "o": 16
            },
            {
                "d": "M4,8 L4,22 5,22 M4,8 L5,8 5,22 M5,12 L8,9 10,8 13,8 15,9 16,12 16,22 M5,12 L8,10 10,9 12,9 14,10 15,12 15,22 16,22",
                "o": 10
            },
            {
                "d": "M8,8 L6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19 16,16 16,14 15,11 13,9 11,8 8,8 M8,9 L6,10 5,11 4,14 4,16 5,19 6,20 8,21 11,21 13,20 14,19 15,16 15,14 14,11 13,10 11,9 8,9",
                "o": 10
            },
            {
                "d": "M4,8 L4,29 5,29 M4,8 L5,8 5,29 M5,11 L7,9 9,8 12,8 14,9 16,11 17,14 17,16 16,19 14,21 12,22 9,22 7,21 5,19 M5,11 L9,9 12,9 14,10 15,11 16,14 16,16 15,19 14,20 12,21 9,21 5,19",
                "o": 10
            },
            {
                "d": "M15,8 L15,29 16,29 M15,8 L16,8 16,29 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19 M15,11 L11,9 8,9 6,10 5,11 4,14 4,16 5,19 6,20 8,21 11,21 15,19",
                "o": 10
            },
            {
                "d": "M4,8 L4,22 5,22 M4,8 L5,8 5,22 M5,14 L6,11 8,9 10,8 13,8 M5,14 L6,12 8,10 10,9 13,9 13,8",
                "o": 7
            },
            {
                "d": "M14,11 L13,9 10,8 7,8 4,9 3,11 4,13 6,14 11,16 13,17 M12,16 L13,18 13,19 12,21 M13,20 L10,21 7,21 4,20 M5,21 L4,19 3,19 M14,11 L13,11 12,9 M13,10 L10,9 7,9 4,10 M5,9 L4,11 5,13 M4,12 L6,13 11,15 13,16 14,18 14,19 13,21 10,22 7,22 4,21 3,19",
                "o": 9
            },
            {
                "d": "M5,1 L5,22 6,22 M5,1 L6,1 6,22 M2,8 L9,8 9,9 M2,8 L2,9 9,9",
                "o": 6
            },
            {
                "d": "M4,8 L4,18 5,21 7,22 10,22 12,21 15,18 M4,8 L5,8 5,18 6,20 8,21 10,21 12,20 15,18 M15,8 L15,22 16,22 M15,8 L16,8 16,22",
                "o": 10
            },
            {
                "d": "M2,8 L8,22 M2,8 L3,8 8,20 M14,8 L13,8 8,20 M14,8 L8,22",
                "o": 8
            },
            {
                "d": "M3,8 L8,22 M3,8 L4,8 8,19 M12,8 L8,19 M12,11 L8,22 M12,11 L16,22 M12,8 L16,19 M21,8 L20,8 16,19 M21,8 L16,22",
                "o": 12
            },
            {
                "d": "M3,8 L14,22 15,22 M3,8 L4,8 15,22 M15,8 L14,8 3,22 M15,8 L4,22 3,22",
                "o": 9
            },
            {
                "d": "M2,8 L8,22 M2,8 L3,8 8,20 M14,8 L13,8 8,20 4,29 M14,8 L8,22 5,29 4,29",
                "o": 8
            },
            {
                "d": "M13,9 L3,22 M15,8 L5,21 M3,8 L15,8 M3,8 L3,9 13,9 M5,21 L15,21 15,22 M3,22 L15,22",
                "o": 9
            },
            {
                "d": "M10,-3 L3,13 10,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M4,-3 L11,13 4,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "gothiceng": {
        "name": "Gothic English",
        "chars": [
            {
                "d": "M6,1 L5,2 3,3 5,4 6,15 M6,4 L7,3 6,2 5,3 6,4 6,15 M6,1 L7,2 9,3 7,4 6,15 M6,19 L4,21 6,22 8,21 6,19 M6,20 L5,21 7,21 6,20",
                "o": 6
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8 M14,1 L13,2 13,8 M14,2 L13,8 M14,1 L15,2 13,8",
                "o": 9
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M8,-3 L8,26 M12,-3 L12,26 M12,1 L14,2 15,4 15,6 17,5 16,3 15,2 12,1 8,1 5,2 3,4 3,7 4,9 7,11 13,13 15,14 16,16 16,19 15,21 M16,5 L15,3 M4,7 L5,9 7,10 13,12 15,13 16,15 M5,20 L4,18 M5,2 L4,4 4,6 5,8 7,9 13,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 5,17 5,19 6,21 8,22",
                "o": 10
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M20,9 L21,10 22,10 23,9 M19,10 L20,11 22,11 M19,11 L20,12 21,12 22,11 23,9 M20,9 L14,15 M13,16 L7,22 3,17 9,11 M10,10 L14,6 10,1 5,7 11,13 15,19 17,21 19,22 21,22 22,21 23,19 M7,21 L4,17 M13,6 L10,2 M6,7 L11,12 15,18 17,20 19,21 22,21 M8,21 L4,16 M13,7 L9,2 M6,6 L12,12 16,18 17,19 19,20 22,20 23,19",
                "o": 13
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8",
                "o": 5
            },
            {
                "d": "M10,-3 L8,-1 6,2 4,6 3,11 3,15 4,20 6,24 8,27 10,29 M6,3 L5,6 4,10 4,16 5,20 6,23 M8,-1 L7,1 6,4 5,10 5,16 6,22 7,25 8,27",
                "o": 7
            },
            {
                "d": "M4,-3 L6,-1 8,2 10,6 11,11 11,15 10,20 8,24 6,27 4,29 M8,3 L9,6 10,10 10,16 9,20 8,23 M6,-1 L7,1 8,4 9,10 9,16 8,22 7,25 6,27",
                "o": 7
            },
            {
                "d": "M8,1 L7,2 9,12 8,13 M8,1 L8,13 M8,1 L9,2 7,12 8,13 M3,4 L4,4 12,10 13,10 M3,4 L13,10 M3,4 L3,5 13,9 13,10 M13,4 L12,4 4,10 3,10 M13,4 L3,10 M13,4 L13,5 3,9 3,10",
                "o": 8
            },
            {
                "d": "M12,4 L12,21 13,21 M12,4 L13,4 13,21 M4,12 L21,12 21,13 M4,12 L4,13 21,13",
                "o": 13
            },
            {
                "d": "M6,25 L6,23 4,21 6,19 7,21 7,23 6,25 4,26 M6,20 L5,21 6,22 6,20",
                "o": 6
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M6,19 L4,21 6,22 8,21 6,19 M6,20 L5,21 7,21 6,20",
                "o": 6
            },
            {
                "d": "M20,-3 L2,29 3,29 M20,-3 L21,-3 3,29",
                "o": 12
            },
            {
                "d": "M4,3 L4,19 2,20 M5,4 L5,19 8,21 M6,3 L6,19 8,20 9,21 M4,3 L6,3 11,2 13,1 M11,2 L12,3 14,4 14,20 M12,2 L15,4 15,19 M13,1 L14,2 16,3 18,3 16,4 16,20 M2,20 L4,20 6,21 7,22 9,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M7,3 L8,4 9,6 9,19 7,20 M9,4 L8,3 9,2 10,4 10,20 12,21 M7,3 L10,1 11,3 11,19 13,20 14,20 M7,20 L8,20 10,21 11,22 12,21 14,20",
                "o": 10
            },
            {
                "d": "M4,3 L6,3 8,2 9,1 11,2 14,3 16,3 M8,3 L10,2 M4,3 L6,4 8,4 10,3 11,2 M14,3 L14,11 M15,4 L15,10 M16,3 L16,11 9,11 6,12 4,14 3,17 3,22 M3,22 L7,20 11,19 14,19 18,20 M6,21 L9,20 14,20 17,21 M3,22 L8,21 13,21 16,22 18,20",
                "o": 10
            },
            {
                "d": "M4,3 L5,3 7,2 8,1 10,2 14,3 16,3 M7,3 L9,2 M4,3 L6,4 8,4 10,2 M14,3 L14,10 M15,4 L15,9 M16,3 L16,10 14,10 11,11 9,12 M9,11 L11,12 14,13 16,13 16,20 M15,14 L15,19 M14,13 L14,20 M3,20 L5,19 7,19 9,20 10,21 M7,20 L9,21 M3,20 L5,20 7,21 8,22 10,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M13,1 L3,11 3,16 12,16 M14,16 L18,16 19,17 19,15 18,16 M4,11 L4,15 M5,9 L5,16 M12,2 L12,19 10,20 M13,5 L14,3 13,2 13,20 15,21 M13,1 L15,3 14,5 14,19 16,20 17,20 M10,20 L11,20 13,21 14,22 15,21 17,20",
                "o": 10
            },
            {
                "d": "M4,1 L4,10 M4,1 L16,1 M5,2 L14,2 M4,3 L13,3 15,2 16,1 M14,7 L13,8 11,9 7,10 4,10 M11,9 L12,9 14,10 14,20 M13,8 L15,9 15,19 M14,7 L15,8 17,9 18,9 16,10 16,20 M3,20 L5,19 7,19 9,20 10,21 M7,20 L9,21 M3,20 L5,20 7,21 8,22 10,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M4,3 L4,19 2,20 M5,4 L5,19 8,21 M6,3 L6,19 8,20 9,21 M4,3 L6,3 10,2 12,1 13,2 15,3 16,3 M11,2 L13,3 M10,2 L12,4 14,4 16,3 M6,11 L7,11 11,10 13,9 14,8 M11,10 L12,10 14,11 14,20 M13,9 L15,11 15,19 M14,8 L15,9 17,10 18,10 16,11 16,20 M2,20 L4,20 6,21 7,22 9,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M3,3 L5,1 8,2 13,2 18,1 M4,2 L7,3 12,3 15,2 M3,3 L7,4 10,4 14,3 18,1 M18,1 L17,3 15,6 11,10 9,13 8,16 8,19 9,22 M10,12 L9,15 9,18 10,21 M13,8 L11,11 10,14 10,17 11,20 9,22",
                "o": 10
            },
            {
                "d": "M4,4 L4,10 M5,5 L5,9 M6,4 L6,10 M4,4 L6,4 11,3 13,2 14,1 M11,3 L12,3 14,4 14,10 M13,2 L15,3 15,9 M14,1 L15,2 17,3 18,3 16,4 16,10 M4,10 L6,10 14,13 16,13 M16,10 L14,10 6,13 4,13 M4,13 L4,19 2,20 M5,14 L5,19 8,21 M6,13 L6,19 8,20 9,21 M14,13 L14,20 M15,14 L15,19 M16,13 L16,20 M2,20 L4,20 6,21 7,22 9,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M4,3 L4,12 2,13 M5,4 L5,13 7,14 M6,3 L6,12 8,13 9,13 M4,3 L6,3 11,2 13,1 M11,2 L12,3 14,4 14,20 M12,2 L15,4 15,19 M13,1 L14,2 16,3 18,3 16,4 16,20 M2,13 L3,13 5,14 6,15 7,14 9,13 13,12 14,12 M3,20 L5,19 7,19 9,20 10,21 M7,20 L9,21 M3,20 L5,20 7,21 8,22 10,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M6,8 L4,10 6,11 8,10 6,8 M6,9 L5,10 7,10 6,9 M6,19 L4,21 6,22 8,21 6,19 M6,20 L5,21 7,21 6,20",
                "o": 6
            },
            {
                "d": "M6,8 L4,10 6,11 8,10 6,8 M6,9 L5,10 7,10 6,9 M6,25 L6,23 4,21 6,19 7,21 7,23 6,25 4,26 M6,20 L5,21 6,22 6,20",
                "o": 6
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,8 L21,8 21,9 M4,8 L4,9 21,9 M4,16 L21,16 21,17 M4,16 L4,17 21,17",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M3,5 L4,3 5,2 8,1 10,1 13,2 14,3 15,5 15,7 14,9 12,11 10,12 M4,5 L5,3 M13,3 L14,4 14,8 13,9 M3,5 L5,6 5,4 6,2 8,1 M10,1 L12,2 13,4 13,8 12,10 10,12 M9,12 L9,15 10,12 8,12 9,15 M9,19 L7,21 9,22 11,21 9,19 M9,20 L8,21 10,21 9,20",
                "o": 9
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M5,4 L7,2 9,1 11,1 12,2 19,18 20,19 22,19 M10,2 L11,3 18,19 19,21 20,20 18,19 M7,2 L9,2 10,3 17,19 18,21 19,22 20,22 22,19 M5,8 L6,7 8,6 9,6 10,7 M9,7 L9,8 M6,7 L8,7 9,9 M0,22 L2,20 4,19 7,19 9,20 M3,20 L7,20 8,21 M0,22 L3,21 6,21 7,22 9,20 M11,5 L5,19 M7,14 L15,14",
                "o": 11
            },
            {
                "d": "M2,3 L4,1 7,1 9,2 11,1 M5,2 L8,2 M2,3 L4,2 6,3 9,3 11,1 M7,6 L6,7 5,9 5,10 3,10 2,11 2,13 3,12 5,12 5,18 M6,8 L6,16 M3,11 L6,11 M7,6 L7,15 6,17 5,18 M12,4 L11,5 10,7 10,16 M11,6 L11,14 M12,4 L12,13 11,15 10,16 M12,4 L18,1 20,2 21,4 21,6 19,8 15,10 M18,2 L20,4 20,6 M16,2 L18,3 19,4 19,7 17,9 M17,9 L20,11 21,13 21,19 M19,11 L20,13 20,18 M17,9 L18,10 19,12 19,19 M4,22 L7,20 10,19 14,19 17,20 M6,21 L9,20 14,20 16,21 M4,22 L8,21 13,21 15,22 17,20 19,19 21,19 M15,10 L15,19 M15,13 L19,13 M15,16 L19,16",
                "o": 12
            },
            {
                "d": "M9,2 L7,3 5,5 4,7 3,10 3,14 4,17 5,19 8,21 11,22 14,22 17,21 19,20 21,18 22,16 M5,6 L4,9 4,14 6,18 9,20 12,21 15,21 18,20 M9,2 L7,4 6,6 5,9 5,13 6,16 9,19 12,20 15,20 18,19 20,18 22,16 M11,5 L11,17 M12,5 L12,15 M13,4 L13,14 12,16 11,17 M11,5 L13,4 16,1 18,2 20,2 21,1 M15,2 L17,3 19,3 M14,3 L16,4 18,4 20,3 21,1 M18,4 L18,19",
                "o": 11
            },
            {
                "d": "M2,1 L16,1 18,2 19,4 19,19 M4,2 L16,2 18,4 18,18 M2,1 L3,2 5,3 16,3 17,4 17,19 M8,6 L7,7 6,9 6,10 4,10 3,11 3,13 4,12 6,12 6,17 M7,8 L7,15 M4,11 L7,11 M8,6 L8,14 7,16 6,17 M2,22 L5,20 8,19 12,19 15,20 M4,21 L7,20 12,20 14,21 M2,22 L6,21 11,21 13,22 15,20 17,19 19,19 M11,3 L11,19 M11,8 L13,9 15,9 17,8 M11,14 L13,13 15,13 17,14",
                "o": 12
            },
            {
                "d": "M2,3 L4,1 6,1 8,2 10,1 M5,2 L7,2 M2,3 L4,2 6,3 8,3 10,1 M7,6 L6,7 5,9 5,10 3,10 2,11 2,13 3,12 5,12 5,18 M6,8 L6,16 M3,11 L6,11 M7,6 L7,15 6,17 5,18 M10,8 L11,5 12,3 13,2 15,1 17,1 20,2 M13,3 L15,2 17,2 19,3 M11,5 L12,4 14,3 16,3 18,4 20,2 M10,16 L11,13 12,11 13,10 15,10 17,11 M13,11 L15,11 16,12 M11,13 L12,12 14,12 15,13 17,11 M4,22 L7,20 11,19 16,19 20,20 M6,21 L9,20 16,20 19,21 M4,22 L8,21 15,21 18,22 20,20 M10,8 L10,19",
                "o": 11
            },
            {
                "d": "M4,3 L6,1 9,1 11,2 13,1 M7,2 L10,2 M4,3 L6,2 8,3 11,3 13,1 M10,6 L9,7 8,9 8,10 6,10 5,11 5,13 6,12 8,12 8,17 M9,8 L9,15 M6,11 L9,11 M10,6 L10,14 9,16 8,17 M13,5 L13,20 12,21 11,21 7,19 5,19 3,20 1,22 M14,5 L14,19 M14,11 L18,11 M10,21 L9,21 7,20 4,20 M15,4 L15,10 18,10 M18,12 L15,12 15,18 14,20 10,22 8,22 6,21 4,21 1,22 M13,5 L15,4 18,1 20,2 22,2 23,1 M17,2 L19,3 21,3 M16,3 L18,4 20,4 22,3 23,1 M18,4 L18,18",
                "o": 11
            },
            {
                "d": "M9,2 L7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 16,22 19,21 21,19 22,17 22,14 21,12 20,11 18,10 16,10 M5,6 L4,9 4,14 5,17 M9,2 L7,4 6,6 5,9 5,14 6,17 7,19 9,21 M20,19 L21,18 21,14 20,12 M16,22 L18,21 19,20 20,18 20,14 19,12 18,11 16,10 M11,5 L11,18 M12,5 L12,16 M13,4 L13,15 12,17 11,18 M11,5 L13,4 16,1 18,2 20,2 21,1 M15,2 L17,3 19,3 M14,3 L16,4 18,4 20,3 21,1 M20,3 L16,10 16,22 M16,14 L20,14 M16,17 L20,17",
                "o": 12
            },
            {
                "d": "M2,3 L4,1 7,1 9,2 11,1 M5,2 L8,2 M2,3 L4,2 6,3 9,3 11,1 M7,6 L6,7 5,9 5,10 3,10 2,11 2,13 3,12 5,12 5,18 M6,8 L6,16 M3,11 L6,11 M7,6 L7,15 6,17 5,18 M4,22 L7,20 10,19 13,19 15,20 M6,21 L9,20 12,20 14,21 M4,22 L8,21 11,21 13,22 15,20 M12,4 L11,5 10,7 10,16 M11,6 L11,14 M12,4 L12,13 11,15 10,16 M12,4 L14,2 16,1 18,1 20,2 M17,2 L18,2 19,3 M14,2 L16,2 18,4 20,2 M15,10 L17,9 19,7 20,8 21,11 21,15 20,19 18,22 M18,8 L19,9 20,11 20,16 19,19 M17,9 L18,9 19,11 19,16 18,22 M15,10 L15,20 M15,13 L19,13 M15,16 L19,16",
                "o": 12
            },
            {
                "d": "M3,3 L5,1 8,1 11,2 13,1 M6,2 L10,2 M3,3 L5,2 8,3 11,3 13,1 M10,6 L9,7 8,9 8,10 6,10 5,11 5,13 6,12 8,12 8,17 M9,8 L9,15 M6,11 L9,11 M10,6 L10,14 9,16 8,17 M16,3 L14,5 13,8 13,19 12,21 10,21 6,19 4,19 2,20 0,22 M14,6 L14,18 M9,21 L8,21 6,20 3,20 M16,3 L15,5 15,17 14,19 12,21 10,22 7,22 5,21 2,21 0,22",
                "o": 10
            },
            {
                "d": "M4,3 L6,1 9,1 12,2 14,1 M7,2 L11,2 M4,3 L6,2 9,3 12,3 14,1 M11,6 L10,7 9,9 9,10 7,10 6,11 6,13 7,12 9,12 9,17 M10,8 L10,15 M7,11 L10,11 M11,6 L11,14 10,16 9,17 M17,3 L15,5 14,8 14,19 13,21 M15,6 L15,18 M17,3 L16,5 16,17 15,19 13,21 10,22 7,22 4,21 2,19 2,17 3,16 4,16 5,17 4,18 3,18 M2,17 L5,17",
                "o": 10
            },
            {
                "d": "M2,3 L4,1 7,1 9,2 11,1 M5,2 L8,2 M2,3 L4,2 6,3 9,3 11,1 M7,6 L6,7 5,9 5,10 3,10 2,11 2,13 3,12 5,12 5,18 M6,8 L6,16 M3,11 L6,11 M7,6 L7,15 6,17 5,18 M4,22 L7,20 10,19 13,19 15,20 M6,21 L8,20 12,20 14,21 M4,22 L8,21 11,21 13,22 15,20 M12,4 L11,5 10,7 10,16 M11,6 L11,14 M12,4 L12,13 11,15 10,16 M12,4 L14,2 16,1 18,1 20,2 M17,2 L18,2 19,3 M14,2 L16,2 18,4 20,2 M15,10 L18,7 19,8 21,9 M17,8 L19,9 21,9 M21,9 L19,12 17,14 15,16 M17,14 L19,15 20,19 21,21 22,21 M19,17 L20,21 M17,14 L18,15 19,21 20,22 21,22 22,21 M15,10 L15,20",
                "o": 12
            },
            {
                "d": "M2,3 L4,1 7,1 9,2 11,1 M5,2 L8,2 M2,3 L4,2 6,3 9,3 11,1 M7,6 L6,7 5,9 5,10 3,10 2,11 2,13 3,12 5,12 5,18 M6,8 L6,16 M3,11 L6,11 M7,6 L7,15 6,17 5,18 M4,22 L7,20 11,19 16,19 20,20 M6,21 L9,20 16,20 19,21 M4,22 L8,21 15,21 18,22 20,20 M12,4 L11,5 10,7 10,16 M11,6 L11,14 M12,4 L12,13 11,15 10,16 M12,4 L14,2 16,1 18,1 20,2 M17,2 L18,2 19,3 M14,2 L16,2 18,4 20,2 M16,2 L16,19",
                "o": 11
            },
            {
                "d": "M8,5 L7,6 6,8 6,10 4,10 3,11 3,13 4,12 6,12 6,16 M7,7 L7,14 M4,11 L7,11 M8,5 L8,13 7,15 6,16 M1,22 L3,20 5,19 7,19 9,20 10,20 11,19 M4,20 L7,20 9,21 M1,22 L3,21 6,21 8,22 9,22 10,21 11,19 M8,5 L12,1 16,5 16,18 17,20 18,20 M12,2 L15,5 15,19 14,20 15,21 16,20 15,19 M12,11 L15,11 M10,3 L11,3 14,6 14,10 11,10 M11,12 L14,12 14,19 13,20 15,22 18,20 19,19 M16,5 L20,1 24,5 24,18 25,20 26,20 M20,2 L23,5 23,19 25,21 M20,11 L23,11 M18,3 L19,3 22,6 22,10 19,10 M19,12 L22,12 22,20 24,22 26,20 M11,3 L11,19 M19,3 L19,19",
                "o": 14
            },
            {
                "d": "M2,4 L4,2 6,1 8,1 10,2 12,5 17,16 19,19 20,20 M8,2 L10,4 11,6 17,18 20,21 M4,2 L6,2 8,3 10,6 15,17 17,20 18,21 20,22 M17,3 L19,4 21,4 23,3 24,1 M18,2 L20,3 22,3 M17,3 L19,1 21,2 23,2 24,1 M6,10 L4,10 3,11 3,13 4,12 6,12 M4,11 L6,11 M2,22 L4,20 6,19 9,19 11,20 M5,20 L8,20 10,21 M2,22 L5,21 8,21 9,22 11,20 M6,2 L6,19 M20,4 L20,22 M13,7 L14,8 16,9 18,9 20,8 M6,15 L8,14 12,14 14,15",
                "o": 12
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,13 4,16 5,18 7,20 9,21 12,22 14,22 17,21 19,20 21,18 22,16 23,13 23,9 22,6 21,4 19,2 17,1 16,2 13,4 10,5 M5,5 L4,8 4,14 5,17 M9,1 L7,3 6,5 5,8 5,14 6,17 7,19 9,21 M21,17 L22,14 22,8 20,4 19,3 M17,21 L19,19 20,17 21,14 21,8 20,6 18,3 16,2 M10,5 L10,18 M11,5 L11,16 M12,5 L12,15 11,17 10,18 M16,2 L16,21 M16,8 L18,9 19,9 21,8 M16,14 L18,13 19,13 21,14",
                "o": 13
            },
            {
                "d": "M3,1 L4,2 5,4 5,10 3,10 2,11 2,13 3,12 5,12 5,20 2,22 5,21 5,29 7,27 M5,3 L6,5 6,27 M3,11 L6,11 M3,1 L5,2 6,3 7,5 7,27 M7,6 L10,4 14,1 18,5 18,19 M14,2 L17,5 17,19 M12,3 L13,3 16,6 16,20 M10,19 L13,19 16,20 M11,20 L13,20 15,21 M10,21 L12,21 14,22 16,20 18,19 M10,4 L10,26 M10,8 L12,9 14,9 16,8 M10,14 L12,13 14,13 16,14",
                "o": 12
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,13 4,16 5,18 7,20 9,21 11,22 15,22 17,21 19,20 21,18 22,16 23,13 23,9 22,6 21,4 19,2 17,1 16,2 13,4 10,5 M5,5 L4,8 4,14 5,17 M9,1 L7,3 6,5 5,8 5,14 6,17 7,19 9,21 M21,17 L22,14 22,8 20,4 19,3 M17,21 L19,19 20,17 21,14 21,8 20,6 18,3 16,2 M10,5 L10,18 M11,5 L11,16 M12,5 L12,15 11,17 10,18 M16,2 L16,21 M16,8 L18,9 19,9 21,8 M16,14 L18,13 19,13 21,14 M11,22 L12,21 13,21 15,22 19,27 21,28 22,28 M15,23 L17,26 19,28 20,28 M13,21 L14,22 17,28 19,29 21,29 22,28",
                "o": 13
            },
            {
                "d": "M2,3 L4,1 7,1 9,2 11,1 M5,2 L8,2 M2,3 L4,2 6,3 9,3 11,1 M7,6 L6,7 5,9 5,10 3,10 2,11 2,13 3,12 5,12 5,18 M6,8 L6,16 M3,11 L6,11 M7,6 L7,15 6,17 5,18 M4,22 L7,20 10,19 12,19 15,20 M6,21 L8,20 12,20 14,21 M4,22 L8,21 11,21 13,22 15,20 M12,4 L11,5 10,7 10,16 M11,6 L11,14 M12,4 L12,13 11,15 10,16 M12,4 L15,2 17,1 19,2 20,4 20,7 19,9 18,10 14,12 12,13 M17,2 L18,2 19,4 19,8 18,9 M15,2 L17,3 18,5 18,8 17,10 14,12 M14,12 L16,13 17,14 20,19 21,20 22,20 M17,15 L19,19 21,21 M14,12 L16,14 18,20 20,22 22,20",
                "o": 12
            },
            {
                "d": "M14,4 L13,3 11,2 8,1 M15,3 L13,2 M16,2 L12,1 8,1 5,2 4,3 3,5 4,7 5,8 8,9 16,9 18,10 19,11 19,13 18,16 M4,6 L5,7 8,8 17,8 19,9 20,10 20,12 19,14 M4,3 L4,5 5,6 8,7 18,7 20,8 21,10 21,12 18,16 14,22 M2,10 L3,11 5,12 14,12 15,13 15,14 14,16 M3,12 L5,13 13,13 14,14 M2,10 L2,11 3,13 5,14 12,14 14,15 14,16 M2,22 L5,20 9,19 12,19 15,20 M4,21 L7,20 11,20 14,21 M2,22 L6,21 11,21 14,22 M16,2 L14,4 12,7 M11,9 L9,12 M8,14 L6,16 4,17 3,17 3,16 4,17",
                "o": 12
            },
            {
                "d": "M5,5 L4,7 3,10 3,14 4,17 6,20 8,21 11,22 14,22 17,21 19,20 21,18 22,16 M4,14 L5,17 7,19 9,20 12,21 15,21 18,20 M5,5 L4,8 4,12 5,15 7,18 9,19 12,20 15,20 18,19 20,18 22,16 M3,4 L4,2 6,1 10,1 16,2 20,2 22,1 M11,2 L15,3 19,3 M3,4 L4,3 6,2 9,2 15,4 18,4 20,3 22,1 M14,4 L13,5 11,6 11,17 M12,6 L12,15 M13,5 L13,14 12,16 11,17 M18,4 L18,19",
                "o": 11
            },
            {
                "d": "M2,3 L4,1 6,1 9,2 11,1 M5,2 L8,2 M2,3 L4,2 7,3 9,3 11,1 M5,5 L4,7 3,10 3,14 4,17 5,19 7,21 10,22 13,22 16,21 18,20 20,22 22,20 M4,14 L5,17 8,20 11,21 14,21 M5,5 L4,9 4,12 5,15 6,17 8,19 11,20 15,20 18,19 M15,4 L11,5 10,7 10,17 M11,6 L11,15 M12,5 L12,14 11,16 10,17 M15,4 L17,3 19,1 20,2 22,3 20,4 20,18 21,20 22,20 M19,4 L20,3 19,2 18,3 19,4 19,19 21,21 M17,3 L18,4 18,19 M15,4 L15,20 M15,9 L18,9 M15,13 L18,13",
                "o": 12
            },
            {
                "d": "M3,1 L4,2 5,4 5,10 3,10 2,11 2,13 3,12 5,12 5,19 3,20 M5,3 L6,5 6,19 M3,11 L6,11 M7,20 L10,20 12,21 M3,1 L5,2 6,3 7,5 7,19 11,19 14,20 M3,20 L6,20 9,21 11,22 14,20 17,19 19,19 M11,5 L14,4 16,3 18,1 19,2 21,3 19,4 19,19 M18,4 L19,3 18,2 17,3 18,4 18,18 M16,3 L17,4 17,19 M11,5 L11,19 M11,8 L13,9 15,9 17,8 M11,14 L13,13 15,13 17,14",
                "o": 12
            },
            {
                "d": "M3,1 L4,2 5,4 5,10 3,10 2,11 2,13 3,12 5,12 5,19 3,20 M5,3 L6,5 6,19 M3,11 L6,11 M7,20 L9,20 11,21 M3,1 L5,2 6,3 7,5 7,19 10,19 12,20 M3,20 L6,20 9,21 10,22 12,20 15,19 17,20 18,22 20,20 23,19 M10,3 L13,1 15,3 15,19 18,19 20,20 M13,2 L14,3 14,19 M10,3 L12,3 13,4 13,19 12,20 M18,20 L19,21 M18,3 L21,1 23,3 23,19 M21,2 L22,3 22,19 M18,3 L20,3 21,4 21,19 20,20 M10,3 L10,19 M18,3 L18,19 M10,9 L13,9 M10,13 L13,13 M18,9 L21,9 M18,13 L21,13",
                "o": 14
            },
            {
                "d": "M1,4 L3,2 5,1 7,1 8,2 16,20 17,21 19,21 M6,2 L7,3 15,20 16,21 M3,2 L5,2 6,3 14,21 15,22 17,22 19,21 21,19 M16,1 L18,2 20,2 21,1 M16,2 L17,3 19,3 M15,3 L16,4 18,4 20,3 21,1 M1,22 L2,20 4,19 6,19 7,20 M3,20 L5,20 6,21 M1,22 L2,21 4,21 6,22 M16,1 L12,10 M10,13 L6,22 M5,11 L9,11 M12,11 L17,11",
                "o": 11
            },
            {
                "d": "M3,1 L4,2 5,4 5,10 3,10 2,11 2,13 3,12 5,12 5,19 3,20 M5,3 L6,5 6,19 M3,11 L6,11 M7,20 L10,20 12,21 M3,1 L5,2 6,3 7,5 7,19 11,19 14,20 M3,20 L6,20 9,21 11,22 14,20 17,19 M11,5 L14,4 16,3 18,1 19,2 21,3 19,4 19,25 18,27 16,29 14,28 10,27 5,27 M18,4 L19,3 18,2 17,3 18,4 18,20 M16,3 L17,4 17,19 19,22 M17,28 L15,27 12,27 M18,27 L15,26 9,26 5,27 M11,5 L11,19 M11,8 L13,9 15,9 17,8 M11,14 L13,13 15,13 17,14",
                "o": 12
            },
            {
                "d": "M16,2 L15,4 10,10 7,14 5,18 2,22 M14,6 L6,17 M18,1 L15,5 13,9 10,13 5,19 4,21 M2,3 L4,1 7,2 13,2 18,1 M3,2 L7,3 11,3 15,2 M2,3 L6,4 10,4 14,3 16,2 M4,21 L6,20 10,19 14,19 18,20 M5,21 L9,20 13,20 17,21 M2,22 L7,21 13,21 16,22 18,20 M5,11 L9,11 M12,11 L16,11",
                "o": 10
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M3,15 L11,10 19,15 M3,15 L11,11 19,15",
                "o": 11
            },
            {
                "d": "M0,29 L22,29",
                "o": 11
            },
            {
                "d": "M4,1 L9,7 M4,1 L3,2 9,7",
                "o": 6
            },
            {
                "d": "M6,13 L4,15 3,17 3,19 4,21 6,22 8,20 11,19 M3,17 L4,19 5,20 7,21 M4,15 L4,17 5,19 7,20 8,20 M4,11 L6,11 9,10 11,9 12,8 14,10 13,11 13,19 14,20 15,20 M5,9 L4,10 7,10 M10,10 L13,10 12,9 12,20 13,21 M3,10 L5,8 6,9 8,10 11,11 11,20 13,22 15,20 M3,10 L8,15",
                "o": 9
            },
            {
                "d": "M3,3 L4,5 4,19 2,20 M5,5 L4,3 5,2 5,19 8,21 M3,3 L6,1 6,19 8,20 9,21 M2,20 L4,20 6,21 7,22 9,21 12,20 14,20 M6,11 L9,10 11,9 12,8 13,9 15,10 16,10 14,11 14,20 M11,9 L13,10 13,19 M9,10 L10,10 12,11 12,20",
                "o": 9
            },
            {
                "d": "M4,10 L4,19 2,20 3,20 5,21 6,22 M5,10 L5,20 7,21 M6,10 L6,19 8,20 9,20 7,21 6,22 M4,10 L8,9 10,8 11,9 13,10 14,10 M9,9 L10,10 12,10 M6,10 L8,9 10,11 12,11 14,10",
                "o": 6
            },
            {
                "d": "M9,8 L7,9 4,10 4,19 2,20 M5,10 L5,19 8,21 M9,8 L6,10 6,19 8,20 9,21 M2,20 L4,20 6,21 7,22 9,21 12,20 14,20 M4,3 L7,1 8,4 14,10 14,20 M7,4 L5,3 6,2 7,4 13,10 13,19 M4,3 L12,11 12,20",
                "o": 8
            },
            {
                "d": "M4,10 L4,19 2,20 3,20 5,21 6,22 M5,10 L5,20 7,21 M6,10 L6,19 8,20 9,20 7,21 6,22 M4,10 L8,9 10,8 13,12 11,13 6,16 M9,9 L12,12 M6,10 L8,9 11,13",
                "o": 6
            },
            {
                "d": "M4,3 L4,19 2,20 3,20 5,21 6,22 M5,3 L5,20 7,21 M6,3 L6,19 8,20 9,20 7,21 6,22 M4,3 L7,2 9,1 10,2 12,3 13,3 M8,2 L9,3 11,3 M6,3 L7,2 9,4 11,4 13,3 M1,8 L4,8 M6,8 L10,8",
                "o": 5
            },
            {
                "d": "M4,10 L4,19 2,20 3,20 5,21 6,22 7,21 9,20 12,19 M5,11 L5,20 7,21 M6,10 L6,19 8,20 9,20 M4,10 L6,10 9,9 11,8 12,9 14,10 16,10 14,11 14,23 13,26 11,28 9,29 8,28 6,27 4,27 M10,9 L13,11 13,23 M10,28 L8,27 7,27 M9,9 L10,10 12,11 12,21 13,24 13,26 M11,28 L10,27 8,26 6,26 4,27",
                "o": 9
            },
            {
                "d": "M3,3 L4,5 4,19 2,20 3,20 5,21 6,22 M5,5 L4,3 5,2 5,20 7,21 M3,3 L6,1 6,19 8,20 6,22 M6,11 L9,10 11,9 12,8 13,9 15,10 16,10 14,11 14,20 12,22 11,24 M11,9 L13,10 13,20 12,22 M9,10 L10,10 12,11 12,20 11,24 11,27 12,29 13,29 11,27",
                "o": 9
            },
            {
                "d": "M5,1 L3,3 5,4 7,3 5,1 M5,2 L4,3 6,3 5,2 M5,8 L4,9 2,10 4,11 4,20 6,22 8,20 M5,11 L6,10 5,9 4,10 5,11 5,20 6,21 M5,8 L6,9 8,10 6,11 6,19 7,20 8,20",
                "o": 5
            },
            {
                "d": "M5,1 L3,3 5,4 7,3 5,1 M5,2 L4,3 6,3 5,2 M5,8 L4,9 2,10 4,11 4,20 6,22 7,24 M5,11 L6,10 5,9 4,10 5,11 5,20 6,22 M5,8 L6,9 8,10 6,11 6,20 7,24 7,27 5,29 3,29 3,28 5,29",
                "o": 5
            },
            {
                "d": "M3,3 L4,5 4,19 2,20 3,20 5,21 6,22 M5,5 L4,3 5,2 5,20 7,21 M3,3 L6,1 6,19 8,20 6,22 M6,11 L9,9 11,8 13,11 10,13 6,16 M10,9 L12,11 M9,9 L11,12 M10,13 L11,14 13,19 14,20 15,20 M10,14 L11,15 12,20 13,21 M9,14 L10,15 11,20 13,22 15,20",
                "o": 8
            },
            {
                "d": "M3,3 L4,5 4,19 2,20 3,20 5,21 6,22 M5,5 L4,3 5,2 5,20 7,21 M3,3 L6,1 6,19 8,20 9,20 7,21 6,22",
                "o": 5
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 2,20 3,20 5,21 6,22 M4,9 L5,10 5,20 7,21 M2,10 L4,8 6,10 6,19 8,20 6,22 M6,11 L9,10 11,9 12,8 14,10 14,19 16,20 14,22 M11,9 L13,10 13,20 15,21 M9,10 L10,10 12,11 12,19 11,20 13,21 14,22 M14,11 L17,10 19,9 20,8 21,9 23,10 24,10 22,11 22,19 23,20 24,20 M19,9 L21,10 21,20 22,21 M17,10 L18,10 20,11 20,20 22,22 24,20",
                "o": 13
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 2,20 3,20 5,21 6,22 M4,9 L5,10 5,20 7,21 M2,10 L4,8 6,10 6,19 8,20 6,22 M6,11 L9,10 11,9 12,8 13,9 15,10 16,10 14,11 14,19 15,20 16,20 M11,9 L13,10 13,20 14,21 M9,10 L10,10 12,11 12,20 14,22 16,20",
                "o": 9
            },
            {
                "d": "M4,10 L4,19 2,20 M5,11 L5,19 8,21 M6,10 L6,19 8,20 9,21 M2,20 L4,20 6,21 7,22 9,21 12,20 14,20 M4,10 L6,10 9,9 11,8 12,9 14,10 16,10 14,11 14,20 M10,9 L13,11 13,19 M9,9 L10,10 12,11 12,20",
                "o": 9
            },
            {
                "d": "M3,8 L4,10 4,19 2,20 4,20 4,29 M4,9 L5,10 5,28 6,27 5,25 M5,20 L6,20 8,21 M3,8 L5,9 6,10 6,19 8,20 9,21 M6,21 L7,22 9,21 12,20 14,20 M6,21 L6,25 7,27 4,29 M6,11 L9,10 11,9 12,8 13,9 15,10 16,10 14,11 14,20 M11,9 L13,10 13,19 M9,10 L10,10 12,11 12,20",
                "o": 9
            },
            {
                "d": "M4,10 L4,19 2,20 M5,11 L5,20 7,21 M6,10 L6,19 8,20 9,20 M2,20 L3,20 5,21 6,22 7,21 9,20 12,19 M4,10 L6,10 9,9 11,8 12,9 14,10 16,10 14,11 14,29 M10,9 L13,11 13,28 12,27 13,25 M9,9 L10,10 12,11 12,25 11,27 14,29",
                "o": 9
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 2,20 3,20 5,21 6,22 M3,9 L5,10 5,20 7,21 M2,10 L4,8 6,10 6,19 8,20 9,20 7,21 6,22 M6,10 L10,8 11,9 13,10 14,10 M9,9 L10,10 12,10 M8,9 L10,11 12,11 14,10",
                "o": 6
            },
            {
                "d": "M3,10 L3,14 5,15 11,15 13,16 13,20 M4,10 L4,14 M12,16 L12,20 M6,9 L5,10 5,14 7,15 M9,15 L11,16 11,20 10,21 M3,10 L6,9 8,8 10,9 12,9 13,8 M7,9 L9,9 M6,9 L8,10 10,10 12,9 M13,20 L10,21 8,22 6,21 4,21 2,22 M9,21 L7,21 M10,21 L8,20 5,20 2,22 M13,8 L12,10 10,13 5,18 2,22",
                "o": 8
            },
            {
                "d": "M3,3 L4,5 4,19 2,20 3,20 5,21 6,22 M5,5 L4,3 5,2 5,20 7,21 M3,3 L6,1 6,19 8,20 9,20 7,21 6,22 M1,8 L4,8 M6,8 L9,8",
                "o": 5
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 2,20 M3,9 L5,10 5,20 7,21 M2,10 L4,8 6,10 6,19 8,20 9,20 M2,20 L3,20 5,21 6,22 7,21 9,20 12,19 M12,8 L13,9 15,10 16,10 14,11 14,19 15,20 16,20 M11,9 L13,10 13,20 14,21 M12,8 L10,10 12,11 12,20 14,22 16,20",
                "o": 9
            },
            {
                "d": "M3,8 L4,10 4,19 7,22 9,20 12,19 14,19 M4,9 L5,10 5,19 8,21 M3,8 L5,9 6,10 6,18 7,19 9,20 M12,8 L13,9 15,10 16,10 14,11 14,19 M11,9 L13,10 13,18 M12,8 L10,10 12,11 12,19",
                "o": 9
            },
            {
                "d": "M3,8 L4,10 4,19 7,22 9,20 12,19 M4,9 L5,10 5,19 8,21 M3,8 L5,9 6,10 6,18 7,19 9,20 M12,8 L10,10 12,11 12,19 15,22 17,20 20,19 22,19 M11,9 L13,10 13,19 16,21 M12,8 L13,9 15,10 14,11 14,18 15,19 17,20 M20,8 L21,9 23,10 24,10 22,11 22,19 M19,9 L21,10 21,18 M20,8 L18,10 20,11 20,19",
                "o": 13
            },
            {
                "d": "M3,10 L4,10 6,11 7,12 11,20 12,21 14,22 16,20 M5,9 L7,10 12,20 14,21 M3,10 L5,8 7,9 8,10 12,18 13,19 15,20 16,20 M10,14 L13,8 14,9 16,9 17,8 M13,9 L14,10 15,10 M12,10 L14,11 16,10 17,8 M9,16 L6,22 5,21 3,21 2,22 M6,21 L5,20 4,20 M7,20 L5,19 3,20 2,22 M5,15 L8,15 M11,15 L14,15",
                "o": 9
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 2,20 M3,9 L5,10 5,20 7,21 M2,10 L4,8 6,10 6,19 8,20 9,20 M2,20 L3,20 5,21 6,22 7,21 9,20 12,19 M12,8 L13,9 15,10 16,10 14,11 14,23 13,26 11,28 9,29 8,28 6,27 4,27 M11,9 L13,10 13,23 M10,28 L8,27 7,27 M12,8 L10,10 12,11 12,21 13,24 13,26 M11,28 L10,27 8,26 6,26 4,27",
                "o": 9
            },
            {
                "d": "M15,8 L3,22 M3,10 L5,11 8,11 11,10 15,8 M4,9 L6,10 10,10 M3,10 L5,8 7,9 11,9 15,8 M3,22 L7,20 10,19 13,19 15,20 M8,20 L12,20 14,21 M3,22 L7,21 11,21 13,22 15,20 M5,15 L13,15",
                "o": 9
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "gothicger": {
        "name": "Gothic German",
        "chars": [
            {
                "d": "M6,1 L5,2 3,3 5,4 6,15 M6,4 L7,3 6,2 5,3 6,4 6,15 M6,1 L7,2 9,3 7,4 6,15 M6,19 L4,21 6,22 8,21 6,19 M6,20 L5,21 7,21 6,20",
                "o": 6
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8 M14,1 L13,2 13,8 M14,2 L13,8 M14,1 L15,2 13,8",
                "o": 9
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M8,-3 L8,26 M12,-3 L12,26 M12,1 L14,2 15,4 15,6 17,5 16,3 15,2 12,1 8,1 5,2 3,4 3,7 4,9 7,11 13,13 15,14 16,16 16,19 15,21 M16,5 L15,3 M4,7 L5,9 7,10 13,12 15,13 16,15 M5,20 L4,18 M5,2 L4,4 4,6 5,8 7,9 13,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 5,17 5,19 6,21 8,22",
                "o": 10
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M20,9 L21,10 22,10 23,9 M19,10 L20,11 22,11 M19,11 L20,12 21,12 22,11 23,9 M20,9 L14,15 M13,16 L7,22 3,17 9,11 M10,10 L14,6 10,1 5,7 11,13 15,19 17,21 19,22 21,22 22,21 23,19 M7,21 L4,17 M13,6 L10,2 M6,7 L11,12 15,18 17,20 19,21 22,21 M8,21 L4,16 M13,7 L9,2 M6,6 L12,12 16,18 17,19 19,20 22,20 23,19",
                "o": 13
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8",
                "o": 5
            },
            {
                "d": "M10,-3 L8,-1 6,2 4,6 3,11 3,15 4,20 6,24 8,27 10,29 M6,3 L5,6 4,10 4,16 5,20 6,23 M8,-1 L7,1 6,4 5,10 5,16 6,22 7,25 8,27",
                "o": 7
            },
            {
                "d": "M4,-3 L6,-1 8,2 10,6 11,11 11,15 10,20 8,24 6,27 4,29 M8,3 L9,6 10,10 10,16 9,20 8,23 M6,-1 L7,1 8,4 9,10 9,16 8,22 7,25 6,27",
                "o": 7
            },
            {
                "d": "M8,1 L7,2 9,12 8,13 M8,1 L8,13 M8,1 L9,2 7,12 8,13 M3,4 L4,4 12,10 13,10 M3,4 L13,10 M3,4 L3,5 13,9 13,10 M13,4 L12,4 4,10 3,10 M13,4 L3,10 M13,4 L13,5 3,9 3,10",
                "o": 8
            },
            {
                "d": "M12,4 L12,21 13,21 M12,4 L13,4 13,21 M4,12 L21,12 21,13 M4,12 L4,13 21,13",
                "o": 13
            },
            {
                "d": "M6,25 L6,23 4,21 6,19 7,21 7,23 6,25 4,26 M6,20 L5,21 6,22 6,20",
                "o": 6
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M6,19 L4,21 6,22 8,21 6,19 M6,20 L5,21 7,21 6,20",
                "o": 6
            },
            {
                "d": "M20,-3 L2,29 3,29 M20,-3 L21,-3 3,29",
                "o": 12
            },
            {
                "d": "M4,3 L4,19 2,20 M5,4 L5,19 8,21 M6,3 L6,19 8,20 9,21 M4,3 L6,3 11,2 13,1 M11,2 L12,3 14,4 14,20 M12,2 L15,4 15,19 M13,1 L14,2 16,3 18,3 16,4 16,20 M2,20 L4,20 6,21 7,22 9,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M7,3 L8,4 9,6 9,19 7,20 M9,4 L8,3 9,2 10,4 10,20 12,21 M7,3 L10,1 11,3 11,19 13,20 14,20 M7,20 L8,20 10,21 11,22 12,21 14,20",
                "o": 10
            },
            {
                "d": "M4,3 L6,3 8,2 9,1 11,2 14,3 16,3 M8,3 L10,2 M4,3 L6,4 8,4 10,3 11,2 M14,3 L14,11 M15,4 L15,10 M16,3 L16,11 9,11 6,12 4,14 3,17 3,22 M3,22 L7,20 11,19 14,19 18,20 M6,21 L9,20 14,20 17,21 M3,22 L8,21 13,21 16,22 18,20",
                "o": 10
            },
            {
                "d": "M4,3 L5,3 7,2 8,1 10,2 14,3 16,3 M7,3 L9,2 M4,3 L6,4 8,4 10,2 M14,3 L14,10 M15,4 L15,9 M16,3 L16,10 14,10 11,11 9,12 M9,11 L11,12 14,13 16,13 16,20 M15,14 L15,19 M14,13 L14,20 M3,20 L5,19 7,19 9,20 10,21 M7,20 L9,21 M3,20 L5,20 7,21 8,22 10,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M13,1 L3,11 3,16 12,16 M14,16 L18,16 19,17 19,15 18,16 M4,11 L4,15 M5,9 L5,16 M12,2 L12,19 10,20 M13,5 L14,3 13,2 13,20 15,21 M13,1 L15,3 14,5 14,19 16,20 17,20 M10,20 L11,20 13,21 14,22 15,21 17,20",
                "o": 10
            },
            {
                "d": "M4,1 L4,10 M4,1 L16,1 M5,2 L14,2 M4,3 L13,3 15,2 16,1 M14,7 L13,8 11,9 7,10 4,10 M11,9 L12,9 14,10 14,20 M13,8 L15,9 15,19 M14,7 L15,8 17,9 18,9 16,10 16,20 M3,20 L5,19 7,19 9,20 10,21 M7,20 L9,21 M3,20 L5,20 7,21 8,22 10,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M4,3 L4,19 2,20 M5,4 L5,19 8,21 M6,3 L6,19 8,20 9,21 M4,3 L6,3 10,2 12,1 13,2 15,3 16,3 M11,2 L13,3 M10,2 L12,4 14,4 16,3 M6,11 L7,11 11,10 13,9 14,8 M11,10 L12,10 14,11 14,20 M13,9 L15,11 15,19 M14,8 L15,9 17,10 18,10 16,11 16,20 M2,20 L4,20 6,21 7,22 9,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M3,3 L5,1 8,2 13,2 18,1 M4,2 L7,3 12,3 15,2 M3,3 L7,4 10,4 14,3 18,1 M18,1 L17,3 15,6 11,10 9,13 8,16 8,19 9,22 M10,12 L9,15 9,18 10,21 M13,8 L11,11 10,14 10,17 11,20 9,22",
                "o": 10
            },
            {
                "d": "M4,4 L4,10 M5,5 L5,9 M6,4 L6,10 M4,4 L6,4 11,3 13,2 14,1 M11,3 L12,3 14,4 14,10 M13,2 L15,3 15,9 M14,1 L15,2 17,3 18,3 16,4 16,10 M4,10 L6,10 14,13 16,13 M16,10 L14,10 6,13 4,13 M4,13 L4,19 2,20 M5,14 L5,19 8,21 M6,13 L6,19 8,20 9,21 M14,13 L14,20 M15,14 L15,19 M16,13 L16,20 M2,20 L4,20 6,21 7,22 9,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M4,3 L4,12 2,13 M5,4 L5,13 7,14 M6,3 L6,12 8,13 9,13 M4,3 L6,3 11,2 13,1 M11,2 L12,3 14,4 14,20 M12,2 L15,4 15,19 M13,1 L14,2 16,3 18,3 16,4 16,20 M2,13 L3,13 5,14 6,15 7,14 9,13 13,12 14,12 M3,20 L5,19 7,19 9,20 10,21 M7,20 L9,21 M3,20 L5,20 7,21 8,22 10,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M6,8 L4,10 6,11 8,10 6,8 M6,9 L5,10 7,10 6,9 M6,19 L4,21 6,22 8,21 6,19 M6,20 L5,21 7,21 6,20",
                "o": 6
            },
            {
                "d": "M6,8 L4,10 6,11 8,10 6,8 M6,9 L5,10 7,10 6,9 M6,25 L6,23 4,21 6,19 7,21 7,23 6,25 4,26 M6,20 L5,21 6,22 6,20",
                "o": 6
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,8 L21,8 21,9 M4,8 L4,9 21,9 M4,16 L21,16 21,17 M4,16 L4,17 21,17",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M3,5 L4,3 5,2 8,1 10,1 13,2 14,3 15,5 15,7 14,9 12,11 10,12 M4,5 L5,3 M13,3 L14,4 14,8 13,9 M3,5 L5,6 5,4 6,2 8,1 M10,1 L12,2 13,4 13,8 12,10 10,12 M9,12 L9,15 10,12 8,12 9,15 M9,19 L7,21 9,22 11,21 9,19 M9,20 L8,21 10,21 9,20",
                "o": 9
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M3,3 L4,4 3,5 2,4 3,2 5,1 7,1 9,2 10,3 11,6 11,10 10,13 8,15 6,16 3,17 M9,3 L10,6 10,11 9,13 M7,1 L8,2 9,5 9,11 8,14 6,16 M6,17 L9,20 M5,17 L9,21 M3,17 L8,22 15,17 M22,2 L21,3 22,3 22,2 21,1 19,1 17,2 16,3 15,5 15,20 17,22 21,18 M17,3 L16,5 16,19 18,21 M19,1 L18,2 17,5 17,18 19,20",
                "o": 12
            },
            {
                "d": "M2,12 L2,13 3,14 5,14 7,13 7,10 6,8 4,5 4,3 6,1 M6,10 L4,6 M5,14 L6,13 6,11 4,8 3,6 3,4 4,2 6,1 9,1 11,2 12,3 13,5 13,13 12,16 10,18 M11,3 L12,5 12,15 M9,1 L10,2 11,5 11,16 10,18 M13,4 L14,2 16,1 18,1 20,2 21,3 22,5 23,6 M20,3 L21,5 M18,1 L19,2 20,5 21,6 23,6 M23,6 L13,11 M20,8 L22,10 23,13 23,16 22,19 20,21 17,22 14,22 11,21 5,18 4,18 3,19 M19,9 L20,9 22,11 M17,9 L20,10 22,12 23,14 M15,21 L13,21 7,18 6,18 M21,20 L19,21 16,21 13,20 9,18 6,17 4,17 3,19 3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M12,3 L10,1 8,1 6,2 4,5 3,9 3,13 4,17 6,20 8,21 11,22 14,22 17,21 19,20 21,18 M6,3 L5,5 4,8 4,13 5,17 7,20 10,21 M8,1 L7,2 6,4 5,8 5,12 6,16 7,18 9,20 12,21 15,21 18,20 21,18 M15,1 L12,3 11,4 10,6 10,7 11,9 14,11 15,13 15,15 M11,6 L11,7 15,11 15,12 M11,4 L11,5 12,7 15,9 16,11 16,13 15,15 13,16 12,16 10,15 9,13 M15,1 L16,2 18,3 20,3 M15,2 L16,3 17,3 M14,2 L16,4 18,4 20,3 21,2",
                "o": 12
            },
            {
                "d": "M3,7 L3,6 4,4 6,2 9,1 13,1 16,2 18,3 20,5 22,8 23,12 23,16 22,19 20,21 17,22 14,22 11,21 5,18 4,18 3,19 M6,3 L8,2 13,2 16,3 18,4 20,6 22,9 M15,21 L13,21 7,18 6,18 M3,6 L5,4 8,3 13,3 16,4 18,5 20,7 22,10 23,13 M21,20 L19,21 16,21 13,20 9,18 6,17 4,17 3,19 3,21 4,22 5,21 4,20 M11,3 L8,6 7,8 7,10 9,14 9,16 M8,9 L8,10 9,12 9,13 M8,6 L8,8 10,12 10,14 9,16 8,17 6,17 5,16 5,15",
                "o": 13
            },
            {
                "d": "M12,3 L10,1 8,1 6,2 4,5 3,9 3,13 4,17 6,20 8,21 11,22 14,22 17,21 19,20 21,18 M6,3 L5,5 4,8 4,13 5,17 7,20 10,21 M8,1 L7,2 6,4 5,8 5,12 6,16 7,18 9,20 12,21 15,21 18,20 21,18 M15,1 L12,3 11,4 10,6 10,7 11,9 14,11 15,13 15,15 M11,6 L11,7 15,11 15,12 M11,4 L11,5 12,7 15,9 16,11 16,13 15,15 13,16 12,16 10,15 9,13 M15,1 L16,2 18,3 20,3 M15,2 L16,3 17,3 M14,2 L16,4 18,4 20,3 21,2 M15,9 L19,6 M19,6 L20,7 22,7 M18,7 L19,8 20,8 M17,8 L18,9 20,9 22,7",
                "o": 12
            },
            {
                "d": "M7,9 L5,8 4,6 4,4 5,2 8,1 11,1 14,2 18,4 M5,3 L7,2 12,2 15,3 M4,6 L5,4 7,3 12,3 18,4 20,4 21,3 21,2 20,1 19,1 M13,3 L12,4 11,6 11,8 12,10 16,14 17,17 17,20 16,23 15,24 13,25 M14,11 L17,14 18,17 18,20 17,22 M11,8 L13,10 16,12 18,14 19,17 19,20 18,22 16,24 13,25 9,25 6,24 5,23 4,21 4,18 6,15 6,13 5,12 M6,23 L5,22 5,18 6,16 M9,25 L7,24 6,22 6,18 7,15 7,13 6,12 4,12 3,13 3,14 M15,11 L19,7 M19,7 L20,8 22,8 M18,8 L19,9 20,9 M17,9 L18,10 20,10 22,8",
                "o": 12
            },
            {
                "d": "M16,5 L15,3 14,2 12,1 9,1 6,2 4,5 3,9 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 22,18 23,15 23,12 22,9 20,7 M6,3 L5,5 4,8 4,13 5,16 6,18 M21,18 L22,16 22,12 21,9 20,8 M9,1 L7,2 6,4 5,8 5,13 6,17 7,19 9,21 M18,21 L20,19 21,16 21,12 20,10 18,8 M16,1 L13,3 11,5 10,7 10,8 11,10 14,12 15,14 15,16 M11,7 L11,8 15,12 15,13 M11,5 L11,6 12,8 15,10 16,12 16,14 15,16 13,17 12,17 10,16 9,14 M15,10 L20,7 21,5 M23,1 L21,5 M20,2 L24,4 M23,1 L22,2 20,2 21,3 21,5 22,4 24,4 23,3 23,1",
                "o": 13
            },
            {
                "d": "M12,1 L10,2 8,4 7,6 7,8 8,10 10,12 11,14 11,16 M8,7 L8,8 11,12 11,13 M8,4 L8,6 9,8 11,10 12,12 12,14 11,16 10,17 8,18 6,18 4,17 3,16 2,14 2,12 3,11 4,12 3,13 M12,1 L14,3 16,3 18,2 M11,2 L13,3 M10,2 L11,3 13,4 15,4 18,2 M12,11 L19,6 M19,6 L21,9 22,12 22,15 21,18 19,20 16,21 12,22 M18,7 L20,9 21,12 21,16 20,18 M16,8 L17,8 19,10 20,13 20,17 19,19 18,20 16,21 M16,21 L14,21 12,20 10,20 8,21 7,23 8,25 10,26 12,26 14,25 M13,21 L11,21 M12,22 L10,21 8,21",
                "o": 13
            },
            {
                "d": "M10,11 L8,11 6,10 5,9 4,7 4,5 5,3 6,2 9,1 11,1 14,2 17,5 19,6 M6,3 L8,2 12,2 14,3 15,4 M4,5 L5,4 7,3 11,3 14,4 16,5 19,6 21,6 22,5 22,3 21,2 19,2 M4,19 L5,20 4,21 3,20 3,18 4,17 6,17 8,18 10,20 12,23 14,25 M8,19 L9,20 11,23 12,24 M6,17 L7,18 8,20 10,23 11,24 13,25 16,25 18,24 19,23 20,21 20,18 19,16 17,13 16,11 16,10 M19,19 L19,18 16,13 16,12 M18,24 L19,22 19,20 18,18 16,15 15,13 15,11 17,9 19,9 20,10 20,11",
                "o": 13
            },
            {
                "d": "M10,11 L8,11 6,10 5,9 4,7 4,5 5,3 6,2 9,1 11,1 14,2 17,5 19,6 M6,3 L8,2 12,2 14,3 15,4 M4,5 L5,4 7,3 11,3 14,4 16,5 19,6 21,6 22,5 22,3 21,2 19,2 M4,19 L5,20 4,21 3,20 3,18 4,17 6,17 8,18 10,20 12,23 14,25 M8,19 L9,20 11,23 12,24 M6,17 L7,18 8,20 10,23 11,24 13,25 16,25 18,24 19,23 20,21 20,18 19,16 17,13 16,11 16,10 M19,19 L19,18 16,13 16,12 M18,24 L19,22 19,20 18,18 16,15 15,13 15,11 17,9 19,9 20,10 20,11",
                "o": 13
            },
            {
                "d": "M22,6 L21,4 19,2 16,1 13,1 10,2 8,4 7,6 7,9 8,12 11,18 11,20 9,22 M8,9 L8,10 11,16 11,17 M9,3 L8,5 8,8 9,10 11,14 12,17 12,19 11,21 9,22 7,22 5,21 M3,17 L5,21 M2,20 L6,18 M3,17 L3,19 2,20 4,20 5,21 5,19 6,18 4,18 3,17 M9,10 L9,8 10,6 12,5 15,5 17,6 19,8 20,8 M16,6 L18,8 M13,5 L15,6 16,7 17,9 M20,8 L11,12 M16,10 L20,19 21,20 22,20 M15,11 L19,19 21,21 M14,11 L18,20 20,22 23,19",
                "o": 13
            },
            {
                "d": "M19,14 L18,15 15,15 14,14 14,12 15,10 17,7 18,5 18,3 M15,12 L15,11 18,7 18,6 M16,15 L15,14 15,13 16,11 18,9 19,7 19,5 18,3 17,2 14,1 9,1 6,2 5,3 4,5 4,7 5,9 7,12 8,14 8,15 7,17 M5,6 L5,7 8,12 8,13 M5,3 L5,5 6,7 8,10 9,12 9,14 8,16 6,18 3,20 M6,18 L8,18 11,20 14,21 17,21 19,20 M7,19 L8,19 12,21 13,21 M3,20 L5,19 6,19 10,21 13,22 15,22 18,21 19,20 20,18",
                "o": 12
            },
            {
                "d": "M3,12 L3,13 4,14 6,14 8,13 8,10 7,8 5,5 5,3 7,1 M7,10 L5,6 M6,14 L7,13 7,11 5,8 4,6 4,4 5,2 7,1 9,1 11,2 13,4 14,7 14,13 13,16 12,18 10,20 7,22 6,21 5,21 M12,4 L13,7 13,13 12,16 11,18 M8,21 L7,20 6,20 M9,1 L11,3 12,6 12,13 11,17 10,19 9,20 8,19 7,19 4,22 M12,2 L14,1 16,1 18,2 20,4 21,7 21,13 20,16 19,18 17,20 15,22 14,21 13,21 M19,4 L20,7 20,13 19,17 M16,21 L15,20 14,20 M16,1 L18,3 19,6 19,14 18,18 17,20 16,19 15,19 12,22 M19,3 L20,2 22,1 24,1 26,2 27,3 28,5 29,6 M26,3 L27,5 M24,1 L25,2 26,5 27,6 29,6 M29,6 L26,8 25,9 24,12 24,15 25,19 27,22 30,19 M26,9 L25,11 25,15 26,18 28,21 M29,6 L27,8 26,10 26,14 27,18 29,20",
                "o": 16
            },
            {
                "d": "M3,12 L3,13 4,14 6,14 8,13 8,10 7,8 5,5 5,3 7,1 M7,10 L5,6 M6,14 L7,13 7,11 5,8 4,6 4,4 5,2 7,1 10,1 12,2 14,4 15,7 15,13 14,16 13,18 11,20 8,22 7,21 5,21 3,22 M13,4 L14,6 14,13 13,16 12,18 11,19 M9,21 L7,20 5,20 M10,1 L12,3 13,6 13,13 12,17 10,20 8,19 6,19 3,22 M14,3 L15,2 17,1 19,1 21,2 22,3 23,5 24,6 M21,3 L22,5 M19,1 L20,2 21,5 22,6 24,6 M24,6 L21,8 20,9 19,12 19,15 20,19 22,22 25,19 M21,9 L20,11 20,15 21,18 23,21 M24,6 L22,8 21,10 21,14 22,18 24,20",
                "o": 14
            },
            {
                "d": "M12,1 L10,2 8,4 7,6 7,8 9,12 9,14 M8,7 L8,8 9,10 9,11 M8,4 L8,6 10,10 10,12 9,14 8,15 6,15 5,14 5,13 M12,1 L13,2 19,4 22,6 23,8 24,11 24,14 23,17 22,19 20,21 17,22 14,22 11,21 5,18 4,18 3,19 M12,2 L13,3 19,5 21,6 22,7 M12,1 L12,3 13,4 19,6 21,7 23,9 24,11 M15,21 L13,21 7,18 6,18 M21,20 L19,21 16,21 13,20 9,18 6,17 4,17 3,19 3,21 4,22 5,21 4,20",
                "o": 14
            },
            {
                "d": "M3,12 L3,13 4,14 6,14 8,13 8,10 7,8 5,5 5,3 7,1 M7,10 L5,6 M6,14 L7,13 7,11 5,8 4,6 4,4 5,2 7,1 10,1 12,2 13,3 14,5 14,16 M14,18 L14,23 13,25 11,26 8,26 7,25 7,23 8,22 9,23 8,24 M12,3 L13,5 13,23 12,25 M10,1 L11,2 12,5 12,16 M12,18 L12,23 11,25 10,26 M14,5 L19,1 M19,1 L21,4 22,6 23,10 23,13 22,16 20,19 17,22 M18,2 L21,6 22,9 22,10 M17,3 L19,5 21,8 22,11 22,14 21,17 20,19 M18,20 L16,17 14,16 M12,16 L10,17 8,19 M18,21 L16,18 14,17 11,17 M17,22 L15,19 14,18 M12,18 L10,18 8,19",
                "o": 14
            },
            {
                "d": "M12,1 L10,2 8,4 7,6 7,8 9,12 9,14 M8,7 L8,8 9,10 9,11 M8,4 L8,6 10,10 10,12 9,14 8,15 6,15 5,14 5,13 M12,1 L13,2 19,4 22,6 23,8 24,11 24,14 23,17 22,19 M20,21 L17,22 14,22 11,21 5,18 4,18 3,19 M12,2 L13,3 19,5 21,6 22,7 M12,1 L12,3 13,4 19,6 21,7 23,9 24,11 M15,21 L13,21 7,18 6,18 M20,21 L16,21 13,20 9,18 6,17 4,17 3,19 3,21 4,22 5,21 4,20 M16,19 L18,17 20,17 24,21 25,21 M19,18 L20,18 23,21 M17,18 L18,18 22,22 24,22 26,20",
                "o": 14
            },
            {
                "d": "M3,12 L3,13 4,14 6,14 8,13 8,10 7,8 5,5 5,3 7,1 M7,10 L5,6 M6,14 L7,13 7,11 5,8 4,6 4,4 5,2 7,1 10,1 12,2 13,3 14,5 14,17 13,19 11,21 9,22 7,22 5,21 M12,3 L13,5 13,17 12,19 M10,1 L11,2 12,5 12,17 11,20 9,22 M3,17 L5,21 M2,20 L6,18 M3,17 L3,19 2,20 4,20 5,21 5,19 6,18 4,18 3,17 M14,4 L15,2 17,1 19,1 21,2 22,3 23,5 24,6 M21,3 L22,5 M19,1 L20,2 21,5 22,6 24,6 M24,6 L14,11 M16,10 L20,20 22,22 25,19 M17,10 L21,19 23,21 M18,9 L22,19 23,20 24,20",
                "o": 14
            },
            {
                "d": "M23,3 L22,2 23,1 24,2 24,4 23,6 21,6 17,4 14,3 10,3 6,4 4,6 M20,5 L17,3 14,2 10,2 7,3 M24,4 L23,5 21,5 17,2 14,1 10,1 7,2 5,4 4,6 3,9 3,13 4,16 5,18 7,20 9,21 12,22 16,22 19,21 21,20 23,18 24,15 24,12 23,10 21,9 18,9 16,10 14,13 12,14 10,14 M7,19 L9,20 12,21 16,21 20,20 M4,16 L6,18 8,19 11,20 16,20 20,19 22,18 23,17 24,15 M19,10 L18,10 14,14 13,14 M24,12 L22,10 20,10 18,11 16,14 14,15 12,15 10,14 9,12 9,10 10,8 12,7",
                "o": 14
            },
            {
                "d": "M6,9 L4,8 3,6 3,4 4,2 7,1 12,1 15,2 19,5 21,5 22,4 M4,3 L6,2 12,2 15,3 18,5 M3,6 L4,4 6,3 12,3 15,4 19,6 21,6 22,4 22,2 21,1 20,2 21,3 M15,4 L12,7 11,9 11,11 13,15 13,17 M12,10 L12,11 13,13 13,14 M12,7 L12,9 14,13 14,15 13,17 12,18 10,18 9,17 9,15 M4,20 L5,21 4,22 3,21 3,19 4,17 6,17 9,18 13,20 16,21 19,21 21,20 M6,18 L7,18 13,21 15,21 M3,19 L4,18 5,18 7,19 11,21 14,22 17,22 20,21 22,19",
                "o": 13
            },
            {
                "d": "M3,3 L4,3 5,4 5,18 3,19 M4,2 L6,3 6,19 9,21 M2,4 L5,1 7,3 7,18 9,20 11,20 M3,19 L4,19 6,20 8,22 11,20 15,17 M13,3 L14,3 15,4 15,20 17,22 20,19 M14,2 L16,3 16,20 18,21 M12,4 L15,1 18,3 17,4 17,19 18,20 19,20",
                "o": 11
            },
            {
                "d": "M3,12 L3,13 4,14 6,14 8,13 8,10 7,8 5,5 5,3 7,1 M7,10 L5,6 M6,14 L7,13 7,11 5,8 4,6 4,4 5,2 7,1 10,1 12,2 13,3 14,5 14,13 13,16 11,18 M12,3 L13,5 13,15 M10,1 L11,2 12,5 12,16 11,18 M14,4 L15,2 17,1 19,1 21,2 23,5 24,6 M21,3 L22,5 M19,1 L20,2 21,5 22,6 24,6 M22,6 L20,6 19,7 19,9 20,11 23,13 24,15 M20,10 L23,12 M19,8 L20,9 23,11 24,13 24,17 23,19 21,21 19,22 15,22 12,21 6,18 5,18 4,19 M16,21 L14,21 8,18 7,18 M22,20 L20,21 17,21 14,20 10,18 7,17 5,17 4,19 4,21 5,22 6,21 5,20",
                "o": 14
            },
            {
                "d": "M3,12 L3,13 4,14 6,14 8,13 8,10 7,8 5,5 5,3 7,1 M7,10 L5,6 M6,14 L7,13 7,11 5,8 4,6 4,4 5,2 7,1 10,1 12,2 13,3 14,5 14,9 13,12 11,15 9,17 M12,3 L13,5 13,10 12,13 M10,1 L11,2 12,5 12,10 11,14 9,17 M12,2 L14,1 17,1 19,2 M21,1 L18,2 17,4 17,8 18,11 20,14 21,16 21,18 20,20 M18,8 L18,9 21,14 21,15 M21,1 L19,2 18,4 18,7 19,9 21,12 22,15 22,17 21,19 19,21 17,22 13,22 11,21 9,19 7,18 5,18 4,19 M12,21 L9,18 8,18 M15,22 L13,21 10,18 8,17 5,17 4,19 4,21 5,22 6,21 5,20 M21,1 L24,1 26,2 28,5 29,6 M26,3 L27,5 M24,1 L25,2 26,5 27,6 29,6 M27,6 L25,6 24,7 24,9 25,11 28,13 29,15 M25,10 L28,12 M24,8 L25,9 28,11 29,13 29,18 28,20 27,21 25,22 22,22 19,21 M23,21 L22,21 20,20 M28,20 L26,21 24,21 22,20 21,19",
                "o": 17
            },
            {
                "d": "M5,3 L7,3 9,4 10,5 11,8 11,10 M11,12 L11,16 10,19 7,22 5,21 3,22 M8,21 L6,20 5,20 M9,20 L8,20 6,19 3,22 M7,2 L10,3 11,4 12,7 12,16 13,18 15,20 17,21 M3,4 L8,1 10,2 12,4 13,7 13,10 M13,12 L13,15 14,18 15,19 17,20 19,20 M11,16 L12,19 14,21 16,22 21,19 M13,7 L14,4 17,1 19,2 21,1 M16,2 L18,3 19,3 M15,3 L16,3 18,4 21,1 M5,14 L7,10 11,10 M13,10 L17,10 19,8 M7,11 L17,11 M5,14 L7,12 11,12 M13,12 L17,12 19,8",
                "o": 12
            },
            {
                "d": "M3,12 L3,13 4,14 6,14 8,13 8,10 7,8 5,5 5,3 7,1 M7,10 L5,6 M6,14 L7,13 7,11 5,8 4,6 4,4 5,2 7,1 10,1 12,2 13,3 14,5 14,10 13,13 12,15 12,16 14,18 15,18 M12,3 L13,5 13,11 12,14 11,16 14,19 M10,1 L11,2 12,5 12,11 11,15 10,17 13,20 16,17 M14,5 L22,1 M20,2 L20,21 19,24 M21,2 L21,19 20,22 M22,1 L22,17 21,21 20,23 18,25 15,26 11,26 8,25 6,23 5,21 6,20 7,21 6,22",
                "o": 13
            },
            {
                "d": "M8,4 L9,2 11,1 14,1 16,2 17,3 18,5 18,8 17,10 16,11 14,12 M11,12 L9,11 8,9 M16,3 L17,4 17,9 16,10 M14,1 L15,2 16,4 16,9 15,11 14,12 M7,16 L8,14 9,13 11,12 14,12 17,13 19,15 20,17 20,21 19,23 17,25 14,26 10,26 8,25 5,21 4,20 M18,15 L19,17 19,21 18,23 M14,12 L17,14 18,16 18,22 17,24 16,25 14,26 M9,25 L8,24 6,21 5,20 M12,26 L10,25 9,24 7,21 6,20 3,20 2,21 2,23 3,24 4,24",
                "o": 12
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M3,15 L11,10 19,15 M3,15 L11,11 19,15",
                "o": 11
            },
            {
                "d": "M0,29 L24,29",
                "o": 12
            },
            {
                "d": "M4,1 L9,7 M4,1 L3,2 9,7",
                "o": 6
            },
            {
                "d": "M10,8 L7,9 5,10 4,11 3,14 3,17 4,20 5,22 11,19 M4,17 L5,20 6,21 M7,9 L5,11 4,14 4,16 5,19 7,21 M8,9 L9,10 11,11 11,20 13,22 16,19 M9,9 L12,11 12,19 14,21 M10,8 L11,9 13,10 14,10 M13,11 L14,10 M13,11 L13,19 14,20 15,20",
                "o": 9
            },
            {
                "d": "M2,3 L3,4 4,6 M10,1 L7,2 5,4 4,6 4,19 3,20 M6,4 L5,6 5,19 8,21 M10,1 L8,2 7,3 6,6 6,19 8,20 9,21 M3,20 L4,20 6,21 7,22 10,21 M6,11 L12,8 13,10 14,13 14,16 13,19 12,20 10,21 M11,9 L12,10 13,12 M10,9 L12,11 13,14 13,16 12,19 10,21",
                "o": 9
            },
            {
                "d": "M7,9 L9,11 11,10 9,8 7,9 4,11 3,13 3,18 4,20 6,22 10,20 M8,9 L10,10 M5,11 L4,13 4,18 5,20 6,21 M6,10 L5,12 5,17 6,19 8,21",
                "o": 6
            },
            {
                "d": "M7,1 L4,4 4,6 5,7 9,9 12,11 13,13 13,16 12,19 10,21 M5,5 L5,6 9,8 12,10 13,11 M5,3 L5,4 6,5 11,8 13,10 14,13 14,16 13,19 10,21 7,22 M8,9 L4,11 4,19 3,20 M5,11 L5,19 8,21 M6,10 L6,19 8,20 9,21 M3,20 L4,20 6,21 7,22",
                "o": 9
            },
            {
                "d": "M5,16 L11,12 8,8 4,11 3,13 3,18 4,20 6,22 10,20 M10,12 L7,9 M5,11 L4,13 4,18 5,20 6,21 M9,13 L7,10 6,10 5,12 5,17 6,19 8,21",
                "o": 6
            },
            {
                "d": "M12,1 L11,2 9,2 7,1 5,1 4,3 4,8 3,10 2,11 M10,3 L8,3 6,2 5,2 M12,1 L11,3 10,4 8,4 6,3 5,3 4,4 M4,6 L5,8 6,9 8,10 10,10 10,11 M2,11 L4,11 M6,11 L10,11 M4,11 L4,15 5,27 M7,10 L4,10 5,9 5,22 M6,11 L6,15 5,27",
                "o": 7
            },
            {
                "d": "M10,8 L7,9 5,10 4,11 3,14 3,17 4,20 5,22 11,19 M4,18 L5,20 6,21 M7,9 L5,11 4,14 4,16 5,19 7,21 M8,9 L9,10 11,11 11,19 12,22 12,24 11,26 M9,9 L12,11 12,21 M10,8 L11,9 13,10 14,10 M13,11 L14,10 M13,11 L13,23 12,25 11,26 9,27 6,27 4,26 3,25 3,24 4,24 4,25",
                "o": 9
            },
            {
                "d": "M2,3 L3,4 4,6 M10,1 L7,2 5,4 4,6 4,19 3,20 M6,4 L5,6 5,20 6,21 M10,1 L8,2 7,3 6,6 6,19 7,20 8,20 M3,20 L5,21 6,22 9,19 M6,11 L12,8 13,10 14,14 14,18 13,21 12,23 10,25 7,27 M11,9 L12,10 13,13 M10,9 L12,12 13,15 13,18 12,22 10,25",
                "o": 9
            },
            {
                "d": "M5,1 L4,2 4,3 5,4 6,3 6,2 5,1 M4,2 L6,3 M4,3 L6,2 M2,10 L3,10 4,11 4,20 6,22 9,19 M3,9 L5,10 5,19 7,21 M1,11 L4,8 5,9 7,10 M6,11 L7,10 M6,11 L6,19 7,20 8,20",
                "o": 5
            },
            {
                "d": "M5,1 L4,2 4,3 5,4 6,3 6,2 5,1 M4,2 L6,3 M4,3 L6,2 M2,10 L3,10 4,11 4,22 3,25 2,26 0,27 M3,9 L5,10 5,22 4,24 M1,11 L4,8 5,9 7,10 M6,11 L7,10 M6,11 L6,22 5,24 3,26 0,27 M6,22 L7,24 8,25",
                "o": 5
            },
            {
                "d": "M3,3 L4,4 5,6 M10,1 L8,2 6,4 5,6 5,8 4,10 3,11 M5,11 L5,19 4,20 M7,4 L6,6 6,8 M6,10 L5,10 6,8 6,19 8,21 M10,1 L8,3 7,6 7,10 M7,11 L7,19 8,20 9,20 M4,20 L6,21 7,22 10,19 M7,7 L11,4 12,5 12,7 10,9 8,10 M10,5 L11,6 11,7 10,9 M7,10 L12,10 12,11 M3,11 L5,11 M7,11 L12,11",
                "o": 7
            },
            {
                "d": "M2,3 L3,4 4,6 M10,1 L7,2 5,4 4,6 4,19 3,20 M6,4 L5,6 5,20 7,21 M10,1 L8,2 7,3 6,6 6,19 7,20 8,20 M3,20 L5,21 6,22 9,19",
                "o": 5
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 3,20 5,22 M3,9 L5,11 5,19 4,20 5,21 6,20 5,19 M1,11 L4,8 6,10 6,19 7,20 5,22 M9,9 L11,10 12,12 12,19 11,20 13,22 M11,9 L12,10 13,12 13,19 12,20 13,21 14,20 13,19 M6,11 L9,9 11,8 13,9 14,11 14,19 15,20 13,22 M17,9 L18,10 20,11 20,20 22,22 25,19 M18,9 L21,11 21,19 23,21 M14,11 L17,9 19,8 20,9 22,10 23,10 M22,11 L23,10 M22,11 L22,19 23,20 24,20",
                "o": 13
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 3,20 5,22 M3,9 L5,11 5,19 4,20 5,21 6,20 5,19 M1,11 L4,8 6,10 6,19 7,20 5,22 M9,9 L10,10 12,11 12,20 14,22 17,19 M10,9 L13,11 13,19 15,21 M6,11 L9,9 11,8 12,9 14,10 15,10 M14,11 L15,10 M14,11 L14,19 15,20 16,20",
                "o": 9
            },
            {
                "d": "M4,11 L4,19 3,20 M5,11 L5,19 8,21 M7,10 L6,11 6,19 8,20 9,21 M3,20 L4,20 6,21 7,22 10,21 M4,11 L7,10 12,8 13,10 14,13 14,16 13,19 12,20 10,21 M11,9 L12,10 13,12 M10,9 L12,11 13,14 13,16 12,19 10,21",
                "o": 9
            },
            {
                "d": "M5,5 L3,7 3,9 4,12 4,19 2,21 M4,20 L5,27 M4,8 L4,9 5,12 5,22 M4,6 L4,7 5,9 6,12 6,19 7,19 9,20 10,21 M6,20 L5,27 M9,21 L7,20 M10,21 L8,22 6,20 M4,20 L2,21 M6,11 L12,8 13,10 14,13 14,16 13,19 12,20 10,21 M11,9 L12,10 13,12 M10,9 L12,11 13,14 13,16 12,19 10,21",
                "o": 9
            },
            {
                "d": "M10,8 L7,9 5,10 4,11 3,14 3,17 4,20 5,22 11,19 M4,18 L5,20 6,21 M7,9 L5,11 4,14 4,16 5,19 7,21 M8,9 L9,10 11,11 11,19 12,27 M9,9 L12,11 12,22 M10,8 L11,9 13,10 14,10 M13,11 L14,10 M13,11 L13,19 12,27",
                "o": 9
            },
            {
                "d": "M3,10 L4,10 5,11 5,19 4,20 M4,9 L6,11 6,20 8,21 M2,11 L5,8 7,10 7,19 8,20 9,20 M4,20 L6,21 7,22 10,19 M9,9 L10,11 12,10 11,8 7,10 M10,9 L11,10",
                "o": 7
            },
            {
                "d": "M12,1 L11,2 9,2 7,1 5,1 4,3 4,8 3,10 2,11 M10,3 L8,3 6,2 5,2 M12,1 L11,3 10,4 8,4 6,3 5,3 4,4 M4,6 L6,11 M4,11 L4,15 5,27 M5,10 L4,10 5,9 5,22 M6,11 L6,15 5,27 M2,11 L4,11",
                "o": 5
            },
            {
                "d": "M7,4 L6,7 5,9 4,10 2,11 M7,4 L7,10 10,10 10,11 M2,11 L5,11 M7,11 L10,11 M5,11 L5,19 4,20 M6,10 L5,10 6,8 6,19 8,21 M7,11 L7,19 8,20 9,20 M4,20 L6,21 7,22 10,19",
                "o": 6
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 3,20 M3,9 L5,11 5,19 7,21 M1,11 L4,8 6,10 6,19 8,20 9,21 M3,20 L4,20 6,21 7,22 9,21 12,19 M13,8 L11,10 12,11 12,20 14,22 17,19 M13,11 L14,10 13,9 12,10 13,11 13,19 15,21 M13,8 L15,10 14,11 14,19 15,20 16,20",
                "o": 9
            },
            {
                "d": "M5,6 L3,8 3,10 4,13 4,19 3,20 M4,9 L4,10 5,13 5,19 8,21 M4,7 L4,8 5,10 6,13 6,19 8,20 9,21 M3,20 L4,20 6,21 7,22 10,21 M6,11 L12,8 13,10 14,13 14,16 13,19 12,20 10,21 M11,9 L12,10 13,12 M10,9 L12,11 13,14 13,16 12,19 10,21",
                "o": 9
            },
            {
                "d": "M5,6 L3,8 3,10 4,13 4,19 3,20 5,22 M4,9 L4,10 5,13 5,19 4,20 5,21 6,20 5,19 M4,7 L4,8 5,10 6,13 6,19 7,20 5,22 M9,9 L11,10 12,12 12,19 11,20 M11,9 L12,10 13,12 13,19 16,21 M6,11 L9,9 11,8 13,9 14,11 14,19 16,20 17,21 M11,20 L12,20 14,21 15,22 18,21 M14,11 L20,8 21,10 22,13 22,15 21,19 20,20 18,21 M19,9 L20,10 21,12 M18,9 L20,11 21,14 21,16 20,19 18,21",
                "o": 13
            },
            {
                "d": "M4,10 L5,10 6,11 6,19 5,19 3,20 2,22 2,24 3,26 5,27 8,27 11,26 11,25 10,25 10,26 M5,9 L7,11 7,19 10,21 M3,11 L6,8 8,10 8,19 10,20 11,21 M13,20 L9,22 8,21 6,20 4,20 2,22 M10,9 L11,11 13,10 12,8 8,10 M11,9 L12,10",
                "o": 8
            },
            {
                "d": "M5,6 L3,8 3,10 4,13 4,19 3,20 M4,9 L4,10 5,13 5,20 7,21 M4,7 L4,8 5,10 6,13 6,19 7,20 8,20 M3,20 L5,21 6,22 9,19 M6,11 L12,8 13,10 14,14 14,18 13,21 12,23 10,25 7,27 M11,9 L12,10 13,13 M10,9 L12,12 13,15 13,18 12,22 10,25",
                "o": 9
            },
            {
                "d": "M3,11 L8,8 10,9 11,11 11,13 10,15 6,17 M8,9 L10,10 M7,9 L9,10 10,12 10,13 9,15 8,16 M8,16 L10,18 11,20 11,24 10,26 8,27 6,27 4,26 3,24 3,22 4,20 6,19 12,17 M7,17 L9,18 10,20 M6,17 L9,19 10,21 10,24 9,26 8,27",
                "o": 7
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "gothicita": {
        "name": "Gothic Italian\t",
        "chars": [
            {
                "d": "M6,1 L5,2 3,3 5,4 6,15 M6,4 L7,3 6,2 5,3 6,4 6,15 M6,1 L7,2 9,3 7,4 6,15 M6,19 L4,21 6,22 8,21 6,19 M6,20 L5,21 7,21 6,20",
                "o": 6
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8 M14,1 L13,2 13,8 M14,2 L13,8 M14,1 L15,2 13,8",
                "o": 9
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M8,-3 L8,26 M12,-3 L12,26 M12,1 L14,2 15,4 15,6 17,5 16,3 15,2 12,1 8,1 5,2 3,4 3,7 4,9 7,11 13,13 15,14 16,16 16,19 15,21 M16,5 L15,3 M4,7 L5,9 7,10 13,12 15,13 16,15 M5,20 L4,18 M5,2 L4,4 4,6 5,8 7,9 13,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 5,17 5,19 6,21 8,22",
                "o": 10
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M20,9 L21,10 22,10 23,9 M19,10 L20,11 22,11 M19,11 L20,12 21,12 22,11 23,9 M20,9 L14,15 M13,16 L7,22 3,17 9,11 M10,10 L14,6 10,1 5,7 11,13 15,19 17,21 19,22 21,22 22,21 23,19 M7,21 L4,17 M13,6 L10,2 M6,7 L11,12 15,18 17,20 19,21 22,21 M8,21 L4,16 M13,7 L9,2 M6,6 L12,12 16,18 17,19 19,20 22,20 23,19",
                "o": 13
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8",
                "o": 5
            },
            {
                "d": "M10,-3 L8,-1 6,2 4,6 3,11 3,15 4,20 6,24 8,27 10,29 M6,3 L5,6 4,10 4,16 5,20 6,23 M8,-1 L7,1 6,4 5,10 5,16 6,22 7,25 8,27",
                "o": 7
            },
            {
                "d": "M4,-3 L6,-1 8,2 10,6 11,11 11,15 10,20 8,24 6,27 4,29 M8,3 L9,6 10,10 10,16 9,20 8,23 M6,-1 L7,1 8,4 9,10 9,16 8,22 7,25 6,27",
                "o": 7
            },
            {
                "d": "M8,1 L7,2 9,12 8,13 M8,1 L8,13 M8,1 L9,2 7,12 8,13 M3,4 L4,4 12,10 13,10 M3,4 L13,10 M3,4 L3,5 13,9 13,10 M13,4 L12,4 4,10 3,10 M13,4 L3,10 M13,4 L13,5 3,9 3,10",
                "o": 8
            },
            {
                "d": "M12,4 L12,21 13,21 M12,4 L13,4 13,21 M4,12 L21,12 21,13 M4,12 L4,13 21,13",
                "o": 13
            },
            {
                "d": "M6,25 L6,23 4,21 6,19 7,21 7,23 6,25 4,26 M6,20 L5,21 6,22 6,20",
                "o": 6
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M6,19 L4,21 6,22 8,21 6,19 M6,20 L5,21 7,21 6,20",
                "o": 6
            },
            {
                "d": "M20,-3 L2,29 3,29 M20,-3 L21,-3 3,29",
                "o": 12
            },
            {
                "d": "M4,3 L4,19 2,20 M5,4 L5,19 8,21 M6,3 L6,19 8,20 9,21 M4,3 L6,3 11,2 13,1 M11,2 L12,3 14,4 14,20 M12,2 L15,4 15,19 M13,1 L14,2 16,3 18,3 16,4 16,20 M2,20 L4,20 6,21 7,22 9,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M7,3 L8,4 9,6 9,19 7,20 M9,4 L8,3 9,2 10,4 10,20 12,21 M7,3 L10,1 11,3 11,19 13,20 14,20 M7,20 L8,20 10,21 11,22 12,21 14,20",
                "o": 10
            },
            {
                "d": "M4,3 L6,3 8,2 9,1 11,2 14,3 16,3 M8,3 L10,2 M4,3 L6,4 8,4 10,3 11,2 M14,3 L14,11 M15,4 L15,10 M16,3 L16,11 9,11 6,12 4,14 3,17 3,22 M3,22 L7,20 11,19 14,19 18,20 M6,21 L9,20 14,20 17,21 M3,22 L8,21 13,21 16,22 18,20",
                "o": 10
            },
            {
                "d": "M4,3 L5,3 7,2 8,1 10,2 14,3 16,3 M7,3 L9,2 M4,3 L6,4 8,4 10,2 M14,3 L14,10 M15,4 L15,9 M16,3 L16,10 14,10 11,11 9,12 M9,11 L11,12 14,13 16,13 16,20 M15,14 L15,19 M14,13 L14,20 M3,20 L5,19 7,19 9,20 10,21 M7,20 L9,21 M3,20 L5,20 7,21 8,22 10,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M13,1 L3,11 3,16 12,16 M14,16 L18,16 19,17 19,15 18,16 M4,11 L4,15 M5,9 L5,16 M12,2 L12,19 10,20 M13,5 L14,3 13,2 13,20 15,21 M13,1 L15,3 14,5 14,19 16,20 17,20 M10,20 L11,20 13,21 14,22 15,21 17,20",
                "o": 10
            },
            {
                "d": "M4,1 L4,10 M4,1 L16,1 M5,2 L14,2 M4,3 L13,3 15,2 16,1 M14,7 L13,8 11,9 7,10 4,10 M11,9 L12,9 14,10 14,20 M13,8 L15,9 15,19 M14,7 L15,8 17,9 18,9 16,10 16,20 M3,20 L5,19 7,19 9,20 10,21 M7,20 L9,21 M3,20 L5,20 7,21 8,22 10,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M4,3 L4,19 2,20 M5,4 L5,19 8,21 M6,3 L6,19 8,20 9,21 M4,3 L6,3 10,2 12,1 13,2 15,3 16,3 M11,2 L13,3 M10,2 L12,4 14,4 16,3 M6,11 L7,11 11,10 13,9 14,8 M11,10 L12,10 14,11 14,20 M13,9 L15,11 15,19 M14,8 L15,9 17,10 18,10 16,11 16,20 M2,20 L4,20 6,21 7,22 9,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M3,3 L5,1 8,2 13,2 18,1 M4,2 L7,3 12,3 15,2 M3,3 L7,4 10,4 14,3 18,1 M18,1 L17,3 15,6 11,10 9,13 8,16 8,19 9,22 M10,12 L9,15 9,18 10,21 M13,8 L11,11 10,14 10,17 11,20 9,22",
                "o": 10
            },
            {
                "d": "M4,4 L4,10 M5,5 L5,9 M6,4 L6,10 M4,4 L6,4 11,3 13,2 14,1 M11,3 L12,3 14,4 14,10 M13,2 L15,3 15,9 M14,1 L15,2 17,3 18,3 16,4 16,10 M4,10 L6,10 14,13 16,13 M16,10 L14,10 6,13 4,13 M4,13 L4,19 2,20 M5,14 L5,19 8,21 M6,13 L6,19 8,20 9,21 M14,13 L14,20 M15,14 L15,19 M16,13 L16,20 M2,20 L4,20 6,21 7,22 9,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M4,3 L4,12 2,13 M5,4 L5,13 7,14 M6,3 L6,12 8,13 9,13 M4,3 L6,3 11,2 13,1 M11,2 L12,3 14,4 14,20 M12,2 L15,4 15,19 M13,1 L14,2 16,3 18,3 16,4 16,20 M2,13 L3,13 5,14 6,15 7,14 9,13 13,12 14,12 M3,20 L5,19 7,19 9,20 10,21 M7,20 L9,21 M3,20 L5,20 7,21 8,22 10,21 14,20 16,20",
                "o": 10
            },
            {
                "d": "M6,8 L4,10 6,11 8,10 6,8 M6,9 L5,10 7,10 6,9 M6,19 L4,21 6,22 8,21 6,19 M6,20 L5,21 7,21 6,20",
                "o": 6
            },
            {
                "d": "M6,8 L4,10 6,11 8,10 6,8 M6,9 L5,10 7,10 6,9 M6,25 L6,23 4,21 6,19 7,21 7,23 6,25 4,26 M6,20 L5,21 6,22 6,20",
                "o": 6
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,8 L21,8 21,9 M4,8 L4,9 21,9 M4,16 L21,16 21,17 M4,16 L4,17 21,17",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M3,5 L4,3 5,2 8,1 10,1 13,2 14,3 15,5 15,7 14,9 12,11 10,12 M4,5 L5,3 M13,3 L14,4 14,8 13,9 M3,5 L5,6 5,4 6,2 8,1 M10,1 L12,2 13,4 13,8 12,10 10,12 M9,12 L9,15 10,12 8,12 9,15 M9,19 L7,21 9,22 11,21 9,19 M9,20 L8,21 10,21 9,20",
                "o": 9
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M9,3 L7,4 5,6 4,8 3,11 3,14 4,16 6,17 M5,7 L4,10 4,14 5,16 M9,3 L7,5 6,7 5,10 5,13 6,17 6,19 5,21 3,22 M17,3 L19,3 19,20 17,20 M20,3 L20,20 M21,2 L21,21 M3,1 L6,2 12,3 17,3 21,2 23,1 M5,11 L19,11 M3,22 L6,21 12,20 17,20 21,21 23,22",
                "o": 13
            },
            {
                "d": "M7,2 L7,21 M8,2 L8,21 M11,1 L9,2 9,21 11,22 M3,5 L5,3 7,2 11,1 16,1 19,2 21,4 21,6 20,8 M19,3 L20,4 20,6 19,8 M16,1 L18,2 19,4 19,6 18,7 M12,16 L10,15 9,13 9,11 10,9 11,8 14,7 17,7 20,8 22,10 23,12 23,15 22,18 20,20 18,21 15,22 11,22 7,21 5,20 3,18 M21,10 L22,12 22,16 21,18 M17,7 L20,9 21,12 21,16 20,19 18,21",
                "o": 13
            },
            {
                "d": "M23,1 L22,3 21,5 19,3 17,2 14,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 14,22 17,21 19,20 21,18 22,20 23,22 M22,3 L21,8 21,15 22,20 M21,6 L20,5 M21,9 L20,6 19,4 17,2 M5,6 L4,9 4,14 5,17 M9,2 L7,4 6,6 5,9 5,14 6,17 7,19 9,21 M20,18 L21,17 M17,21 L19,19 20,17 21,14",
                "o": 13
            },
            {
                "d": "M6,2 L6,21 M7,2 L7,21 M9,1 L8,2 8,21 9,22 M3,6 L4,4 6,2 9,1 14,1 17,2 19,3 21,5 22,7 23,10 23,13 22,16 21,18 19,20 17,21 14,22 9,22 6,21 4,19 3,17 M21,6 L22,9 22,14 21,17 M17,2 L19,4 20,6 21,9 21,14 20,17 19,19 17,21",
                "o": 13
            },
            {
                "d": "M23,1 L22,3 21,5 19,3 17,2 14,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 14,22 17,21 19,20 21,18 22,20 23,22 M22,3 L21,8 21,15 22,20 M21,6 L20,5 M21,8 L19,4 17,2 M5,6 L4,9 4,14 5,17 M9,2 L7,4 6,6 5,9 5,14 6,17 7,19 9,21 M20,18 L21,17 M17,21 L19,19 20,17 21,14 M5,11 L6,10 9,10 16,12 19,12 21,11 M11,11 L13,12 16,13 18,13 20,12 M8,10 L13,13 16,14 18,14 20,13 21,11 M21,8 L20,7 19,7 18,8 19,9 20,8",
                "o": 13
            },
            {
                "d": "M5,3 L5,21 M8,2 L6,3 6,20 M10,1 L8,2 7,4 7,20 9,20 M3,5 L5,3 7,2 10,1 14,1 17,2 19,3 20,4 23,1 M23,1 L22,3 21,7 21,10 22,14 23,16 M21,4 L20,6 M17,2 L19,4 20,7 21,10 M7,11 L8,10 10,10 15,11 18,11 20,10 M12,11 L15,12 17,12 19,11 M9,10 L15,13 17,13 19,12 20,10 20,7 19,6 18,6 17,7 18,8 19,7 M3,22 L5,21 9,20 14,20 20,21 23,22",
                "o": 13
            },
            {
                "d": "M23,1 L22,3 21,5 19,3 17,2 14,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 17,21 19,20 20,19 21,17 22,20 23,22 M22,3 L21,8 21,15 22,20 M21,6 L20,5 M21,9 L20,6 19,4 17,2 M5,6 L4,9 4,14 5,17 M9,2 L7,4 6,6 5,9 5,14 6,17 7,19 9,21 M19,19 L20,17 20,13 M17,21 L18,20 19,17 19,12 M6,14 L7,13 8,14 7,15 6,15 5,14 M5,11 L6,9 8,8 10,8 13,9 16,11 18,12 M6,10 L8,9 10,9 13,10 15,11 M5,11 L7,10 10,10 16,12 20,12 21,11",
                "o": 13
            },
            {
                "d": "M5,2 L5,21 3,22 M6,3 L6,21 M9,3 L7,3 7,21 M3,1 L5,2 9,3 14,3 20,2 23,1 M7,11 L8,9 10,7 13,6 17,6 20,7 22,9 23,12 23,15 22,16 20,17 M21,9 L22,11 22,14 21,16 M17,6 L19,7 20,8 21,10 21,14 20,17 20,19 21,21 23,22 M3,22 L7,21 11,21 16,22",
                "o": 13
            },
            {
                "d": "M12,4 L12,20 M13,5 L13,19 M14,4 L14,20 M3,1 L7,3 11,4 15,4 19,3 23,1 M3,22 L6,21 10,20 16,20 20,21 23,22",
                "o": 13
            },
            {
                "d": "M15,4 L17,4 17,19 16,21 14,22 M18,4 L18,19 17,20 M19,3 L19,20 M3,1 L7,3 11,4 15,4 19,3 23,1 M4,10 L3,12 3,16 4,19 6,21 9,22 14,22 17,21 19,20 21,18 23,15 M4,16 L5,19 6,20 M3,14 L5,16 6,19 7,21 9,22",
                "o": 13
            },
            {
                "d": "M5,2 L5,21 3,22 M6,3 L6,21 M9,3 L7,3 7,21 M3,1 L5,2 9,3 14,3 20,2 23,1 M7,11 L8,9 10,7 13,6 16,6 19,7 20,8 20,10 19,11 14,13 12,14 11,15 11,16 12,17 13,16 12,15 M18,7 L19,8 19,10 18,11 M16,6 L18,8 18,10 17,11 14,13 M14,13 L17,13 20,14 21,16 21,18 20,19 M18,14 L20,16 20,18 M14,13 L17,14 19,16 20,19 21,21 22,22 23,22 M3,22 L7,21 11,21 16,22",
                "o": 13
            },
            {
                "d": "M5,2 L5,21 M6,3 L6,20 M9,3 L7,3 7,20 9,20 M23,6 L21,9 20,11 19,14 19,16 20,18 22,19 M21,10 L20,13 20,16 21,18 M23,6 L22,8 21,12 21,15 22,19 23,22 M3,1 L5,2 9,3 14,3 20,2 23,1 M3,22 L5,21 9,20 14,20 20,21 23,22",
                "o": 13
            },
            {
                "d": "M12,4 L12,20 M13,5 L13,19 M14,4 L14,20 M9,20 L7,18 5,17 4,16 3,13 3,8 4,5 6,3 8,2 11,1 15,1 18,2 20,3 22,5 23,8 23,13 22,16 21,17 19,18 17,20 M5,16 L4,13 4,8 5,5 M7,18 L6,16 5,13 5,7 6,4 8,2 M21,5 L22,8 22,13 21,16 M18,2 L20,4 21,7 21,13 20,16 19,18 M3,1 L7,3 11,4 15,4 19,3 23,1 M3,22 L6,21 10,20 16,20 20,21 23,22",
                "o": 13
            },
            {
                "d": "M5,3 L5,21 3,22 M7,3 L6,4 6,21 M10,1 L8,2 7,4 7,21 M3,5 L5,3 7,2 10,1 14,1 17,2 19,3 21,5 22,7 23,10 23,14 22,16 20,17 M21,6 L22,9 22,13 21,16 M17,2 L19,4 20,6 21,9 21,13 20,17 20,19 21,21 22,22 23,22 M3,22 L7,21 11,21 16,22",
                "o": 13
            },
            {
                "d": "M12,1 L9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 14,22 17,21 19,20 21,18 22,16 23,13 23,10 22,7 21,5 19,3 17,2 14,1 12,1 M5,6 L4,9 4,14 5,17 M9,2 L7,4 6,6 5,9 5,14 6,17 7,19 9,21 M21,17 L22,14 22,9 21,6 M17,21 L19,19 20,17 21,14 21,9 20,6 19,4 17,2",
                "o": 13
            },
            {
                "d": "M5,4 L5,21 M8,3 L6,5 6,20 M12,1 L10,2 8,4 7,6 7,20 9,20 M3,6 L5,4 9,2 12,1 15,1 18,2 20,3 22,5 23,8 23,10 22,13 20,15 17,16 13,16 10,15 8,13 7,10 M21,5 L22,7 22,11 21,13 M18,2 L20,4 21,7 21,11 20,14 17,16 M3,22 L5,21 9,20 14,20 20,21 23,22",
                "o": 13
            },
            {
                "d": "M12,1 L9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 14,22 17,21 19,20 21,18 22,16 23,13 23,10 22,7 21,5 19,3 17,2 14,1 12,1 M5,6 L4,9 4,14 5,17 M9,2 L7,4 6,6 5,9 5,14 6,17 7,19 9,21 M21,17 L22,14 22,9 21,6 M17,21 L19,19 20,17 21,14 21,9 20,6 19,4 17,2 M5,14 L6,16 9,17 15,18 22,18 23,19 23,21 22,22 22,21 23,20 M11,18 L13,18 M6,16 L9,18 12,19 14,19 15,18",
                "o": 13
            },
            {
                "d": "M5,4 L5,21 3,22 M6,4 L6,21 M7,3 L7,21 M3,6 L5,4 7,3 9,2 12,1 16,1 20,2 22,4 23,6 23,9 22,11 21,12 M20,3 L21,4 22,6 22,9 21,11 M16,1 L18,2 20,4 21,6 21,10 20,12 M19,13 L16,14 13,14 11,13 11,11 13,10 16,10 19,11 21,13 23,16 23,18 22,19 21,19 M19,12 L20,13 22,17 22,18 21,15 M15,10 L17,11 19,13 20,15 21,19 22,21 23,22 M3,22 L7,21 11,21 16,22",
                "o": 13
            },
            {
                "d": "M15,1 L21,2 23,1 22,3 22,5 20,3 18,2 15,1 11,1 8,2 5,5 4,8 4,10 5,13 7,15 10,16 13,16 15,15 16,14 17,12 17,11 M22,2 L21,3 22,5 M5,11 L6,13 7,14 10,15 13,15 15,14 M6,4 L5,6 5,9 6,11 8,13 11,14 13,14 15,13 17,11 18,10 19,10 M7,12 L8,12 9,11 11,9 13,8 16,8 18,9 20,11 21,13 21,16 20,19 18,21 M11,8 L13,7 16,7 19,8 21,10 22,13 22,16 21,18 M4,18 L5,20 4,21 M9,11 L9,10 10,8 11,7 13,6 16,6 19,7 22,10 23,13 23,15 22,18 20,20 18,21 15,22 11,22 8,21 6,20 4,18 4,20 3,22 5,21 11,22",
                "o": 13
            },
            {
                "d": "M12,3 L8,3 6,4 5,5 4,7 3,10 3,14 4,17 5,19 6,20 8,21 11,22 14,22 17,21 19,20 21,18 22,16 23,13 23,9 22,6 20,4 18,3 M16,3 L15,4 15,6 16,7 17,6 16,5 M4,14 L5,17 7,19 9,20 12,21 15,21 18,20 M5,5 L4,9 4,12 5,15 7,18 9,19 12,20 15,20 18,19 20,18 22,15 23,13 M3,1 L6,4 M6,3 L7,2 M4,2 L5,2 6,1 8,2 12,3 18,3 21,2 23,1",
                "o": 13
            },
            {
                "d": "M7,3 L5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 16,22 19,21 21,20 M6,5 L5,7 4,10 4,14 5,17 M6,4 L7,5 7,6 6,8 5,11 5,14 6,17 7,19 9,21 M17,3 L19,3 19,19 18,21 16,22 M20,3 L20,19 19,20 M21,2 L21,20 23,22 M3,1 L6,2 12,3 17,3 21,2 23,1",
                "o": 13
            },
            {
                "d": "M3,1 L13,22 M4,2 L5,3 12,18 13,20 M5,2 L6,3 13,18 14,19 M23,1 L13,22 M18,9 L16,14 M20,7 L16,12 15,15 15,17 M3,1 L5,2 10,3 16,3 21,2 23,1",
                "o": 13
            },
            {
                "d": "M7,3 L5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 14,22 17,21 19,20 21,18 22,16 23,13 23,10 22,7 21,5 19,3 M5,7 L4,10 4,13 5,16 6,18 M5,5 L6,6 6,7 5,10 5,13 6,17 7,19 9,21 M20,18 L21,16 22,13 22,10 21,7 M17,21 L19,19 20,17 21,13 21,10 20,7 20,6 21,5 M12,4 L12,22 M13,5 L13,21 M14,4 L14,22 M3,1 L7,3 11,4 15,4 19,3 23,1",
                "o": 13
            },
            {
                "d": "M3,1 L19,20 20,21 M4,2 L6,3 21,21 M7,3 L23,22 M23,1 L14,11 M12,13 L5,21 M11,14 L8,16 7,18 M12,13 L8,15 7,16 6,18 6,20 M3,1 L7,3 11,4 15,4 19,3 23,1 M3,22 L5,21 9,20 14,20 20,21 23,22",
                "o": 13
            },
            {
                "d": "M19,3 L19,21 M20,3 L20,20 M21,2 L21,20 M6,3 L4,5 3,8 3,11 4,14 6,16 8,17 11,18 14,18 17,17 19,16 M7,16 L10,17 16,17 M3,11 L4,13 6,15 9,16 15,16 17,17 M3,1 L7,3 11,4 15,4 19,3 23,1 M3,18 L5,20 7,21 11,22 15,22 19,21 23,19",
                "o": 13
            },
            {
                "d": "M3,1 L4,2 6,3 9,3 14,1 17,1 20,2 21,4 21,6 20,8 M19,2 L20,4 20,6 19,8 M17,1 L18,2 19,4 19,7 M19,9 L15,10 13,10 11,9 11,7 13,6 15,6 19,7 M15,6 L17,7 18,8 17,9 15,10 M20,8 L22,10 23,13 23,15 22,18 20,20 18,21 15,22 11,22 8,21 6,20 4,18 3,15 3,13 4,10 5,9 7,8 9,8 11,9 11,11 10,12 9,11 10,10 M19,8 L21,10 22,12 22,16 21,18 M19,9 L20,10 21,12 21,16 20,19 18,21",
                "o": 13
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M3,15 L11,10 19,15 M3,15 L11,11 19,15",
                "o": 11
            },
            {
                "d": "M0,29 L26,29",
                "o": 13
            },
            {
                "d": "M4,1 L9,7 M4,1 L3,2 9,7",
                "o": 6
            },
            {
                "d": "M6,12 L3,15 3,19 6,22 10,20 M4,15 L4,19 6,21 M5,13 L5,18 8,21 M8,14 L3,9 4,8 5,9 4,10 M5,9 L9,9 11,8 13,10 13,19 14,20 M11,9 L12,10 12,19 11,20 12,21 13,20 12,19 M9,9 L11,11 11,19 10,20 12,22 14,20",
                "o": 9
            },
            {
                "d": "M5,3 L3,1 4,5 4,19 7,22 12,20 14,19 M5,3 L5,19 7,21 M5,3 L7,1 6,5 6,18 9,21 M6,10 L11,8 14,11 14,19 M11,9 L13,11 13,19 M9,9 L12,12 12,20",
                "o": 8
            },
            {
                "d": "M3,11 L3,20 5,22 7,20 M4,11 L4,20 5,21 M5,10 L5,19 6,20 7,20 M3,11 L9,8 11,10 9,11 7,9 M8,9 L10,10",
                "o": 5
            },
            {
                "d": "M8,8 L3,11 3,19 6,22 8,21 11,20 13,20 M4,11 L4,19 6,21 M5,10 L5,18 8,21 M6,4 L6,1 7,4 13,11 13,20 M6,4 L12,11 12,19 M6,4 L3,4 6,5 11,11 11,20",
                "o": 8
            },
            {
                "d": "M3,11 L3,20 5,22 7,20 M4,11 L4,20 5,21 M5,10 L5,19 6,20 7,20 M3,11 L9,8 12,12 5,16 M8,9 L11,12 M7,9 L10,13",
                "o": 6
            },
            {
                "d": "M4,4 L4,19 3,20 5,22 M5,4 L5,19 4,20 5,21 6,20 5,19 M6,3 L6,19 7,20 5,22 M4,4 L10,1 12,3 10,4 8,2 M9,2 L11,3 M1,8 L4,8 M6,8 L10,8",
                "o": 5
            },
            {
                "d": "M3,11 L3,19 6,22 11,20 M4,11 L4,19 6,21 M5,10 L5,18 8,21 M3,11 L5,10 10,8 13,11 13,24 12,26 11,27 9,28 7,28 5,27 3,28 5,29 7,28 M10,9 L12,11 12,24 11,26 M6,28 L4,28 M8,9 L11,12 11,25 10,27 9,28",
                "o": 9
            },
            {
                "d": "M5,3 L3,1 4,5 4,19 3,20 5,22 M5,3 L5,19 4,20 5,21 6,20 5,19 M5,3 L7,1 6,5 6,19 7,20 5,22 M6,10 L9,9 11,8 14,11 14,20 11,24 11,27 12,29 13,29 11,27 M11,9 L13,11 13,20 12,22 M9,9 L12,12 12,21 11,24",
                "o": 9
            },
            {
                "d": "M5,1 L3,3 5,5 7,3 5,1 M5,2 L4,3 5,4 6,3 5,2 M5,8 L3,10 4,11 4,19 3,20 5,22 M5,11 L6,10 5,9 4,10 5,11 5,19 4,20 5,21 6,20 5,19 M5,8 L7,10 6,11 6,19 7,20 5,22",
                "o": 5
            },
            {
                "d": "M5,1 L3,3 5,5 7,3 5,1 M5,2 L4,3 5,4 6,3 5,2 M5,8 L3,10 4,11 4,20 7,24 M5,11 L6,10 5,9 4,10 5,11 5,20 6,22 M5,8 L7,10 6,11 6,21 7,24 7,27 5,29 3,28 3,29 5,29",
                "o": 5
            },
            {
                "d": "M5,3 L3,1 4,5 4,19 3,20 5,22 M5,3 L5,19 4,20 5,21 6,20 5,19 M5,3 L7,1 6,5 6,19 7,20 5,22 M6,11 L9,9 11,8 13,11 10,13 6,16 M10,9 L12,11 M9,9 L11,12 M9,14 L10,15 11,20 13,22 15,20 M10,14 L11,16 12,20 13,21 M10,13 L11,14 13,19 14,20 15,20",
                "o": 8
            },
            {
                "d": "M5,3 L3,1 4,5 4,19 3,20 5,22 M5,3 L5,19 4,20 5,21 6,20 5,19 M5,3 L7,1 6,5 6,19 7,20 5,22",
                "o": 5
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 3,20 5,22 M4,9 L5,10 5,19 4,20 5,21 6,20 5,19 M2,10 L4,8 6,10 6,19 7,20 5,22 M6,10 L9,9 11,8 14,10 14,19 15,20 13,22 M11,9 L13,10 13,19 12,20 13,21 14,20 13,19 M9,9 L12,11 12,19 11,20 13,22 M14,10 L17,9 19,8 22,10 22,19 23,20 21,22 M19,9 L21,10 21,19 20,20 21,21 22,20 21,19 M17,9 L20,11 20,19 19,20 21,22",
                "o": 13
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 3,20 5,22 M4,9 L5,10 5,19 4,20 5,21 6,20 5,19 M2,10 L4,8 6,10 6,19 7,20 5,22 M6,10 L9,9 11,8 14,10 14,19 15,20 13,22 M11,9 L13,10 13,19 12,20 13,21 14,20 13,19 M9,9 L12,11 12,19 11,20 13,22",
                "o": 9
            },
            {
                "d": "M3,11 L3,19 6,22 11,20 13,19 M4,11 L4,19 6,21 M5,10 L5,18 8,21 M3,11 L5,10 10,8 13,11 13,19 M10,9 L12,11 12,19 M8,9 L11,12 11,20",
                "o": 8
            },
            {
                "d": "M3,8 L4,10 4,19 2,20 4,20 4,26 3,29 5,27 M5,10 L5,27 M3,8 L5,9 6,10 6,19 8,20 9,21 M5,20 L6,20 8,21 M6,21 L7,22 12,20 14,19 M6,21 L6,26 7,29 5,27 M6,10 L9,9 11,8 14,11 14,19 M11,9 L13,11 13,19 M9,9 L12,12 12,20",
                "o": 8
            },
            {
                "d": "M3,11 L3,19 6,22 11,20 M4,11 L4,19 6,21 M5,10 L5,18 8,21 M3,11 L5,10 10,8 13,11 13,26 14,29 12,27 M10,9 L12,11 12,27 M8,9 L11,12 11,26 10,29 12,27",
                "o": 9
            },
            {
                "d": "M2,10 L3,10 4,11 4,19 3,20 5,22 M4,9 L5,10 5,19 4,20 5,21 6,20 5,19 M2,10 L4,8 6,10 6,19 7,20 5,22 M6,10 L10,8 12,10 10,11 8,9 M9,9 L11,10",
                "o": 6
            },
            {
                "d": "M3,11 L3,14 5,16 11,13 13,15 13,19 M4,11 L4,14 5,15 M5,10 L5,14 6,15 M11,14 L12,15 12,19 M10,14 L11,15 11,20 M3,11 L9,8 12,9 10,10 7,9 M8,9 L11,9 M13,19 L7,22 3,20 5,19 9,21 M5,20 L7,21",
                "o": 8
            },
            {
                "d": "M5,3 L3,1 4,5 4,19 3,20 5,22 M5,3 L5,19 4,20 5,21 6,20 5,19 M5,3 L7,1 6,5 6,19 7,20 5,22 M1,8 L4,8 M6,8 L9,8",
                "o": 5
            },
            {
                "d": "M2,10 L3,10 4,11 4,20 7,22 12,20 M4,9 L5,10 5,20 7,21 M2,10 L4,8 6,10 6,19 9,21 M13,8 L15,10 14,11 14,19 15,20 16,20 M13,11 L14,10 13,9 12,10 13,11 13,20 14,21 M13,8 L11,10 12,11 12,20 14,22 16,20",
                "o": 9
            },
            {
                "d": "M3,8 L4,10 4,19 8,22 10,20 14,18 M4,9 L5,10 5,19 8,21 M3,8 L5,9 6,10 6,18 9,20 10,20 M13,8 L15,10 14,11 14,18 M13,11 L14,10 13,9 12,10 13,11 13,18 M13,8 L11,10 12,11 12,19",
                "o": 9
            },
            {
                "d": "M3,8 L4,10 4,19 8,22 10,20 12,19 M4,9 L5,10 5,19 8,21 M3,8 L5,9 6,10 6,18 9,20 10,20 M13,8 L11,10 12,11 12,19 16,22 18,20 22,18 M13,11 L14,10 13,9 12,10 13,11 13,19 16,21 M13,8 L15,10 14,11 14,18 17,20 18,20 M21,8 L23,10 22,11 22,18 M21,11 L22,10 21,9 20,10 21,11 21,18 M21,8 L19,10 20,11 20,19",
                "o": 13
            },
            {
                "d": "M3,10 L5,11 12,21 13,22 15,20 M4,9 L6,10 12,20 14,21 M3,10 L5,8 6,9 13,19 15,20 M15,8 L13,8 13,10 15,10 15,8 13,10 10,14 M8,16 L5,20 3,22 5,22 5,20 3,20 3,22 M5,15 L8,15 M10,15 L13,15",
                "o": 9
            },
            {
                "d": "M2,10 L3,10 4,11 4,20 7,22 12,20 M4,9 L5,10 5,20 7,21 M2,10 L4,8 6,10 6,19 9,21 M13,8 L15,10 14,11 14,24 13,26 12,27 10,28 8,28 6,27 4,28 6,29 8,28 M13,11 L14,10 13,9 12,10 13,11 13,25 12,26 M7,28 L5,28 M13,8 L11,10 12,11 12,25 11,27 10,28",
                "o": 9
            },
            {
                "d": "M6,9 L3,11 3,10 6,9 8,8 11,10 11,14 6,16 M8,9 L10,10 10,14 M6,9 L9,11 9,14 8,15 M6,16 L11,18 11,24 10,26 9,27 7,28 5,28 3,27 1,28 3,29 5,28 M10,18 L10,25 9,26 M4,28 L2,28 M8,17 L9,18 9,25 8,27 7,28",
                "o": 9
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "greek": {
        "name": "Greek 1-stroke",
        "chars": [
            {
                "d": "M5,1 L5,15 M5,20 L4,21 5,22 6,21 5,20",
                "o": 5
            },
            {
                "d": "M4,1 L4,8 M12,1 L12,8",
                "o": 8
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M8,-3 L8,26 M12,-3 L12,26 M17,4 L15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 16,14 17,16 17,19 15,21 12,22 8,22 5,21 3,19",
                "o": 10
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M23,10 L23,9 22,8 21,8 20,9 19,11 17,16 15,19 13,21 11,22 7,22 5,21 4,20 3,18 3,16 4,14 5,13 12,9 13,8 14,6 14,4 13,2 11,1 9,2 8,4 8,6 9,9 11,12 16,19 18,21 20,22 22,22 23,21 23,20",
                "o": 13
            },
            {
                "d": "M5,3 L4,2 5,1 6,2 6,4 5,6 4,7",
                "o": 5
            },
            {
                "d": "M11,-3 L9,-1 7,2 5,6 4,11 4,15 5,20 7,24 9,27 11,29",
                "o": 7
            },
            {
                "d": "M3,-3 L5,-1 7,2 9,6 10,11 10,15 9,20 7,24 5,27 3,29",
                "o": 7
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M20,-3 L2,29",
                "o": 11
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22",
                "o": 10
            },
            {
                "d": "M4,6 L4,5 5,3 6,2 8,1 12,1 14,2 15,3 16,5 16,7 15,9 13,12 3,22 17,22",
                "o": 10
            },
            {
                "d": "M5,1 L16,1 10,9 13,9 15,10 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M13,1 L3,15 18,15 M13,1 L13,22",
                "o": 10
            },
            {
                "d": "M15,1 L5,1 4,10 5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M16,4 L15,2 12,1 10,1 7,2 5,5 4,10 4,15 5,19 7,21 10,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 4,15",
                "o": 10
            },
            {
                "d": "M17,1 L7,22 M3,1 L17,1",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,6 5,8 7,9 11,10 14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,15 4,13 6,11 9,10 13,9 15,8 16,6 16,4 15,2 12,1 8,1",
                "o": 10
            },
            {
                "d": "M16,8 L15,11 13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 10,1 13,2 15,4 16,8 16,13 15,18 13,21 10,22 8,22 5,21 4,19",
                "o": 10
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M3,6 L3,5 4,3 5,2 7,1 11,1 13,2 14,3 15,5 15,7 14,9 13,10 9,12 9,15 M9,20 L8,21 9,22 10,21 9,20",
                "o": 9
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M9,1 L1,22 M9,1 L17,22 M4,15 L14,15",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,7 17,9 16,10 13,11 M4,11 L13,11 16,12 17,13 18,15 18,18 17,20 16,21 13,22 4,22",
                "o": 10
            },
            {
                "d": "M3,1 L17,22 M3,22 L17,1",
                "o": 10
            },
            {
                "d": "M9,1 L1,22 M9,1 L17,22 M1,22 L17,22",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 M4,1 L17,1 M4,11 L12,11 M4,22 L17,22",
                "o": 9
            },
            {
                "d": "M10,1 L10,22 M8,6 L5,7 4,8 3,10 3,13 4,15 5,16 8,17 12,17 15,16 16,15 17,13 17,10 16,8 15,7 12,6 8,6",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,1 L16,1",
                "o": 7
            },
            {
                "d": "M4,1 L4,22 M18,1 L18,22 M4,11 L18,11",
                "o": 11
            },
            {
                "d": "M4,1 L4,22",
                "o": 4
            },
            {
                "d": "M2,12 L2,13 3,13 3,12 2,12",
                "o": 3
            },
            {
                "d": "M4,1 L4,22 M18,1 L4,15 M9,10 L18,22",
                "o": 10
            },
            {
                "d": "M9,1 L1,22 M9,1 L17,22",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 M4,1 L12,22 M20,1 L12,22 M20,1 L20,22",
                "o": 12
            },
            {
                "d": "M4,1 L4,22 M4,1 L18,22 M18,1 L18,22",
                "o": 11
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 19,14 19,9 18,6 17,4 15,2 13,1 9,1",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M18,1 L18,22 M4,1 L18,1",
                "o": 11
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 19,14 19,9 18,6 17,4 15,2 13,1 9,1 M8,11 L14,11",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,8 17,10 16,11 13,12 4,12",
                "o": 10
            },
            {
                "d": "M2,1 L9,11 2,22 M2,1 L16,1 M2,22 L16,22",
                "o": 9
            },
            {
                "d": "M8,1 L8,22 M1,1 L15,1",
                "o": 8
            },
            {
                "d": "M2,6 L2,4 3,2 4,1 6,1 7,2 8,4 9,8 9,22 M16,6 L16,4 15,2 14,1 12,1 11,2 10,4 9,8",
                "o": 9
            },
            {
                "d": "M6,1 L4,2 3,4 3,6 4,8 6,9 8,9 10,8 11,6 11,4 10,2 8,1 6,1",
                "o": 7
            },
            {
                "d": "M3,22 L7,22 4,15 3,11 3,7 4,4 6,2 9,1 11,1 14,2 16,4 17,7 17,11 16,15 13,22 17,22",
                "o": 10
            },
            {
                "d": "M2,1 L16,1 M6,11 L12,11 M2,22 L16,22",
                "o": 9
            },
            {
                "d": "M11,1 L11,22 M2,7 L3,7 4,8 5,12 6,14 7,15 10,16 12,16 15,15 16,14 17,12 18,8 19,7 20,7",
                "o": 11
            },
            {
                "d": "M17,1 L3,22 M3,1 L17,1 M3,22 L17,22",
                "o": 10
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M8,-1 L0,13 M8,-1 L16,13",
                "o": 8
            },
            {
                "d": "M0,29 L18,29",
                "o": 9
            },
            {
                "d": "M5,6 L3,8 3,10 4,11 5,10 4,9 3,10",
                "o": 4
            },
            {
                "d": "M9,8 L7,9 5,11 4,13 3,16 3,19 4,21 6,22 8,22 10,21 13,18 15,15 17,11 18,8 M9,8 L11,8 12,9 13,11 15,19 16,21 17,22 18,22",
                "o": 11
            },
            {
                "d": "M12,1 L10,2 8,4 6,8 5,11 4,15 3,21 2,29 M12,1 L14,1 16,3 16,6 15,8 14,9 12,10 9,10 M9,10 L11,11 13,13 14,15 14,18 13,20 12,21 10,22 8,22 6,21 5,20 4,17",
                "o": 10
            },
            {
                "d": "M2,8 L4,8 6,10 12,27 14,29 16,29 M17,8 L16,10 14,13 4,24 2,27 1,29",
                "o": 9
            },
            {
                "d": "M11,8 L8,8 6,9 4,11 3,14 3,17 4,20 5,21 7,22 9,22 11,21 13,19 14,16 14,13 13,10 11,8 9,6 8,4 8,2 9,1 11,1 13,2 15,4",
                "o": 9
            },
            {
                "d": "M13,10 L12,9 10,8 7,8 5,9 5,11 6,13 9,14 M9,14 L5,15 3,17 3,19 4,21 6,22 9,22 11,21 13,19",
                "o": 8
            },
            {
                "d": "M8,9 L6,10 4,12 3,15 3,18 4,20 5,21 7,22 10,22 13,21 16,19 18,16 19,13 19,10 17,8 15,8 13,10 11,14 9,19 6,29",
                "o": 11
            },
            {
                "d": "M1,11 L3,9 5,8 6,8 8,9 9,10 10,13 10,17 9,22 M17,8 L16,11 15,13 9,22 7,26 6,29",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 7,11 6,15 4,22 M6,15 L8,11 10,9 12,8 14,8 16,10 16,13 15,18 12,29",
                "o": 10
            },
            {
                "d": "M6,8 L4,15 3,19 3,21 4,22 6,22 8,20 9,18",
                "o": 5
            },
            {
                "d": "M4,6 L18,20 M18,6 L4,20",
                "o": 11
            },
            {
                "d": "M6,8 L2,22 M16,9 L15,8 14,8 12,9 8,13 6,14 5,14 M5,14 L7,15 8,16 10,21 11,22 12,22 13,21",
                "o": 9
            },
            {
                "d": "M1,1 L3,1 5,2 6,3 14,22 M8,8 L2,22",
                "o": 8
            },
            {
                "d": "M7,8 L1,29 M6,12 L5,17 5,20 7,22 9,22 11,21 13,19 15,15 M17,8 L15,15 14,19 14,21 15,22 17,22 19,20 20,18",
                "o": 11
            },
            {
                "d": "M3,8 L6,8 5,14 4,19 3,22 M16,8 L15,11 14,13 12,16 9,19 6,21 3,22",
                "o": 9
            },
            {
                "d": "M8,8 L6,9 4,11 3,14 3,17 4,20 5,21 7,22 9,22 11,21 13,19 14,16 14,13 13,10 12,9 10,8 8,8",
                "o": 9
            },
            {
                "d": "M9,8 L5,22 M14,8 L15,14 16,19 17,22 M2,11 L4,9 7,8 20,8",
                "o": 11
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 7,11 6,16 6,19 7,21 8,22 10,22 12,21 14,18 15,16 16,13 17,8 17,5 16,2 14,1 12,1 11,3 11,5 12,8 14,11 16,13 19,15",
                "o": 10
            },
            {
                "d": "M4,14 L4,17 5,20 6,21 8,22 10,22 12,21 14,19 15,16 15,13 14,10 13,9 11,8 9,8 7,9 5,11 4,14 0,29",
                "o": 9
            },
            {
                "d": "M18,8 L8,8 6,9 4,11 3,14 3,17 4,20 5,21 7,22 9,22 11,21 13,19 14,16 14,13 13,10 12,9 10,8",
                "o": 11
            },
            {
                "d": "M11,8 L8,22 M2,11 L4,9 7,8 18,8",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 7,11 5,17 5,20 7,22 9,22 12,21 14,19 16,15 17,11 17,8",
                "o": 10
            },
            {
                "d": "M13,4 L12,5 13,6 14,5 13,4 M4,13 L22,13 M13,20 L12,21 13,22 14,21 13,20",
                "o": 13
            },
            {
                "d": "M8,8 L6,9 4,12 3,15 3,18 4,21 5,22 7,22 9,21 11,18 M12,14 L11,18 12,21 13,22 15,22 17,21 19,18 20,15 20,12 19,9 18,8",
                "o": 11
            },
            {
                "d": "M10,1 L8,2 7,3 7,4 8,5 11,6 14,6 M11,6 L8,7 6,8 5,10 5,12 7,14 10,15 12,15 M10,15 L6,16 4,17 3,19 3,21 5,23 9,25 10,26 10,28 8,29 6,29",
                "o": 8
            },
            {
                "d": "M16,1 L8,29 M1,12 L2,10 4,8 6,8 7,9 7,11 6,16 6,19 7,21 9,22 11,22 14,21 16,19 18,16 20,11 21,8",
                "o": 11
            },
            {
                "d": "M10,1 L8,2 7,3 7,4 8,5 11,6 14,6 M14,6 L10,8 7,10 4,13 3,16 3,18 4,20 6,22 9,24 10,26 10,28 9,29 7,29 6,27",
                "o": 7
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "japanese": {
        "name": "Japanese",
        "chars": [
            {
                "d": "M4,22 L3,21 4,20 5,21 5,23 4,25 3,26",
                "o": 13
            },
            {
                "d": "M4,10 L6,12 22,11 24,12 M5,11 L24,12",
                "o": 13
            },
            {
                "d": "M9,3 L7,2 7,23 M8,3 L8,23 M8,21 L20,21 M8,3 L20,3 M19,3 L20,2 22,3 21,5 M20,3 L20,23 M21,3 L21,23 M8,12 L20,12",
                "o": 13
            },
            {
                "d": "M7,2 L5,1 5,22 M6,2 L6,22 M6,2 L21,2 M20,2 L21,1 23,2 22,4 M21,2 L21,18 20,20 19,21 M21,19 L21,20 20,21 M22,2 L22,20 21,22 20,22 19,21 17,20 M13,2 L13,11 M14,2 L14,11 M6,11 L21,11",
                "o": 13
            },
            {
                "d": "M8,1 L6,6 3,12 M8,1 L9,2 8,4 5,9 3,12 M8,4 L24,4 22,2 20,4 M20,4 L23,3 M14,4 L14,23 M15,4 L15,23 M10,10 L8,9 8,16 M9,10 L9,16 M9,10 L23,10 21,8 19,10 M19,10 L22,9 M3,16 L25,16 23,14 21,16 M21,16 L24,15",
                "o": 13
            },
            {
                "d": "M13,1 L13,7 12,12 10,16 8,19 6,21 3,23 M14,2 L14,6 M13,1 L15,2 14,9 13,13 11,17 8,20 5,22 3,23 M3,8 L25,8 23,6 21,8 M21,8 L24,7 M15,8 L16,12 17,15 18,17 20,20 23,23 25,22 M21,20 L23,22 M15,8 L16,11 18,15 20,18 22,20 25,22",
                "o": 13
            },
            {
                "d": "M6,2 L4,1 4,23 M5,2 L5,23 M5,2 L23,2 M22,2 L23,1 25,2 24,4 M23,2 L23,23 M24,2 L24,23 M7,6 L21,6 20,5 19,6 M13,6 L13,18 M14,6 L14,18 M8,12 L20,12 19,11 18,12 M7,18 L21,18 20,17 19,18 M16,14 L17,16 18,16 18,15 16,14 M5,22 L23,22",
                "o": 13
            },
            {
                "d": "M13,1 L13,7 12,12 10,16 8,19 6,21 3,23 M14,2 L14,6 M13,1 L15,2 14,9 13,13 11,17 8,20 5,22 3,23 M15,2 L15,6 16,12 17,15 18,17 20,20 23,23 25,22 M21,20 L23,22 M15,6 L16,11 18,15 20,18 22,20 25,22",
                "o": 13
            },
            {
                "d": "M14,2 L15,1 13,1 13,23 M14,2 L14,23 M3,3 L25,3 23,2 22,3 M8,7 L6,6 6,16 M7,7 L7,16 M7,7 L20,7 M19,7 L20,6 22,7 21,9 M20,7 L20,16 M21,7 L21,16 M7,11 L20,11 M7,15 L20,15 M13,15 L10,18 7,20 3,22 M13,17 L10,19 8,20 3,22 M14,15 L17,19 20,21 23,22 25,21 M14,15 L17,18 21,20 25,21",
                "o": 13
            },
            {
                "d": "M14,3 L15,2 13,1 13,24 M14,2 L14,24 M6,7 L4,6 4,17 M5,7 L5,17 M5,7 L22,7 M21,7 L22,6 24,7 23,9 M22,7 L22,17 M23,7 L23,17 M5,16 L22,16",
                "o": 13
            },
            {
                "d": "M14,3 L15,2 13,1 13,23 M14,2 L14,23 M3,7 L25,7 23,5 21,7 M21,7 L24,6 M12,7 L9,13 6,17 3,20 M13,8 L11,12 9,15 7,17 3,20 M15,8 L16,11 17,13 20,17 23,20 25,19 M21,17 L23,19 M15,8 L16,10 19,14 22,17 25,19 M9,18 L18,18 17,17 16,18",
                "o": 13
            },
            {
                "d": "M14,2 L14,1 15,0 13,0 13,2 M3,2 L25,2 23,0 21,2 M21,2 L24,1 M8,6 L6,5 6,13 M7,6 L7,13 M7,6 L20,6 M19,6 L20,5 22,6 21,8 M20,6 L20,13 M21,6 L21,13 M7,12 L20,12 M13,12 L13,19 12,21 11,22 M13,20 L13,21 12,22 M14,12 L14,21 13,23 12,23 11,22 9,21 M8,15 L3,20 M8,15 L9,16 3,20 M18,15 L20,17 22,20 23,20 23,19 22,18 18,15",
                "o": 13
            },
            {
                "d": "M13,1 L13,22 M13,1 L14,1 14,22 M6,3 L6,11 M6,3 L7,3 7,11 M7,10 L20,10 M20,3 L20,11 M20,3 L21,3 21,11 M4,14 L4,23 M4,14 L5,14 5,23 M5,22 L22,22 M22,14 L22,23 M22,14 L23,14 23,23",
                "o": 13
            },
            {
                "d": "M4,1 L4,18 M4,1 L5,2 5,18 M5,2 L9,2 M9,2 L9,17 M8,2 L9,1 10,2 10,17 M5,9 L9,9 M5,16 L9,16 M17,2 L18,1 16,1 16,9 M17,2 L17,9 M12,5 L23,5 21,4 20,5 M10,9 L25,9 23,8 22,9 M19,9 L19,21 18,22 M20,9 L20,21 19,23 18,22 16,21 M10,13 L25,13 23,12 22,13 M13,15 L14,16 15,18 16,18 16,17 15,16 13,15",
                "o": 13
            },
            {
                "d": "M14,3 L15,2 13,1 13,22 M14,2 L14,22 M14,11 L23,11 21,9 19,11 M19,11 L22,10 M3,22 L25,22 23,20 21,22 M21,22 L24,21",
                "o": 13
            },
            {
                "d": "M13,2 L14,1 12,1 12,8 M13,2 L13,8 M5,4 L17,4 16,3 15,4 M3,8 L25,8 23,7 22,8 M20,2 L17,6 14,9 10,12 5,15 M20,2 L21,3 16,8 12,11 9,13 5,15 3,16 M11,12 L9,11 9,23 M10,12 L10,23 M10,12 L20,12 M19,12 L20,11 22,12 21,14 M20,12 L20,23 M21,12 L21,23 M10,17 L20,17 M10,22 L20,22",
                "o": 13
            },
            {
                "d": "M14,3 L15,2 13,1 13,23 M14,2 L14,23 M3,10 L25,10 23,8 21,10 M21,10 L24,9",
                "o": 13
            },
            {
                "d": "M3,12 L25,12 22,10 20,12 M20,12 L23,11",
                "o": 13
            },
            {
                "d": "M6,5 L22,5 20,3 18,5 M18,5 L21,4 M3,19 L25,19 23,17 21,19 M21,19 L24,18",
                "o": 13
            },
            {
                "d": "M5,4 L23,4 21,2 19,4 M19,4 L22,3 M7,12 L21,12 19,10 17,12 M17,12 L20,11 M3,21 L25,21 23,19 21,21 M21,21 L24,20",
                "o": 13
            },
            {
                "d": "M6,4 L4,3 4,21 M5,4 L5,21 M5,4 L23,4 M22,4 L23,3 25,4 24,6 M23,4 L23,21 M24,4 L24,21 M11,4 L11,9 10,13 9,15 M12,4 L12,9 11,12 9,15 7,17 M15,4 L15,14 16,15 20,15 21,14 20,13 M16,4 L16,13 17,14 19,14 20,13 20,11 M5,20 L23,20",
                "o": 13
            },
            {
                "d": "M5,3 L22,3 20,1 18,3 M18,3 L21,2 M13,3 L10,21 M14,3 L11,21 M5,11 L18,11 M17,11 L18,10 20,11 19,13 M18,11 L17,21 M19,11 L19,13 18,21 M3,21 L25,21 23,19 21,21 M21,21 L24,20",
                "o": 13
            },
            {
                "d": "M14,4 L15,3 13,2 13,8 M14,3 L14,8 M3,8 L25,8 23,6 21,8 M21,8 L24,7 M9,12 L8,15 6,19 4,22 M10,13 L9,15 M9,12 L11,13 9,16 6,20 4,22 M16,12 L18,14 21,18 22,20 23,21 24,20 23,18 20,15 16,12 M21,17 L23,20",
                "o": 13
            },
            {
                "d": "M9,4 L10,3 8,2 8,21 9,22 21,22 22,21 21,19 M20,21 L21,21 21,20 M9,3 L9,20 10,21 19,21 21,19 22,16 M4,12 L10,11 15,10 23,8 20,7 19,9 M19,9 L21,8",
                "o": 13
            },
            {
                "d": "M14,7 L13,10 11,15 9,18 6,21 3,23 M14,7 L15,8 14,11 12,15 10,18 8,20 5,22 3,23 M8,2 L17,2 M14,2 L15,1 17,2 15,4 M15,2 L15,7 16,12 17,15 18,17 20,20 23,23 25,22 M21,20 L23,22 M15,7 L16,11 18,15 20,18 22,20 25,22",
                "o": 13
            },
            {
                "d": "M10,4 L11,3 9,2 9,13 8,17 6,20 3,23 M10,3 L10,13 9,17 7,20 3,23 M3,9 L16,8 M15,8 L16,7 18,8 17,10 M16,8 L16,21 17,22 24,22 25,21 24,19 M23,21 L24,21 24,20 M17,8 L17,20 18,21 22,21 24,19 25,16",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M11,1 L13,2 13,4 12,7 M12,2 L12,13 13,17 M7,6 L9,7 14,7 17,6 20,5 M17,6 L18,5 20,5 M15,14 L16,10 17,11 15,14 12,18 10,20 8,21 6,21 5,20 5,17 6,15 8,13 10,12 13,11 18,11 21,12 23,14 24,16 24,18 23,20 21,22 18,23 16,23 M7,21 L6,20 6,17 7,15 9,13 13,11 M18,11 L20,12 22,14 23,16 23,18 22,20 20,22 18,23",
                "o": 13
            },
            {
                "d": "M4,3 L6,4 6,6 5,8 4,11 M5,4 L5,6 4,11 4,14 5,17 6,18 8,19 9,19 9,17 10,14 11,12 M6,18 L8,18 9,17 M19,7 L21,8 23,11 24,14 24,16 22,15 19,16 M23,11 L23,14 22,15",
                "o": 13
            },
            {
                "d": "M10,0 L11,1 13,2 17,2 M11,1 L16,1 17,2 13,4 M7,8 L7,9 8,10 9,10 12,8 14,7 M7,9 L8,9 11,8 14,7 16,7 19,8 20,10 20,13 19,16 17,19 14,21 10,23 M16,7 L18,8 19,10 19,13 18,16 17,18 15,20 12,22",
                "o": 13
            },
            {
                "d": "M10,0 L11,1 13,2 17,2 M11,1 L16,1 17,2 13,4 M6,8 L6,9 7,10 9,10 14,8 17,7 18,8 M6,9 L9,9 14,8 M18,8 L14,11 8,17 4,21 M17,7 L14,11 M4,21 L4,20 8,17 10,16 14,16 15,17 16,21 17,22 24,22 M19,22 L22,21 23,21 24,22",
                "o": 13
            },
            {
                "d": "M9,1 L11,2 11,4 10,8 M10,2 L10,22 M10,15 L11,21 10,22 9,20 7,19 4,18 M4,8 L6,9 10,9 13,8 15,7 M10,9 L12,8 13,7 15,7 M4,19 L8,16 13,13 16,12 18,12 21,13 22,15 22,17 21,19 19,20 17,20 15,19 14,17 M4,19 L4,18 8,16 M18,12 L20,13 21,15 21,17 20,19 19,20 M19,6 L21,6 23,7 24,8 22,8 21,9 M21,6 L22,7 22,8",
                "o": 13
            },
            {
                "d": "M10,1 L12,2 12,4 11,7 9,12 4,22 M11,2 L11,4 9,10 7,15 4,22 M4,8 L4,9 5,10 6,10 9,8 11,7 14,7 16,8 17,10 17,13 16,17 14,21 13,22 12,22 12,21 11,20 9,19 M4,9 L6,9 9,8 M14,7 L16,9 16,13 15,17 14,19 12,21 M19,8 L21,9 23,11 24,13 24,15 23,14 21,15 M22,10 L23,12 23,14",
                "o": 13
            },
            {
                "d": "M10,1 L13,2 14,6 15,9 17,13 20,17 19,18 M12,2 L14,6 M17,13 L19,18 M8,6 L10,7 13,7 16,6 M5,10 L6,11 9,12 14,12 19,11 22,10 M19,11 L21,9 22,10 M19,18 L17,17 14,16 10,16 8,17 7,18 7,20 8,21 10,22 13,23 19,23 M10,22 L18,22 19,23",
                "o": 13
            },
            {
                "d": "M16,1 L18,2 18,3 10,11 M17,2 L16,4 10,11 10,12 15,17 17,20 18,22 M10,12 L18,19 19,21 18,22",
                "o": 13
            },
            {
                "d": "M5,2 L7,3 7,5 6,8 5,12 M6,3 L6,6 5,12 5,16 6,20 7,21 7,19 8,15 9,12 10,10 11,9 13,8 22,8 24,7 M5,16 L6,18 7,19 M18,8 L22,7 23,6 24,7 M18,1 L20,2 20,16 19,19 17,21 14,23 M19,2 L20,5 M20,13 L19,17 17,20 14,23",
                "o": 13
            },
            {
                "d": "M9,3 L11,4 14,5 16,5 19,4 M11,4 L16,4 18,3 19,4 13,7 M7,14 L7,16 8,18 10,19 14,20 21,20 M10,19 L20,19 21,20",
                "o": 13
            },
            {
                "d": "M6,6 L7,7 10,8 14,8 18,7 20,6 21,5 M18,7 L20,5 21,5 M12,1 L13,4 14,6 17,10 19,13 20,15 M12,1 L13,1 13,3 14,6 M17,10 L20,13 21,15 21,16 M21,16 L20,15 18,14 14,13 10,13 7,14 6,16 6,18 7,20 9,21 13,22 17,22 M6,18 L7,19 9,20 13,21 18,21 17,22",
                "o": 13
            },
            {
                "d": "M8,1 L10,2 9,14 9,19 10,21 M9,2 L8,14 8,18 9,20 10,21 12,22 15,22 18,21 21,19 23,17",
                "o": 13
            },
            {
                "d": "M14,1 L16,2 16,14 15,16 13,17 11,16 10,14 10,13 11,11 13,10 15,11 16,13 16,17 15,21 13,23 M15,2 L16,7 M16,17 L15,20 13,23 M4,5 L6,7 9,7 18,6 22,5 24,6 M5,6 L9,7 M18,6 L24,6",
                "o": 13
            },
            {
                "d": "M8,5 L10,6 10,17 11,19 12,20 14,21 22,21 M9,6 L10,9 M17,21 L20,20 21,20 22,21 M17,2 L19,3 19,15 18,17 17,16 14,14 M18,3 L19,6 M19,12 L18,15 17,16 M4,9 L6,11 8,11 12,10 17,9 21,8 23,8 24,9 M5,10 L12,10 M17,9 L24,9",
                "o": 13
            },
            {
                "d": "M10,2 L12,4 18,2 M11,3 L13,3 18,2 19,3 M19,3 L16,6 10,10 6,12 M18,2 L17,4 15,6 10,10 M5,11 L7,13 11,11 M6,12 L11,11 17,10 22,9 23,10 M17,10 L23,10 M17,10 L15,11 13,13 12,15 12,17 13,19 14,20 17,21 21,21 M13,19 L16,20 20,20 21,21",
                "o": 13
            },
            {
                "d": "M10,1 L12,2 12,4 10,10 9,13 7,18 5,22 M11,2 L11,5 10,10 M9,13 L7,17 5,20 5,22 M4,8 L6,9 9,9 12,8 14,7 16,6 M14,7 L15,5 16,6 M17,9 L20,9 23,10 21,10 19,11 M20,9 L21,10 M14,17 L15,19 16,20 18,21 23,21 M15,19 L17,20 24,20 23,21",
                "o": 13
            },
            {
                "d": "M9,1 L11,2 11,4 9,9 8,13 8,15 9,16 M10,2 L10,6 M9,9 L9,16 M4,5 L7,6 11,6 15,5 17,4 M15,5 L16,3 17,4 M9,16 L11,14 14,12 17,11 20,11 22,12 23,14 23,17 22,19 19,21 15,22 11,22 M20,11 L21,12 22,14 22,17 21,19 19,21",
                "o": 13
            },
            {
                "d": "M4,7 L6,9 11,7 15,6 19,6 22,7 23,8 24,10 24,13 23,15 22,16 20,17 17,18 12,19 M5,8 L11,7 M19,6 L21,7 22,8 23,10 23,13 22,15 20,17",
                "o": 13
            },
            {
                "d": "M4,4 L6,6 8,6 12,5 17,4 21,3 23,3 24,4 M5,5 L12,5 M17,4 L24,4 M21,4 L18,5 15,7 13,10 12,13 12,16 13,19 14,20 16,21 20,22 23,22 M12,16 L13,18 14,19 16,20 20,21 22,21 23,22",
                "o": 13
            },
            {
                "d": "M11,1 L13,2 13,12 M12,2 L12,8 13,12 M19,9 L21,11 13,12 M20,10 L16,11 13,12 10,13 8,14 7,16 7,18 8,20 9,21 11,22 22,22 M8,20 L10,21 21,21 22,22",
                "o": 13
            },
            {
                "d": "M9,1 L11,2 11,4 9,10 8,13 6,18 4,22 M10,2 L10,5 9,10 M8,13 L6,17 4,20 4,22 M4,8 L6,9 8,9 11,8 13,7 15,6 M13,7 L14,5 15,6 M18,5 L20,5 22,6 24,8 22,7 20,8 M20,5 L22,7 M18,10 L18,12 19,15 20,17 20,19 19,21 17,22 14,22 12,21 11,19 12,17 14,16 17,16 19,17 23,21 M18,12 L19,17 19,20 17,22 M21,19 L22,21 23,21",
                "o": 13
            },
            {
                "d": "M5,2 L7,3 7,5 6,8 5,12 M6,3 L6,6 5,12 5,16 6,20 7,21 7,19 8,15 9,12 M5,16 L6,18 7,19 M15,5 L18,4 21,4 23,5 21,5 19,6 M19,4 L21,5 M13,14 L13,16 14,18 17,19 24,19 M13,16 L14,17 17,18 22,18 24,19",
                "o": 13
            },
            {
                "d": "M6,4 L8,5 8,10 M7,5 L8,10 9,13 10,15 11,17 12,18 M10,15 L12,17 12,18 M14,2 L16,3 16,5 14,10 11,17 9,20 7,21 5,21 4,19 4,17 5,14 6,12 8,10 11,8 15,7 18,7 21,8 23,10 24,13 24,16 23,19 22,20 20,21 18,21 16,20 15,19 15,18 16,17 18,16 20,16 22,17 24,20 M15,3 L15,5 14,10 M6,21 L5,19 5,16 6,13 8,10 M18,7 L20,8 22,10 23,13 23,17 22,20 M22,17 L24,19 24,20",
                "o": 13
            },
            {
                "d": "M9,1 L11,2 11,4 9,22 M10,2 L10,22 9,22 8,19 7,18 5,17 M4,7 L5,9 7,9 11,7 11,8 M5,8 L8,8 10,7 9,11 M11,8 L9,11 7,14 4,18 4,17 7,14 11,10 15,7 18,6 20,6 22,7 23,9 23,16 22,19 21,20 19,21 17,21 15,20 14,19 14,18 15,17 17,16 19,16 21,17 22,18 24,21 M20,6 L21,7 22,9 22,17 21,20 M22,18 L24,20 24,21",
                "o": 13
            },
            {
                "d": "M13,3 L14,5 14,8 13,11 12,13 10,16 8,18 6,18 5,17 4,15 4,12 5,9 7,6 10,4 13,3 16,3 19,4 21,5 23,7 24,10 24,13 23,16 21,18 19,19 16,20 13,20 M10,16 L8,17 6,17 5,15 5,12 6,8 7,6 M19,4 L22,7 23,10 23,13 22,16 19,19",
                "o": 13
            },
            {
                "d": "M5,2 L7,3 7,5 6,8 5,12 M6,3 L6,6 5,12 5,16 6,20 7,21 7,19 8,15 9,12 M5,16 L6,18 7,19 M17,2 L19,3 19,18 18,20 16,21 14,21 12,20 11,18 12,16 14,15 16,15 18,16 23,20 M18,3 L19,7 M18,16 L23,19 23,20 M12,8 L14,9 17,9 21,8 23,7 M21,8 L22,6 23,7",
                "o": 13
            },
            {
                "d": "M5,2 L6,3 8,4 12,4 M6,3 L11,3 12,4 M12,4 L9,6 7,8 5,11 4,14 4,17 5,19 6,20 8,21 11,21 14,20 16,18 17,16 18,12 18,8 17,4 18,4 M4,16 L5,18 6,19 8,20 11,20 14,19 16,17 17,15 18,12 M18,4 L20,7 21,9 23,12 24,14 22,14 21,15 M17,4 L20,7 M21,9 L22,12 22,14",
                "o": 13
            },
            {
                "d": "M12,1 L13,4 15,5 M13,3 L15,4 16,4 16,5 M16,5 L13,6 11,7 10,8 10,10 11,11 13,12 15,13 16,14 17,16 17,18 16,20 14,21 12,21 9,20 7,18 M13,12 L15,14 16,16 16,18 15,20 14,21 M5,20 L8,17 11,15 14,14 M18,14 L21,15 23,17 24,19 21,19 M5,20 L5,19 8,17 M18,14 L20,15 22,17 23,19",
                "o": 13
            },
            {
                "d": "M4,11 L6,13 9,8 M5,12 L10,7 12,7 17,12 21,15 23,16 24,17 22,17 20,18 M17,12 L22,17",
                "o": 13
            },
            {
                "d": "M5,2 L7,3 7,5 6,8 5,12 M6,3 L6,6 5,12 5,16 6,20 7,21 7,19 8,15 9,12 M5,16 L6,18 7,19 M13,2 L15,3 17,3 21,2 M17,3 L19,2 20,1 21,2 M12,10 L15,11 18,11 21,10 23,9 M21,10 L22,8 23,9 M17,3 L19,4 19,18 18,20 16,21 14,21 12,20 11,18 12,16 14,15 16,15 18,16 23,20 M18,4 L19,8 M18,16 L23,19 23,20",
                "o": 13
            },
            {
                "d": "M14,1 L16,2 16,19 15,21 13,22 11,22 9,21 8,19 9,17 11,16 13,16 16,17 19,19 22,22 M15,2 L16,6 M19,19 L22,21 22,22 M4,4 L6,6 15,6 22,5 M5,5 L10,6 M15,6 L19,5 21,4 22,5 M9,11 L11,12 15,12 19,11 21,10 M19,11 L20,9 21,10",
                "o": 13
            },
            {
                "d": "M6,2 L7,4 9,4 12,3 15,1 16,2 M7,3 L12,3 M16,2 L12,8 9,14 7,17 6,18 5,18 4,17 4,15 5,13 7,12 12,12 16,13 19,14 24,16 M15,1 L12,8 M16,13 L24,15 24,16 M18,8 L20,9 20,15 19,18 17,20 15,21 12,22 M19,9 L19,16 18,19",
                "o": 13
            },
            {
                "d": "M9,1 L11,2 11,4 10,8 9,12 8,15 7,17 6,18 5,18 4,17 4,15 5,13 6,12 7,12 8,13 8,18 9,21 11,22 17,22 20,21 21,20 20,19 19,17 18,13 18,9 19,8 21,8 23,9 24,11 24,12 23,12 21,13 M10,2 L10,8 M8,18 L9,20 11,21 17,21 19,20 20,19 M21,8 L23,10 23,12 M4,6 L7,7 10,7 13,6 15,5 M13,6 L14,4 15,5",
                "o": 13
            },
            {
                "d": "M6,4 L8,5 8,10 M7,5 L8,10 9,13 10,15 11,17 12,18 M10,15 L12,17 12,18 M15,2 L17,3 17,5 16,8 14,13 13,15 11,18 9,20 7,21 5,21 4,20 4,18 5,15 6,13 8,11 11,9 14,8 18,8 21,9 23,11 24,14 24,17 23,19 22,20 20,21 17,22 14,22 M16,3 L16,5 14,13 M6,21 L5,20 5,17 6,14 8,11 M18,8 L20,9 22,11 23,14 23,17 22,19 20,21",
                "o": 13
            },
            {
                "d": "M11,1 L13,2 13,4 12,8 M12,2 L12,15 13,19 14,21 16,22 20,22 22,21 23,19 23,17 22,15 20,13 17,11 M19,22 L21,21 22,19 22,16 21,14 M8,5 L9,6 12,7 15,7 M9,6 L14,6 15,7 M15,7 L12,8 10,9 8,11 7,13 8,15 10,16 15,16",
                "o": 13
            },
            {
                "d": "M12,1 L14,2 15,3 16,5 15,6 12,5 9,5 8,6 8,8 11,15 13,22 M14,2 L15,4 15,6 M11,15 L14,21 13,22 M4,9 L5,11 7,11 10,9 14,7 18,6 21,6 23,7 24,9 24,11 23,13 22,14 19,15 16,15 13,14 M5,10 L7,10 10,9 M21,6 L22,7 23,9 23,11 22,13 21,14 19,15",
                "o": 13
            },
            {
                "d": "M4,3 L6,4 6,6 5,8 4,11 M5,4 L5,6 4,11 4,14 5,17 6,18 8,19 9,19 9,17 10,14 11,12 M6,18 L8,18 9,17 M19,7 L21,8 23,11 24,14 24,16 22,15 19,16 M23,11 L23,14 22,15",
                "o": 13
            },
            {
                "d": "M4,5 L6,6 6,8 5,11 M5,6 L5,15 6,18 7,19 7,17 8,14 9,12 11,9 14,6 17,5 20,5 22,6 23,7 24,9 24,12 23,14 21,16 19,17 16,17 14,16 12,14 11,11 11,8 12,4 13,2 15,1 17,1 18,3 18,14 17,18 16,20 13,22 M5,15 L6,17 7,17 M22,6 L23,9 23,12 22,15 M18,14 L17,17 16,19 13,22",
                "o": 13
            },
            {
                "d": "M10,0 L11,1 13,2 17,2 M11,1 L16,1 17,2 13,4 M6,8 L6,9 7,10 9,10 14,8 17,7 18,8 M6,9 L9,9 14,8 M18,8 L14,11 8,17 4,21 M17,7 L14,11 M4,21 L4,20 8,17 10,16 14,16 15,17 16,21 17,22 24,22 M19,22 L22,21 23,21 24,22",
                "o": 13
            },
            {
                "d": "M12,1 L15,2 15,4 14,9 M14,2 L14,19 13,21 11,22 8,22 6,21 5,20 5,18 6,17 8,16 11,16 14,17 18,19 23,22 M14,17 L19,19 23,21 23,22 M14,9 L17,9 21,8 M17,9 L19,8 20,7 21,8",
                "o": 13
            },
            {
                "d": "M11,1 L13,1 15,2 16,3 14,3 12,4 M13,1 L14,2 14,3 M8,7 L7,9 6,13 6,15 7,16 11,13 13,12 16,11 19,11 21,12 22,14 22,16 21,18 19,20 17,21 13,22 11,22 M6,13 L7,15 8,15 M19,11 L20,12 21,14 21,16 20,18 18,20 16,21 13,22",
                "o": 13
            },
            {
                "d": "M8,1 L10,2 10,4 8,8 M9,2 L9,4 8,8 8,11 9,14 10,15 10,13 11,10 13,7 15,5 17,4 19,4 20,5 21,8 21,13 20,17 18,20 15,22 11,23 M8,11 L9,13 10,13 M18,4 L19,5 20,8 20,13 19,17 17,20 15,22",
                "o": 13
            },
            {
                "d": "M9,2 L10,4 12,4 17,2 M10,3 L12,3 17,2 18,3 M18,3 L14,6 10,11 7,16 M17,2 L14,6 M10,11 L7,14 7,16 M7,16 L9,14 12,12 15,11 18,11 20,12 21,13 22,15 22,18 21,20 20,21 18,22 15,22 13,21 12,20 12,19 13,18 15,18 17,19 19,21 M18,11 L20,13 21,15 21,18 20,20 18,22",
                "o": 13
            },
            {
                "d": "M9,1 L11,2 11,4 9,22 M10,2 L10,22 9,22 8,19 7,18 5,17 M4,7 L5,9 7,9 11,7 11,8 M5,8 L8,8 11,7 9,11 M11,8 L9,11 7,14 4,18 4,17 7,14 11,10 15,7 18,6 19,6 21,7 22,9 21,19 21,21 22,22 M19,6 L20,7 21,9 20,19 20,21 21,22 22,22 23,21 24,19",
                "o": 13
            },
            {
                "d": "M9,2 L10,4 12,4 17,2 M10,3 L12,3 17,2 18,3 M18,3 L14,6 10,11 7,16 M17,2 L14,6 M10,11 L7,14 7,16 M7,16 L9,14 12,12 15,11 18,11 20,12 21,13 22,15 22,18 21,20 19,22 16,23 14,23 M18,11 L20,13 21,15 21,18 20,21",
                "o": 13
            },
            {
                "d": "M9,1 L11,2 11,4 9,22 M10,2 L10,22 9,22 8,19 7,18 5,17 M4,7 L5,9 7,9 11,7 11,8 M5,8 L8,8 11,7 9,11 M11,8 L9,11 7,14 4,18 4,17 7,14 10,11 13,9 16,8 19,8 21,9 22,10 23,12 23,15 22,18 20,20 18,21 15,22 M19,8 L21,10 22,12 22,15 21,18 18,21",
                "o": 13
            },
            {
                "d": "M9,2 L10,4 12,4 16,2 M10,3 L12,3 16,2 17,3 M17,3 L13,9 9,17 8,19 7,20 5,20 4,18 4,16 5,14 7,12 10,10 14,9 18,9 21,10 23,12 24,14 24,17 23,19 21,21 19,22 16,22 14,21 13,20 13,19 14,18 16,18 18,19 20,21 M16,2 L13,9 M6,20 L5,18 5,16 6,13 M22,11 L23,14 23,17 22,20",
                "o": 13
            },
            {
                "d": "M10,0 L11,1 13,2 17,2 M11,1 L16,1 17,2 13,4 M7,8 L7,9 8,10 9,10 12,8 14,7 M7,9 L8,9 11,8 14,7 16,7 19,8 20,10 20,13 19,16 17,19 14,21 10,23 M16,7 L18,8 19,10 19,13 18,16 17,18 15,20 12,22",
                "o": 13
            },
            {
                "d": "M8,2 L9,4 11,4 13,3 16,1 M9,3 L11,3 14,2 16,1 17,2 M17,2 L13,5 9,9 6,13 M16,1 L13,5 M6,13 L6,11 9,9 11,8 14,7 17,7 19,8 20,10 20,12 19,14 17,15 12,15 11,14 11,12 12,11 14,11 15,12 15,14 14,15 10,16 7,18 4,21 4,22 5,22 7,18 M6,20 L8,19 10,19 12,21 13,21 15,19 18,18 21,18 23,19 24,20 24,22 23,21 21,21 M10,19 L12,20 13,20 15,19 M21,18 L23,20 23,21",
                "o": 13
            },
            {
                "d": "M12,1 L14,2 14,3 12,7 10,10 7,13 4,16 M13,2 L13,3 12,7 M7,13 L4,15 4,16 M9,11 L11,10 13,10 15,11 16,13 16,17 M13,10 L14,11 15,13 15,18 16,17 M5,5 L8,6 11,6 15,5 19,4 M15,5 L18,3 19,4 M23,10 L24,12 16,13 13,14 11,15 10,17 10,19 11,21 13,22 21,22 M23,11 L16,13 M16,22 L20,21 21,22",
                "o": 13
            },
            {
                "d": "M13,1 L15,3 12,8 8,15 4,22 M14,2 L12,8 M4,22 L4,20 8,15 10,13 12,12 14,12 16,13 16,18 17,20 18,21 M14,12 L15,13 15,18 16,20 18,21 20,21 22,20 23,18 24,15",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M10,1 L12,2 12,4 11,7 9,12 4,22 M11,2 L11,4 9,10 7,15 4,22 M4,8 L4,9 5,10 6,10 9,8 11,7 14,7 16,8 17,10 17,13 16,17 14,21 13,22 12,22 12,21 11,20 9,19 M4,9 L6,9 9,8 M14,7 L16,9 16,13 15,17 14,19 12,21 M19,8 L21,9 23,11 24,13 24,15 23,14 21,15 M22,10 L23,12 23,14 M19,3 L21,5 M21,1 L23,3",
                "o": 13
            },
            {
                "d": "M10,1 L13,2 14,6 15,9 17,13 20,17 19,18 M12,2 L14,6 M17,13 L19,18 M8,6 L10,7 13,7 16,6 M5,10 L6,11 9,12 14,12 19,11 22,10 M19,11 L21,9 22,10 M19,18 L17,17 14,16 10,16 8,17 7,18 7,20 8,21 10,22 13,23 19,23 M10,22 L18,22 19,23 M19,3 L21,5 M21,1 L23,3",
                "o": 13
            },
            {
                "d": "M16,1 L18,2 18,3 10,11 M17,2 L16,4 10,11 10,12 15,17 17,20 18,22 M10,12 L18,19 19,21 18,22 M21,3 L23,5 M23,1 L25,3",
                "o": 13
            },
            {
                "d": "M5,2 L7,3 7,5 6,8 5,12 M6,3 L6,6 5,12 5,16 6,20 7,21 7,19 8,15 9,12 10,10 11,9 13,8 22,8 24,7 M5,16 L6,18 7,19 M18,8 L22,7 23,6 24,7 M18,1 L20,2 20,16 19,19 17,21 14,23 M19,2 L20,5 M20,13 L19,17 17,20 14,23 M23,2 L25,4 M25,0 L27,2",
                "o": 13
            },
            {
                "d": "M9,3 L11,4 14,5 16,5 19,4 M11,4 L16,4 18,3 19,4 13,7 M7,14 L7,16 8,18 10,19 14,20 21,20 M10,19 L20,19 21,20 M21,2 L23,4 M23,0 L25,2",
                "o": 13
            },
            {
                "d": "M6,6 L7,7 10,8 14,8 18,7 20,6 21,5 M18,7 L20,5 21,5 M12,1 L13,4 14,6 17,10 19,13 20,15 M12,1 L13,1 13,3 14,6 M17,10 L20,13 21,15 21,16 M21,16 L20,15 18,14 14,13 10,13 7,14 6,16 6,18 7,20 9,21 13,22 17,22 M6,18 L7,19 9,20 13,21 18,21 17,22 M22,2 L24,4 M24,0 L26,2",
                "o": 13
            },
            {
                "d": "M8,1 L10,2 9,14 9,19 10,21 M9,2 L8,14 8,18 9,20 10,21 12,22 15,22 18,21 21,19 23,17 M15,3 L17,5 M17,1 L19,3",
                "o": 13
            },
            {
                "d": "M14,1 L16,2 16,14 15,16 13,17 11,16 10,14 10,13 11,11 13,10 15,11 16,13 16,17 15,21 13,23 M15,2 L16,7 M16,17 L15,20 13,23 M4,5 L6,7 9,7 18,6 22,5 24,6 M5,6 L9,7 M18,6 L24,6 M20,1 L22,3 M23,0 L25,2",
                "o": 13
            },
            {
                "d": "M8,5 L10,6 10,17 11,19 12,20 14,21 22,21 M9,6 L10,9 M17,21 L20,20 21,20 22,21 M17,2 L19,3 19,15 18,17 17,16 14,14 M18,3 L19,6 M19,12 L18,15 17,16 M4,9 L6,11 8,11 12,10 17,9 21,8 23,8 24,9 M5,10 L12,10 M17,9 L24,9 M22,3 L24,5 M24,1 L26,3",
                "o": 13
            },
            {
                "d": "M10,2 L12,4 18,2 M11,3 L13,3 18,2 19,3 M19,3 L16,6 10,10 6,12 M18,2 L17,4 15,6 10,10 M5,11 L7,13 11,11 M6,12 L11,11 17,10 22,9 23,10 M17,10 L23,10 M17,10 L15,11 13,13 12,15 12,17 13,19 14,20 17,21 21,21 M13,19 L16,20 20,20 21,21 M22,3 L24,5 M24,1 L26,3",
                "o": 13
            },
            {
                "d": "M10,1 L12,2 12,4 10,10 9,13 7,18 5,22 M11,2 L11,5 10,10 M9,13 L7,17 5,20 5,22 M4,8 L6,9 9,9 12,8 14,7 16,6 M14,7 L15,5 16,6 M17,9 L20,9 23,10 21,10 19,11 M20,9 L21,10 M14,17 L15,19 16,20 18,21 23,21 M15,19 L17,20 24,20 23,21 M20,3 L22,5 M22,1 L24,3",
                "o": 13
            },
            {
                "d": "M9,1 L11,2 11,4 9,9 8,13 8,15 9,16 M10,2 L10,6 M9,9 L9,16 M4,5 L7,6 11,6 15,5 17,4 M15,5 L16,3 17,4 M9,16 L11,14 14,12 17,11 20,11 22,12 23,14 23,17 22,19 19,21 15,22 11,22 M20,11 L21,12 22,14 22,17 21,19 19,21 M20,4 L22,6 M22,2 L24,4",
                "o": 13
            },
            {
                "d": "M4,7 L6,9 11,7 15,6 19,6 22,7 23,8 24,10 24,13 23,15 22,16 20,17 17,18 12,19 M5,8 L11,7 M19,6 L21,7 22,8 23,10 23,13 22,15 20,17 M22,3 L24,5 M24,1 L26,3",
                "o": 13
            },
            {
                "d": "M4,4 L6,6 8,6 12,5 17,4 21,3 23,3 24,4 M5,5 L12,5 M17,4 L24,4 M21,4 L18,5 15,7 13,10 12,13 12,16 13,19 14,20 16,21 20,22 23,22 M12,16 L13,18 14,19 16,20 20,21 22,21 23,22 M22,8 L24,10 M24,6 L26,8",
                "o": 13
            },
            {
                "d": "M11,1 L13,2 13,12 M12,2 L12,8 13,12 M19,9 L21,11 13,12 M20,10 L16,11 13,12 10,13 8,14 7,16 7,18 8,20 9,21 11,22 22,22 M8,20 L10,21 21,21 22,22 M20,4 L22,6 M22,2 L24,4",
                "o": 13
            },
            {
                "d": "M5,2 L7,3 7,5 6,8 5,12 M6,3 L6,6 5,12 5,16 6,20 7,21 7,19 8,15 9,12 M5,16 L6,18 7,19 M17,2 L19,3 19,18 18,20 16,21 14,21 12,20 11,18 12,16 14,15 16,15 18,16 23,20 M18,3 L19,7 M18,16 L23,19 23,20 M12,8 L14,9 17,9 21,8 23,7 M21,8 L22,6 23,7 M22,2 L24,4 M24,0 L26,2",
                "o": 13
            },
            {
                "d": "M5,2 L6,3 8,4 12,4 M6,3 L11,3 12,4 M12,4 L9,6 7,8 5,11 4,14 4,17 5,19 6,20 8,21 11,21 14,20 16,18 17,16 18,12 18,8 17,4 18,4 M4,16 L5,18 6,19 8,20 11,20 14,19 16,17 17,15 18,12 M18,4 L20,7 21,9 23,12 24,14 22,14 21,15 M17,4 L20,7 M21,9 L22,12 22,14 M21,3 L23,5 M23,1 L25,3",
                "o": 13
            },
            {
                "d": "M12,1 L13,4 15,5 M13,3 L15,4 16,4 16,5 M16,5 L13,6 11,7 10,8 10,10 11,11 13,12 15,13 16,14 17,16 17,18 16,20 14,21 12,21 9,20 7,18 M13,12 L15,14 16,16 16,18 15,20 14,21 M5,20 L8,17 11,15 14,14 M18,14 L21,15 23,17 24,19 21,19 M5,20 L5,19 8,17 M18,14 L20,15 22,17 23,19 M19,4 L21,6 M21,2 L23,4",
                "o": 13
            },
            {
                "d": "M4,11 L6,13 9,8 M5,12 L10,7 12,7 17,12 21,15 23,16 24,17 22,17 20,18 M17,12 L22,17 M17,5 L19,7 M19,3 L21,5",
                "o": 13
            },
            {
                "d": "M5,2 L7,3 7,5 6,8 5,12 M6,3 L6,6 5,12 5,16 6,20 7,21 7,19 8,15 9,12 M5,16 L6,18 7,19 M13,2 L15,3 17,3 21,2 M17,3 L19,2 20,1 21,2 M12,10 L15,11 18,11 21,10 23,9 M21,10 L22,8 23,9 M17,3 L19,4 19,18 18,20 16,21 14,21 12,20 11,18 12,16 14,15 16,15 18,16 23,20 M18,4 L19,8 M18,16 L23,19 23,20 M23,4 L25,6 M25,2 L27,4",
                "o": 13
            },
            {
                "d": "M5,2 L7,3 7,5 6,8 5,12 M6,3 L6,6 5,12 5,16 6,20 7,21 7,19 8,15 9,12 M5,16 L6,18 7,19 M17,2 L19,3 19,18 18,20 16,21 14,21 12,20 11,18 12,16 14,15 16,15 18,16 23,20 M18,3 L19,7 M18,16 L23,19 23,20 M12,8 L14,9 17,9 21,8 23,7 M21,8 L22,6 23,7 M23,0 L22,1 22,3 23,4 25,4 26,3 26,1 25,0 23,0",
                "o": 13
            },
            {
                "d": "M5,2 L6,3 8,4 12,4 M6,3 L11,3 12,4 M12,4 L9,6 7,8 5,11 4,14 4,17 5,19 6,20 8,21 11,21 14,20 16,18 17,16 18,12 18,8 17,4 18,4 M4,16 L5,18 6,19 8,20 11,20 14,19 16,17 17,15 18,12 M18,4 L20,7 21,9 23,12 24,14 22,14 21,15 M17,4 L20,7 M21,9 L22,12 22,14 M22,1 L21,2 21,4 22,5 24,5 25,4 25,2 24,1 22,1",
                "o": 13
            },
            {
                "d": "M12,1 L13,4 15,5 M13,3 L15,4 16,4 16,5 M16,5 L13,6 11,7 10,8 10,10 11,11 13,12 15,13 16,14 17,16 17,18 16,20 14,21 12,21 9,20 7,18 M13,12 L15,14 16,16 16,18 15,20 14,21 M5,20 L8,17 11,15 14,14 M18,14 L21,15 23,17 24,19 21,19 M5,20 L5,19 8,17 M18,14 L20,15 22,17 23,19 M20,2 L19,3 19,5 20,6 22,6 23,5 23,3 22,2 20,2",
                "o": 13
            },
            {
                "d": "M4,11 L6,13 9,8 M5,12 L10,7 12,7 17,12 21,15 23,16 24,17 22,17 20,18 M17,12 L22,17 M18,3 L17,4 17,6 18,7 20,7 21,6 21,4 20,3 18,3",
                "o": 13
            },
            {
                "d": "M5,2 L7,3 7,5 6,8 5,12 M6,3 L6,6 5,12 5,16 6,20 7,21 7,19 8,15 9,12 M5,16 L6,18 7,19 M13,2 L15,3 17,3 21,2 M17,3 L19,2 20,1 21,2 M12,10 L15,11 18,11 21,10 23,9 M21,10 L22,8 23,9 M17,3 L19,4 19,18 18,20 16,21 14,21 12,20 11,18 12,16 14,15 16,15 18,16 23,20 M18,4 L19,8 M18,16 L23,19 23,20 M24,2 L23,3 23,5 24,6 26,6 27,5 27,3 26,2 24,2",
                "o": 13
            },
            {
                "d": "M4,2 L6,4 11,3 17,2 22,1 24,3 M5,3 L11,3 M17,2 L23,2 M24,3 L22,5 18,8 16,9 M23,2 L21,5 19,7 16,9 M14,8 L16,9 16,12 15,16 13,19 10,22 M14,8 L15,9 15,12 14,16 12,20",
                "o": 13
            },
            {
                "d": "M18,1 L20,3 17,7 14,10 10,13 7,15 M19,2 L18,4 15,8 11,12 7,15 M15,9 L17,10 17,23 M15,9 L16,10 16,22 17,23",
                "o": 13
            },
            {
                "d": "M12,1 L15,2 15,4 14,6 M12,1 L14,2 14,6 M6,4 L7,6 7,11 8,12 M8,6 L8,12 M8,6 L21,6 M6,4 L7,5 11,6 M17,6 L20,5 22,7 M22,7 L19,13 17,16 14,19 11,21 9,22 M21,6 L20,9 18,13 15,17 12,20 9,22",
                "o": 13
            },
            {
                "d": "M13,6 L15,7 15,18 M14,7 L14,18 M6,5 L8,7 20,5 22,6 M7,6 L22,6 M4,17 L6,19 22,17 24,18 M5,18 L24,18",
                "o": 13
            },
            {
                "d": "M14,1 L17,2 17,20 16,22 15,20 12,18 M16,2 L16,19 15,20 M4,7 L6,9 11,8 17,7 22,6 24,7 M5,8 L11,8 M17,7 L24,7 M15,8 L12,12 8,16 4,19 M16,8 L14,11 11,14 7,17 4,19",
                "o": 13
            },
            {
                "d": "M13,1 L15,3 14,6 12,11 10,15 8,18 5,22 M14,2 L13,5 11,11 9,16 5,22 M5,7 L7,9 11,8 16,7 20,6 22,8 M6,8 L11,8 M16,7 L21,7 M22,8 L21,14 20,18 19,20 17,22 16,21 14,20 M21,7 L20,14 19,18 18,20 16,21",
                "o": 13
            },
            {
                "d": "M11,1 L14,2 16,22 M13,2 L15,23 16,22 M6,7 L8,9 12,8 16,7 20,6 22,7 M7,8 L12,8 M16,7 L22,7 M4,13 L6,15 11,14 17,13 22,12 24,13 M5,14 L11,14 M17,13 L24,13",
                "o": 13
            },
            {
                "d": "M12,1 L14,3 12,7 10,10 8,12 6,14 M13,2 L12,5 10,9 8,12 M13,5 L15,6 20,6 M17,6 L19,5 21,7 M21,7 L18,13 15,17 12,20 9,22 7,23 M20,6 L19,9 17,13 14,17 11,20 7,23",
                "o": 13
            },
            {
                "d": "M10,1 L12,3 10,7 8,10 6,12 4,14 M11,2 L10,5 8,9 6,12 M8,10 L16,9 21,8 24,9 M16,9 L24,9 M14,10 L16,12 14,16 12,19 10,21 8,23 M15,11 L14,14 12,18 10,21",
                "o": 13
            },
            {
                "d": "M6,5 L8,7 20,5 22,7 M7,6 L21,6 M22,7 L21,10 20,17 M21,6 L20,11 20,17 M6,17 L8,19 20,17 21,18 M7,18 L21,18",
                "o": 13
            },
            {
                "d": "M8,4 L10,5 10,10 9,14 M9,5 L9,14 M16,1 L19,2 19,7 18,12 17,15 16,17 14,20 12,22 M18,2 L18,7 17,12 16,16 14,20 M4,8 L6,10 11,9 17,8 22,7 24,8 M5,9 L11,9 M17,8 L24,8",
                "o": 13
            },
            {
                "d": "M9,2 L11,3 12,4 12,5 11,5 10,3 9,2 M4,9 L6,10 7,11 7,12 6,12 5,10 4,9 M5,19 L7,21 11,19 15,16 19,12 23,7 M6,20 L11,18 15,15 23,7",
                "o": 13
            },
            {
                "d": "M6,4 L8,6 19,4 21,6 M7,5 L20,5 M21,6 L19,9 16,12 13,14 11,15 6,17 M20,5 L19,7 17,10 15,12 12,14 6,17 M17,14 L20,16 22,18 22,19 21,19 20,17 17,14",
                "o": 13
            },
            {
                "d": "M11,2 L14,3 14,5 13,14 M13,3 L13,17 14,19 16,20 23,20 M18,20 L22,19 23,20 M4,9 L6,11 11,10 17,9 22,8 24,10 M5,10 L11,10 M17,9 L23,9 M24,10 L21,12 18,15 M23,9 L18,15",
                "o": 13
            },
            {
                "d": "M5,4 L7,5 8,6 8,7 7,7 6,5 5,4 M20,3 L22,5 19,11 16,15 13,18 10,20 8,21 M21,4 L20,7 18,11 15,15 12,18 8,21",
                "o": 13
            },
            {
                "d": "M12,1 L14,3 12,7 10,10 8,12 6,14 M13,2 L12,5 10,9 8,12 M13,5 L15,6 20,6 M17,6 L19,5 21,7 M21,7 L18,13 15,17 12,20 9,22 7,23 M20,6 L19,9 17,13 14,17 11,20 7,23 M10,11 L13,12 15,13 18,15 19,16 19,17 18,17 17,15 15,13",
                "o": 13
            },
            {
                "d": "M8,5 L11,5 16,4 20,2 M11,5 L14,4 16,3 19,1 20,2 M12,5 L15,6 15,12 14,16 12,19 9,22 M14,6 L14,12 13,16 11,20 M4,9 L6,11 11,10 17,9 22,8 24,9 M5,10 L11,10 M17,9 L24,9",
                "o": 13
            },
            {
                "d": "M5,4 L7,5 8,6 8,7 7,7 6,5 5,4 M11,3 L13,4 14,5 14,6 13,6 12,4 11,3 M20,3 L22,5 19,11 16,15 13,18 10,20 8,21 M21,4 L20,7 18,11 15,15 12,18 8,21",
                "o": 13
            },
            {
                "d": "M8,1 L10,3 19,1 20,2 M9,2 L20,2 M4,9 L6,11 11,10 17,9 22,8 24,9 M5,10 L11,10 M17,9 L24,9 M15,10 L15,12 14,16 12,19 9,22 M14,10 L14,12 13,16 11,20",
                "o": 13
            },
            {
                "d": "M9,1 L12,2 12,23 M11,2 L11,22 12,23 M12,10 L14,10 17,11 18,12 18,13 17,13 16,11 14,10",
                "o": 13
            },
            {
                "d": "M13,1 L16,2 16,7 15,12 14,15 13,17 11,20 9,22 M15,2 L15,7 14,12 13,16 11,20 M4,9 L6,11 11,10 17,9 22,8 24,9 M5,10 L11,10 M17,9 L24,9",
                "o": 13
            },
            {
                "d": "M8,5 L10,7 19,5 20,6 M9,6 L20,6 M4,17 L6,19 22,17 24,18 M5,18 L24,18",
                "o": 13
            },
            {
                "d": "M6,4 L8,6 19,4 21,6 M7,5 L20,5 M21,6 L19,9 16,12 13,14 11,15 6,17 M20,5 L19,7 17,10 15,12 12,14 6,17 M9,10 L12,11 16,13 19,15 21,17 21,18 20,18 18,15 16,13",
                "o": 13
            },
            {
                "d": "M12,1 L14,2 15,3 15,4 14,4 13,2 12,1 M6,7 L8,9 18,7 20,9 M7,8 L19,8 M20,9 L16,13 13,15 11,16 6,18 M19,8 L17,11 15,13 12,15 6,18 M14,14 L15,15 15,23 M14,14 L14,22 15,23 M20,16 L22,17 23,18 23,19 22,19 21,17 20,16",
                "o": 13
            },
            {
                "d": "M19,3 L21,5 18,11 15,15 12,18 9,20 7,21 M20,4 L19,7 16,12 13,16 10,19 7,21",
                "o": 13
            },
            {
                "d": "M8,7 L10,9 9,12 8,14 6,16 4,17 M9,8 L8,12 6,16 M18,7 L20,9 22,12 24,16 24,17 23,17 22,13 20,9",
                "o": 13
            },
            {
                "d": "M5,2 L8,3 8,5 7,14 M7,3 L7,17 8,19 10,20 21,20 M13,20 L17,19 19,19 21,20 M7,13 L11,12 14,11 20,9 M14,11 L18,9 20,9",
                "o": 13
            },
            {
                "d": "M6,2 L8,4 21,4 M7,3 L11,4 M17,4 L20,3 22,5 M22,5 L19,11 17,14 14,17 11,19 9,20 M21,4 L20,7 18,11 15,15 12,18 9,20",
                "o": 13
            },
            {
                "d": "M3,12 L5,14 12,7 13,7 23,17 M4,13 L6,12 10,9 M17,11 L22,15 24,16 23,17",
                "o": 13
            },
            {
                "d": "M12,1 L15,2 15,20 14,22 13,20 11,19 M14,2 L14,19 13,20 M4,7 L6,9 11,8 17,7 22,6 24,7 M5,8 L11,8 M17,7 L24,7 M8,14 L6,17 5,18 4,18 4,17 6,16 8,14 M20,14 L23,16 24,17 24,18 23,18 22,16 20,14",
                "o": 13
            },
            {
                "d": "M4,7 L6,9 11,8 17,7 22,6 24,8 M5,8 L11,8 M17,7 L23,7 M24,8 L22,10 18,13 16,14 M23,7 L21,10 19,12 16,14 M13,13 L16,15 18,17 18,18 17,18 16,16 13,13",
                "o": 13
            },
            {
                "d": "M12,1 L15,2 17,3 18,4 18,5 17,5 15,3 12,1 M9,8 L12,9 14,10 15,11 15,12 14,12 12,10 9,8 M10,17 L15,19 17,20 18,21 18,22 17,22 15,20 12,18 10,17",
                "o": 13
            },
            {
                "d": "M14,4 L16,6 9,18 M15,5 L9,18 M4,18 L6,20 21,17 M5,19 L21,17 M18,14 L20,16 22,19 23,19 23,18 21,16 18,14",
                "o": 13
            },
            {
                "d": "M19,3 L21,5 18,11 15,15 12,18 9,20 7,21 M20,4 L19,7 17,11 14,15 11,18 7,21 M11,9 L15,11 18,13 20,15 20,16 19,16 18,14 16,12 13,10",
                "o": 13
            },
            {
                "d": "M8,4 L10,6 19,4 20,5 M9,5 L20,5 M13,6 L13,17 14,19 16,20 23,20 M14,6 L14,8 13,14 M18,20 L22,19 23,20 M4,12 L6,14 11,13 17,12 22,11 24,12 M5,13 L11,13 M17,12 L24,12",
                "o": 13
            },
            {
                "d": "M11,1 L14,2 16,22 M13,2 L15,23 16,22 M4,7 L6,9 11,8 17,7 22,6 24,8 M5,8 L11,8 M17,7 L23,7 M24,8 L21,11 18,13 16,14 M23,7 L22,9 19,12 16,14",
                "o": 13
            },
            {
                "d": "M18,1 L20,3 17,7 14,10 10,13 7,15 M19,2 L18,4 15,8 11,12 7,15 M15,9 L17,10 17,23 M15,9 L16,10 16,22 17,23",
                "o": 13
            },
            {
                "d": "M5,5 L7,7 17,5 19,7 M6,6 L18,6 M19,7 L18,10 17,17 M18,6 L17,11 17,17 M4,17 L6,19 22,17 24,18 M5,18 L24,18",
                "o": 13
            },
            {
                "d": "M13,6 L15,7 15,18 M14,7 L14,18 M6,5 L8,7 20,5 22,6 M7,6 L22,6 M4,17 L6,19 22,17 24,18 M5,18 L24,18",
                "o": 13
            },
            {
                "d": "M6,5 L8,7 20,5 22,7 M7,6 L21,6 M6,11 L8,13 20,11 M7,12 L20,12 M6,17 L8,19 20,17 M7,18 L20,18 M22,7 L21,10 21,20 M21,6 L20,11 20,20",
                "o": 13
            },
            {
                "d": "M8,1 L10,3 19,1 20,2 M9,2 L20,2 M6,8 L8,10 19,8 21,10 M7,9 L20,9 M21,10 L19,13 16,16 13,18 11,19 8,20 M20,9 L19,11 17,14 15,16 12,18 8,20",
                "o": 13
            },
            {
                "d": "M8,4 L10,5 10,10 9,14 M9,5 L9,14 M16,1 L19,2 19,12 18,16 16,19 13,22 M18,2 L18,12 17,16 16,18 13,22",
                "o": 13
            },
            {
                "d": "M8,5 L10,6 10,9 9,13 7,16 4,19 M9,6 L9,9 8,13 7,15 4,19 M13,4 L15,5 15,8 14,14 M14,5 L14,17 15,19 17,19 19,18 21,16 24,12 M14,17 L15,18 18,18 20,17",
                "o": 13
            },
            {
                "d": "M4,4 L7,5 7,8 6,14 M6,5 L6,17 7,19 10,19 13,18 16,16 20,13 23,10 M6,17 L7,18 10,18 13,17 17,15 20,13",
                "o": 13
            },
            {
                "d": "M6,5 L8,7 20,5 22,7 M7,6 L21,6 M7,6 L7,21 M8,7 L8,19 7,21 M22,7 L21,10 20,17 M21,6 L20,11 20,17 M8,19 L20,17 21,18 M8,18 L21,18",
                "o": 13
            },
            {
                "d": "M6,2 L7,4 7,9 8,10 M8,4 L8,10 M8,4 L21,4 M6,2 L7,3 11,4 M17,4 L20,3 22,5 M22,5 L19,11 17,14 14,17 11,19 9,20 M21,4 L20,7 18,11 15,15 12,18 9,20",
                "o": 13
            },
            {
                "d": "M13,1 L16,2 16,22 M15,2 L15,23 16,22 M6,5 L8,7 20,5 22,6 M7,6 L22,6 M8,7 L10,8 10,13 M9,8 L9,13 M4,12 L6,14 22,12 24,13 M5,13 L24,13",
                "o": 13
            },
            {
                "d": "M12,1 L15,2 15,4 14,6 M12,1 L14,2 14,6 M6,4 L7,6 7,11 8,12 M8,6 L8,12 M8,6 L21,6 M6,4 L7,5 11,6 M17,6 L20,5 22,7 M22,7 L19,13 17,16 14,19 11,21 9,22 M21,6 L20,9 18,13 15,17 12,20 9,22",
                "o": 13
            },
            {
                "d": "M6,5 L8,7 20,5 22,7 M7,6 L21,6 M22,7 L19,9 15,12 M21,6 L15,12 M13,11 L15,12 15,18 M14,12 L14,18 M4,17 L6,19 22,17 24,18 M5,18 L24,18",
                "o": 13
            },
            {
                "d": "M6,2 L8,4 21,4 M7,3 L11,4 M17,4 L20,3 22,5 M8,9 L10,11 18,10 M9,10 L18,10 M22,5 L19,11 17,14 14,17 11,19 9,20 M21,4 L20,7 18,11 15,15 12,18 9,20",
                "o": 13
            },
            {
                "d": "M6,3 L8,4 9,5 9,6 8,6 7,4 6,3 M5,18 L7,20 11,18 15,15 19,11 23,6 M6,19 L11,17 15,14 23,6",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M4,20 L3,21 4,22 5,21 4,20",
                "o": 13
            },
            {
                "d": "M13,1 L15,3 14,6 12,11 10,15 8,18 5,22 M14,2 L13,5 11,11 9,16 5,22 M5,7 L7,9 11,8 16,7 20,6 22,8 M6,8 L11,8 M16,7 L21,7 M22,8 L21,14 20,18 19,20 17,22 16,21 14,20 M21,7 L20,14 19,18 18,20 16,21 M22,3 L24,5 M24,1 L26,3",
                "o": 13
            },
            {
                "d": "M11,1 L14,2 16,22 M13,2 L15,23 16,22 M6,7 L8,9 12,8 16,7 20,6 22,7 M7,8 L12,8 M16,7 L22,7 M4,13 L6,15 11,14 17,13 22,12 24,13 M5,14 L11,14 M17,13 L24,13 M22,3 L24,5 M24,1 L26,3",
                "o": 13
            },
            {
                "d": "M12,1 L14,3 12,7 10,10 8,12 6,14 M13,2 L12,5 10,9 8,12 M13,5 L15,6 20,6 M17,6 L19,5 21,7 M21,7 L18,13 15,17 12,20 9,22 7,23 M20,6 L19,9 17,13 14,17 11,20 7,23 M21,3 L23,5 M23,1 L25,3",
                "o": 13
            },
            {
                "d": "M10,1 L12,3 10,7 8,10 6,12 4,14 M11,2 L10,5 8,9 6,12 M8,10 L16,9 21,8 24,9 M16,9 L24,9 M14,10 L16,12 14,16 12,19 10,21 8,23 M15,11 L14,14 12,18 10,21 M21,3 L23,5 M23,1 L25,3",
                "o": 13
            },
            {
                "d": "M6,5 L8,7 20,5 22,7 M7,6 L21,6 M22,7 L21,10 20,17 M21,6 L20,11 20,17 M6,17 L8,19 20,17 21,18 M7,18 L21,18 M22,3 L24,5 M24,1 L26,3",
                "o": 13
            },
            {
                "d": "M8,4 L10,5 10,10 9,14 M9,5 L9,14 M16,1 L19,2 19,7 18,12 17,15 16,17 14,20 12,22 M18,2 L18,7 17,12 16,16 14,20 M4,8 L6,10 11,9 17,8 22,7 24,8 M5,9 L11,9 M17,8 L24,8 M22,3 L24,5 M24,1 L26,3",
                "o": 13
            },
            {
                "d": "M9,2 L11,3 12,4 12,5 11,5 10,3 9,2 M4,9 L6,10 7,11 7,12 6,12 5,10 4,9 M5,19 L7,21 11,19 15,16 19,12 23,7 M6,20 L11,18 15,15 23,7 M18,3 L20,5 M20,1 L22,3",
                "o": 13
            },
            {
                "d": "M6,4 L8,6 19,4 21,6 M7,5 L20,5 M21,6 L19,9 16,12 13,14 11,15 6,17 M20,5 L19,7 17,10 15,12 12,14 6,17 M17,14 L20,16 22,18 22,19 21,19 20,17 17,14 M22,3 L24,5 M24,1 L26,3",
                "o": 13
            },
            {
                "d": "M11,2 L14,3 14,5 13,14 M13,3 L13,17 14,19 16,20 23,20 M18,20 L22,19 23,20 M4,9 L6,11 11,10 17,9 22,8 24,10 M5,10 L11,10 M17,9 L23,9 M24,10 L21,12 18,15 M23,9 L18,15 M21,3 L23,5 M23,1 L25,3",
                "o": 13
            },
            {
                "d": "M5,4 L7,5 8,6 8,7 7,7 6,5 5,4 M20,3 L22,5 19,11 16,15 13,18 10,20 8,21 M21,4 L20,7 18,11 15,15 12,18 8,21 M23,2 L25,4 M25,0 L27,2",
                "o": 13
            },
            {
                "d": "M12,1 L14,3 12,7 10,10 8,12 6,14 M13,2 L12,5 10,9 8,12 M13,5 L15,6 20,6 M17,6 L19,5 21,7 M21,7 L18,13 15,17 12,20 9,22 7,23 M20,6 L19,9 17,13 14,17 11,20 7,23 M10,11 L13,12 15,13 18,15 19,16 19,17 18,17 17,15 15,13 M21,3 L23,5 M23,1 L25,3",
                "o": 13
            },
            {
                "d": "M8,5 L11,5 16,4 20,2 M11,5 L14,4 16,3 19,1 20,2 M12,5 L15,6 15,12 14,16 12,19 9,22 M14,6 L14,12 13,16 11,20 M4,9 L6,11 11,10 17,9 22,8 24,9 M5,10 L11,10 M17,9 L24,9 M22,3 L24,5 M24,1 L26,3",
                "o": 13
            },
            {
                "d": "M5,4 L7,5 8,6 8,7 7,7 6,5 5,4 M11,3 L13,4 14,5 14,6 13,6 12,4 11,3 M20,3 L22,5 19,11 16,15 13,18 10,20 8,21 M21,4 L20,7 18,11 15,15 12,18 8,21 M23,2 L25,4 M25,0 L27,2",
                "o": 13
            },
            {
                "d": "M8,1 L10,3 19,1 20,2 M9,2 L20,2 M4,9 L6,11 11,10 17,9 22,8 24,9 M5,10 L11,10 M17,9 L24,9 M15,10 L15,12 14,16 12,19 9,22 M14,10 L14,12 13,16 11,20 M22,3 L24,5 M24,1 L26,3",
                "o": 13
            },
            {
                "d": "M9,1 L12,2 12,23 M11,2 L11,22 12,23 M12,10 L14,10 17,11 18,12 18,13 17,13 16,11 14,10 M16,3 L18,5 M18,1 L20,3",
                "o": 13
            },
            {
                "d": "M8,7 L10,9 9,12 8,14 6,16 4,17 M9,8 L8,12 6,16 M18,7 L20,9 22,12 24,16 24,17 23,17 22,13 20,9 M21,4 L23,6 M23,2 L25,4",
                "o": 13
            },
            {
                "d": "M5,2 L8,3 8,5 7,14 M7,3 L7,17 8,19 10,20 21,20 M13,20 L17,19 19,19 21,20 M7,13 L11,12 14,11 20,9 M14,11 L18,9 20,9 M21,4 L23,6 M23,2 L25,4",
                "o": 13
            },
            {
                "d": "M6,2 L8,4 21,4 M7,3 L11,4 M17,4 L20,3 22,5 M22,5 L19,11 17,14 14,17 11,19 9,20 M21,4 L20,7 18,11 15,15 12,18 9,20 M23,2 L25,4 M25,0 L27,2",
                "o": 13
            },
            {
                "d": "M3,12 L5,14 12,7 13,7 23,17 M4,13 L6,12 10,9 M17,11 L22,15 24,16 23,17 M18,5 L20,7 M20,3 L22,5",
                "o": 13
            },
            {
                "d": "M12,1 L15,2 15,20 14,22 13,20 11,19 M14,2 L14,19 13,20 M4,7 L6,9 11,8 17,7 22,6 24,7 M5,8 L11,8 M17,7 L24,7 M8,14 L6,17 5,18 4,18 4,17 6,16 8,14 M20,14 L23,16 24,17 24,18 23,18 22,16 20,14 M22,2 L24,4 M24,0 L26,2",
                "o": 13
            },
            {
                "d": "M8,7 L10,9 9,12 8,14 6,16 4,17 M9,8 L8,12 6,16 M18,7 L20,9 22,12 24,16 24,17 23,17 22,13 20,9 M22,2 L21,3 21,5 22,6 24,6 25,5 25,3 24,2 22,2",
                "o": 13
            },
            {
                "d": "M5,2 L8,3 8,5 7,14 M7,3 L7,17 8,19 10,20 21,20 M13,20 L17,19 19,19 21,20 M7,13 L11,12 14,11 20,9 M14,11 L18,9 20,9 M22,2 L21,3 21,5 22,6 24,6 25,5 25,3 24,2 22,2",
                "o": 13
            },
            {
                "d": "M6,2 L8,4 21,4 M7,3 L11,4 M17,4 L20,3 22,5 M22,5 L19,11 17,14 14,17 11,19 9,20 M21,4 L20,7 18,11 15,15 12,18 9,20 M24,0 L23,1 23,3 24,4 26,4 27,3 27,1 26,0 24,0",
                "o": 13
            },
            {
                "d": "M3,12 L5,14 12,7 13,7 23,17 M4,13 L6,12 10,9 M17,11 L22,15 24,16 23,17 M19,3 L18,4 18,6 19,7 21,7 22,6 22,4 21,3 19,3",
                "o": 13
            },
            {
                "d": "M12,1 L15,2 15,20 14,22 13,20 11,19 M14,2 L14,19 13,20 M4,7 L6,9 11,8 17,7 22,6 24,7 M5,8 L11,8 M17,7 L24,7 M8,14 L6,17 5,18 4,18 4,17 6,16 8,14 M20,14 L23,16 24,17 24,18 23,18 22,16 20,14 M23,0 L22,1 22,3 23,4 25,4 26,3 26,1 25,0 23,0",
                "o": 13
            }
        ]
    },
    "markers": {
        "name": "Markers",
        "chars": [
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "M6,6 L3,7 1,9 0,12 0,14 1,17 3,19 6,20 8,20 11,19 13,17 14,14 14,12 13,9 11,7 8,6 6,6",
                "o": 7
            },
            {
                "d": "M0,7 L0,19 12,19 12,7 0,7",
                "o": 6
            },
            {
                "d": "M7,5 L0,17 14,17 7,5",
                "o": 7
            },
            {
                "d": "M6,3 L0,13 6,23 12,13 6,3",
                "o": 6
            },
            {
                "d": "M8,4 L6,10 0,10 5,14 3,20 8,16 13,20 11,14 16,10 10,10 8,4",
                "o": 8
            },
            {
                "d": "M4,7 L4,11 0,11 0,15 4,15 4,19 8,19 8,15 12,15 12,11 8,11 8,7 4,7",
                "o": 6
            },
            {
                "d": "M7,6 L7,20 M0,13 L14,13",
                "o": 7
            },
            {
                "d": "M0,8 L10,18 M10,8 L0,18",
                "o": 5
            },
            {
                "d": "M5,7 L5,19 M0,10 L10,16 M10,10 L0,16",
                "o": 5
            },
            {
                "d": "M3,9 L1,10 0,12 0,14 1,16 3,17 5,17 7,16 8,14 8,12 7,10 5,9 3,9 M1,12 L1,14 M2,11 L2,15 M3,10 L3,16 M4,10 L4,16 M5,10 L5,16 M6,11 L6,15 M7,12 L7,14",
                "o": 4
            },
            {
                "d": "M0,9 L0,17 8,17 8,9 0,9 M1,10 L1,16 M2,10 L2,16 M3,10 L3,16 M4,10 L4,16 M5,10 L5,16 M6,10 L6,16 M7,10 L7,16",
                "o": 4
            },
            {
                "d": "M5,7 L0,16 10,16 5,7 M5,10 L2,15 M5,10 L8,15 M5,13 L4,15 M5,13 L6,15",
                "o": 5
            },
            {
                "d": "M0,13 L9,18 9,8 0,13 M3,13 L8,16 M3,13 L8,10 M6,13 L8,14 M6,13 L8,12",
                "o": 3
            },
            {
                "d": "M5,19 L10,10 0,10 5,19 M5,16 L8,11 M5,16 L2,11 M5,13 L6,11 M5,13 L4,11",
                "o": 5
            },
            {
                "d": "M9,13 L0,8 0,18 9,13 M6,13 L1,10 M6,13 L1,16 M3,13 L1,12 M3,13 L1,14",
                "o": 6
            },
            {
                "d": "M0,13 L28,13 M0,13 L14,29 M28,13 L14,29",
                "o": 14
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "M6,6 L3,7 1,9 0,12 0,14 1,17 3,19 6,20 8,20 11,19 13,17 14,14 14,12 13,9 11,7 8,6 6,6",
                "o": 7
            },
            {
                "d": "M0,7 L0,19 12,19 12,7 0,7",
                "o": 6
            },
            {
                "d": "M7,5 L0,17 14,17 7,5",
                "o": 7
            },
            {
                "d": "M6,3 L0,13 6,23 12,13 6,3",
                "o": 6
            },
            {
                "d": "M8,4 L6,10 0,10 5,14 3,20 8,16 13,20 11,14 16,10 10,10 8,4",
                "o": 8
            },
            {
                "d": "M4,7 L4,11 0,11 0,15 4,15 4,19 8,19 8,15 12,15 12,11 8,11 8,7 4,7",
                "o": 6
            },
            {
                "d": "M7,6 L7,20 M0,13 L14,13",
                "o": 7
            },
            {
                "d": "M0,8 L10,18 M10,8 L0,18",
                "o": 5
            },
            {
                "d": "M5,7 L5,19 M0,10 L10,16 M10,10 L0,16",
                "o": 5
            },
            {
                "d": "M3,9 L1,10 0,12 0,14 1,16 3,17 5,17 7,16 8,14 8,12 7,10 5,9 3,9 M1,12 L1,14 M2,11 L2,15 M3,10 L3,16 M4,10 L4,16 M5,10 L5,16 M6,11 L6,15 M7,12 L7,14",
                "o": 4
            },
            {
                "d": "M0,9 L0,17 8,17 8,9 0,9 M1,10 L1,16 M2,10 L2,16 M3,10 L3,16 M4,10 L4,16 M5,10 L5,16 M6,10 L6,16 M7,10 L7,16",
                "o": 4
            },
            {
                "d": "M5,7 L0,16 10,16 5,7 M5,10 L2,15 M5,10 L8,15 M5,13 L4,15 M5,13 L6,15",
                "o": 5
            },
            {
                "d": "M0,13 L9,18 9,8 0,13 M3,13 L8,16 M3,13 L8,10 M6,13 L8,14 M6,13 L8,12",
                "o": 3
            },
            {
                "d": "M5,19 L10,10 0,10 5,19 M5,16 L8,11 M5,16 L2,11 M5,13 L6,11 M5,13 L4,11",
                "o": 5
            },
            {
                "d": "M9,13 L0,8 0,18 9,13 M6,13 L1,10 M6,13 L1,16 M3,13 L1,12 M3,13 L1,14",
                "o": 6
            },
            {
                "d": "M0,13 L28,13 M0,13 L14,29 M28,13 L14,29",
                "o": 14
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            },
            {
                "d": "",
                "o": 8
            }
        ]
    },
    "mathlow": {
        "name": "Math (lower)",
        "chars": [
            {
                "d": "M12,5 L12,22 M4,13 L20,13 M4,22 L20,22",
                "o": 12
            },
            {
                "d": "M12,5 L12,22 M4,5 L20,5 M4,13 L20,13",
                "o": 12
            },
            {
                "d": "M4,6 L18,20 M18,6 L4,20",
                "o": 11
            },
            {
                "d": "M2,12 L2,13 3,13 3,12 2,12",
                "o": 3
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M20,1 L4,8 20,15 M4,17 L20,17 M4,22 L20,22",
                "o": 12
            },
            {
                "d": "M4,1 L20,8 4,15 M4,17 L20,17 M4,22 L20,22",
                "o": 12
            },
            {
                "d": "M11,-3 L9,-1 7,2 5,6 4,11 4,15 5,20 7,24 9,27 11,29 M9,-1 L7,3 6,6 5,11 5,15 6,20 7,23 9,27",
                "o": 7
            },
            {
                "d": "M3,-3 L5,-1 7,2 9,6 10,11 10,15 9,20 7,24 5,27 3,29 M5,-1 L7,3 8,6 9,11 9,15 8,20 7,23 5,27",
                "o": 7
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M20,-3 L2,29",
                "o": 11
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22",
                "o": 10
            },
            {
                "d": "M4,6 L4,5 5,3 6,2 8,1 12,1 14,2 15,3 16,5 16,7 15,9 13,12 3,22 17,22",
                "o": 10
            },
            {
                "d": "M5,1 L16,1 10,9 13,9 15,10 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M13,1 L3,15 18,15 M13,1 L13,22",
                "o": 10
            },
            {
                "d": "M15,1 L5,1 4,10 5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M16,4 L15,2 12,1 10,1 7,2 5,5 4,10 4,15 5,19 7,21 10,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 4,15",
                "o": 10
            },
            {
                "d": "M17,1 L7,22 M3,1 L17,1",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,6 5,8 7,9 11,10 14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,15 4,13 6,11 9,10 13,9 15,8 16,6 16,4 15,2 12,1 8,1",
                "o": 10
            },
            {
                "d": "M16,8 L15,11 13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 10,1 13,2 15,4 16,8 16,13 15,18 13,21 10,22 8,22 5,21 4,19",
                "o": 10
            },
            {
                "d": "M7,-3 L7,29 M8,-3 L8,29 M26,-3 L26,29 M27,-3 L27,29 M3,-3 L31,-3 M3,29 L12,29 M22,29 L31,29",
                "o": 17
            },
            {
                "d": "M5,-3 L15,11 4,29 M4,-3 L14,11 M3,-3 L14,12 M3,-3 L26,-3 28,4 25,-3 M5,28 L26,28 M4,29 L26,29 28,22 25,29",
                "o": 15
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M20,4 L6,22 M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,8 L22,8 M4,13 L22,13 M4,18 L22,18",
                "o": 13
            },
            {
                "d": "M15,8 L15,22 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,11 L6,9 8,8 11,8 13,9 15,11 16,14 16,16 15,19 13,21 11,22 8,22 6,21 4,19",
                "o": 9
            },
            {
                "d": "M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 9
            },
            {
                "d": "M15,1 L15,22 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M3,14 L15,14 15,12 14,10 13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 9
            },
            {
                "d": "M10,1 L8,1 6,2 5,5 5,22 M2,8 L9,8",
                "o": 7
            },
            {
                "d": "M15,8 L15,24 14,27 13,28 11,29 8,29 6,28 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,12 L7,9 9,8 12,8 14,9 15,12 15,22",
                "o": 10
            },
            {
                "d": "M3,1 L4,2 5,1 4,0 3,1 M4,8 L4,22",
                "o": 4
            },
            {
                "d": "M5,1 L6,2 7,1 6,0 5,1 M6,8 L6,25 5,28 3,29 1,29",
                "o": 5
            },
            {
                "d": "M4,1 L4,22 M14,8 L4,18 M8,14 L15,22",
                "o": 8
            },
            {
                "d": "M4,1 L4,22",
                "o": 4
            },
            {
                "d": "M4,8 L4,22 M4,12 L7,9 9,8 12,8 14,9 15,12 15,22 M15,12 L18,9 20,8 23,8 25,9 26,12 26,22",
                "o": 15
            },
            {
                "d": "M4,8 L4,22 M4,12 L7,9 9,8 12,8 14,9 15,12 15,22",
                "o": 10
            },
            {
                "d": "M8,8 L6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19 16,16 16,14 15,11 13,9 11,8 8,8",
                "o": 10
            },
            {
                "d": "M4,8 L4,29 M4,11 L6,9 8,8 11,8 13,9 15,11 16,14 16,16 15,19 13,21 11,22 8,22 6,21 4,19",
                "o": 9
            },
            {
                "d": "M15,8 L15,29 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M4,8 L4,22 M4,14 L5,11 7,9 9,8 12,8",
                "o": 6
            },
            {
                "d": "M14,11 L13,9 10,8 7,8 4,9 3,11 4,13 6,14 11,15 13,16 14,18 14,19 13,21 10,22 7,22 4,21 3,19",
                "o": 9
            },
            {
                "d": "M5,1 L5,18 6,21 8,22 10,22 M2,8 L9,8",
                "o": 7
            },
            {
                "d": "M4,8 L4,18 5,21 7,22 10,22 12,21 15,18 M15,8 L15,22",
                "o": 10
            },
            {
                "d": "M2,8 L8,22 M14,8 L8,22",
                "o": 8
            },
            {
                "d": "M3,8 L7,22 M11,8 L7,22 M11,8 L15,22 M19,8 L15,22",
                "o": 11
            },
            {
                "d": "M3,8 L14,22 M14,8 L3,22",
                "o": 9
            },
            {
                "d": "M2,8 L8,22 M14,8 L8,22 6,26 4,28 2,29 1,29",
                "o": 8
            },
            {
                "d": "M14,8 L3,22 M3,8 L14,8 M3,22 L14,22",
                "o": 9
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M21,18 L19,18 17,17 15,15 12,11 11,10 9,9 7,9 5,10 4,12 4,14 5,16 7,17 9,17 11,16 12,15 15,11 17,9 19,8 21,8",
                "o": 13
            },
            {
                "d": "M22,14 L21,16 19,17 17,17 15,16 14,15 11,11 10,10 8,9 6,9 4,10 3,12 3,14 4,16 6,17 8,17 10,16 11,15 14,11 15,10 17,9 19,9 21,10 22,12 22,14",
                "o": 13
            },
            {
                "d": "M6,1 L4,2 3,4 3,6 4,8 6,9 8,9 10,8 11,6 11,4 10,2 8,1 6,1",
                "o": 7
            },
            {
                "d": "M8,-3 L8,26 M12,-3 L12,26 M17,4 L15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 16,14 17,16 17,19 15,21 12,22 8,22 5,21 3,19",
                "o": 10
            },
            {
                "d": "M3,8 L7,8 13,20 M6,8 L13,22 M22,-3 L13,22",
                "o": 9
            },
            {
                "d": "M3,8 L8,8 17,20 M7,9 L17,22 M33,-11 L17,22",
                "o": 16
            },
            {
                "d": "M20,5 L13,5 9,6 7,7 5,9 4,12 4,14 5,17 7,19 9,20 13,21 20,21",
                "o": 12
            },
            {
                "d": "M4,5 L4,12 5,16 6,18 8,20 11,21 13,21 16,20 18,18 19,16 20,12 20,5",
                "o": 12
            },
            {
                "d": "M4,5 L11,5 15,6 17,7 19,9 20,12 20,14 19,17 17,19 15,20 11,21 4,21",
                "o": 12
            },
            {
                "d": "M4,21 L4,14 5,10 6,8 8,6 11,5 13,5 16,6 18,8 19,10 20,14 20,21",
                "o": 12
            },
            {
                "d": "M20,5 L13,5 9,6 7,7 5,9 4,12 4,14 5,17 7,19 9,20 13,21 20,21 M4,13 L16,13",
                "o": 12
            },
            {
                "d": "M19,11 L22,13 19,15 M16,8 L21,13 16,18 M4,13 L21,13",
                "o": 13
            },
            {
                "d": "M6,7 L8,4 10,7 M3,10 L8,5 13,10 M8,5 L8,22",
                "o": 8
            },
            {
                "d": "M7,11 L4,13 7,15 M10,8 L5,13 10,18 M5,13 L22,13",
                "o": 13
            },
            {
                "d": "M6,19 L8,22 10,19 M3,16 L8,21 13,16 M8,4 L8,21",
                "o": 8
            },
            {
                "d": "M15,13 L14,10 13,9 11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,19 15,16 16,11 16,6 15,3 14,2 12,1 9,1 7,2 6,3 6,4 7,4 7,3 M9,8 L7,9 5,12 4,15 4,19 5,21 M9,22 L11,21 13,19 14,16 15,11 15,6 14,3 12,1",
                "o": 10
            },
            {
                "d": "M2,1 L10,22 M3,1 L10,20 M18,1 L10,22 M2,1 L18,1 M3,2 L17,2",
                "o": 10
            },
            {
                "d": "M3,8 L8,8 17,20 M7,9 L17,22 M33,-11 L17,22",
                "o": 16
            },
            {
                "d": "M21,-2 L20,-1 21,0 22,-1 22,-2 21,-3 19,-3 17,-2 15,0 14,2 13,5 12,9 10,21 9,25 8,27 M16,-1 L15,1 14,5 12,17 11,21 10,24 9,26 7,28 5,29 3,29 2,28 2,27 3,26 4,27 3,28",
                "o": 12
            },
            {
                "d": "M26,-23 L25,-23 24,-22 24,-21 25,-20 26,-20 27,-21 27,-23 26,-25 24,-26 22,-26 20,-25 18,-23 17,-21 16,-18 15,-11 14,5 14,37 13,46 12,49 M25,-22 L25,-21 26,-21 26,-22 25,-22 M15,-11 L15,37 M18,-23 L17,-20 16,-11 16,21 15,37 14,44 13,47 12,49 10,51 8,52 6,52 4,51 3,49 3,47 4,46 5,46 6,47 6,48 5,49 4,49 M4,47 L4,48 5,48 5,47 4,47",
                "o": 15
            },
            {
                "d": "M15,-26 L12,-20 9,-13 7,-8 6,-4 5,1 4,9 4,17 5,25 6,30 7,34 9,39 12,46 15,52 M12,-20 L10,-15 8,-9 7,-5 6,1 5,9 5,17 6,25 7,31 8,35 10,41 12,46",
                "o": 9
            },
            {
                "d": "M3,-26 L6,-20 9,-13 11,-8 12,-4 13,1 14,9 14,17 13,25 12,30 11,34 9,39 6,46 3,52 M6,-20 L8,-15 10,-9 11,-5 12,1 13,9 13,17 12,25 11,31 10,35 8,41 6,46",
                "o": 9
            },
            {
                "d": "M4,-26 L4,13 4,52 M5,-26 L5,13 5,52 M4,-26 L15,-26 M4,52 L15,52",
                "o": 9
            },
            {
                "d": "M13,-26 L13,13 13,52 M14,-26 L14,13 14,52 M3,-26 L14,-26 M3,52 L14,52",
                "o": 9
            },
            {
                "d": "M15,1 L15,22 M2,1 L15,1 M7,11 L15,11 M2,22 L15,22",
                "o": 10
            },
            {
                "d": "M3,4 L4,6 16,18 17,20 17,22 M4,7 L16,19 M3,4 L3,6 4,8 16,20 17,22 M8,11 L4,15 3,17 3,19 4,21 3,22 M3,17 L5,21 M4,15 L4,17 5,19 5,21 3,22 M11,14 L16,9 M14,4 L14,7 15,9 17,9 17,7 15,6 14,4 M14,4 L15,7 17,9",
                "o": 10
            },
            {
                "d": "M13,4 L12,5 13,6 14,5 13,4 M4,13 L22,13 M13,20 L12,21 13,22 14,21 13,20",
                "o": 13
            },
            {
                "d": "M4,-3 L4,29 M10,-3 L10,29",
                "o": 7
            },
            {
                "d": "M12,-3 L12,22 M3,22 L21,22",
                "o": 12
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M21,-3 L3,22 21,22",
                "o": 12
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M13,4 L12,5 13,6 14,5 13,4 M4,20 L3,21 4,22 5,21 4,20 M22,20 L21,21 22,22 23,21 22,20",
                "o": 13
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            }
        ]
    },
    "mathupp": {
        "name": "Math (upper)",
        "chars": [
            {
                "d": "M12,5 L12,22 M4,13 L20,13 M4,22 L20,22",
                "o": 12
            },
            {
                "d": "M12,5 L12,22 M4,5 L20,5 M4,13 L20,13",
                "o": 12
            },
            {
                "d": "M4,6 L18,20 M18,6 L4,20",
                "o": 11
            },
            {
                "d": "M2,12 L2,13 3,13 3,12 2,12",
                "o": 3
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M20,1 L4,8 20,15 M4,17 L20,17 M4,22 L20,22",
                "o": 12
            },
            {
                "d": "M4,1 L20,8 4,15 M4,17 L20,17 M4,22 L20,22",
                "o": 12
            },
            {
                "d": "M11,-3 L9,-1 7,2 5,6 4,11 4,15 5,20 7,24 9,27 11,29 M9,-1 L7,3 6,6 5,11 5,15 6,20 7,23 9,27",
                "o": 7
            },
            {
                "d": "M3,-3 L5,-1 7,2 9,6 10,11 10,15 9,20 7,24 5,27 3,29 M5,-1 L7,3 8,6 9,11 9,15 8,20 7,23 5,27",
                "o": 7
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M6,21 L5,22 4,21 5,20 6,21 6,23 5,25 4,26",
                "o": 5
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,20 L4,21 5,22 6,21 5,20",
                "o": 5
            },
            {
                "d": "M20,-3 L2,29",
                "o": 11
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22",
                "o": 10
            },
            {
                "d": "M4,6 L4,5 5,3 6,2 8,1 12,1 14,2 15,3 16,5 16,7 15,9 13,12 3,22 17,22",
                "o": 10
            },
            {
                "d": "M5,1 L16,1 10,9 13,9 15,10 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M13,1 L3,15 18,15 M13,1 L13,22",
                "o": 10
            },
            {
                "d": "M15,1 L5,1 4,10 5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M16,4 L15,2 12,1 10,1 7,2 5,5 4,10 4,15 5,19 7,21 10,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 4,15",
                "o": 10
            },
            {
                "d": "M17,1 L7,22 M3,1 L17,1",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,6 5,8 7,9 11,10 14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,15 4,13 6,11 9,10 13,9 15,8 16,6 16,4 15,2 12,1 8,1",
                "o": 10
            },
            {
                "d": "M16,8 L15,11 13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 10,1 13,2 15,4 16,8 16,13 15,18 13,21 10,22 8,22 5,21 4,19",
                "o": 10
            },
            {
                "d": "M7,-3 L7,29 M8,-3 L8,29 M26,-3 L26,29 M27,-3 L27,29 M3,-3 L31,-3 M3,29 L12,29 M22,29 L31,29",
                "o": 17
            },
            {
                "d": "M5,-3 L15,11 4,29 M4,-3 L14,11 M3,-3 L14,12 M3,-3 L26,-3 28,4 25,-3 M5,28 L26,28 M4,29 L26,29 28,22 25,29",
                "o": 15
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M20,4 L6,22 M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,8 L22,8 M4,13 L22,13 M4,18 L22,18",
                "o": 13
            },
            {
                "d": "M9,1 L1,22 M9,1 L17,22 M4,15 L14,15",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,7 17,9 16,10 13,11 M4,11 L13,11 16,12 17,13 18,15 18,18 17,20 16,21 13,22 4,22",
                "o": 10
            },
            {
                "d": "M18,6 L17,4 15,2 13,1 9,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L11,1 14,2 16,4 17,6 18,9 18,14 17,17 16,19 14,21 11,22 4,22",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,1 L17,1 M4,11 L12,11 M4,22 L17,22",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 M4,1 L17,1 M4,11 L12,11",
                "o": 8
            },
            {
                "d": "M18,6 L17,4 15,2 13,1 9,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 18,14 M13,14 L18,14",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M18,1 L18,22 M4,11 L18,11",
                "o": 11
            },
            {
                "d": "M4,1 L4,22",
                "o": 4
            },
            {
                "d": "M12,1 L12,17 11,20 10,21 8,22 6,22 4,21 3,20 2,17 2,15",
                "o": 8
            },
            {
                "d": "M4,1 L4,22 M18,1 L4,15 M9,10 L18,22",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,22 L16,22",
                "o": 7
            },
            {
                "d": "M4,1 L4,22 M4,1 L12,22 M20,1 L12,22 M20,1 L20,22",
                "o": 12
            },
            {
                "d": "M4,1 L4,22 M4,1 L18,22 M18,1 L18,22",
                "o": 11
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 19,14 19,9 18,6 17,4 15,2 13,1 9,1",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,8 17,10 16,11 13,12 4,12",
                "o": 10
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 19,14 19,9 18,6 17,4 15,2 13,1 9,1 M12,18 L18,24",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,7 17,9 16,10 13,11 4,11 M11,11 L18,22",
                "o": 10
            },
            {
                "d": "M17,4 L15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 16,14 17,16 17,19 15,21 12,22 8,22 5,21 3,19",
                "o": 10
            },
            {
                "d": "M8,1 L8,22 M1,1 L15,1",
                "o": 8
            },
            {
                "d": "M4,1 L4,16 5,19 7,21 10,22 12,22 15,21 17,19 18,16 18,1",
                "o": 11
            },
            {
                "d": "M1,1 L9,22 M17,1 L9,22",
                "o": 9
            },
            {
                "d": "M2,1 L7,22 M12,1 L7,22 M12,1 L17,22 M22,1 L17,22",
                "o": 12
            },
            {
                "d": "M3,1 L17,22 M17,1 L3,22",
                "o": 10
            },
            {
                "d": "M1,1 L9,11 9,22 M17,1 L9,11",
                "o": 9
            },
            {
                "d": "M17,1 L3,22 M3,1 L17,1 M3,22 L17,22",
                "o": 10
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M21,18 L19,18 17,17 15,15 12,11 11,10 9,9 7,9 5,10 4,12 4,14 5,16 7,17 9,17 11,16 12,15 15,11 17,9 19,8 21,8",
                "o": 13
            },
            {
                "d": "M22,14 L21,16 19,17 17,17 15,16 14,15 11,11 10,10 8,9 6,9 4,10 3,12 3,14 4,16 6,17 8,17 10,16 11,15 14,11 15,10 17,9 19,9 21,10 22,12 22,14",
                "o": 13
            },
            {
                "d": "M6,1 L4,2 3,4 3,6 4,8 6,9 8,9 10,8 11,6 11,4 10,2 8,1 6,1",
                "o": 7
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M3,8 L7,8 13,20 M6,8 L13,22 M22,-3 L13,22",
                "o": 9
            },
            {
                "d": "M3,8 L8,8 17,20 M7,9 L17,22 M33,-11 L17,22",
                "o": 16
            },
            {
                "d": "M20,5 L13,5 9,6 7,7 5,9 4,12 4,14 5,17 7,19 9,20 13,21 20,21",
                "o": 12
            },
            {
                "d": "M4,5 L4,12 5,16 6,18 8,20 11,21 13,21 16,20 18,18 19,16 20,12 20,5",
                "o": 12
            },
            {
                "d": "M4,5 L11,5 15,6 17,7 19,9 20,12 20,14 19,17 17,19 15,20 11,21 4,21",
                "o": 12
            },
            {
                "d": "M4,21 L4,14 5,10 6,8 8,6 11,5 13,5 16,6 18,8 19,10 20,14 20,21",
                "o": 12
            },
            {
                "d": "M20,5 L13,5 9,6 7,7 5,9 4,12 4,14 5,17 7,19 9,20 13,21 20,21 M4,13 L16,13",
                "o": 12
            },
            {
                "d": "M19,11 L22,13 19,15 M16,8 L21,13 16,18 M4,13 L21,13",
                "o": 13
            },
            {
                "d": "M6,7 L8,4 10,7 M3,10 L8,5 13,10 M8,5 L8,22",
                "o": 8
            },
            {
                "d": "M7,11 L4,13 7,15 M10,8 L5,13 10,18 M5,13 L22,13",
                "o": 13
            },
            {
                "d": "M6,19 L8,22 10,19 M3,16 L8,21 13,16 M8,4 L8,21",
                "o": 8
            },
            {
                "d": "M15,13 L14,10 13,9 11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,19 15,16 16,11 16,6 15,3 14,2 12,1 9,1 7,2 6,3 6,4 7,4 7,3 M9,8 L7,9 5,12 4,15 4,19 5,21 M9,22 L11,21 13,19 14,16 15,11 15,6 14,3 12,1",
                "o": 10
            },
            {
                "d": "M2,1 L10,22 M3,1 L10,20 M18,1 L10,22 M2,1 L18,1 M3,2 L17,2",
                "o": 10
            },
            {
                "d": "M3,8 L8,8 17,20 M7,9 L17,22 M33,-11 L17,22",
                "o": 16
            },
            {
                "d": "M21,-2 L20,-1 21,0 22,-1 22,-2 21,-3 19,-3 17,-2 15,0 14,2 13,5 12,9 10,21 9,25 8,27 M16,-1 L15,1 14,5 12,17 11,21 10,24 9,26 7,28 5,29 3,29 2,28 2,27 3,26 4,27 3,28",
                "o": 12
            },
            {
                "d": "M26,-23 L25,-23 24,-22 24,-21 25,-20 26,-20 27,-21 27,-23 26,-25 24,-26 22,-26 20,-25 18,-23 17,-21 16,-18 15,-11 14,5 14,37 13,46 12,49 M25,-22 L25,-21 26,-21 26,-22 25,-22 M15,-11 L15,37 M18,-23 L17,-20 16,-11 16,21 15,37 14,44 13,47 12,49 10,51 8,52 6,52 4,51 3,49 3,47 4,46 5,46 6,47 6,48 5,49 4,49 M4,47 L4,48 5,48 5,47 4,47",
                "o": 15
            },
            {
                "d": "M15,-26 L12,-20 9,-13 7,-8 6,-4 5,1 4,9 4,17 5,25 6,30 7,34 9,39 12,46 15,52 M12,-20 L10,-15 8,-9 7,-5 6,1 5,9 5,17 6,25 7,31 8,35 10,41 12,46",
                "o": 9
            },
            {
                "d": "M3,-26 L6,-20 9,-13 11,-8 12,-4 13,1 14,9 14,17 13,25 12,30 11,34 9,39 6,46 3,52 M6,-20 L8,-15 10,-9 11,-5 12,1 13,9 13,17 12,25 11,31 10,35 8,41 6,46",
                "o": 9
            },
            {
                "d": "M4,-26 L4,13 4,52 M5,-26 L5,13 5,52 M4,-26 L15,-26 M4,52 L15,52",
                "o": 9
            },
            {
                "d": "M13,-26 L13,13 13,52 M14,-26 L14,13 14,52 M3,-26 L14,-26 M3,52 L14,52",
                "o": 9
            },
            {
                "d": "M15,1 L15,22 M2,1 L15,1 M7,11 L15,11 M2,22 L15,22",
                "o": 10
            },
            {
                "d": "M3,4 L4,6 16,18 17,20 17,22 M4,7 L16,19 M3,4 L3,6 4,8 16,20 17,22 M8,11 L4,15 3,17 3,19 4,21 3,22 M3,17 L5,21 M4,15 L4,17 5,19 5,21 3,22 M11,14 L16,9 M14,4 L14,7 15,9 17,9 17,7 15,6 14,4 M14,4 L15,7 17,9",
                "o": 10
            },
            {
                "d": "M13,4 L12,5 13,6 14,5 13,4 M4,13 L22,13 M13,20 L12,21 13,22 14,21 13,20",
                "o": 13
            },
            {
                "d": "M4,-3 L4,29 M10,-3 L10,29",
                "o": 7
            },
            {
                "d": "M12,-3 L12,22 M3,22 L21,22",
                "o": 12
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M21,-3 L3,22 21,22",
                "o": 12
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M13,4 L12,5 13,6 14,5 13,4 M4,20 L3,21 4,22 5,21 4,20 M22,20 L21,21 22,22 23,21 22,20",
                "o": 13
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            }
        ]
    },
    "meteorology": {
        "name": "Meteorology",
        "chars": [
            {
                "d": "M3,13 L2,14 1,14 0,13 0,12 1,11 2,11 3,12 3,14 2,16 1,17 M1,12 L1,13 2,13 2,12 1,12",
                "o": 1
            },
            {
                "d": "M1,11 L0,12 0,14 1,15 3,15 4,14 4,12 3,11 1,11 M2,12 L1,13 2,14 3,13 2,12",
                "o": 2
            },
            {
                "d": "M2,10 L6,16 M6,10 L2,16 M0,13 L8,13",
                "o": 4
            },
            {
                "d": "M5,6 L4,8 2,11 0,13 M5,6 L6,8 8,11 10,13 M5,8 L2,12 M5,8 L8,12 M5,10 L3,12 M5,10 L7,12 M4,12 L6,12 M0,13 L10,13",
                "o": 5
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M0,13 L0,12 1,10 2,9 4,8 6,8 8,9 9,10 10,12 10,13 M3,9 L7,9 M2,10 L8,10 M1,11 L9,11 M1,12 L9,12 M0,13 L10,13",
                "o": 5
            },
            {
                "d": "M0,1 L0,13 6,13 0,1 M0,4 L4,12 M0,7 L3,13 M0,10 L1,12",
                "o": 0
            },
            {
                "d": "M5,6 L4,8 2,11 0,13 M5,6 L6,8 8,11 10,13",
                "o": 5
            },
            {
                "d": "M10,13 L10,12 9,10 8,9 6,8 4,8 2,9 1,10 0,12 0,13",
                "o": 5
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M22,13 L22,11 21,8 19,5 16,3 13,2 9,2 6,3 3,5 1,8 0,11 0,13",
                "o": 11
            },
            {
                "d": "M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M20,-3 L2,29",
                "o": 11
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22",
                "o": 10
            },
            {
                "d": "M4,6 L4,5 5,3 6,2 8,1 12,1 14,2 15,3 16,5 16,7 15,9 13,12 3,22 17,22",
                "o": 10
            },
            {
                "d": "M5,1 L16,1 10,9 13,9 15,10 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M13,1 L3,15 18,15 M13,1 L13,22",
                "o": 10
            },
            {
                "d": "M15,1 L5,1 4,10 5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18",
                "o": 10
            },
            {
                "d": "M16,4 L15,2 12,1 10,1 7,2 5,5 4,10 4,15 5,19 7,21 10,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 4,15",
                "o": 10
            },
            {
                "d": "M17,1 L7,22 M3,1 L17,1",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,6 5,8 7,9 11,10 14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,15 4,13 6,11 9,10 13,9 15,8 16,6 16,4 15,2 12,1 8,1",
                "o": 10
            },
            {
                "d": "M16,8 L15,11 13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 10,1 13,2 15,4 16,8 16,13 15,18 13,21 10,22 8,22 5,21 4,19",
                "o": 10
            },
            {
                "d": "M0,13 L0,14 1,16 2,17 4,18 6,18 8,17 9,16 10,14 10,13",
                "o": 5
            },
            {
                "d": "M0,11 L2,13 5,14 7,14 10,13 12,11",
                "o": 6
            },
            {
                "d": "M0,16 L2,15 3,13 2,11 0,10",
                "o": 3
            },
            {
                "d": "M0,13 L3,11 4,9 4,7 3,6 2,6",
                "o": 4
            },
            {
                "d": "M4,13 L1,11 0,9 0,7 1,6 2,6",
                "o": 0
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 7,1 10,1 13,2 14,3 15,5 15,7 14,9 13,10 9,12 9,15 M10,1 L12,2 13,3 14,5 14,7 13,9 11,11 M9,20 L8,21 9,22 10,21 9,20",
                "o": 9
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M9,1 L1,22 M9,1 L17,22 M4,15 L14,15",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,7 17,9 16,10 13,11 M4,11 L13,11 16,12 17,13 18,15 18,18 17,20 16,21 13,22 4,22",
                "o": 10
            },
            {
                "d": "M18,6 L17,4 15,2 13,1 9,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L11,1 14,2 16,4 17,6 18,9 18,14 17,17 16,19 14,21 11,22 4,22",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,1 L17,1 M4,11 L12,11 M4,22 L17,22",
                "o": 9
            },
            {
                "d": "M4,1 L4,22 M4,1 L17,1 M4,11 L12,11",
                "o": 8
            },
            {
                "d": "M18,6 L17,4 15,2 13,1 9,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 18,14 M13,14 L18,14",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M18,1 L18,22 M4,11 L18,11",
                "o": 11
            },
            {
                "d": "M4,1 L4,22",
                "o": 4
            },
            {
                "d": "M12,1 L12,17 11,20 10,21 8,22 6,22 4,21 3,20 2,17 2,15",
                "o": 8
            },
            {
                "d": "M4,1 L4,22 M18,1 L4,15 M9,10 L18,22",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,22 L16,22",
                "o": 7
            },
            {
                "d": "M4,1 L4,22 M4,1 L12,22 M20,1 L12,22 M20,1 L20,22",
                "o": 12
            },
            {
                "d": "M4,1 L4,22 M4,1 L18,22 M18,1 L18,22",
                "o": 11
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 19,14 19,9 18,6 17,4 15,2 13,1 9,1",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,8 17,10 16,11 13,12 4,12",
                "o": 10
            },
            {
                "d": "M9,1 L7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 9,22 13,22 15,21 17,19 18,17 19,14 19,9 18,6 17,4 15,2 13,1 9,1 M12,18 L18,24",
                "o": 11
            },
            {
                "d": "M4,1 L4,22 M4,1 L13,1 16,2 17,3 18,5 18,7 17,9 16,10 13,11 4,11 M11,11 L18,22",
                "o": 10
            },
            {
                "d": "M17,4 L15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 16,14 17,16 17,19 15,21 12,22 8,22 5,21 3,19",
                "o": 10
            },
            {
                "d": "M8,1 L8,22 M1,1 L15,1",
                "o": 8
            },
            {
                "d": "M4,1 L4,16 5,19 7,21 10,22 12,22 15,21 17,19 18,16 18,1",
                "o": 11
            },
            {
                "d": "M1,1 L9,22 M17,1 L9,22",
                "o": 9
            },
            {
                "d": "M2,1 L7,22 M12,1 L7,22 M12,1 L17,22 M22,1 L17,22",
                "o": 12
            },
            {
                "d": "M3,1 L17,22 M17,1 L3,22",
                "o": 10
            },
            {
                "d": "M1,1 L9,11 9,22 M17,1 L9,11",
                "o": 9
            },
            {
                "d": "M17,1 L3,22 M3,1 L17,1 M3,22 L17,22",
                "o": 10
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M17,4 L15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 16,14 17,16 17,19 15,21 12,22 8,22 5,21 3,19",
                "o": 10
            },
            {
                "d": "M11,13 L13,16 14,17 16,18 18,18 20,17 21,16 22,14 22,12 21,10 20,9 18,8 16,8 14,9 13,10 9,16 8,17 6,18 4,18 2,17 1,16 0,14 0,12 1,10 2,9 4,8 6,8 8,9 9,10 11,13",
                "o": 11
            },
            {
                "d": "M2,18 L1,17 0,15 0,12 1,10 2,9 4,8 6,8 8,9 9,10 13,16 14,17 16,18 18,18 20,17 21,16 22,14 22,11 21,9 20,8",
                "o": 11
            },
            {
                "d": "M15,8 L15,22 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,11 L6,9 8,8 11,8 13,9 15,11 16,14 16,16 15,19 13,21 11,22 8,22 6,21 4,19",
                "o": 9
            },
            {
                "d": "M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 9
            },
            {
                "d": "M15,1 L15,22 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M3,14 L15,14 15,12 14,10 13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 9
            },
            {
                "d": "M10,1 L8,1 6,2 5,5 5,22 M2,8 L9,8",
                "o": 7
            },
            {
                "d": "M15,8 L15,24 14,27 13,28 11,29 8,29 6,28 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M4,1 L4,22 M4,12 L7,9 9,8 12,8 14,9 15,12 15,22",
                "o": 10
            },
            {
                "d": "M3,1 L4,2 5,1 4,0 3,1 M4,8 L4,22",
                "o": 4
            },
            {
                "d": "M5,1 L6,2 7,1 6,0 5,1 M6,8 L6,25 5,28 3,29 1,29",
                "o": 5
            },
            {
                "d": "M4,1 L4,22 M14,8 L4,18 M8,14 L15,22",
                "o": 8
            },
            {
                "d": "M4,1 L4,22",
                "o": 4
            },
            {
                "d": "M4,8 L4,22 M4,12 L7,9 9,8 12,8 14,9 15,12 15,22 M15,12 L18,9 20,8 23,8 25,9 26,12 26,22",
                "o": 15
            },
            {
                "d": "M4,8 L4,22 M4,12 L7,9 9,8 12,8 14,9 15,12 15,22",
                "o": 10
            },
            {
                "d": "M8,8 L6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19 16,16 16,14 15,11 13,9 11,8 8,8",
                "o": 10
            },
            {
                "d": "M4,8 L4,29 M4,11 L6,9 8,8 11,8 13,9 15,11 16,14 16,16 15,19 13,21 11,22 8,22 6,21 4,19",
                "o": 9
            },
            {
                "d": "M15,8 L15,29 M15,11 L13,9 11,8 8,8 6,9 4,11 3,14 3,16 4,19 6,21 8,22 11,22 13,21 15,19",
                "o": 10
            },
            {
                "d": "M4,8 L4,22 M4,14 L5,11 7,9 9,8 12,8",
                "o": 6
            },
            {
                "d": "M14,11 L13,9 10,8 7,8 4,9 3,11 4,13 6,14 11,15 13,16 14,18 14,19 13,21 10,22 7,22 4,21 3,19",
                "o": 9
            },
            {
                "d": "M5,1 L5,18 6,21 8,22 10,22 M2,8 L9,8",
                "o": 7
            },
            {
                "d": "M4,8 L4,18 5,21 7,22 10,22 12,21 15,18 M15,8 L15,22",
                "o": 10
            },
            {
                "d": "M2,8 L8,22 M14,8 L8,22",
                "o": 8
            },
            {
                "d": "M3,8 L7,22 M11,8 L7,22 M11,8 L15,22 M19,8 L15,22",
                "o": 11
            },
            {
                "d": "M3,8 L14,22 M14,8 L3,22",
                "o": 9
            },
            {
                "d": "M2,8 L8,22 M14,8 L8,22 6,26 4,28 2,29 1,29",
                "o": 8
            },
            {
                "d": "M14,8 L3,22 M3,8 L14,8 M3,22 L14,22",
                "o": 9
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M3,1 L3,22 M0,1 L19,1 9,11 19,21 M18,17 L19,20 20,22 M18,17 L18,20 M15,20 L18,20 M15,20 L18,21 20,22",
                "o": 10
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M10,-4 L7,-3 5,-2 3,0 1,3 0,7 0,13 1,16 3,18 6,19 8,19 11,18 13,16 14,13 M0,11 L1,8 3,6 6,5 8,5 11,6 13,8 14,11 14,17 13,21 11,24 9,26 7,27 4,28",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            }
        ]
    },
    "music": {
        "name": "Music",
        "chars": [
            {
                "d": "M5,1 L4,3 5,15 6,3 5,1 M5,3 L5,9 M5,20 L4,21 5,22 6,21 5,20",
                "o": 5
            },
            {
                "d": "M3,11 L7,13 10,15 12,17 13,20 13,22 12,24 11,25 M3,12 L9,15 M3,13 L6,14 10,16 12,18 13,20",
                "o": 8
            },
            {
                "d": "M13,6 L12,8 10,10 6,12 3,13 M9,11 L3,14 M11,1 L12,2 13,4 13,6 12,9 10,11 7,13 3,15",
                "o": 8
            },
            {
                "d": "M11,8 L7,9 4,11 3,13 3,15 4,17 6,18 9,18 13,17 16,15 17,13 17,11 16,9 14,8 11,8 M16,9 L11,8 M14,8 L9,9 4,11 M7,9 L3,13 M4,17 L9,18 M6,18 L11,17 16,15 M13,17 L17,13",
                "o": 10
            },
            {
                "d": "M11,8 L7,9 4,11 3,13 3,15 4,17 6,18 9,18 13,17 16,15 17,13 17,11 16,9 14,8 11,8 M16,9 L11,8 M14,8 L9,9 4,11 M7,9 L3,13 M4,17 L9,18 M6,18 L11,17 16,15 M13,17 L17,13",
                "o": 10
            },
            {
                "d": "M9,8 L6,9 4,11 3,13 3,15 4,17 6,18 8,18 11,17 13,15 14,13 14,11 13,9 11,8 9,8 M5,11 L11,8 M4,13 L12,9 M3,15 L13,10 M4,16 L14,11 M5,17 L13,13 M6,18 L12,15",
                "o": 9
            },
            {
                "d": "M5,2 L5,25 M11,1 L11,24 M3,9 L13,7 M3,10 L13,8 M3,18 L13,16 M3,19 L13,17",
                "o": 8
            },
            {
                "d": "M4,1 L4,19 M12,7 L12,25 M4,9 L12,7 M4,10 L12,8 M4,18 L12,16 M4,19 L12,17",
                "o": 8
            },
            {
                "d": "M4,-3 L4,18 M4,9 L7,7 10,7 12,8 13,10 13,12 12,14 9,16 7,17 4,18 M4,9 L7,8 10,8 12,9 M11,8 L12,10 12,12 11,14 9,16",
                "o": 8
            },
            {
                "d": "M3,4 L3,7 M23,4 L23,7 M3,4 L23,4 M3,5 L23,5 M3,6 L23,6 M3,7 L23,7",
                "o": 13
            },
            {
                "d": "M3,9 L3,12 M13,9 L13,12 M3,9 L13,9 M3,10 L13,10 M3,11 L13,11 M3,12 L13,12",
                "o": 8
            },
            {
                "d": "M3,7 L13,19 M3,7 L5,9 7,10 10,10 12,9 13,8 13,6 11,6 11,8 10,10 M5,9 L10,10 M7,10 L13,8 M12,6 L12,9 M11,7 L13,7 M13,19 L11,17 9,16 6,16 4,17 3,18 3,20 5,20 5,18 6,16 M11,17 L6,16 M9,16 L3,18 M4,17 L4,20 M3,19 L5,19",
                "o": 8
            },
            {
                "d": "M6,10 L5,8 5,6 3,6 3,8 4,9 6,10 9,10 11,9 13,7 M4,6 L4,9 M3,7 L5,7 M3,8 L9,10 M6,10 L11,9 M13,7 L13,20",
                "o": 8
            },
            {
                "d": "M7,-2 L12,8 8,15 8,16 M11,7 L7,14 M10,4 L10,6 6,13 8,16 11,20 M13,23 L11,20 9,19 7,19 5,20 4,22 4,24 5,26 8,28 M13,23 L11,21 9,20 5,20 5,24 6,26 8,28 M9,19 L6,21 4,24",
                "o": 8
            },
            {
                "d": "M9,14 L10,16 12,17 14,17 16,16 17,14 17,12 16,10 14,9 12,9 10,10 9,11 8,14 8,17 9,20 11,22 14,23 17,23 20,22 22,20 23,18 24,15 24,11 23,8 21,5 19,4 16,3 13,3 10,4 8,5 6,7 4,10 3,14 3,19 4,24 6,28 8,30 11,32 15,33 20,33 24,32 27,30 29,28 M6,7 L5,9 4,13 4,19 5,23 7,27 9,29 12,31 16,32 20,32 24,31 26,30 29,28 M11,10 L15,10 M10,11 L16,11 M9,12 L17,12 M9,13 L17,13 M9,14 L17,14 M10,15 L16,15 M11,16 L15,16 M28,7 L28,9 30,9 30,7 28,7 M29,7 L29,9 M28,8 L30,8 M28,17 L28,19 30,19 30,17 28,17 M29,17 L29,19 M28,18 L30,18",
                "o": 20
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1 M9,1 L7,2 6,3 5,5 4,10 4,13 5,18 6,20 7,21 9,22 M11,22 L13,21 14,20 15,18 16,13 16,10 15,5 14,3 13,2 11,1",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22 M10,2 L10,22 M6,22 L15,22",
                "o": 10
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 8,1 12,1 15,2 16,3 17,5 17,7 16,9 13,11 8,13 6,14 4,16 3,19 3,22 M12,1 L14,2 15,3 16,5 16,7 15,9 12,11 8,13 M3,20 L4,19 6,19 11,21 14,21 16,20 17,19 M6,19 L11,22 15,22 16,21 17,19 17,17",
                "o": 10
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 8,1 12,1 15,2 16,4 16,7 15,9 12,10 9,10 M12,1 L14,2 15,4 15,7 14,9 12,10 M12,10 L14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M15,12 L16,15 16,18 15,20 14,21 12,22",
                "o": 10
            },
            {
                "d": "M12,3 L12,22 M13,1 L13,22 M13,1 L2,16 18,16 M9,22 L16,22",
                "o": 10
            },
            {
                "d": "M5,1 L3,11 M3,11 L5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M11,8 L13,9 15,11 16,14 16,16 15,19 13,21 11,22 M5,1 L15,1 M5,2 L10,2 15,1",
                "o": 10
            },
            {
                "d": "M15,4 L14,5 15,6 16,5 16,4 15,2 13,1 10,1 7,2 5,4 4,6 3,10 3,16 4,19 6,21 9,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 4,15 M10,1 L8,2 6,4 5,6 4,10 4,16 5,19 7,21 9,22 M11,22 L13,21 15,19 16,16 16,15 15,12 13,10 11,9",
                "o": 10
            },
            {
                "d": "M3,1 L3,7 M3,5 L4,3 6,1 8,1 13,4 15,4 16,3 17,1 M4,3 L6,2 8,2 13,4 M17,1 L17,4 16,7 12,12 11,14 10,17 10,22 M16,7 L11,12 10,14 9,17 9,22",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,7 5,9 8,10 12,10 15,9 16,7 16,4 15,2 12,1 8,1 M8,1 L6,2 5,4 5,7 6,9 8,10 M12,10 L14,9 15,7 15,4 14,2 12,1 M8,10 L5,11 4,12 3,14 3,18 4,20 5,21 8,22 12,22 15,21 16,20 17,18 17,14 16,12 15,11 12,10 M8,10 L6,11 5,12 4,14 4,18 5,20 6,21 8,22 M12,22 L14,21 15,20 16,18 16,14 15,12 14,11 12,10",
                "o": 10
            },
            {
                "d": "M16,8 L15,11 13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 11,1 14,2 16,4 17,7 17,13 16,17 15,19 13,21 10,22 7,22 5,21 4,19 4,18 5,17 6,18 5,19 M9,14 L7,13 5,11 4,8 4,7 5,4 7,2 9,1 M11,1 L13,2 15,4 16,7 16,13 15,17 14,19 12,21 10,22",
                "o": 10
            },
            {
                "d": "M5,8 L4,9 5,10 6,9 5,8 M5,20 L4,21 5,22 6,21 5,20",
                "o": 5
            },
            {
                "d": "M6,33 L7,33 8,32 8,31 7,30 6,30 5,31 5,33 6,35 8,36 10,36 13,35 15,33 16,31 17,27 17,16 16,-10 16,-17 17,-22 18,-24 20,-25 21,-25 23,-24 24,-22 24,-18 23,-15 22,-13 20,-10 15,-6 9,-2 7,0 5,3 4,5 3,9 3,13 4,17 6,20 9,22 13,23 17,23 21,22 23,21 25,18 26,15 26,11 25,8 24,6 22,4 19,3 15,3 12,4 10,6 9,9 9,13 10,16 12,18 M6,31 L6,32 7,32 7,31 6,31 M20,-10 L16,-6 11,-2 8,1 6,4 5,6 4,9 4,13 5,17 6,19 9,22 M17,23 L20,22 22,21 24,18 25,15 25,11 24,8 23,6 21,4 19,3",
                "o": 12
            },
            {
                "d": "M9,14 L10,16 12,17 14,17 16,16 17,14 17,12 16,10 14,9 12,9 10,10 9,11 8,14 8,17 9,20 11,22 14,23 17,23 20,22 22,20 23,18 24,15 24,11 23,8 21,5 19,4 16,3 13,3 10,4 8,5 6,7 4,10 3,14 3,19 4,24 6,28 8,30 11,32 15,33 20,33 24,32 27,30 29,28 M6,7 L5,9 4,13 4,19 5,23 7,27 9,29 12,31 16,32 20,32 24,31 26,30 29,28 M11,10 L15,10 M10,11 L16,11 M9,12 L17,12 M9,13 L17,13 M9,14 L17,14 M10,15 L16,15 M11,16 L15,16 M28,7 L28,9 30,9 30,7 28,7 M29,7 L29,9 M28,8 L30,8 M28,17 L28,19 30,19 30,17 28,17 M29,17 L29,19 M28,18 L30,18",
                "o": 20
            },
            {
                "d": "M5,12 L6,10 8,9 10,9 12,10 13,12 13,14 12,16 10,17 8,17 6,16 5,15 4,12 4,9 5,6 7,4 10,3 14,3 18,4 21,6 23,9 24,13 24,18 23,22 22,24 20,27 17,30 13,33 8,36 4,38 M14,3 L17,4 20,6 22,9 23,13 23,18 22,22 21,24 19,27 16,30 11,34 8,36 M7,10 L11,10 M6,11 L12,11 M5,12 L13,12 M5,13 L13,13 M5,14 L13,14 M6,15 L12,15 M7,16 L11,16 M28,7 L28,9 30,9 30,7 28,7 M29,7 L29,9 M28,8 L30,8 M28,17 L28,19 30,19 30,17 28,17 M29,17 L29,19 M28,18 L30,18",
                "o": 24
            },
            {
                "d": "M4,-5 L4,31 M9,-5 L9,31 M19,-5 L19,31 M24,-5 L24,31 M9,8 L19,6 M9,9 L19,7 M9,10 L19,8 M9,18 L19,16 M9,19 L19,17 M9,20 L19,18",
                "o": 14
            },
            {
                "d": "M4,-7 L4,33 M5,-7 L5,33 M9,-7 L9,33 M13,-3 L15,-3 15,-1 13,-1 13,-4 14,-6 16,-7 19,-7 21,-6 23,-4 24,-1 24,4 23,7 21,9 19,10 17,10 15,9 14,7 13,9 11,12 10,13 11,14 13,17 14,19 15,17 17,16 19,16 21,17 23,19 24,22 24,27 23,30 21,32 19,33 16,33 14,32 13,30 13,27 15,27 15,29 13,29 M14,-3 L14,-1 M13,-2 L15,-2 M21,-6 L22,-4 23,-1 23,4 22,7 21,9 M14,7 L14,9 12,12 10,13 12,14 14,17 14,19 M21,17 L22,19 23,22 23,27 22,30 21,32 M14,27 L14,29 M13,28 L15,28",
                "o": 14
            },
            {
                "d": "M3,9 L3,12 M13,9 L13,12 M3,9 L13,9 M3,10 L13,10 M3,11 L13,11 M3,12 L13,12",
                "o": 8
            },
            {
                "d": "M13,1 L0,22 M13,1 L14,22 M12,3 L13,22 M4,16 L13,16 M-2,22 L4,22 M10,22 L16,22",
                "o": 10
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L17,1 20,2 21,4 21,6 20,9 19,10 16,11 M17,1 L19,2 20,4 20,6 19,9 18,10 16,11 M7,11 L16,11 18,12 19,14 19,16 18,19 16,21 12,22 0,22 M16,11 L17,12 18,14 18,16 17,19 15,21 12,22",
                "o": 12
            },
            {
                "d": "M18,3 L19,3 20,1 19,7 19,5 18,3 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 8,22 11,22 13,21 15,19 16,17 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 6,21 8,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L15,1 18,2 19,3 20,6 20,10 19,14 17,18 15,20 13,21 9,22 0,22 M15,1 L17,2 18,3 19,6 19,10 18,14 16,18 14,20 12,21 9,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M14,7 L12,15 M6,1 L21,1 20,7 20,1 M7,11 L13,11 M0,22 L15,22 17,17 14,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M14,7 L12,15 M6,1 L21,1 20,7 20,1 M7,11 L13,11 M0,22 L7,22",
                "o": 10
            },
            {
                "d": "M18,3 L19,3 20,1 19,7 19,5 18,3 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 8,22 10,22 13,21 15,19 17,15 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 6,21 8,22 M10,22 L12,21 14,19 16,15 M13,15 L20,15",
                "o": 12
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M22,1 L16,22 M23,1 L17,22 M6,1 L13,1 M19,1 L26,1 M7,11 L19,11 M0,22 L7,22 M13,22 L20,22",
                "o": 13
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L13,1 M0,22 L7,22",
                "o": 7
            },
            {
                "d": "M15,1 L10,18 9,20 8,21 6,22 4,22 2,21 1,19 1,17 2,16 3,17 2,18 M14,1 L9,18 8,20 6,22 M11,1 L18,1",
                "o": 9
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M23,1 L6,14 M13,10 L17,22 M12,10 L16,22 M6,1 L13,1 M19,1 L25,1 M0,22 L7,22 M13,22 L19,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L13,1 M0,22 L15,22 17,16 14,22",
                "o": 10
            },
            {
                "d": "M9,1 L3,22 M9,1 L10,22 M10,1 L11,20 M23,1 L10,22 M23,1 L17,22 M24,1 L18,22 M6,1 L10,1 M23,1 L27,1 M0,22 L6,22 M14,22 L21,22",
                "o": 14
            },
            {
                "d": "M9,1 L3,22 M9,1 L16,19 M9,4 L16,22 M22,1 L16,22 M6,1 L9,1 M19,1 L25,1 M0,22 L6,22",
                "o": 13
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 10,22 13,21 15,19 17,16 18,13 19,9 19,6 18,3 17,2 15,1 12,1 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 7,22 M10,22 L12,21 14,19 16,16 17,13 18,9 18,6 17,3 15,1",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L18,1 21,2 22,4 22,6 21,9 19,11 15,12 7,12 M18,1 L20,2 21,4 21,6 20,9 18,11 15,12 M0,22 L7,22",
                "o": 11
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 10,22 13,21 15,19 17,16 18,13 19,9 19,6 18,3 17,2 15,1 12,1 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 7,22 M10,22 L12,21 14,19 16,16 17,13 18,9 18,6 17,3 15,1 M5,20 L5,19 6,17 8,16 9,16 11,17 12,19 12,26 13,27 15,27 16,25 16,24 M12,19 L13,25 14,26 15,26 16,25",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L17,1 20,2 21,4 21,6 20,9 19,10 16,11 7,11 M17,1 L19,2 20,4 20,6 19,9 18,10 16,11 M12,11 L14,12 15,13 16,21 17,22 19,22 20,20 20,19 M15,13 L17,20 18,21 19,21 20,20 M0,22 L7,22",
                "o": 12
            },
            {
                "d": "M19,3 L20,3 21,1 20,7 20,5 19,3 18,2 15,1 11,1 8,2 6,4 6,6 7,8 8,9 15,13 17,15 M6,6 L8,8 15,12 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,16 2,22 3,20 4,20",
                "o": 12
            },
            {
                "d": "M13,1 L7,22 M14,1 L8,22 M7,1 L4,7 6,1 21,1 20,7 20,1 M4,22 L11,22",
                "o": 11
            },
            {
                "d": "M8,1 L5,12 4,16 4,19 5,21 8,22 12,22 15,21 17,19 18,16 22,1 M9,1 L6,12 5,16 5,19 6,21 8,22 M5,1 L12,1 M19,1 L25,1",
                "o": 13
            },
            {
                "d": "M6,1 L7,22 M7,1 L8,20 M20,1 L7,22 M4,1 L10,1 M16,1 L22,1",
                "o": 10
            },
            {
                "d": "M8,1 L6,22 M9,1 L7,20 M16,1 L6,22 M16,1 L14,22 M17,1 L15,20 M24,1 L14,22 M5,1 L12,1 M21,1 L27,1",
                "o": 13
            },
            {
                "d": "M7,1 L14,22 M8,1 L15,22 M21,1 L1,22 M5,1 L11,1 M17,1 L23,1 M-1,22 L5,22 M11,22 L17,22",
                "o": 11
            },
            {
                "d": "M6,1 L10,11 7,22 M7,1 L11,11 8,22 M21,1 L11,11 M4,1 L10,1 M17,1 L23,1 M4,22 L11,22",
                "o": 11
            },
            {
                "d": "M20,1 L1,22 M21,1 L2,22 M8,1 L5,7 7,1 21,1 M1,22 L15,22 17,16 14,22",
                "o": 11
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M8,1 L3,7 M8,1 L9,2 3,7",
                "o": 6
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M4,1 L9,7 M4,1 L3,2 9,7",
                "o": 6
            },
            {
                "d": "M16,8 L14,15 13,19 13,21 14,22 17,22 19,20 20,18 M17,8 L15,15 14,19 14,21 15,22 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 11,21 13,18 14,15 M9,8 L7,9 5,12 4,15 4,19 5,21",
                "o": 11
            },
            {
                "d": "M8,1 L4,14 4,17 5,20 6,21 M9,1 L5,14 M5,14 L6,11 8,9 10,8 12,8 14,9 15,10 16,12 16,15 15,18 13,21 10,22 8,22 6,21 5,18 5,14 M14,9 L15,11 15,15 14,18 12,21 10,22 M5,1 L9,1",
                "o": 9
            },
            {
                "d": "M14,11 L14,12 15,12 15,11 14,9 12,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,18 M9,8 L7,9 5,12 4,15 4,19 5,21",
                "o": 9
            },
            {
                "d": "M18,1 L14,15 13,19 13,21 14,22 17,22 19,20 20,18 M19,1 L15,15 14,19 14,21 15,22 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 11,21 13,18 14,15 M9,8 L7,9 5,12 4,15 4,19 5,21 M15,1 L19,1",
                "o": 11
            },
            {
                "d": "M4,17 L8,16 11,15 14,13 15,11 14,9 12,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,19 M9,8 L7,9 5,12 4,15 4,19 5,21",
                "o": 9
            },
            {
                "d": "M15,2 L14,3 15,4 16,3 16,2 15,1 13,1 11,2 10,3 9,5 8,8 5,22 4,26 3,28 M13,1 L11,3 10,5 9,9 7,18 6,22 5,25 4,27 3,28 1,29 -1,29 -2,28 -2,27 -1,26 0,27 -1,28 M4,8 L14,8",
                "o": 8
            },
            {
                "d": "M17,8 L13,22 12,25 10,28 7,29 4,29 2,28 1,27 1,26 2,25 3,26 2,27 M16,8 L12,22 11,25 9,28 7,29 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 11,21 13,18 14,15 M9,8 L7,9 5,12 4,15 4,19 5,21",
                "o": 10
            },
            {
                "d": "M8,1 L2,22 M9,1 L3,22 M5,15 L7,11 9,9 11,8 13,8 15,9 16,10 16,12 14,18 14,21 15,22 M13,8 L15,10 15,12 13,18 13,21 14,22 17,22 19,20 20,18 M5,1 L9,1",
                "o": 11
            },
            {
                "d": "M9,1 L8,2 9,3 10,2 9,1 M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,21 7,22 M6,8 L7,9 7,12 5,18 5,21 6,22 9,22 11,20 12,18",
                "o": 7
            },
            {
                "d": "M10,1 L9,2 10,3 11,2 10,1 M2,12 L3,10 5,8 8,8 9,9 9,12 6,22 5,25 4,27 3,28 1,29 -1,29 -2,28 -2,27 -1,26 0,27 -1,28 M7,8 L8,9 8,12 5,22 4,25 3,27 1,29",
                "o": 7
            },
            {
                "d": "M8,1 L2,22 M9,1 L3,22 M16,9 L15,10 16,11 17,10 17,9 16,8 15,8 13,9 9,13 7,14 5,14 M7,14 L9,15 11,21 12,22 M7,14 L8,15 10,21 11,22 13,22 15,21 17,18 M5,1 L9,1",
                "o": 10
            },
            {
                "d": "M8,1 L4,15 3,19 3,21 4,22 7,22 9,20 10,18 M9,1 L5,15 4,19 4,21 5,22 M5,1 L9,1",
                "o": 7
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 7,15 5,22 M6,8 L7,9 7,11 6,15 4,22 M7,15 L9,11 11,9 13,8 15,8 17,9 18,10 18,12 15,22 M15,8 L17,10 17,12 14,22 M17,15 L19,11 21,9 23,8 25,8 27,9 28,10 28,12 26,18 26,21 27,22 M25,8 L27,10 27,12 25,18 25,21 26,22 29,22 31,20 32,18",
                "o": 16
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 7,15 5,22 M6,8 L7,9 7,11 6,15 4,22 M7,15 L9,11 11,9 13,8 15,8 17,9 18,10 18,12 16,18 16,21 17,22 M15,8 L17,10 17,12 15,18 15,21 16,22 19,22 21,20 22,18",
                "o": 11
            },
            {
                "d": "M9,8 L6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,18 15,15 15,12 14,10 13,9 11,8 9,8 M9,8 L7,9 5,12 4,15 4,19 5,21 M9,22 L11,21 13,18 14,15 14,11 13,9",
                "o": 9
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 7,15 3,29 M6,8 L7,9 7,11 6,15 2,29 M7,15 L8,12 10,9 12,8 14,8 16,9 17,10 18,12 18,15 17,18 15,21 12,22 10,22 8,21 7,18 7,15 M16,9 L17,11 17,15 16,18 14,21 12,22 M-1,29 L6,29",
                "o": 10
            },
            {
                "d": "M16,8 L10,29 M17,8 L11,29 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 11,21 13,18 14,15 M9,8 L7,9 5,12 4,15 4,19 5,21 M7,29 L14,29",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 7,15 5,22 M6,8 L7,9 7,11 6,15 4,22 M7,15 L9,11 11,9 13,8 15,8 16,9 16,10 15,11 14,10 15,9",
                "o": 8
            },
            {
                "d": "M14,10 L14,11 15,11 15,10 14,9 11,8 8,8 5,9 4,10 4,12 5,13 12,17 13,18 M4,11 L5,12 12,16 13,17 13,20 12,21 9,22 6,22 3,21 2,20 2,19 3,19 3,20",
                "o": 9
            },
            {
                "d": "M9,1 L5,15 4,19 4,21 5,22 8,22 10,20 11,18 M10,1 L6,15 5,19 5,21 6,22 M3,8 L12,8",
                "o": 7
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,20 8,22 M6,8 L7,9 7,12 5,18 5,20 6,21 8,22 10,22 12,21 14,19 16,15 M18,8 L16,15 15,19 15,21 16,22 19,22 21,20 22,18 M19,8 L17,15 16,19 16,21 17,22",
                "o": 11
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,20 8,22 M6,8 L7,9 7,12 5,18 5,20 6,21 8,22 9,22 12,21 14,19 16,16 17,12 17,8 16,8 17,10",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,20 8,22 M6,8 L7,9 7,12 5,18 5,20 6,21 8,22 10,22 12,21 14,19 15,17 M17,8 L15,17 15,20 16,21 18,22 20,22 22,21 24,19 25,17 26,13 26,8 25,8 26,10 M18,8 L16,17 16,20 18,22",
                "o": 14
            },
            {
                "d": "M3,12 L5,9 7,8 10,8 11,10 11,13 M9,8 L10,10 10,13 9,17 8,19 6,21 4,22 3,22 2,21 2,20 3,19 4,20 3,21 M9,17 L9,20 10,22 13,22 15,21 17,18 M17,9 L16,10 17,11 18,10 18,9 17,8 16,8 14,9 12,11 11,13 10,17 10,20 11,22",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,20 8,22 M6,8 L7,9 7,12 5,18 5,20 6,21 8,22 10,22 12,21 14,19 16,15 M19,8 L15,22 14,25 12,28 9,29 6,29 4,28 3,27 3,26 4,25 5,26 4,27 M18,8 L14,22 13,25 11,28 9,29",
                "o": 10
            },
            {
                "d": "M17,8 L16,10 14,12 6,18 4,20 3,22 M4,12 L5,10 7,8 10,8 14,10 M5,10 L7,9 10,9 14,10 16,10 M4,20 L6,20 10,21 13,21 15,20 M6,20 L10,22 13,22 15,20 16,18",
                "o": 10
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "scriptc": {
        "name": "Script medium",
        "chars": [
            {
                "d": "M8,1 L7,2 5,14 M8,2 L5,14 M8,1 L9,2 5,14 M3,20 L2,21 3,22 4,21 3,20",
                "o": 6
            },
            {
                "d": "M7,1 L5,8 M8,1 L5,8 M16,1 L14,8 M17,1 L14,8",
                "o": 9
            },
            {
                "d": "M11,1 L4,29 M17,1 L10,29 M4,12 L18,12 M3,18 L17,18",
                "o": 11
            },
            {
                "d": "M12,-3 L4,26 M17,-3 L9,26 M18,5 L17,6 18,7 19,6 19,5 18,3 17,2 14,1 10,1 7,2 5,4 5,6 6,8 7,9 14,13 16,15 M5,6 L7,8 14,12 15,13 16,15 16,18 15,20 14,21 11,22 7,22 4,21 3,20 2,18 2,17 3,16 4,17 3,18",
                "o": 11
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M23,9 L22,10 23,11 24,10 24,9 23,8 22,8 20,9 18,11 13,19 11,21 9,22 6,22 3,21 2,19 2,17 3,15 4,14 6,13 11,11 13,10 15,8 16,6 16,4 15,2 13,1 11,2 10,4 10,7 11,13 12,16 14,19 16,21 18,22 20,22 21,20 21,19 M6,22 L4,21 3,19 3,17 4,15 5,14 11,11 M10,7 L11,12 12,15 14,18 16,20 18,21 20,21 21,20",
                "o": 13
            },
            {
                "d": "M8,3 L7,2 8,1 9,2 9,3 8,5 6,7",
                "o": 6
            },
            {
                "d": "M15,-3 L11,0 8,3 6,6 4,10 3,15 3,19 4,24 5,27 6,29 M11,0 L8,4 6,8 5,11 4,16 4,21 5,26 6,29",
                "o": 8
            },
            {
                "d": "M9,-3 L10,-1 11,2 12,7 12,11 11,16 9,20 7,23 4,26 0,29 M9,-3 L10,0 11,5 11,10 10,15 9,18 7,22 4,26",
                "o": 7
            },
            {
                "d": "M10,1 L10,13 M5,4 L15,10 M15,4 L5,10",
                "o": 9
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M3,22 L2,21 3,20 4,21 4,22 3,24 1,26",
                "o": 6
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M3,20 L2,21 3,22 4,21 3,20",
                "o": 6
            },
            {
                "d": "M24,-3 L-2,29",
                "o": 11
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 9,22 12,21 14,19 16,16 17,13 18,9 18,6 17,3 16,2 14,1 12,1 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 7,22 M9,22 L11,21 13,19 15,16 16,13 17,9 17,6 16,3 14,1",
                "o": 11
            },
            {
                "d": "M12,5 L7,22 M14,1 L8,22 M14,1 L11,4 8,6 6,7 M13,4 L9,6 6,7",
                "o": 11
            },
            {
                "d": "M7,5 L8,6 7,7 6,6 6,5 7,3 8,2 11,1 14,1 17,2 18,4 18,6 17,8 15,10 12,12 8,14 5,16 3,18 1,22 M14,1 L16,2 17,4 17,6 16,8 14,10 8,14 M2,20 L3,19 5,19 10,21 13,21 15,20 16,18 M5,19 L10,22 13,22 15,21 16,18",
                "o": 11
            },
            {
                "d": "M7,5 L8,6 7,7 6,6 6,5 7,3 8,2 11,1 14,1 17,2 18,4 18,6 17,8 14,10 11,11 M14,1 L16,2 17,4 17,6 16,8 14,10 M9,11 L11,11 14,12 15,13 16,15 16,18 15,20 14,21 11,22 7,22 4,21 3,20 2,18 2,17 3,16 4,17 3,18 M11,11 L13,12 14,13 15,15 15,18 14,20 13,21 11,22",
                "o": 11
            },
            {
                "d": "M16,2 L10,22 M17,1 L11,22 M17,1 L2,16 18,16",
                "o": 11
            },
            {
                "d": "M9,1 L4,11 M9,1 L19,1 M9,2 L14,2 19,1 M4,11 L5,10 8,9 11,9 14,10 15,11 16,13 16,16 15,19 13,21 10,22 7,22 4,21 3,20 2,18 2,17 3,16 4,17 3,18 M11,9 L13,10 14,11 15,13 15,16 14,19 12,21 10,22",
                "o": 11
            },
            {
                "d": "M17,4 L16,5 17,6 18,5 18,4 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,18 4,20 5,21 7,22 10,22 13,21 15,19 16,17 16,14 15,12 14,11 12,10 9,10 7,11 5,13 4,15 M12,1 L10,2 8,4 6,7 5,10 4,14 4,19 5,21 M10,22 L12,21 14,19 15,17 15,13 14,11",
                "o": 11
            },
            {
                "d": "M6,1 L4,7 M19,1 L18,4 16,7 11,13 9,16 8,18 7,22 M16,7 L10,13 8,16 7,18 6,22 M5,4 L8,1 10,1 15,4 M6,3 L8,2 10,2 15,4 17,4 18,3 19,1",
                "o": 11
            },
            {
                "d": "M11,1 L8,2 7,3 6,5 6,8 7,10 9,11 12,11 16,10 17,9 18,7 18,4 17,2 14,1 11,1 M11,1 L9,2 8,3 7,5 7,8 8,10 9,11 M12,11 L15,10 16,9 17,7 17,4 16,2 14,1 M9,11 L5,12 3,14 2,16 2,19 3,21 6,22 10,22 14,21 15,20 16,18 16,15 15,13 14,12 12,11 M9,11 L6,12 4,14 3,16 3,19 4,21 6,22 M10,22 L13,21 14,20 15,18 15,14 14,12",
                "o": 11
            },
            {
                "d": "M17,8 L16,10 14,12 12,13 9,13 7,12 6,11 5,9 5,6 6,4 8,2 11,1 14,1 16,2 17,3 18,5 18,9 17,13 16,16 14,19 12,21 9,22 6,22 4,21 3,19 3,18 4,17 5,18 4,19 M7,12 L6,10 6,6 7,4 9,2 11,1 M16,2 L17,4 17,9 16,13 15,16 13,19 11,21 9,22",
                "o": 11
            },
            {
                "d": "M6,8 L5,9 6,10 7,9 6,8 M3,20 L2,21 3,22 4,21",
                "o": 6
            },
            {
                "d": "M6,8 L5,9 6,10 7,9 6,8 M3,22 L2,21 3,20 4,21 4,22 3,24 1,26",
                "o": 6
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M7,5 L8,6 7,7 6,6 6,5 7,3 8,2 11,1 15,1 18,2 19,4 19,6 18,8 17,9 11,11 9,12 9,14 10,15 12,15 M15,1 L17,2 18,4 18,6 17,8 16,9 14,10 M8,20 L7,21 8,22 9,21 8,20",
                "o": 11
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M19,1 L17,3 15,6 12,11 10,14 7,18 4,21 2,22 0,22 -1,21 -1,19 0,18 1,19 0,20 M19,1 L18,5 16,15 15,22 M19,1 L16,22 M15,22 L15,20 14,17 13,15 11,13 9,12 7,12 6,13 6,15 7,18 10,21 13,22 17,22 19,21",
                "o": 10
            },
            {
                "d": "M15,2 L14,3 13,5 11,10 9,16 8,18 6,21 4,22 M14,3 L13,6 11,14 10,17 9,19 7,21 4,22 2,22 1,21 1,19 2,18 3,19 2,20 M9,7 L8,9 7,10 5,10 4,9 4,7 5,5 7,3 9,2 12,1 18,1 20,2 21,4 21,6 20,8 18,9 14,10 12,10 M18,1 L19,2 20,4 20,6 19,8 18,9 M14,10 L17,11 18,12 19,14 19,17 18,20 17,21 15,22 13,22 12,21 12,19 13,16 M14,10 L16,11 17,12 18,14 18,17 17,20 15,22",
                "o": 12
            },
            {
                "d": "M3,3 L2,5 2,7 3,9 6,10 9,10 13,9 15,8 17,6 18,4 18,2 17,1 15,1 12,2 9,5 7,8 5,12 4,16 4,19 5,21 8,22 10,22 13,21 15,19 16,17 16,15 15,13 13,13 11,14 10,16 M15,1 L13,2 10,5 8,8 6,12 5,16 5,19 6,21 8,22",
                "o": 11
            },
            {
                "d": "M15,2 L14,3 13,5 11,10 9,16 8,18 6,21 4,22 M14,3 L13,6 11,14 10,17 9,19 7,21 4,22 2,22 1,21 1,19 2,18 4,18 6,19 8,21 10,22 13,22 15,21 17,19 19,15 20,10 20,7 19,4 17,2 15,1 10,1 7,2 5,4 4,6 4,8 5,9 7,9 8,8 9,6",
                "o": 11
            },
            {
                "d": "M14,4 L13,5 13,7 14,8 16,8 17,6 17,4 16,2 14,1 11,1 9,2 8,3 7,5 7,7 8,9 10,10 M11,1 L9,3 8,5 8,8 10,10 M10,10 L8,10 5,11 3,13 2,15 2,18 3,20 4,21 6,22 9,22 12,21 14,19 15,17 15,15 14,13 12,13 10,14 9,16 M8,10 L6,11 4,13 3,15 3,19 4,21",
                "o": 10
            },
            {
                "d": "M16,3 L15,5 13,10 11,16 10,18 8,21 6,22 M10,7 L9,9 7,10 5,10 4,8 4,6 5,4 7,2 10,1 20,1 17,2 16,3 15,6 13,14 12,17 11,19 9,21 6,22 4,22 2,21 1,20 1,19 2,18 3,19 2,20 M12,1 L16,2 17,2 M8,14 L9,13 11,12 15,12 17,11 19,8 17,15",
                "o": 10
            },
            {
                "d": "M3,4 L2,6 2,8 3,10 5,11 8,11 11,10 13,9 16,6 17,3 17,2 16,1 15,1 13,2 11,4 10,6 9,9 9,12 10,14 12,15 14,15 16,14 18,12 19,10 M16,1 L14,2 12,4 11,6 10,9 10,13 12,15 M19,10 L18,14 16,18 14,20 12,21 8,22 5,22 3,21 2,19 2,18 3,17 4,18 3,19 M18,14 L16,17 14,19 11,21 8,22",
                "o": 11
            },
            {
                "d": "M6,7 L5,6 5,4 6,2 9,1 12,1 9,12 7,18 6,20 5,21 3,22 1,22 0,21 0,19 1,18 2,19 1,20 M12,1 L9,10 8,13 6,18 5,20 3,22 M4,15 L5,14 7,13 16,10 18,9 21,7 23,5 24,3 24,2 23,1 22,1 20,2 18,5 17,7 15,13 14,17 14,20 16,22 17,22 19,21 21,19 M22,1 L20,3 18,7 16,13 15,17 15,20 16,22",
                "o": 12
            },
            {
                "d": "M14,3 L12,6 10,11 8,16 7,18 5,21 3,22 M16,7 L14,9 11,10 8,10 6,9 5,7 5,5 6,3 8,2 12,1 16,1 14,3 13,5 11,11 9,17 8,19 6,21 3,22 1,22 0,21 0,19 1,18 2,19 1,20",
                "o": 7
            },
            {
                "d": "M16,1 L14,3 12,6 10,11 7,20 5,24 M16,8 L14,10 11,11 8,11 6,10 5,8 5,6 6,4 8,2 12,1 16,1 14,4 13,6 10,15 8,19 7,21 5,24 4,25 2,26 1,25 1,23 2,21 4,19 6,18 9,17 13,16",
                "o": 8
            },
            {
                "d": "M6,7 L5,6 5,4 7,2 10,1 12,1 9,12 7,18 6,20 5,21 3,22 1,22 0,21 0,19 1,18 2,19 1,20 M12,1 L9,10 8,13 6,18 5,20 3,22 M20,2 L17,6 15,8 13,9 10,10 M23,2 L22,3 23,4 24,3 24,2 23,1 22,1 20,2 17,7 16,8 14,9 10,10 M10,10 L13,11 14,13 15,20 16,22 M10,10 L12,11 13,13 14,20 16,22 17,22 19,21 21,19",
                "o": 12
            },
            {
                "d": "M4,4 L3,6 3,8 4,10 6,11 9,11 12,10 14,9 17,6 18,3 18,2 17,1 16,1 14,2 13,3 11,6 7,16 6,18 4,21 2,22 M13,3 L11,7 9,14 8,17 7,19 5,21 2,22 0,22 -1,21 -1,19 0,18 2,18 4,19 7,21 9,22 12,22 14,21 16,19",
                "o": 9
            },
            {
                "d": "M14,1 L10,10 7,16 5,19 3,21 1,22 -1,22 -2,21 -2,19 -1,18 0,19 -1,20 M14,1 L12,8 11,12 10,17 10,21 12,22 M14,1 L13,5 12,10 11,17 11,21 12,22 M23,1 L19,10 14,19 12,22 M23,1 L21,8 20,12 19,17 19,21 21,22 22,22 24,21 26,19 M23,1 L22,5 21,10 20,17 20,21 21,22",
                "o": 14
            },
            {
                "d": "M11,1 L10,5 8,11 6,16 5,18 3,21 1,22 -1,22 -2,21 -2,19 -1,18 0,19 -1,20 M11,1 L11,6 12,17 13,22 M11,1 L12,6 13,17 13,22 M25,2 L24,3 25,4 26,3 26,2 25,1 23,1 21,2 19,5 18,7 16,12 14,18 13,22",
                "o": 12
            },
            {
                "d": "M11,1 L9,2 7,4 5,7 4,9 3,13 3,17 4,20 5,21 7,22 9,22 12,21 14,19 16,16 17,14 18,10 18,6 17,3 16,2 15,2 13,3 11,5 9,9 8,14 8,17 M9,2 L7,5 5,9 4,13 4,17 5,20 7,22",
                "o": 11
            },
            {
                "d": "M15,2 L14,3 13,5 11,10 9,16 8,18 6,21 4,22 M14,3 L13,6 11,14 10,17 9,19 7,21 4,22 2,22 1,21 1,19 2,18 3,19 2,20 M9,7 L8,9 7,10 5,10 4,9 4,7 5,5 7,3 9,2 12,1 16,1 19,2 20,3 21,5 21,8 20,10 19,11 16,12 14,12 12,11 M16,1 L18,2 19,3 20,5 20,8 19,10 18,11 16,12",
                "o": 11
            },
            {
                "d": "M13,5 L13,7 12,9 11,10 9,11 7,11 6,9 6,7 7,4 9,2 12,1 15,1 17,2 18,4 18,8 17,11 15,14 11,18 8,20 6,21 3,22 1,22 0,21 0,19 1,18 3,18 5,19 8,21 11,22 14,22 16,21 18,19 M15,1 L16,2 17,4 17,8 16,11 14,14 11,17 7,20 3,22",
                "o": 11
            },
            {
                "d": "M15,2 L14,3 13,5 11,10 9,16 8,18 6,21 4,22 M14,3 L13,6 11,14 10,17 9,19 7,21 4,22 2,22 1,21 1,19 2,18 3,19 2,20 M9,7 L8,9 7,10 5,10 4,9 4,7 5,5 7,3 9,2 12,1 17,1 20,2 21,4 21,6 20,8 19,9 16,10 12,10 M17,1 L19,2 20,4 20,6 19,8 18,9 16,10 M12,10 L15,11 16,13 17,20 18,22 M12,10 L14,11 15,13 16,20 18,22 19,22 21,21 23,19",
                "o": 12
            },
            {
                "d": "M6,4 L5,6 5,8 6,10 8,11 11,11 14,10 16,9 19,6 20,3 20,2 19,1 18,1 16,2 15,3 14,5 13,8 11,15 10,18 8,21 6,22 M14,5 L13,9 12,16 11,19 9,21 6,22 3,22 1,21 0,19 0,18 1,17 2,18 1,19",
                "o": 10
            },
            {
                "d": "M16,3 L15,5 13,10 11,16 10,18 8,21 6,22 M10,7 L9,9 7,10 5,10 4,8 4,6 5,4 7,2 10,1 19,1 17,2 16,3 15,6 13,14 12,17 11,19 9,21 6,22 4,22 2,21 1,20 1,19 2,18 3,19 2,20 M12,1 L16,2 17,2",
                "o": 9
            },
            {
                "d": "M1,5 L3,2 5,1 6,1 8,3 8,6 7,9 4,17 4,20 5,22 M6,1 L7,3 7,6 4,14 3,17 3,20 5,22 7,22 9,21 12,18 14,15 15,13 M19,1 L15,13 14,17 14,20 16,22 17,22 19,21 21,19 M20,1 L16,13 15,17 15,20 16,22",
                "o": 11
            },
            {
                "d": "M1,5 L3,2 5,1 6,1 8,3 8,6 7,10 5,17 5,20 6,22 M6,1 L7,3 7,6 5,13 4,17 4,20 6,22 7,22 10,21 13,18 15,15 17,11 18,8 19,4 19,2 18,1 17,1 16,2 15,4 15,7 16,9 18,11 20,12 22,12",
                "o": 10
            },
            {
                "d": "M3,7 L2,7 1,6 1,4 2,2 4,1 8,1 7,3 6,7 5,16 4,22 M6,7 L6,16 5,22 M16,1 L14,3 12,7 9,16 7,20 5,22 M16,1 L15,3 14,7 13,16 12,22 M14,7 L14,16 13,22 M26,1 L24,2 22,4 20,7 17,16 15,20 13,22",
                "o": 11
            },
            {
                "d": "M8,6 L7,7 5,7 4,6 4,4 5,2 7,1 9,1 11,2 12,4 12,7 11,11 9,16 7,19 5,21 2,22 0,22 -1,21 -1,19 0,18 1,19 0,20 M9,1 L10,2 11,4 11,7 10,11 8,16 6,19 4,21 2,22 M21,2 L20,3 21,4 22,3 22,2 21,1 19,1 17,2 15,4 13,7 11,11 10,16 10,19 11,21 12,22 13,22 15,21 17,19",
                "o": 10
            },
            {
                "d": "M3,5 L5,2 7,1 8,1 10,2 10,4 8,10 8,13 9,15 M8,1 L9,2 9,4 7,10 7,13 9,15 11,15 14,14 16,12 18,9 19,7 M21,1 L19,7 16,15 14,19 M22,1 L20,7 18,12 16,16 14,19 12,21 9,22 5,22 3,21 2,19 2,18 3,17 4,18 3,19",
                "o": 11
            },
            {
                "d": "M19,3 L18,5 16,10 15,13 14,15 12,18 10,20 8,21 5,22 M12,7 L11,9 9,10 7,10 6,8 6,6 7,4 9,2 12,1 22,1 20,2 19,3 18,6 17,10 15,16 13,19 10,21 5,22 1,22 0,21 0,19 1,18 3,18 5,19 8,21 10,22 13,22 16,21 18,19 M15,1 L19,2 20,2",
                "o": 10
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M6,7 L8,4 10,7 M3,10 L8,5 13,10 M8,5 L8,22",
                "o": 8
            },
            {
                "d": "M0,24 L16,24",
                "o": 8
            },
            {
                "d": "M9,1 L7,3 6,5 6,6 7,7 8,6 7,5",
                "o": 6
            },
            {
                "d": "M10,16 L9,14 7,13 5,13 3,14 2,15 1,17 1,19 2,21 4,22 6,22 8,21 9,19 M5,13 L3,15 2,17 2,20 4,22 M11,13 L9,19 9,21 11,22 13,21 14,20 16,17 M12,13 L10,19 10,21 11,22",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 4,10 M7,1 L1,19 1,21 3,22 4,22 6,21 8,19 9,16 9,13 10,17 11,18 12,18 14,17 M8,1 L2,19 2,21 3,22",
                "o": 8
            },
            {
                "d": "M8,14 L7,15 8,15 8,14 7,13 5,13 3,14 2,15 1,17 1,19 2,21 4,22 7,22 10,20 12,17 M5,13 L3,15 2,17 2,20 4,22",
                "o": 6
            },
            {
                "d": "M10,16 L9,14 7,13 5,13 3,14 2,15 1,17 1,19 2,21 4,22 6,22 8,21 9,19 M5,13 L3,15 2,17 2,20 4,22 M15,1 L9,19 9,21 11,22 13,21 14,20 16,17 M16,1 L10,19 10,21 11,22",
                "o": 9
            },
            {
                "d": "M3,20 L5,19 6,18 7,16 7,14 6,13 5,13 3,14 2,15 1,17 1,19 2,21 4,22 7,22 10,20 12,17 M5,13 L3,15 2,17 2,20 4,22",
                "o": 6
            },
            {
                "d": "M3,13 L6,10 8,7 9,4 9,2 8,1 6,2 5,4 -4,31 -4,33 -3,34 -1,33 0,30 1,21 2,22 4,22 6,21 7,20 9,17 M5,4 L4,9 3,13 0,22 -2,27 -4,31",
                "o": 6
            },
            {
                "d": "M10,16 L9,14 7,13 5,13 3,14 2,15 1,17 1,19 2,21 4,22 6,22 8,21 9,19 M5,13 L3,15 2,17 2,20 4,22 M11,13 L5,31 M12,13 L9,22 7,27 5,31 4,33 2,34 1,33 1,31 2,28 4,26 7,24 11,22 14,20 16,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 4,10 M7,1 L0,22 M8,1 L1,22 M3,16 L5,14 7,13 8,13 10,14 10,16 9,19 9,21 10,22 M8,13 L9,14 9,16 8,19 8,21 10,22 12,21 13,20 15,17",
                "o": 9
            },
            {
                "d": "M5,7 L4,8 5,9 6,8 5,7 M3,13 L1,19 1,21 3,22 5,21 6,20 8,17 M4,13 L2,19 2,21 3,22",
                "o": 4
            },
            {
                "d": "M5,7 L4,8 5,9 6,8 5,7 M3,13 L-3,31 M4,13 L1,22 -1,27 -3,31 -4,33 -6,34 -7,33 -7,31 -6,28 -4,26 -1,24 3,22 6,20 8,17",
                "o": 4
            },
            {
                "d": "M0,17 L2,14 4,10 M7,1 L0,22 M8,1 L1,22 M9,13 L9,14 10,14 9,13 8,13 6,15 3,16 M3,16 L6,17 7,21 8,22 M3,16 L5,17 6,21 8,22 9,22 12,20 14,17",
                "o": 8
            },
            {
                "d": "M0,17 L2,14 4,10 M7,1 L1,19 1,21 3,22 5,21 6,20 8,17 M8,1 L2,19 2,21 3,22",
                "o": 4
            },
            {
                "d": "M0,17 L2,14 4,13 6,14 6,16 4,22 M4,13 L5,14 5,16 3,22 M6,16 L8,14 10,13 11,13 13,14 13,16 11,22 M11,13 L12,14 12,16 10,22 M13,16 L15,14 17,13 18,13 20,14 20,16 19,19 19,21 20,22 M18,13 L19,14 19,16 18,19 18,21 20,22 22,21 23,20 25,17",
                "o": 12
            },
            {
                "d": "M0,17 L2,14 4,13 6,14 6,16 4,22 M4,13 L5,14 5,16 3,22 M6,16 L8,14 10,13 11,13 13,14 13,16 12,19 12,21 13,22 M11,13 L12,14 12,16 11,19 11,21 13,22 15,21 16,20 18,17",
                "o": 9
            },
            {
                "d": "M7,13 L5,13 3,14 2,15 1,17 1,19 2,21 4,22 6,22 8,21 9,20 10,18 10,16 9,14 7,13 6,14 6,16 7,18 9,19 11,19 13,18 14,17 M5,13 L3,15 2,17 2,20 4,22",
                "o": 7
            },
            {
                "d": "M0,17 L2,14 4,10 M5,7 L-4,34 M6,7 L-3,34 M3,16 L5,14 7,13 8,13 10,14 10,16 9,19 9,21 10,22 M8,13 L9,14 9,16 8,19 8,21 10,22 12,21 13,20 15,17",
                "o": 9
            },
            {
                "d": "M10,16 L9,14 7,13 5,13 3,14 2,15 1,17 1,19 2,21 4,22 6,22 8,21 M5,13 L3,15 2,17 2,20 4,22 M11,13 L5,31 5,33 6,34 8,33 9,30 9,22 11,22 14,20 16,17 M12,13 L9,22 7,27 5,31",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 4,13 6,14 6,16 4,22 M4,13 L5,14 5,16 3,22 M6,16 L8,14 10,13 11,13 10,16 M10,13 L10,16 11,18 12,18 14,17",
                "o": 8
            },
            {
                "d": "M0,17 L2,14 3,12 3,14 6,16 7,18 7,20 6,21 4,22 M3,14 L5,16 6,18 6,20 4,22 M0,21 L2,22 7,22 10,20 12,17",
                "o": 8
            },
            {
                "d": "M0,17 L2,14 4,10 M7,1 L1,19 1,21 3,22 5,21 6,20 8,17 M8,1 L2,19 2,21 3,22 M2,9 L8,9",
                "o": 4
            },
            {
                "d": "M3,13 L1,19 1,21 3,22 4,22 6,21 8,19 10,16 M4,13 L2,19 2,21 3,22 M11,13 L9,19 9,21 11,22 13,21 14,20 16,17 M12,13 L10,19 10,21 11,22",
                "o": 9
            },
            {
                "d": "M3,13 L2,15 1,18 1,21 3,22 4,22 7,21 9,19 10,16 10,13 M4,13 L3,15 2,18 2,21 3,22 M10,13 L11,17 12,18 13,18 15,17",
                "o": 8
            },
            {
                "d": "M4,13 L2,15 1,18 1,21 3,22 4,22 6,21 8,19 M5,13 L3,15 2,18 2,21 3,22 M10,13 L8,19 8,21 10,22 11,22 13,21 15,19 16,16 16,13 M11,13 L9,19 9,21 10,22 M16,13 L17,17 18,18 19,18 21,17",
                "o": 11
            },
            {
                "d": "M0,17 L2,14 4,13 6,13 7,14 7,16 6,19 5,21 3,22 2,22 1,21 1,20 2,20 1,21 M13,14 L12,15 13,15 13,14 12,13 11,13 9,14 8,16 7,19 7,21 8,22 11,22 14,20 16,17 M7,14 L8,16 M9,14 L7,16 M6,19 L7,21 M7,19 L5,21",
                "o": 8
            },
            {
                "d": "M3,13 L1,19 1,21 3,22 4,22 6,21 8,19 10,16 M4,13 L2,19 2,21 3,22 M11,13 L5,31 M12,13 L9,22 7,27 5,31 4,33 2,34 1,33 1,31 2,28 4,26 7,24 11,22 14,20 16,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 4,13 6,13 8,14 8,17 7,19 4,21 2,22 M6,13 L7,14 7,17 6,19 4,21 M2,22 L4,23 5,25 5,28 4,31 2,33 0,34 -1,33 -1,31 0,28 3,25 6,23 10,20 13,17 M2,22 L3,23 4,25 4,28 3,31 2,33",
                "o": 7
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M8,1 L6,2 5,4 5,6 6,8 8,9 10,9 12,8 13,6 13,4 12,2 10,1 8,1",
                "o": 8
            }
        ]
    },
    "scripts": {
        "name": "Script 1-stroke",
        "chars": [
            {
                "d": "M8,1 L7,2 5,14 M8,2 L5,14 M8,1 L9,2 5,14 M3,20 L2,21 3,22 4,21 3,20",
                "o": 6
            },
            {
                "d": "M7,1 L5,8 M8,1 L5,8 M16,1 L14,8 M17,1 L14,8",
                "o": 9
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M12,-3 L4,26 M17,-3 L9,26 M18,5 L17,6 18,7 19,6 19,5 18,3 17,2 14,1 10,1 7,2 5,4 5,6 6,8 7,9 14,13 16,15 M5,6 L7,8 14,12 15,13 16,15 16,18 15,20 14,21 11,22 7,22 4,21 3,20 2,18 2,17 3,16 4,17 3,18",
                "o": 11
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M23,9 L22,10 23,11 24,10 24,9 23,8 22,8 20,9 18,11 13,19 11,21 9,22 6,22 3,21 2,19 2,17 3,15 4,14 6,13 11,11 13,10 15,8 16,6 16,4 15,2 13,1 11,2 10,4 10,7 11,13 12,16 14,19 16,21 18,22 20,22 21,20 21,19 M6,22 L4,21 3,19 3,17 4,15 5,14 11,11 M10,7 L11,12 12,15 14,18 16,20 18,21 20,21 21,20",
                "o": 13
            },
            {
                "d": "M8,3 L7,2 8,1 9,2 9,3 8,5 6,7",
                "o": 6
            },
            {
                "d": "M15,-3 L11,0 8,3 6,6 4,10 3,15 3,19 4,24 5,27 6,29 M11,0 L8,4 6,8 5,11 4,16 4,21 5,26 6,29",
                "o": 8
            },
            {
                "d": "M9,-3 L10,-1 11,2 12,7 12,11 11,16 9,20 7,23 4,26 0,29 M9,-3 L10,0 11,5 11,10 10,15 9,18 7,22 4,26",
                "o": 7
            },
            {
                "d": "M10,1 L10,13 M5,4 L15,10 M15,4 L5,10",
                "o": 9
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M3,22 L2,21 3,20 4,21 4,22 3,24 1,26",
                "o": 6
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,20 L4,21 5,22 6,21 5,20",
                "o": 5
            },
            {
                "d": "M24,-3 L-2,29",
                "o": 11
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 9,22 12,21 14,19 16,16 17,13 18,9 18,6 17,3 16,2 14,1 12,1 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 7,22 M9,22 L11,21 13,19 15,16 16,13 17,9 17,6 16,3 14,1",
                "o": 11
            },
            {
                "d": "M12,5 L7,22 M14,1 L8,22 M14,1 L11,4 8,6 6,7 M13,4 L9,6 6,7",
                "o": 11
            },
            {
                "d": "M7,5 L8,6 7,7 6,6 6,5 7,3 8,2 11,1 14,1 17,2 18,4 18,6 17,8 15,10 12,12 8,14 5,16 3,18 1,22 M14,1 L16,2 17,4 17,6 16,8 14,10 8,14 M2,20 L3,19 5,19 10,21 13,21 15,20 16,18 M5,19 L10,22 13,22 15,21 16,18",
                "o": 11
            },
            {
                "d": "M7,5 L8,6 7,7 6,6 6,5 7,3 8,2 11,1 14,1 17,2 18,4 18,6 17,8 14,10 11,11 M14,1 L16,2 17,4 17,6 16,8 14,10 M9,11 L11,11 14,12 15,13 16,15 16,18 15,20 14,21 11,22 7,22 4,21 3,20 2,18 2,17 3,16 4,17 3,18 M11,11 L13,12 14,13 15,15 15,18 14,20 13,21 11,22",
                "o": 11
            },
            {
                "d": "M16,2 L10,22 M17,1 L11,22 M17,1 L2,16 18,16",
                "o": 11
            },
            {
                "d": "M9,1 L4,11 M9,1 L19,1 M9,2 L14,2 19,1 M4,11 L5,10 8,9 11,9 14,10 15,11 16,13 16,16 15,19 13,21 10,22 7,22 4,21 3,20 2,18 2,17 3,16 4,17 3,18 M11,9 L13,10 14,11 15,13 15,16 14,19 12,21 10,22",
                "o": 11
            },
            {
                "d": "M17,4 L16,5 17,6 18,5 18,4 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,18 4,20 5,21 7,22 10,22 13,21 15,19 16,17 16,14 15,12 14,11 12,10 9,10 7,11 5,13 4,15 M12,1 L10,2 8,4 6,7 5,10 4,14 4,19 5,21 M10,22 L12,21 14,19 15,17 15,13 14,11",
                "o": 11
            },
            {
                "d": "M6,1 L4,7 M19,1 L18,4 16,7 11,13 9,16 8,18 7,22 M16,7 L10,13 8,16 7,18 6,22 M5,4 L8,1 10,1 15,4 M6,3 L8,2 10,2 15,4 17,4 18,3 19,1",
                "o": 11
            },
            {
                "d": "M11,1 L8,2 7,3 6,5 6,8 7,10 9,11 12,11 16,10 17,9 18,7 18,4 17,2 14,1 11,1 M11,1 L9,2 8,3 7,5 7,8 8,10 9,11 M12,11 L15,10 16,9 17,7 17,4 16,2 14,1 M9,11 L5,12 3,14 2,16 2,19 3,21 6,22 10,22 14,21 15,20 16,18 16,15 15,13 14,12 12,11 M9,11 L6,12 4,14 3,16 3,19 4,21 6,22 M10,22 L13,21 14,20 15,18 15,14 14,12",
                "o": 11
            },
            {
                "d": "M17,8 L16,10 14,12 12,13 9,13 7,12 6,11 5,9 5,6 6,4 8,2 11,1 14,1 16,2 17,3 18,5 18,9 17,13 16,16 14,19 12,21 9,22 6,22 4,21 3,19 3,18 4,17 5,18 4,19 M7,12 L6,10 6,6 7,4 9,2 11,1 M16,2 L17,4 17,9 16,13 15,16 13,19 11,21 9,22",
                "o": 11
            },
            {
                "d": "M6,8 L5,9 6,10 7,9 6,8 M3,20 L2,21 3,22 4,21",
                "o": 6
            },
            {
                "d": "M6,8 L5,9 6,10 7,9 6,8 M3,22 L2,21 3,20 4,21 4,22 3,24 1,26",
                "o": 6
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M7,5 L8,6 7,7 6,6 6,5 7,3 8,2 11,1 15,1 18,2 19,4 19,6 18,8 17,9 11,11 9,12 9,14 10,15 12,15 M15,1 L17,2 18,4 18,6 17,8 16,9 14,10 M8,20 L7,21 8,22 9,21 8,20",
                "o": 11
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M0,22 L2,21 5,18 8,14 12,7 15,1 15,22 14,19 12,16 10,14 7,12 5,12 4,13 4,15 5,17 7,19 10,21 13,22 18,22",
                "o": 9
            },
            {
                "d": "M13,3 L14,4 14,7 13,11 12,14 11,16 9,19 7,21 5,22 4,22 3,21 3,18 4,13 5,10 6,8 8,5 10,3 12,2 15,1 18,1 20,2 21,4 21,6 20,8 19,9 17,10 14,11 M13,11 L14,11 17,12 18,13 19,15 19,18 18,20 17,21 15,22 12,22 10,21 9,19",
                "o": 11
            },
            {
                "d": "M12,7 L12,8 13,9 15,9 17,8 18,6 18,4 17,2 15,1 12,1 9,2 7,4 5,7 4,9 3,13 3,17 4,20 5,21 7,22 9,22 12,21 14,19 15,17",
                "o": 10
            },
            {
                "d": "M13,1 L11,2 10,4 9,8 8,14 7,17 6,19 4,21 2,22 0,22 -1,21 -1,19 0,18 2,18 4,19 6,21 9,22 12,22 15,21 17,19 19,15 20,10 20,6 19,3 18,2 16,1 13,1 11,3 11,5 12,8 14,11 16,13 19,15 21,16",
                "o": 12
            },
            {
                "d": "M14,5 L14,6 15,7 17,7 18,6 18,4 17,2 14,1 10,1 7,2 6,4 6,7 7,9 8,10 11,11 8,11 5,12 4,13 3,15 3,18 4,20 5,21 8,22 11,22 14,21 16,19 17,17",
                "o": 10
            },
            {
                "d": "M10,7 L8,7 6,6 5,4 6,2 9,1 12,1 16,2 19,2 21,1 M16,2 L14,9 12,15 10,19 8,21 6,22 4,22 2,21 1,19 1,17 2,16 4,16 6,17 M9,11 L18,11",
                "o": 10
            },
            {
                "d": "M0,22 L2,21 6,17 9,12 10,9 11,5 11,2 10,1 9,1 8,2 7,4 7,7 8,9 10,10 14,10 17,9 18,8 19,6 19,12 18,17 17,19 15,21 12,22 8,22 5,21 3,19 2,17 2,15",
                "o": 12
            },
            {
                "d": "M7,8 L5,7 4,5 4,4 5,2 7,1 8,1 10,2 11,4 11,6 10,10 8,16 6,20 4,22 2,22 1,21 1,19 M7,13 L16,10 18,9 21,7 23,5 24,3 24,2 23,1 22,1 20,3 18,7 16,13 15,18 15,21 16,22 17,22 19,21 20,20 22,17",
                "o": 12
            },
            {
                "d": "M14,17 L12,15 10,12 9,10 8,7 8,4 9,2 10,1 12,1 13,2 14,4 14,7 13,12 11,17 10,19 8,21 6,22 4,22 2,21 1,19 1,17 2,16 4,16 6,17",
                "o": 8
            },
            {
                "d": "M10,25 L8,22 6,17 5,11 5,5 6,2 8,1 10,1 11,2 12,5 12,8 11,13 8,22 6,28 5,31 4,33 2,34 1,33 1,31 2,28 4,25 6,23 9,21 13,19",
                "o": 7
            },
            {
                "d": "M7,8 L5,7 4,5 4,4 5,2 7,1 8,1 10,2 11,4 11,6 10,10 8,16 6,20 4,22 2,22 1,21 1,19 M24,4 L24,2 23,1 22,1 20,2 18,4 16,7 14,9 12,10 10,10 M12,10 L13,12 13,19 14,21 15,22 16,22 18,21 19,20 21,17",
                "o": 12
            },
            {
                "d": "M4,13 L6,13 10,12 13,10 15,8 16,6 16,3 15,1 13,1 12,2 11,4 10,9 9,14 8,17 7,19 5,21 3,22 1,22 0,21 0,19 1,18 3,18 5,19 8,21 11,22 13,22 16,21 18,19",
                "o": 10
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,6 8,11 7,15 5,22 M7,15 L10,7 12,3 13,2 15,1 16,1 18,2 19,4 19,6 18,11 17,15 15,22 M17,15 L20,7 22,3 23,2 25,1 26,1 28,2 29,4 29,6 28,11 26,18 26,21 27,22 28,22 30,21 31,20 33,17",
                "o": 15
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,6 8,11 7,15 5,22 M7,15 L10,7 12,3 13,2 15,1 17,1 19,2 20,4 20,6 19,11 17,18 17,21 18,22 19,22 21,21 22,20 24,17",
                "o": 11
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,9 3,13 3,17 4,20 5,21 7,22 9,22 12,21 14,19 16,16 17,14 18,10 18,6 17,3 16,2 14,1 12,1 10,3 10,6 11,9 13,12 15,14 18,16 20,17",
                "o": 11
            },
            {
                "d": "M13,3 L14,4 14,7 13,11 12,14 11,16 9,19 7,21 5,22 4,22 3,21 3,18 4,13 5,10 6,8 8,5 10,3 12,2 15,1 20,1 22,2 23,3 24,5 24,8 23,10 22,11 20,12 17,12 15,11 14,10",
                "o": 13
            },
            {
                "d": "M13,7 L12,9 11,10 9,11 7,11 6,9 6,7 7,4 9,2 12,1 15,1 17,2 18,4 18,8 17,11 15,14 11,18 8,20 6,21 3,22 1,22 0,21 0,19 1,18 3,18 5,19 8,21 11,22 14,22 17,21 19,19",
                "o": 12
            },
            {
                "d": "M13,3 L14,4 14,7 13,11 12,14 11,16 9,19 7,21 5,22 4,22 3,21 3,18 4,13 5,10 6,8 8,5 10,3 12,2 15,1 19,1 21,2 22,3 23,5 23,8 22,10 21,11 19,12 16,12 13,11 14,12 15,14 15,19 16,21 18,22 20,21 21,20 23,17",
                "o": 13
            },
            {
                "d": "M0,22 L2,21 4,19 7,15 9,12 11,8 12,5 12,2 11,1 10,1 9,2 8,4 8,6 9,8 11,10 14,12 16,14 17,16 17,18 16,20 15,21 12,22 8,22 5,21 3,19 2,17 2,15",
                "o": 10
            },
            {
                "d": "M10,7 L8,7 6,6 5,4 6,2 9,1 12,1 16,2 19,2 21,1 M16,2 L14,9 12,15 10,19 8,21 6,22 4,22 2,21 1,19 1,17 2,16 4,16 6,17",
                "o": 9
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,6 8,10 7,13 6,17 6,19 7,21 9,22 11,22 13,21 14,20 16,16 19,8 21,1 M19,8 L18,12 17,18 17,21 18,22 19,22 21,21 22,20 24,17",
                "o": 11
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,6 8,10 7,13 6,17 6,20 7,22 9,22 11,21 14,18 16,15 18,11 19,8 20,4 20,2 19,1 18,1 17,2 16,4 16,6 17,9 19,11 21,12",
                "o": 11
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,7 8,22 M18,1 L8,22 M18,1 L16,22 M30,1 L28,2 25,5 22,9 19,15 16,22",
                "o": 13
            },
            {
                "d": "M8,7 L6,7 5,6 5,4 6,2 8,1 10,1 12,2 13,4 13,7 11,16 11,19 12,21 14,22 16,22 18,21 19,19 19,17 18,16 16,16 M23,4 L23,2 22,1 20,1 18,2 16,4 14,7 10,16 8,19 6,21 4,22 2,22 1,21 1,19",
                "o": 12
            },
            {
                "d": "M5,8 L3,7 2,5 2,4 3,2 5,1 6,1 8,2 9,4 9,6 8,10 7,13 6,17 6,19 7,21 8,22 10,22 12,21 14,19 16,16 17,14 19,8 M21,1 L19,8 16,18 14,24 12,29 10,33 8,34 7,33 7,31 8,28 10,25 13,22 16,20 21,17",
                "o": 11
            },
            {
                "d": "M13,7 L12,9 11,10 9,11 7,11 6,9 6,7 7,4 9,2 12,1 15,1 17,2 18,4 18,8 17,11 15,15 12,18 8,21 6,22 3,22 2,21 2,19 3,18 6,18 8,19 9,20 10,22 10,25 9,28 8,30 6,33 4,34 3,33 3,31 4,28 6,25 9,22 12,20 18,17",
                "o": 11
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M6,7 L8,4 10,7 M3,10 L8,5 13,10 M8,5 L8,22",
                "o": 8
            },
            {
                "d": "M0,24 L16,24",
                "o": 8
            },
            {
                "d": "M9,1 L7,3 6,5 6,6 7,7 8,6 7,5",
                "o": 6
            },
            {
                "d": "M9,16 L8,14 6,13 4,13 2,14 1,15 0,17 0,19 1,21 3,22 5,22 7,21 8,19 10,13 9,18 9,21 10,22 11,22 13,21 14,20 16,17",
                "o": 10
            },
            {
                "d": "M0,17 L2,14 5,9 6,7 7,4 7,2 6,1 4,2 3,4 2,8 1,15 1,21 2,22 3,22 5,21 7,19 8,16 8,13 9,17 10,18 12,18 14,17",
                "o": 9
            },
            {
                "d": "M7,15 L7,14 6,13 4,13 2,14 1,15 0,17 0,19 1,21 3,22 6,22 9,20 11,17",
                "o": 6
            },
            {
                "d": "M9,16 L8,14 6,13 4,13 2,14 1,15 0,17 0,19 1,21 3,22 5,22 7,21 8,19 14,1 M10,13 L9,18 9,21 10,22 11,22 13,21 14,20 16,17",
                "o": 10
            },
            {
                "d": "M1,20 L3,19 4,18 5,16 5,14 4,13 3,13 1,14 0,16 0,19 1,21 3,22 5,22 7,21 8,20 10,17",
                "o": 6
            },
            {
                "d": "M0,17 L4,12 6,9 7,7 8,4 8,2 7,1 5,2 4,4 2,12 -1,21 -4,28 -5,31 -5,33 -4,34 -2,33 -1,30 0,21 1,22 3,22 5,21 6,20 8,17",
                "o": 5
            },
            {
                "d": "M9,16 L8,14 6,13 4,13 2,14 1,15 0,17 0,19 1,21 3,22 5,22 7,21 8,20 M10,13 L8,20 4,31 3,33 1,34 0,33 0,31 1,28 4,25 7,23 9,22 12,20 15,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 5,9 6,7 7,4 7,2 6,1 4,2 3,4 2,8 1,14 0,22 M0,22 L1,19 2,17 4,14 6,13 8,13 9,14 9,16 8,19 8,21 9,22 10,22 12,21 13,20 15,17",
                "o": 10
            },
            {
                "d": "M3,8 L3,9 4,9 4,8 3,8 M0,17 L2,13 0,19 0,21 1,22 2,22 4,21 5,20 7,17",
                "o": 5
            },
            {
                "d": "M3,8 L3,9 4,9 4,8 3,8 M0,17 L2,13 -4,31 -5,33 -7,34 -8,33 -8,31 -7,28 -4,25 -1,23 1,22 4,20 7,17",
                "o": 5
            },
            {
                "d": "M0,17 L2,14 5,9 6,7 7,4 7,2 6,1 4,2 3,4 2,8 1,14 0,22 M0,22 L1,19 2,17 4,14 6,13 8,13 9,14 9,16 7,17 4,17 M4,17 L6,18 7,21 8,22 9,22 11,21 12,20 14,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 5,9 6,7 7,4 7,2 6,1 4,2 3,4 2,8 1,15 1,21 2,22 3,22 5,21 6,20 8,17",
                "o": 5
            },
            {
                "d": "M0,17 L2,14 4,13 5,14 5,15 4,19 3,22 M4,19 L5,17 7,14 9,13 11,13 12,14 12,15 11,19 10,22 M11,19 L12,17 14,14 16,13 18,13 19,14 19,16 18,19 18,21 19,22 20,22 22,21 23,20 25,17",
                "o": 12
            },
            {
                "d": "M0,17 L2,14 4,13 5,14 5,15 4,19 3,22 M4,19 L5,17 7,14 9,13 11,13 12,14 12,16 11,19 11,21 12,22 13,22 15,21 16,20 18,17",
                "o": 10
            },
            {
                "d": "M6,13 L4,13 2,14 1,15 0,17 0,19 1,21 3,22 5,22 7,21 8,20 9,18 9,16 8,14 6,13 5,14 5,16 6,18 8,19 11,19 13,18 14,17",
                "o": 8
            },
            {
                "d": "M0,17 L2,14 3,12 2,16 -4,34 M2,16 L3,14 5,13 7,13 9,14 10,16 10,18 9,20 8,21 6,22 M2,21 L4,22 7,22 10,21 12,20 15,17",
                "o": 8
            },
            {
                "d": "M9,16 L8,14 6,13 4,13 2,14 1,15 0,17 0,19 1,21 3,22 5,22 7,21 M10,13 L9,16 7,21 4,28 3,31 3,33 4,34 6,33 7,30 7,23 9,22 12,20 15,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 3,12 3,14 6,14 7,15 7,17 6,20 6,21 7,22 8,22 10,21 11,20 13,17",
                "o": 8
            },
            {
                "d": "M0,17 L2,14 3,12 3,14 5,17 6,19 6,21 4,22 M0,21 L2,22 6,22 8,21 9,20 11,17",
                "o": 7
            },
            {
                "d": "M0,17 L2,14 4,10 M7,1 L1,19 1,21 2,22 4,22 6,21 7,20 9,17 M1,9 L8,9",
                "o": 6
            },
            {
                "d": "M0,17 L2,13 0,19 0,21 1,22 3,22 5,21 7,19 9,16 M10,13 L8,19 8,21 9,22 10,22 12,21 13,20 15,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,13 1,18 1,21 2,22 3,22 6,21 8,19 9,16 9,13 M9,13 L10,17 11,18 13,18 15,17",
                "o": 9
            },
            {
                "d": "M3,13 L1,15 0,18 0,20 1,22 3,22 5,21 7,19 M9,13 L7,19 7,21 8,22 10,22 12,21 14,19 15,16 15,13 M15,13 L16,17 17,18 19,18 21,17",
                "o": 12
            },
            {
                "d": "M0,17 L2,14 4,13 6,13 7,14 7,21 8,22 11,22 14,20 16,17 M13,14 L12,13 10,13 9,14 5,21 4,22 2,22 1,21",
                "o": 8
            },
            {
                "d": "M0,17 L2,13 0,19 0,21 1,22 3,22 5,21 7,19 9,16 M10,13 L4,31 3,33 1,34 0,33 0,31 1,28 4,25 7,23 9,22 12,20 15,17",
                "o": 9
            },
            {
                "d": "M0,17 L2,14 4,13 6,13 8,15 8,17 7,19 5,21 2,22 4,23 5,25 5,28 4,31 3,33 1,34 0,33 0,31 1,28 4,25 7,23 11,20 14,17",
                "o": 8
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M6,1 L4,2 3,4 3,6 4,8 6,9 8,9 10,8 11,6 11,4 10,2 8,1 6,1",
                "o": 7
            }
        ]
    },
    "symbolic": {
        "name": "Symbolic",
        "chars": [
            {
                "d": "M0,13 L28,13",
                "o": 14
            },
            {
                "d": "M0,27 L28,-1",
                "o": 14
            },
            {
                "d": "M0,-7 L0,33",
                "o": 0
            },
            {
                "d": "M0,-1 L28,27",
                "o": 14
            },
            {
                "d": "M0,13 L28,13",
                "o": 14
            },
            {
                "d": "M0,20 L24,6",
                "o": 12
            },
            {
                "d": "M0,25 L14,1",
                "o": 7
            },
            {
                "d": "M0,-1 L0,27",
                "o": 0
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M0,6 L24,20",
                "o": 12
            },
            {
                "d": "M0,13 L14,13",
                "o": 7
            },
            {
                "d": "M0,18 L10,8",
                "o": 5
            },
            {
                "d": "M0,6 L0,20",
                "o": 0
            },
            {
                "d": "M0,8 L10,18",
                "o": 5
            },
            {
                "d": "M11,2 L9,2 6,3 3,5 1,8 0,11 0,13",
                "o": 0
            },
            {
                "d": "M0,13 L0,15 1,18 3,21 6,23 9,24 11,24",
                "o": 0
            },
            {
                "d": "M0,24 L2,24 5,23 8,21 10,18 11,15 11,13",
                "o": 11
            },
            {
                "d": "M11,13 L11,11 10,8 8,5 5,3 2,2 0,2",
                "o": 11
            },
            {
                "d": "M0,10 L3,12 7,14 12,15 16,15 21,14 25,12 28,10",
                "o": 14
            },
            {
                "d": "M5,-1 L3,2 1,6 0,11 0,15 1,20 3,24 5,27",
                "o": 3
            },
            {
                "d": "M0,-1 L2,2 4,6 5,11 5,15 4,20 2,24 0,27",
                "o": 2
            },
            {
                "d": "M0,16 L3,14 7,12 12,11 16,11 21,12 25,14 28,16",
                "o": 14
            },
            {
                "d": "M7,5 L14,9 0,17 7,21",
                "o": 7
            },
            {
                "d": "M0,13 L4,6 12,20 16,13",
                "o": 8
            },
            {
                "d": "M0,17 L0,9 14,17 14,9",
                "o": 7
            },
            {
                "d": "M2,19 L0,11 16,15 14,7",
                "o": 8
            },
            {
                "d": "M0,24 L2,24 5,23 7,22 10,19 11,17 12,14 12,10 11,7 10,5 9,4 7,4 6,5 5,7 4,10 4,14 5,17 6,19 9,22 11,23 14,24 16,24",
                "o": 8
            },
            {
                "d": "M20,21 L20,19 19,16 18,14 15,11 13,10 10,9 6,9 3,10 1,11 0,12 0,14 1,15 3,16 6,17 10,17 13,16 15,15 18,12 19,10 20,7 20,5",
                "o": 11
            },
            {
                "d": "M16,2 L14,2 11,3 9,4 6,7 5,9 4,12 4,16 5,19 6,21 7,22 9,22 10,21 11,19 12,16 12,12 11,9 10,7 7,4 5,3 2,2 0,2",
                "o": 8
            },
            {
                "d": "M0,5 L0,7 1,10 2,12 5,15 7,16 10,17 14,17 17,16 19,15 20,14 20,12 19,11 17,10 14,9 10,9 7,10 5,11 2,14 1,16 0,19 0,21",
                "o": 9
            },
            {
                "d": "M0,11 L1,13 3,15 5,16 8,17 12,17 16,16 19,14 21,11 22,9 21,7 18,7 14,8 12,9 9,11 7,14 6,17 6,20 7,23 8,25",
                "o": 9
            },
            {
                "d": "M0,15 L3,17 6,18 11,18 14,17 17,15 19,12 20,9 20,7 19,6 17,6 14,7 11,9 9,12 8,15 8,20 9,23 11,26",
                "o": 7
            },
            {
                "d": "M2,10 L0,12 0,14 2,16 4,16 6,14 6,12 4,10 2,10 M2,11 L1,12 1,14 2,15 4,15 5,14 5,12 4,11 2,11 M3,12 L2,13 3,14 4,13 3,12",
                "o": 3
            },
            {
                "d": "M0,8 L1,8 3,9 4,10 5,12 5,14 4,16 3,17 1,18 0,18",
                "o": 5
            },
            {
                "d": "M0,13 L6,13 M11,13 L17,13 M22,13 L28,13",
                "o": 14
            },
            {
                "d": "M0,16 L0,10 28,10 28,16",
                "o": 14
            },
            {
                "d": "M8,-1 L0,13 M8,-1 L16,13",
                "o": 8
            },
            {
                "d": "M0,13 L28,13 M6,20 L22,20 M12,27 L16,27",
                "o": 14
            },
            {
                "d": "M0,13 L28,13 M0,13 L14,29 M28,13 L14,29",
                "o": 14
            },
            {
                "d": "M6,6 L3,7 1,9 0,12 0,14 1,17 3,19 6,20 8,20 11,19 13,17 14,14 14,12 13,9 11,7 8,6 6,6",
                "o": 7
            },
            {
                "d": "M0,7 L0,19 12,19 12,7 0,7",
                "o": 6
            },
            {
                "d": "M7,5 L0,17 14,17 7,5",
                "o": 7
            },
            {
                "d": "M6,3 L0,13 6,23 12,13 6,3",
                "o": 6
            },
            {
                "d": "M8,4 L6,10 0,10 5,14 3,20 8,16 13,20 11,14 16,10 10,10 8,4",
                "o": 8
            },
            {
                "d": "M7,6 L7,20 M0,13 L14,13",
                "o": 7
            },
            {
                "d": "M0,8 L10,18 M10,8 L0,18",
                "o": 5
            },
            {
                "d": "M5,7 L5,19 M0,10 L10,16 M10,10 L0,16",
                "o": 5
            },
            {
                "d": "M3,9 L1,10 0,12 0,14 1,16 3,17 5,17 7,16 8,14 8,12 7,10 5,9 3,9 M1,12 L1,14 M2,11 L2,15 M3,10 L3,16 M4,10 L4,16 M5,10 L5,16 M6,11 L6,15 M7,12 L7,14",
                "o": 4
            },
            {
                "d": "M0,9 L0,17 8,17 8,9 0,9 M1,10 L1,16 M2,10 L2,16 M3,10 L3,16 M4,10 L4,16 M5,10 L5,16 M6,10 L6,16 M7,10 L7,16",
                "o": 4
            },
            {
                "d": "M5,7 L0,16 10,16 5,7 M5,10 L2,15 M5,10 L8,15 M5,13 L4,15 M5,13 L6,15",
                "o": 5
            },
            {
                "d": "M0,13 L9,18 9,8 0,13 M3,13 L8,16 M3,13 L8,10 M6,13 L8,14 M6,13 L8,12",
                "o": 3
            },
            {
                "d": "M5,19 L10,10 0,10 5,19 M5,16 L8,11 M5,16 L2,11 M5,13 L6,11 M5,13 L4,11",
                "o": 5
            },
            {
                "d": "M9,13 L0,8 0,18 9,13 M6,13 L1,10 M6,13 L1,16 M3,13 L1,12 M3,13 L1,14",
                "o": 6
            },
            {
                "d": "M0,6 L0,20 M0,6 L7,9 0,12 M1,8 L4,9 1,10",
                "o": 7
            },
            {
                "d": "M9,2 L9,17 M4,5 L14,11 M14,5 L4,11 M0,17 L3,23 M18,17 L15,23 M0,17 L18,17 M3,23 L15,23",
                "o": 9
            },
            {
                "d": "M5,7 L5,19 M2,10 L8,10 M0,16 L2,18 4,19 6,19 8,18 10,16",
                "o": 5
            },
            {
                "d": "M6,7 L6,19 M0,12 L1,10 11,10 12,12 M4,18 L8,18",
                "o": 6
            },
            {
                "d": "M2,9 L12,19 M12,9 L2,19 M4,7 L1,10 0,12 M10,7 L13,10 14,12",
                "o": 7
            },
            {
                "d": "M5,4 L0,22 M13,4 L18,22 M4,8 L18,22 M14,8 L0,22 M5,4 L13,4 M4,8 L14,8",
                "o": 9
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M6,5 L12,17 M4,11 L12,7 M0,23 L20,23 20,13 0,23",
                "o": 9
            },
            {
                "d": "M4,7 L4,11 0,11 0,15 4,15 4,19 8,19 8,15 12,15 12,11 8,11 8,7 4,7",
                "o": 6
            },
            {
                "d": "M14,11 L13,9 11,7 8,6 6,6 3,7 1,9 0,12 0,14 1,17 3,19 6,20 8,20 11,19 13,17 14,15 M14,11 L12,9 10,8 8,8 6,9 5,10 4,12 4,14 5,16 6,17 8,18 10,18 12,17 14,15",
                "o": 7
            },
            {
                "d": "M7,5 L0,17 14,17 7,5 M7,21 L14,9 0,9 7,21",
                "o": 7
            },
            {
                "d": "M9,4 L9,2 10,1 12,1 13,2 13,4 M0,21 L1,19 3,17 4,15 5,11 5,6 6,5 8,4 14,4 16,5 17,6 17,11 18,15 19,17 21,19 22,21 M0,21 L22,21 M10,21 L9,22 10,23 12,23 13,22 12,21",
                "o": 11
            },
            {
                "d": "M8,8 L8,14 M8,14 L7,23 M8,14 L9,23 M7,23 L9,23 M8,8 L7,5 6,3 4,2 M7,5 L4,2 M8,8 L9,5 10,3 12,2 M9,5 L12,2 M8,8 L4,6 2,6 0,8 M6,7 L2,7 0,8 M8,8 L12,6 14,6 16,8 M10,7 L14,7 16,8 M8,8 L6,9 5,10 5,13 M8,8 L6,10 5,13 M8,8 L10,9 11,10 11,13 M8,8 L10,10 11,13",
                "o": 8
            },
            {
                "d": "M8,4 L8,6 M8,9 L8,11 M8,14 L8,16 M8,20 L7,23 M8,20 L9,23 M7,23 L9,23 M8,2 L7,4 6,5 M8,2 L9,4 10,5 M6,5 L8,4 10,5 M8,6 L6,9 4,10 3,9 M8,6 L10,9 12,10 13,9 M4,10 L6,10 8,9 10,10 12,10 M8,11 L6,14 4,15 2,15 1,13 1,14 2,15 M8,11 L10,14 12,15 14,15 15,13 15,14 14,15 M4,15 L6,15 8,14 10,15 12,15 M8,16 L6,19 5,20 3,21 2,21 1,20 0,18 0,20 2,21 M8,16 L10,19 11,20 13,21 14,21 15,20 16,18 16,20 14,21 M3,21 L5,21 8,20 11,21 13,21",
                "o": 8
            },
            {
                "d": "M8,20 L7,23 M8,20 L9,23 M7,23 L9,23 M8,20 L11,21 14,21 16,19 16,16 15,15 13,15 15,13 16,10 15,8 13,7 11,8 12,5 11,3 9,2 7,2 5,3 4,5 5,8 3,7 1,8 0,10 1,13 3,15 1,15 0,16 0,19 2,21 5,21 8,20",
                "o": 8
            },
            {
                "d": "M8,20 L7,23 M8,20 L9,23 M7,23 L9,23 M8,20 L12,19 12,17 14,16 14,13 16,12 16,7 15,4 14,3 12,3 10,2 6,2 4,3 2,3 1,4 0,7 0,12 2,13 2,16 4,17 4,19 8,20",
                "o": 8
            },
            {
                "d": "M0,11 L2,13 M3,6 L5,11 M9,2 L9,10 M15,6 L13,11 M18,11 L16,13",
                "o": 9
            },
            {
                "d": "M2,4 L3,6 4,10 4,16 3,20 2,22 M20,4 L19,6 18,10 18,16 19,20 20,22 M2,4 L4,5 8,6 14,6 18,5 20,4 M2,22 L4,21 8,20 14,20 18,21 20,22",
                "o": 11
            },
            {
                "d": "M12,13 L12,22 11,23 M12,17 L11,23 M12,4 L11,3 9,3 8,4 8,6 9,9 12,13 M12,4 L13,3 15,3 16,4 16,6 15,9 12,13 M12,13 L8,10 6,9 4,9 3,10 3,12 4,13 M12,13 L16,10 18,9 20,9 21,10 21,12 20,13 M12,13 L8,16 6,17 4,17 3,16 3,14 4,13 M12,13 L16,16 18,17 20,17 21,16 21,14 20,13",
                "o": 12
            },
            {
                "d": "M11,4 L10,5 11,6 12,5 12,4 11,2 9,1 7,1 5,2 4,4 4,6 5,8 7,10 12,13 M5,8 L10,11 12,13 13,15 13,17 12,19 10,21 M6,9 L4,11 3,13 3,15 4,17 6,19 11,22 M4,17 L9,20 11,22 12,24 12,26 11,28 9,29 7,29 5,28 4,26 4,25 5,24 6,25 5,26",
                "o": 8
            },
            {
                "d": "M8,1 L7,3 8,5 9,3 8,1 M8,1 L8,29 M8,12 L7,15 8,29 9,15 8,12 M2,8 L4,9 6,8 4,7 2,8 M2,8 L14,8 M10,8 L12,9 14,8 12,7 10,8",
                "o": 8
            },
            {
                "d": "M8,1 L7,3 8,5 9,3 8,1 M8,1 L8,15 M8,11 L7,13 9,17 8,19 7,17 9,13 8,11 M8,15 L8,29 M8,25 L7,27 8,29 9,27 8,25 M2,8 L4,9 6,8 4,7 2,8 M2,8 L14,8 M10,8 L12,9 14,8 12,7 10,8 M2,22 L4,23 6,22 4,21 2,22 M2,22 L14,22 M10,22 L12,23 14,22 12,21 10,22",
                "o": 8
            },
            {
                "d": "M13,4 L12,5 13,6 14,5 13,4 M4,20 L3,21 4,22 5,21 4,20 M22,20 L21,21 22,22 23,21 22,20",
                "o": 13
            },
            {
                "d": "M12,3 L8,7 5,11 4,14 4,16 5,18 7,19 9,19 11,18 12,16 M12,3 L16,7 19,11 20,14 20,16 19,18 17,19 15,19 13,18 12,16 M12,16 L11,20 10,23 M12,16 L13,20 14,23 M10,23 L14,23",
                "o": 12
            },
            {
                "d": "M12,9 L11,6 10,4 8,3 7,3 5,4 4,6 4,10 5,13 6,15 8,18 12,23 M12,9 L13,6 14,4 16,3 17,3 19,4 20,6 20,10 19,13 18,15 16,18 12,23",
                "o": 12
            },
            {
                "d": "M12,2 L10,5 6,10 3,13 M12,2 L14,5 18,10 21,13 M3,13 L6,16 10,21 12,24 M21,13 L18,16 14,21 12,24",
                "o": 12
            },
            {
                "d": "M12,15 L14,18 16,19 18,19 20,18 21,16 21,14 20,12 18,11 16,11 13,12 M13,12 L15,10 16,8 16,6 15,4 13,3 11,3 9,4 8,6 8,8 9,10 11,12 M11,12 L8,11 6,11 4,12 3,14 3,16 4,18 6,19 8,19 10,18 12,15 M12,15 L11,20 10,23 M12,15 L13,20 14,23 M10,23 L14,23",
                "o": 12
            },
            {
                "d": "M13,-26 L10,-24 8,-22 7,-20 6,-17 6,-13 7,-9 11,-1 12,2 12,5 11,8 9,11 M10,-24 L8,-21 7,-17 7,-13 8,-10 12,-2 13,2 13,5 12,8 9,11 5,13 9,15 12,18 13,21 13,24 12,28 8,36 7,39 7,43 8,47 10,50 M9,15 L11,18 12,21 12,24 11,27 7,35 6,39 6,43 7,46 8,48 10,50 13,52",
                "o": 9
            },
            {
                "d": "M5,-26 L8,-24 10,-22 11,-20 12,-17 12,-13 11,-9 7,-1 6,2 6,5 7,8 9,11 M8,-24 L10,-21 11,-17 11,-13 10,-10 6,-2 5,2 5,5 6,8 9,11 13,13 9,15 6,18 5,21 5,24 6,28 10,36 11,39 11,43 10,47 8,50 M9,15 L7,18 6,21 6,24 7,27 11,35 12,39 12,43 11,46 10,48 8,50 5,52",
                "o": 9
            },
            {
                "d": "M13,-23 L10,-20 8,-17 6,-13 5,-8 5,-2 6,4 7,8 10,19 11,23 12,29 12,34 11,39 10,42 8,46 M10,-20 L8,-16 7,-13 6,-8 6,-3 7,3 8,7 11,18 12,22 13,28 13,34 12,39 10,43 8,46 5,49",
                "o": 9
            },
            {
                "d": "M5,-23 L8,-20 10,-17 12,-13 13,-8 13,-2 12,4 11,8 8,19 7,23 6,29 6,34 7,39 8,42 10,46 M8,-20 L10,-16 11,-13 12,-8 12,-3 11,3 10,7 7,18 6,22 5,28 5,34 6,39 8,43 10,46 13,49",
                "o": 9
            },
            {
                "d": "M3,13 L10,13 27,42 M9,13 L26,42 M8,13 L27,45 M35,-35 L31,5 27,45",
                "o": 8
            },
            {
                "d": "M11,8 L13,9 15,11 15,10 14,9 11,8 8,8 5,9 4,10 3,12 3,14 4,16 6,18 10,21 M8,8 L6,9 5,10 4,12 4,14 5,16 10,21 11,23 11,25 10,26 8,26",
                "o": 9
            },
            {
                "d": "M5,8 L4,9 3,11 3,13 4,16 8,20 9,22 M3,13 L4,15 8,19 9,22 9,24 8,27 6,29 5,29 4,28 3,26 3,23 4,19 6,15 8,12 11,9 13,8 15,8 18,9 19,11 19,15 18,19 16,21 14,22 13,22 12,21 12,19 13,18 14,19 13,20 M15,8 L17,9 18,11 18,15 17,19 16,21",
                "o": 11
            },
            {
                "d": "M20,2 L19,3 20,4 21,3 20,2 18,1 15,1 12,2 10,4 9,6 8,9 7,13 5,22 4,26 3,28 M15,1 L13,2 11,4 10,6 9,9 7,18 6,22 5,25 4,27 3,28 1,29 -1,29 -2,28 -2,27 -1,26 0,27 -1,28 M26,2 L25,3 26,4 27,3 27,2 26,1 24,1 22,2 21,3 20,5 19,8 16,22 15,26 14,28 M24,1 L22,3 21,5 20,9 18,18 17,22 16,25 15,27 14,28 12,29 10,29 9,28 9,27 10,26 11,27 10,28 M4,8 L25,8",
                "o": 13
            },
            {
                "d": "M21,2 L20,3 21,4 22,3 21,2 18,1 15,1 12,2 10,4 9,6 8,9 7,13 5,22 4,26 3,28 M15,1 L13,2 11,4 10,6 9,9 7,18 6,22 5,25 4,27 3,28 1,29 -1,29 -2,28 -2,27 -1,26 0,27 -1,28 M19,8 L17,15 16,19 16,21 17,22 20,22 22,20 23,18 M20,8 L18,15 17,19 17,21 18,22 M4,8 L20,8",
                "o": 12
            },
            {
                "d": "M19,2 L18,3 19,4 20,3 20,2 18,1 M22,1 L15,1 12,2 10,4 9,6 8,9 7,13 5,22 4,26 3,28 M15,1 L13,2 11,4 10,6 9,9 7,18 6,22 5,25 4,27 3,28 1,29 -1,29 -2,28 -2,27 -1,26 0,27 -1,28 M21,1 L17,15 16,19 16,21 17,22 20,22 22,20 23,18 M22,1 L18,15 17,19 17,21 18,22 M4,8 L19,8",
                "o": 12
            },
            {
                "d": "M20,2 L19,3 20,4 21,3 20,2 18,1 15,1 12,2 10,4 9,6 8,9 7,13 5,22 4,26 3,28 M15,1 L13,2 11,4 10,6 9,9 7,18 6,22 5,25 4,27 3,28 1,29 -1,29 -2,28 -2,27 -1,26 0,27 -1,28 M32,2 L31,3 32,4 33,3 32,2 29,1 26,1 23,2 21,4 20,6 19,9 18,13 16,22 15,26 14,28 M26,1 L24,2 22,4 21,6 20,9 18,18 17,22 16,25 15,27 14,28 12,29 10,29 9,28 9,27 10,26 11,27 10,28 M30,8 L28,15 27,19 27,21 28,22 31,22 33,20 34,18 M31,8 L29,15 28,19 28,21 29,22 M4,8 L31,8",
                "o": 17
            },
            {
                "d": "M20,2 L19,3 20,4 21,3 20,2 18,1 15,1 12,2 10,4 9,6 8,9 7,13 5,22 4,26 3,28 M15,1 L13,2 11,4 10,6 9,9 7,18 6,22 5,25 4,27 3,28 1,29 -1,29 -2,28 -2,27 -1,26 0,27 -1,28 M30,2 L29,3 30,4 31,3 31,2 29,1 M33,1 L26,1 23,2 21,4 20,6 19,9 18,13 16,22 15,26 14,28 M26,1 L24,2 22,4 21,6 20,9 18,18 17,22 16,25 15,27 14,28 12,29 10,29 9,28 9,27 10,26 11,27 10,28 M32,1 L28,15 27,19 27,21 28,22 31,22 33,20 34,18 M33,1 L29,15 28,19 28,21 29,22 M4,8 L30,8",
                "o": 17
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,21 7,22 M6,8 L7,9 7,12 5,18 5,21 6,22 9,22 11,20 12,18",
                "o": 7
            },
            {
                "d": "M6,7 L2,18 12,11 0,11 10,18 6,7 M6,13 L6,7 M6,13 L0,11 M6,13 L2,18 M6,13 L10,18 M6,13 L12,11",
                "o": 6
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            }
        ]
    },
    "timesg": {
        "name": "Greek medium",
        "chars": [
            {
                "d": "M5,1 L4,3 5,15 6,3 5,1 M5,3 L5,9 M5,20 L4,21 5,22 6,21 5,20",
                "o": 5
            },
            {
                "d": "M4,1 L3,8 M5,1 L3,8 M12,1 L11,8 M13,1 L11,8",
                "o": 8
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M8,-3 L8,26 M12,-3 L12,26 M16,4 L15,5 16,6 17,5 17,4 15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 17,15 M3,6 L5,8 7,9 13,11 15,12 16,13 17,15 17,19 15,21 12,22 8,22 5,21 3,19 3,18 4,17 5,18 4,19",
                "o": 10
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M21,9 L20,10 21,11 22,10 22,9 21,8 20,8 19,9 18,11 16,16 14,19 12,21 10,22 7,22 4,21 3,19 3,16 4,14 10,10 12,8 13,6 13,4 12,2 10,1 8,2 7,4 7,6 8,9 10,12 15,19 17,21 20,22 21,22 22,21 22,20 M7,22 L5,21 4,19 4,16 5,14 7,12 M7,6 L8,8 16,19 18,21 20,22",
                "o": 13
            },
            {
                "d": "M4,1 L3,8 M5,1 L3,8",
                "o": 4
            },
            {
                "d": "M10,-3 L8,-1 6,2 4,6 3,11 3,15 4,20 6,24 8,27 10,29 11,29 M10,-3 L11,-3 9,-1 7,2 5,6 4,11 4,15 5,20 7,24 9,27 11,29",
                "o": 7
            },
            {
                "d": "M3,-3 L5,-1 7,2 9,6 10,11 10,15 9,20 7,24 5,27 3,29 4,29 M3,-3 L4,-3 6,-1 8,2 10,6 11,11 11,15 10,20 8,24 6,27 4,29",
                "o": 7
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M20,-3 L2,29",
                "o": 11
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1 M9,1 L7,2 6,3 5,5 4,10 4,13 5,18 6,20 7,21 9,22 M11,22 L13,21 14,20 15,18 16,13 16,10 15,5 14,3 13,2 11,1",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22 M10,2 L10,22 M6,22 L15,22",
                "o": 10
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 8,1 12,1 15,2 16,3 17,5 17,7 16,9 13,11 8,13 6,14 4,16 3,19 3,22 M12,1 L14,2 15,3 16,5 16,7 15,9 12,11 8,13 M3,20 L4,19 6,19 11,21 14,21 16,20 17,19 M6,19 L11,22 15,22 16,21 17,19 17,17",
                "o": 10
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 8,1 12,1 15,2 16,4 16,7 15,9 12,10 9,10 M12,1 L14,2 15,4 15,7 14,9 12,10 M12,10 L14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M15,12 L16,15 16,18 15,20 14,21 12,22",
                "o": 10
            },
            {
                "d": "M12,3 L12,22 M13,1 L13,22 M13,1 L2,16 18,16 M9,22 L16,22",
                "o": 10
            },
            {
                "d": "M5,1 L3,11 M3,11 L5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M11,8 L13,9 15,11 16,14 16,16 15,19 13,21 11,22 M5,1 L15,1 M5,2 L10,2 15,1",
                "o": 10
            },
            {
                "d": "M15,4 L14,5 15,6 16,5 16,4 15,2 13,1 10,1 7,2 5,4 4,6 3,10 3,16 4,19 6,21 9,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 4,15 M10,1 L8,2 6,4 5,6 4,10 4,16 5,19 7,21 9,22 M11,22 L13,21 15,19 16,16 16,15 15,12 13,10 11,9",
                "o": 10
            },
            {
                "d": "M3,1 L3,7 M3,5 L4,3 6,1 8,1 13,4 15,4 16,3 17,1 M4,3 L6,2 8,2 13,4 M17,1 L17,4 16,7 12,12 11,14 10,17 10,22 M16,7 L11,12 10,14 9,17 9,22",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,7 5,9 8,10 12,10 15,9 16,7 16,4 15,2 12,1 8,1 M8,1 L6,2 5,4 5,7 6,9 8,10 M12,10 L14,9 15,7 15,4 14,2 12,1 M8,10 L5,11 4,12 3,14 3,18 4,20 5,21 8,22 12,22 15,21 16,20 17,18 17,14 16,12 15,11 12,10 M8,10 L6,11 5,12 4,14 4,18 5,20 6,21 8,22 M12,22 L14,21 15,20 16,18 16,14 15,12 14,11 12,10",
                "o": 10
            },
            {
                "d": "M16,8 L15,11 13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 11,1 14,2 16,4 17,7 17,13 16,17 15,19 13,21 10,22 7,22 5,21 4,19 4,18 5,17 6,18 5,19 M9,14 L7,13 5,11 4,8 4,7 5,4 7,2 9,1 M11,1 L13,2 15,4 16,7 16,13 15,17 14,19 12,21 10,22",
                "o": 10
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 7,1 10,1 13,2 14,3 15,5 15,7 14,9 13,10 9,12 9,15 M10,1 L12,2 13,3 14,5 14,7 13,9 11,11 M9,20 L8,21 9,22 10,21 9,20",
                "o": 9
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M10,1 L3,22 M10,1 L17,22 M10,4 L16,22 M5,16 L14,16 M1,22 L7,22 M13,22 L19,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L14,1 17,2 18,3 19,5 19,7 18,9 17,10 14,11 M14,1 L16,2 17,3 18,5 18,7 17,9 16,10 14,11 M6,11 L14,11 17,12 18,13 19,15 19,18 18,20 17,21 14,22 2,22 M14,11 L16,12 17,13 18,15 18,18 17,20 16,21 14,22",
                "o": 11
            },
            {
                "d": "M3,1 L16,22 M4,1 L17,22 M17,1 L3,22 M1,1 L7,1 M13,1 L19,1 M1,22 L7,22 M13,22 L19,22",
                "o": 10
            },
            {
                "d": "M10,1 L2,22 M10,1 L18,22 M10,4 L17,22 M3,21 L17,21 M2,22 L18,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M12,7 L12,15 M2,1 L18,1 18,7 17,1 M6,11 L12,11 M2,22 L18,22 18,16 17,22",
                "o": 10
            },
            {
                "d": "M10,1 L10,22 M11,1 L11,22 M8,6 L5,7 4,8 3,10 3,13 4,15 5,16 8,17 13,17 16,16 17,15 18,13 18,10 17,8 16,7 13,6 8,6 M8,6 L6,7 5,8 4,10 4,13 5,15 6,16 8,17 M13,17 L15,16 16,15 17,13 17,10 16,8 15,7 13,6 M7,1 L14,1 M7,22 L14,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L17,1 17,7 16,1 M2,22 L9,22",
                "o": 9
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M18,1 L18,22 M19,1 L19,22 M2,1 L9,1 M15,1 L22,1 M6,11 L18,11 M2,22 L9,22 M15,22 L22,22",
                "o": 12
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L9,1 M2,22 L9,22",
                "o": 6
            },
            {
                "d": "M2,12 L2,13 3,13 3,12 2,12",
                "o": 3
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M19,1 L6,14 M11,10 L19,22 M10,10 L18,22 M2,1 L9,1 M15,1 L21,1 M2,22 L9,22 M15,22 L21,22",
                "o": 10
            },
            {
                "d": "M10,1 L3,22 M10,1 L17,22 M10,4 L16,22 M1,22 L7,22 M13,22 L19,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L12,19 M5,1 L12,22 M19,1 L12,22 M19,1 L19,22 M20,1 L20,22 M2,1 L6,1 M19,1 L23,1 M2,22 L8,22 M16,22 L23,22",
                "o": 13
            },
            {
                "d": "M5,1 L5,22 M6,1 L18,20 M6,3 L18,22 M18,1 L18,22 M2,1 L6,1 M15,1 L21,1 M2,22 L8,22",
                "o": 12
            },
            {
                "d": "M10,1 L7,2 5,4 4,6 3,10 3,13 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 19,13 19,10 18,6 17,4 15,2 12,1 10,1 M10,1 L8,2 6,4 5,6 4,10 4,13 5,17 6,19 8,21 10,22 M12,22 L14,21 16,19 17,17 18,13 18,10 17,6 16,4 14,2 12,1",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M18,1 L18,22 M19,1 L19,22 M2,1 L22,1 M2,22 L9,22 M15,22 L22,22",
                "o": 12
            },
            {
                "d": "M10,1 L7,2 5,4 4,6 3,10 3,13 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 19,13 19,10 18,6 17,4 15,2 12,1 10,1 M10,1 L8,2 6,4 5,6 4,10 4,13 5,17 6,19 8,21 10,22 M12,22 L14,21 16,19 17,17 18,13 18,10 17,6 16,4 14,2 12,1 M8,8 L8,15 M14,8 L14,15 M8,11 L14,11 M8,12 L14,12",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L14,1 17,2 18,3 19,5 19,8 18,10 17,11 14,12 6,12 M14,1 L16,2 17,3 18,5 18,8 17,10 16,11 14,12 M2,22 L9,22",
                "o": 11
            },
            {
                "d": "M3,1 L10,11 2,22 M2,1 L9,11 M2,1 L17,1 18,7 16,1 M3,21 L16,21 M2,22 L17,22 18,16 16,22",
                "o": 11
            },
            {
                "d": "M9,1 L9,22 M10,1 L10,22 M3,1 L2,7 2,1 17,1 17,7 16,1 M6,22 L13,22",
                "o": 10
            },
            {
                "d": "M2,6 L2,4 3,2 4,1 6,1 7,2 8,4 9,8 9,22 M2,4 L4,2 6,2 8,4 M17,6 L17,4 16,2 15,1 13,1 12,2 11,4 10,8 10,22 M17,4 L15,2 13,2 11,4 M6,22 L13,22",
                "o": 10
            },
            {
                "d": "M6,1 L4,2 3,4 3,6 4,8 6,9 8,9 10,8 11,6 11,4 10,2 8,1 6,1",
                "o": 7
            },
            {
                "d": "M3,19 L4,22 8,22 6,18 4,14 3,11 3,7 4,4 6,2 9,1 13,1 16,2 18,4 19,7 19,11 18,14 16,18 14,22 18,22 19,19 M6,18 L5,15 4,11 4,7 5,4 7,2 9,1 M13,1 L15,2 17,4 18,7 18,11 17,15 16,18 M4,21 L7,21 M15,21 L18,21",
                "o": 11
            },
            {
                "d": "M4,0 L3,5 M19,0 L18,5 M8,9 L7,14 M15,9 L14,14 M4,18 L3,23 M19,18 L18,23 M4,2 L18,2 M4,3 L18,3 M8,11 L14,11 M8,12 L14,12 M4,20 L18,20 M4,21 L18,21",
                "o": 11
            },
            {
                "d": "M11,1 L11,22 M12,1 L12,22 M2,8 L3,7 5,8 6,12 7,14 8,15 10,16 M3,7 L4,8 5,12 6,14 7,15 10,16 13,16 16,15 17,14 18,12 19,8 20,7 M13,16 L15,15 16,14 17,12 18,8 20,7 21,8 M8,1 L15,1 M8,22 L15,22",
                "o": 12
            },
            {
                "d": "M16,1 L3,22 M17,1 L4,22 M4,1 L3,7 3,1 17,1 M3,22 L17,22 17,16 16,22",
                "o": 10
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M3,15 L11,10 19,15 M3,15 L11,11 19,15",
                "o": 11
            },
            {
                "d": "M0,29 L20,29",
                "o": 10
            },
            {
                "d": "M4,1 L9,7 M4,1 L3,2 9,7",
                "o": 6
            },
            {
                "d": "M10,8 L7,9 5,11 4,13 3,16 3,19 4,21 7,22 9,22 11,21 14,18 16,15 18,11 19,8 M10,8 L8,9 6,11 5,13 4,16 4,19 5,21 7,22 M10,8 L12,8 14,9 15,11 17,19 18,21 19,22 M12,8 L13,9 14,11 16,19 17,21 19,22 20,22",
                "o": 12
            },
            {
                "d": "M13,1 L10,2 8,4 6,8 5,11 4,15 3,21 2,29 M13,1 L11,2 9,4 7,8 6,11 5,15 4,21 3,29 M13,1 L15,1 17,2 18,3 18,6 17,8 16,9 13,10 9,10 M15,1 L17,3 17,6 16,8 15,9 13,10 M9,10 L13,11 15,13 16,15 16,18 15,20 14,21 11,22 9,22 7,21 6,20 5,17 M9,10 L12,11 14,13 15,15 15,18 14,20 13,21 11,22",
                "o": 10
            },
            {
                "d": "M2,8 L4,8 6,9 7,11 12,26 13,28 14,29 M4,8 L5,9 6,11 11,26 12,28 14,29 16,29 M17,8 L16,10 14,13 4,24 2,27 1,29",
                "o": 9
            },
            {
                "d": "M13,9 L11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,18 15,15 15,12 14,10 10,5 9,3 9,1 10,0 12,0 14,1 16,3 M9,8 L7,9 5,12 4,15 4,19 5,21 M9,22 L11,21 13,18 14,15 14,11 13,9 11,6 10,4 10,2 11,1 13,1 16,3",
                "o": 10
            },
            {
                "d": "M15,11 L13,9 11,8 7,8 5,9 5,11 7,13 10,14 M7,8 L6,9 6,11 8,13 10,14 M10,14 L5,15 3,17 3,19 4,21 7,22 10,22 12,21 14,19 M10,14 L6,15 4,17 4,19 5,21 7,22",
                "o": 9
            },
            {
                "d": "M8,9 L6,10 4,12 3,15 3,18 4,20 5,21 7,22 10,22 13,21 16,19 18,16 19,13 19,10 17,8 15,8 13,10 11,14 9,19 6,29 M3,18 L5,20 7,21 10,21 13,20 16,18 18,16 M19,10 L17,9 15,9 13,11 11,14 9,20 7,29",
                "o": 11
            },
            {
                "d": "M1,11 L3,9 5,8 7,8 9,9 10,10 11,13 11,17 10,21 7,29 M2,10 L4,9 8,9 10,10 M18,8 L17,11 16,13 11,20 8,25 6,29 M17,8 L16,11 15,13 11,20",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 7,15 5,22 M6,8 L7,9 7,11 6,15 4,22 M7,15 L9,11 11,9 13,8 15,8 17,9 18,10 18,13 17,18 14,29 M15,8 L17,10 17,13 16,18 13,29",
                "o": 11
            },
            {
                "d": "M6,8 L4,15 3,19 3,21 4,22 7,22 9,20 10,18 M7,8 L5,15 4,19 4,21 5,22",
                "o": 6
            },
            {
                "d": "M4,6 L18,20 M18,6 L4,20",
                "o": 11
            },
            {
                "d": "M6,8 L2,22 M7,8 L3,22 M16,8 L17,9 18,9 17,8 15,8 13,9 9,13 7,14 5,14 M7,14 L9,15 11,21 12,22 M7,14 L8,15 10,21 11,22 13,22 15,21 17,18",
                "o": 10
            },
            {
                "d": "M3,1 L5,1 7,2 8,3 9,5 15,19 16,21 17,22 M5,1 L7,3 8,5 14,19 15,21 17,22 18,22 M10,8 L2,22 M10,8 L3,22",
                "o": 10
            },
            {
                "d": "M7,8 L1,29 M8,8 L2,29 M7,11 L6,17 6,20 8,22 10,22 12,21 14,19 16,16 M18,8 L15,19 15,21 16,22 19,22 21,20 22,18 M19,8 L16,19 16,21 17,22",
                "o": 11
            },
            {
                "d": "M6,8 L4,22 M7,8 L6,14 5,19 4,22 M17,8 L16,12 14,16 M18,8 L17,11 16,13 14,16 12,18 9,20 7,21 4,22 M3,8 L7,8",
                "o": 10
            },
            {
                "d": "M9,8 L6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,18 15,15 15,12 14,10 13,9 11,8 9,8 M9,8 L7,9 5,12 4,15 4,19 5,21 M9,22 L11,21 13,18 14,15 14,11 13,9",
                "o": 9
            },
            {
                "d": "M9,9 L5,22 M9,9 L6,22 M15,9 L15,22 M15,9 L16,22 M2,11 L4,9 7,8 20,8 M2,11 L4,10 7,9 20,9",
                "o": 11
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 7,16 7,19 8,21 9,22 M6,8 L7,9 7,11 6,16 6,19 7,21 9,22 11,22 13,21 15,19 17,16 18,13 19,8 19,4 18,2 16,1 14,1 12,3 12,5 13,8 15,11 17,13 20,15 M13,21 L15,18 16,16 17,13 18,8 18,4 17,2 16,1",
                "o": 11
            },
            {
                "d": "M4,17 L5,20 6,21 8,22 10,22 13,21 15,18 16,15 16,12 15,10 14,9 12,8 10,8 7,9 5,12 4,15 0,29 M10,22 L12,21 14,18 15,15 15,11 14,9 M10,8 L8,9 6,12 5,15 1,29",
                "o": 9
            },
            {
                "d": "M19,8 L9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,18 15,15 15,12 14,10 13,9 11,8 M9,8 L7,9 5,12 4,15 4,19 5,21 M9,22 L11,21 13,18 14,15 14,11 13,9 M13,9 L19,9",
                "o": 11
            },
            {
                "d": "M11,9 L8,22 M11,9 L9,22 M2,11 L4,9 7,8 18,8 M2,11 L4,10 7,9 18,9",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 6,17 6,20 8,22 M6,8 L7,9 7,11 5,17 5,20 6,21 8,22 9,22 12,21 14,19 16,16 17,13 17,10 16,8 15,9 16,10 17,13 M16,16 L17,10",
                "o": 10
            },
            {
                "d": "M13,4 L12,5 13,6 14,5 13,4 M4,13 L22,13 M13,20 L12,21 13,22 14,21 13,20",
                "o": 13
            },
            {
                "d": "M4,12 L6,10 9,9 8,8 6,9 4,12 3,15 3,18 4,21 5,22 7,22 9,21 11,18 12,15 M3,18 L4,20 5,21 7,21 9,20 11,18 M11,15 L11,18 12,21 13,22 15,22 17,21 19,18 20,15 20,12 19,9 18,8 17,9 19,10 20,12 M11,18 L12,20 13,21 15,21 17,20 19,18",
                "o": 11
            },
            {
                "d": "M11,1 L9,2 8,3 8,4 9,5 12,6 15,6 M12,6 L8,7 6,8 5,10 5,12 7,14 10,15 13,15 M12,6 L9,7 7,8 6,10 6,12 8,14 10,15 M10,15 L6,16 4,17 3,19 3,21 5,23 10,25 11,26 11,28 9,29 7,29 M10,15 L7,16 5,17 4,19 4,21 6,23 10,25",
                "o": 8
            },
            {
                "d": "M15,1 L9,29 M16,1 L8,29 M1,12 L2,10 4,8 7,8 8,9 8,11 7,16 7,19 9,21 12,21 14,20 17,17 19,14 M6,8 L7,9 7,11 6,16 6,19 7,21 9,22 12,22 14,21 16,19 18,16 19,14 21,8",
                "o": 11
            },
            {
                "d": "M11,1 L9,2 8,3 8,4 9,5 12,6 17,6 17,5 14,6 10,8 7,10 4,13 3,16 3,18 4,20 7,22 10,24 11,26 11,28 10,29 8,29 7,28 M12,7 L8,10 5,13 4,16 4,18 5,20 7,22",
                "o": 9
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "timesi": {
        "name": "Serif medium italic",
        "chars": [
            {
                "d": "M8,1 L7,2 5,14 M8,2 L5,14 M8,1 L9,2 5,14 M3,20 L2,21 3,22 4,21 3,20",
                "o": 6
            },
            {
                "d": "M7,1 L5,8 M8,1 L5,8 M16,1 L14,8 M17,1 L14,8",
                "o": 9
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M12,-3 L4,26 M17,-3 L9,26 M18,5 L17,6 18,7 19,6 19,5 18,3 17,2 14,1 10,1 7,2 5,4 5,6 6,8 7,9 14,13 16,15 M5,6 L7,8 14,12 15,13 16,15 16,18 15,20 14,21 11,22 7,22 4,21 3,20 2,18 2,17 3,16 4,17 3,18",
                "o": 11
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M21,9 L20,10 21,11 22,10 22,9 21,8 20,8 19,9 18,11 16,16 14,19 12,21 10,22 7,22 4,21 3,19 3,16 4,14 10,10 12,8 13,6 13,4 12,2 10,1 8,2 7,4 7,6 8,9 10,12 15,19 17,21 20,22 21,22 22,21 22,20 M7,22 L5,21 4,19 4,16 5,14 7,12 M7,6 L8,8 16,19 18,21 20,22",
                "o": 13
            },
            {
                "d": "M7,1 L5,8 M8,1 L5,8",
                "o": 5
            },
            {
                "d": "M15,-3 L11,0 8,3 6,6 4,10 3,15 3,19 4,24 5,27 6,29 M11,0 L8,4 6,8 5,11 4,16 4,21 5,26 6,29",
                "o": 8
            },
            {
                "d": "M9,-3 L10,-1 11,2 12,7 12,11 11,16 9,20 7,23 4,26 0,29 M9,-3 L10,0 11,5 11,10 10,15 9,18 7,22 4,26",
                "o": 7
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M3,22 L2,21 3,20 4,21 4,22 3,24 1,26",
                "o": 6
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M3,20 L2,21 3,22 4,21 3,20",
                "o": 6
            },
            {
                "d": "M20,-3 L2,29",
                "o": 11
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 9,22 12,21 14,19 16,16 17,13 18,9 18,6 17,3 16,2 14,1 12,1 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 7,22 M9,22 L11,21 13,19 15,16 16,13 17,9 17,6 16,3 14,1",
                "o": 11
            },
            {
                "d": "M12,5 L7,22 M14,1 L8,22 M14,1 L11,4 8,6 6,7 M13,4 L9,6 6,7",
                "o": 11
            },
            {
                "d": "M7,5 L8,6 7,7 6,6 6,5 7,3 8,2 11,1 14,1 17,2 18,4 18,6 17,8 15,10 12,12 8,14 5,16 3,18 1,22 M14,1 L16,2 17,4 17,6 16,8 14,10 8,14 M2,20 L3,19 5,19 10,21 13,21 15,20 16,18 M5,19 L10,22 13,22 15,21 16,18",
                "o": 11
            },
            {
                "d": "M7,5 L8,6 7,7 6,6 6,5 7,3 8,2 11,1 14,1 17,2 18,4 18,6 17,8 14,10 11,11 M14,1 L16,2 17,4 17,6 16,8 14,10 M9,11 L11,11 14,12 15,13 16,15 16,18 15,20 14,21 11,22 7,22 4,21 3,20 2,18 2,17 3,16 4,17 3,18 M11,11 L13,12 14,13 15,15 15,18 14,20 13,21 11,22",
                "o": 11
            },
            {
                "d": "M16,2 L10,22 M17,1 L11,22 M17,1 L2,16 18,16",
                "o": 11
            },
            {
                "d": "M9,1 L4,11 M9,1 L19,1 M9,2 L14,2 19,1 M4,11 L5,10 8,9 11,9 14,10 15,11 16,13 16,16 15,19 13,21 10,22 7,22 4,21 3,20 2,18 2,17 3,16 4,17 3,18 M11,9 L13,10 14,11 15,13 15,16 14,19 12,21 10,22",
                "o": 11
            },
            {
                "d": "M17,4 L16,5 17,6 18,5 18,4 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,18 4,20 5,21 7,22 10,22 13,21 15,19 16,17 16,14 15,12 14,11 12,10 9,10 7,11 5,13 4,15 M12,1 L10,2 8,4 6,7 5,10 4,14 4,19 5,21 M10,22 L12,21 14,19 15,17 15,13 14,11",
                "o": 11
            },
            {
                "d": "M6,1 L4,7 M19,1 L18,4 16,7 11,13 9,16 8,18 7,22 M16,7 L10,13 8,16 7,18 6,22 M5,4 L8,1 10,1 15,4 M6,3 L8,2 10,2 15,4 17,4 18,3 19,1",
                "o": 11
            },
            {
                "d": "M11,1 L8,2 7,3 6,5 6,8 7,10 9,11 12,11 16,10 17,9 18,7 18,4 17,2 14,1 11,1 M11,1 L9,2 8,3 7,5 7,8 8,10 9,11 M12,11 L15,10 16,9 17,7 17,4 16,2 14,1 M9,11 L5,12 3,14 2,16 2,19 3,21 6,22 10,22 14,21 15,20 16,18 16,15 15,13 14,12 12,11 M9,11 L6,12 4,14 3,16 3,19 4,21 6,22 M10,22 L13,21 14,20 15,18 15,14 14,12",
                "o": 11
            },
            {
                "d": "M17,8 L16,10 14,12 12,13 9,13 7,12 6,11 5,9 5,6 6,4 8,2 11,1 14,1 16,2 17,3 18,5 18,9 17,13 16,16 14,19 12,21 9,22 6,22 4,21 3,19 3,18 4,17 5,18 4,19 M7,12 L6,10 6,6 7,4 9,2 11,1 M16,2 L17,4 17,9 16,13 15,16 13,19 11,21 9,22",
                "o": 11
            },
            {
                "d": "M6,8 L5,9 6,10 7,9 6,8 M3,20 L2,21 3,22 4,21",
                "o": 6
            },
            {
                "d": "M6,8 L5,9 6,10 7,9 6,8 M3,22 L2,21 3,20 4,21 4,22 3,24 1,26",
                "o": 6
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M7,5 L8,6 7,7 6,6 6,5 7,3 8,2 11,1 15,1 18,2 19,4 19,6 18,8 17,9 11,11 9,12 9,14 10,15 12,15 M15,1 L17,2 18,4 18,6 17,8 16,9 14,10 M8,20 L7,21 8,22 9,21 8,20",
                "o": 11
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M13,1 L0,22 M13,1 L14,22 M12,3 L13,22 M4,16 L13,16 M-2,22 L4,22 M10,22 L16,22",
                "o": 10
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L17,1 20,2 21,4 21,6 20,9 19,10 16,11 M17,1 L19,2 20,4 20,6 19,9 18,10 16,11 M7,11 L16,11 18,12 19,14 19,16 18,19 16,21 12,22 0,22 M16,11 L17,12 18,14 18,16 17,19 15,21 12,22",
                "o": 12
            },
            {
                "d": "M18,3 L19,3 20,1 19,7 19,5 18,3 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 8,22 11,22 13,21 15,19 16,17 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 6,21 8,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L15,1 18,2 19,3 20,6 20,10 19,14 17,18 15,20 13,21 9,22 0,22 M15,1 L17,2 18,3 19,6 19,10 18,14 16,18 14,20 12,21 9,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M14,7 L12,15 M6,1 L21,1 20,7 20,1 M7,11 L13,11 M0,22 L15,22 17,17 14,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M14,7 L12,15 M6,1 L21,1 20,7 20,1 M7,11 L13,11 M0,22 L7,22",
                "o": 10
            },
            {
                "d": "M18,3 L19,3 20,1 19,7 19,5 18,3 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 8,22 10,22 13,21 15,19 17,15 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 6,21 8,22 M10,22 L12,21 14,19 16,15 M13,15 L20,15",
                "o": 12
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M22,1 L16,22 M23,1 L17,22 M6,1 L13,1 M19,1 L26,1 M7,11 L19,11 M0,22 L7,22 M13,22 L20,22",
                "o": 13
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L13,1 M0,22 L7,22",
                "o": 7
            },
            {
                "d": "M15,1 L10,18 9,20 8,21 6,22 4,22 2,21 1,19 1,17 2,16 3,17 2,18 M14,1 L9,18 8,20 6,22 M11,1 L18,1",
                "o": 9
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M23,1 L6,14 M13,10 L17,22 M12,10 L16,22 M6,1 L13,1 M19,1 L25,1 M0,22 L7,22 M13,22 L19,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L13,1 M0,22 L15,22 17,16 14,22",
                "o": 10
            },
            {
                "d": "M9,1 L3,22 M9,1 L10,22 M10,1 L11,20 M23,1 L10,22 M23,1 L17,22 M24,1 L18,22 M6,1 L10,1 M23,1 L27,1 M0,22 L6,22 M14,22 L21,22",
                "o": 14
            },
            {
                "d": "M9,1 L3,22 M9,1 L16,19 M9,4 L16,22 M22,1 L16,22 M6,1 L9,1 M19,1 L25,1 M0,22 L6,22",
                "o": 13
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 10,22 13,21 15,19 17,16 18,13 19,9 19,6 18,3 17,2 15,1 12,1 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 7,22 M10,22 L12,21 14,19 16,16 17,13 18,9 18,6 17,3 15,1",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L18,1 21,2 22,4 22,6 21,9 19,11 15,12 7,12 M18,1 L20,2 21,4 21,6 20,9 18,11 15,12 M0,22 L7,22",
                "o": 11
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 10,22 13,21 15,19 17,16 18,13 19,9 19,6 18,3 17,2 15,1 12,1 M12,1 L10,2 8,4 6,7 5,10 4,14 4,17 5,20 7,22 M10,22 L12,21 14,19 16,16 17,13 18,9 18,6 17,3 15,1 M5,20 L5,19 6,17 8,16 9,16 11,17 12,19 12,26 13,27 15,27 16,25 16,24 M12,19 L13,25 14,26 15,26 16,25",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M6,1 L17,1 20,2 21,4 21,6 20,9 19,10 16,11 7,11 M17,1 L19,2 20,4 20,6 19,9 18,10 16,11 M12,11 L14,12 15,13 16,21 17,22 19,22 20,20 20,19 M15,13 L17,20 18,21 19,21 20,20 M0,22 L7,22",
                "o": 12
            },
            {
                "d": "M19,3 L20,3 21,1 20,7 20,5 19,3 18,2 15,1 11,1 8,2 6,4 6,6 7,8 8,9 15,13 17,15 M6,6 L8,8 15,12 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,16 2,22 3,20 4,20",
                "o": 12
            },
            {
                "d": "M13,1 L7,22 M14,1 L8,22 M7,1 L4,7 6,1 21,1 20,7 20,1 M4,22 L11,22",
                "o": 11
            },
            {
                "d": "M8,1 L5,12 4,16 4,19 5,21 8,22 12,22 15,21 17,19 18,16 22,1 M9,1 L6,12 5,16 5,19 6,21 8,22 M5,1 L12,1 M19,1 L25,1",
                "o": 13
            },
            {
                "d": "M6,1 L7,22 M7,1 L8,20 M20,1 L7,22 M4,1 L10,1 M16,1 L22,1",
                "o": 10
            },
            {
                "d": "M8,1 L6,22 M9,1 L7,20 M16,1 L6,22 M16,1 L14,22 M17,1 L15,20 M24,1 L14,22 M5,1 L12,1 M21,1 L27,1",
                "o": 13
            },
            {
                "d": "M7,1 L14,22 M8,1 L15,22 M21,1 L1,22 M5,1 L11,1 M17,1 L23,1 M-1,22 L5,22 M11,22 L17,22",
                "o": 11
            },
            {
                "d": "M6,1 L10,11 7,22 M7,1 L11,11 8,22 M21,1 L11,11 M4,1 L10,1 M17,1 L23,1 M4,22 L11,22",
                "o": 11
            },
            {
                "d": "M20,1 L1,22 M21,1 L2,22 M8,1 L5,7 7,1 21,1 M1,22 L15,22 17,16 14,22",
                "o": 11
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M3,15 L11,10 19,15 M3,15 L11,11 19,15",
                "o": 11
            },
            {
                "d": "M0,29 L20,29",
                "o": 10
            },
            {
                "d": "M4,1 L9,7 M4,1 L3,2 9,7",
                "o": 6
            },
            {
                "d": "M16,8 L14,15 13,19 13,21 14,22 17,22 19,20 20,18 M17,8 L15,15 14,19 14,21 15,22 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 11,21 13,18 14,15 M9,8 L7,9 5,12 4,15 4,19 5,21",
                "o": 11
            },
            {
                "d": "M8,1 L4,14 4,17 5,20 6,21 M9,1 L5,14 M5,14 L6,11 8,9 10,8 12,8 14,9 15,10 16,12 16,15 15,18 13,21 10,22 8,22 6,21 5,18 5,14 M14,9 L15,11 15,15 14,18 12,21 10,22 M5,1 L9,1",
                "o": 9
            },
            {
                "d": "M14,11 L14,12 15,12 15,11 14,9 12,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,18 M9,8 L7,9 5,12 4,15 4,19 5,21",
                "o": 9
            },
            {
                "d": "M18,1 L14,15 13,19 13,21 14,22 17,22 19,20 20,18 M19,1 L15,15 14,19 14,21 15,22 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 11,21 13,18 14,15 M9,8 L7,9 5,12 4,15 4,19 5,21 M15,1 L19,1",
                "o": 11
            },
            {
                "d": "M4,17 L8,16 11,15 14,13 15,11 14,9 12,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,19 M9,8 L7,9 5,12 4,15 4,19 5,21",
                "o": 9
            },
            {
                "d": "M15,2 L14,3 15,4 16,3 16,2 15,1 13,1 11,2 10,3 9,5 8,8 5,22 4,26 3,28 M13,1 L11,3 10,5 9,9 7,18 6,22 5,25 4,27 3,28 1,29 -1,29 -2,28 -2,27 -1,26 0,27 -1,28 M4,8 L14,8",
                "o": 8
            },
            {
                "d": "M17,8 L13,22 12,25 10,28 7,29 4,29 2,28 1,27 1,26 2,25 3,26 2,27 M16,8 L12,22 11,25 9,28 7,29 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 11,21 13,18 14,15 M9,8 L7,9 5,12 4,15 4,19 5,21",
                "o": 10
            },
            {
                "d": "M8,1 L2,22 M9,1 L3,22 M5,15 L7,11 9,9 11,8 13,8 15,9 16,10 16,12 14,18 14,21 15,22 M13,8 L15,10 15,12 13,18 13,21 14,22 17,22 19,20 20,18 M5,1 L9,1",
                "o": 11
            },
            {
                "d": "M9,1 L8,2 9,3 10,2 9,1 M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,21 7,22 M6,8 L7,9 7,12 5,18 5,21 6,22 9,22 11,20 12,18",
                "o": 7
            },
            {
                "d": "M10,1 L9,2 10,3 11,2 10,1 M2,12 L3,10 5,8 8,8 9,9 9,12 6,22 5,25 4,27 3,28 1,29 -1,29 -2,28 -2,27 -1,26 0,27 -1,28 M7,8 L8,9 8,12 5,22 4,25 3,27 1,29",
                "o": 7
            },
            {
                "d": "M8,1 L2,22 M9,1 L3,22 M16,9 L15,10 16,11 17,10 17,9 16,8 15,8 13,9 9,13 7,14 5,14 M7,14 L9,15 11,21 12,22 M7,14 L8,15 10,21 11,22 13,22 15,21 17,18 M5,1 L9,1",
                "o": 10
            },
            {
                "d": "M8,1 L4,15 3,19 3,21 4,22 7,22 9,20 10,18 M9,1 L5,15 4,19 4,21 5,22 M5,1 L9,1",
                "o": 7
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 7,15 5,22 M6,8 L7,9 7,11 6,15 4,22 M7,15 L9,11 11,9 13,8 15,8 17,9 18,10 18,12 15,22 M15,8 L17,10 17,12 14,22 M17,15 L19,11 21,9 23,8 25,8 27,9 28,10 28,12 26,18 26,21 27,22 M25,8 L27,10 27,12 25,18 25,21 26,22 29,22 31,20 32,18",
                "o": 16
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 7,15 5,22 M6,8 L7,9 7,11 6,15 4,22 M7,15 L9,11 11,9 13,8 15,8 17,9 18,10 18,12 16,18 16,21 17,22 M15,8 L17,10 17,12 15,18 15,21 16,22 19,22 21,20 22,18",
                "o": 11
            },
            {
                "d": "M9,8 L6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 12,21 14,18 15,15 15,12 14,10 13,9 11,8 9,8 M9,8 L7,9 5,12 4,15 4,19 5,21 M9,22 L11,21 13,18 14,15 14,11 13,9",
                "o": 9
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 7,15 3,29 M6,8 L7,9 7,11 6,15 2,29 M7,15 L8,12 10,9 12,8 14,8 16,9 17,10 18,12 18,15 17,18 15,21 12,22 10,22 8,21 7,18 7,15 M16,9 L17,11 17,15 16,18 14,21 12,22 M-1,29 L6,29",
                "o": 10
            },
            {
                "d": "M16,8 L10,29 M17,8 L11,29 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,18 4,20 5,21 7,22 9,22 11,21 13,18 14,15 M9,8 L7,9 5,12 4,15 4,19 5,21 M7,29 L14,29",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,11 7,15 5,22 M6,8 L7,9 7,11 6,15 4,22 M7,15 L9,11 11,9 13,8 15,8 16,9 16,10 15,11 14,10 15,9",
                "o": 8
            },
            {
                "d": "M14,10 L14,11 15,11 15,10 14,9 11,8 8,8 5,9 4,10 4,12 5,13 12,17 13,18 M4,11 L5,12 12,16 13,17 13,20 12,21 9,22 6,22 3,21 2,20 2,19 3,19 3,20",
                "o": 9
            },
            {
                "d": "M9,1 L5,15 4,19 4,21 5,22 8,22 10,20 11,18 M10,1 L6,15 5,19 5,21 6,22 M3,8 L12,8",
                "o": 7
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,20 8,22 M6,8 L7,9 7,12 5,18 5,20 6,21 8,22 10,22 12,21 14,19 16,15 M18,8 L16,15 15,19 15,21 16,22 19,22 21,20 22,18 M19,8 L17,15 16,19 16,21 17,22",
                "o": 11
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,20 8,22 M6,8 L7,9 7,12 5,18 5,20 6,21 8,22 9,22 12,21 14,19 16,16 17,12 17,8 16,8 17,10",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,20 8,22 M6,8 L7,9 7,12 5,18 5,20 6,21 8,22 10,22 12,21 14,19 15,17 M17,8 L15,17 15,20 16,21 18,22 20,22 22,21 24,19 25,17 26,13 26,8 25,8 26,10 M18,8 L16,17 16,20 18,22",
                "o": 14
            },
            {
                "d": "M3,12 L5,9 7,8 10,8 11,10 11,13 M9,8 L10,10 10,13 9,17 8,19 6,21 4,22 3,22 2,21 2,20 3,19 4,20 3,21 M9,17 L9,20 10,22 13,22 15,21 17,18 M17,9 L16,10 17,11 18,10 18,9 17,8 16,8 14,9 12,11 11,13 10,17 10,20 11,22",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 7,8 8,9 8,12 6,18 6,20 8,22 M6,8 L7,9 7,12 5,18 5,20 6,21 8,22 10,22 12,21 14,19 16,15 M19,8 L15,22 14,25 12,28 9,29 6,29 4,28 3,27 3,26 4,25 5,26 4,27 M18,8 L14,22 13,25 11,28 9,29",
                "o": 10
            },
            {
                "d": "M17,8 L16,10 14,12 6,18 4,20 3,22 M4,12 L5,10 7,8 10,8 14,10 M5,10 L7,9 10,9 14,10 16,10 M4,20 L6,20 10,21 13,21 15,20 M6,20 L10,22 13,22 15,20 16,18",
                "o": 10
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "timesib": {
        "name": "Serif bold italic",
        "chars": [
            {
                "d": "M9,1 L8,1 7,2 5,15 M9,2 L8,2 5,15 M9,2 L9,3 5,15 M9,1 L10,2 10,3 5,15 M3,19 L2,20 2,21 3,22 4,22 5,21 5,20 4,19 3,19 M3,20 L3,21 4,21 4,20 3,20",
                "o": 6
            },
            {
                "d": "M7,1 L5,8 M8,1 L5,8 M16,1 L14,8 M17,1 L14,8",
                "o": 9
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M12,-3 L4,26 M17,-3 L9,26 M18,6 L18,5 17,5 17,7 19,7 19,5 18,3 17,2 14,1 10,1 7,2 5,4 5,7 6,9 8,11 14,14 15,16 15,19 14,21 M6,7 L7,9 14,13 15,15 M7,2 L6,4 6,6 7,8 13,11 15,13 16,15 16,18 15,20 14,21 11,22 7,22 4,21 3,20 2,18 2,16 4,16 4,18 3,18 3,17",
                "o": 11
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M23,10 L23,9 22,9 22,11 24,11 24,9 23,8 22,8 20,9 18,11 13,19 11,21 9,22 6,22 3,21 2,19 2,17 3,15 4,14 6,13 11,11 13,10 15,8 16,6 16,4 15,2 13,1 11,2 10,4 10,7 11,13 12,16 13,18 15,21 17,22 19,22 20,20 20,19 M7,22 L3,21 M4,21 L3,19 3,17 4,15 5,14 7,13 M11,11 L12,14 15,20 17,21 M6,22 L5,21 4,19 4,17 5,15 6,14 8,13 13,10 M10,7 L11,10 12,13 14,17 16,20 18,21 19,21 20,20",
                "o": 13
            },
            {
                "d": "M7,1 L5,8 M8,1 L5,8",
                "o": 5
            },
            {
                "d": "M16,-3 L14,-2 11,0 8,3 6,6 4,10 3,14 3,19 4,23 5,26 7,29 M9,3 L7,6 5,10 4,15 4,23 M16,-3 L13,-1 10,2 8,5 7,7 6,10 5,14 4,23 M4,15 L5,24 6,27 7,29",
                "o": 8
            },
            {
                "d": "M9,-3 L11,0 12,3 13,7 13,12 12,16 10,20 8,23 5,26 2,28 0,29 M12,3 L12,11 11,16 9,20 7,23 M9,-3 L10,-1 11,2 12,11 M12,3 L11,12 10,16 9,19 8,21 6,24 3,27 0,29",
                "o": 8
            },
            {
                "d": "M10,1 L9,2 11,12 10,13 M10,1 L10,13 M10,1 L11,2 9,12 10,13 M5,4 L6,4 14,10 15,10 M5,4 L15,10 M5,4 L5,5 15,9 15,10 M15,4 L14,4 6,10 5,10 M15,4 L5,10 M15,4 L15,5 5,9 5,10",
                "o": 9
            },
            {
                "d": "M12,4 L12,21 13,21 M12,4 L13,4 13,21 M4,12 L21,12 21,13 M4,12 L4,13 21,13",
                "o": 13
            },
            {
                "d": "M4,22 L3,22 2,21 2,20 3,19 4,19 5,20 5,22 4,24 3,25 1,26 M3,20 L3,21 4,21 4,20 3,20 M4,22 L4,23 3,25",
                "o": 6
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M3,19 L2,20 2,21 3,22 4,22 5,21 5,20 4,19 3,19 M3,20 L3,21 4,21 4,20 3,20",
                "o": 6
            },
            {
                "d": "M20,-3 L2,29 3,29 M20,-3 L21,-3 3,29",
                "o": 12
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 9,22 12,21 14,19 16,16 17,13 18,9 18,6 17,3 16,2 14,1 12,1 M9,3 L7,5 6,7 5,10 4,14 4,18 5,20 M12,20 L14,18 15,16 16,13 17,9 17,5 16,3 M12,1 L10,2 8,5 7,7 6,10 5,14 5,19 6,21 7,22 M9,22 L11,21 13,18 14,16 15,13 16,9 16,4 15,2 14,1",
                "o": 11
            },
            {
                "d": "M12,5 L7,22 9,22 M15,1 L13,5 8,22 M15,1 L9,22 M15,1 L12,4 9,6 7,7 M12,5 L10,6 7,7",
                "o": 11
            },
            {
                "d": "M7,6 L7,5 8,5 8,7 6,7 6,5 7,3 8,2 11,1 14,1 17,2 18,4 18,6 17,8 15,10 5,16 3,18 1,22 M16,2 L17,4 17,6 16,8 14,10 11,12 M14,1 L15,2 16,4 16,6 15,8 13,10 5,16 M2,20 L3,19 5,19 10,20 15,20 16,19 M5,19 L10,21 15,21 M5,19 L10,22 13,22 15,21 16,19 16,18",
                "o": 11
            },
            {
                "d": "M7,6 L7,5 8,5 8,7 6,7 6,5 7,3 8,2 11,1 14,1 17,2 18,4 18,6 17,8 16,9 14,10 11,11 M16,2 L17,4 17,6 16,8 15,9 M14,1 L15,2 16,4 16,6 15,8 13,10 11,11 M9,11 L11,11 14,12 15,13 16,15 16,18 15,20 13,21 10,22 7,22 4,21 3,20 2,18 2,16 4,16 4,18 3,18 3,17 M14,13 L15,15 15,18 14,20 M11,11 L13,12 14,14 14,18 13,20 12,21 10,22",
                "o": 11
            },
            {
                "d": "M15,5 L10,22 12,22 M18,1 L16,5 11,22 M18,1 L12,22 M18,1 L2,16 18,16",
                "o": 11
            },
            {
                "d": "M9,1 L4,11 M9,1 L19,1 M9,2 L17,2 M8,3 L13,3 17,2 19,1 M4,11 L5,10 8,9 11,9 14,10 15,11 16,13 16,16 15,19 13,21 9,22 6,22 4,21 3,20 2,18 2,16 4,16 4,18 3,18 3,17 M14,11 L15,13 15,16 14,19 12,21 M11,9 L13,10 14,12 14,16 13,19 11,21 9,22",
                "o": 11
            },
            {
                "d": "M17,5 L17,4 16,4 16,6 18,6 18,4 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 10,22 13,21 15,19 16,17 16,14 15,12 14,11 12,10 9,10 7,11 6,12 5,14 M8,4 L6,7 5,10 4,14 4,18 5,20 M14,19 L15,17 15,14 14,12 M12,1 L10,2 8,5 7,7 6,10 5,14 5,19 6,21 7,22 M10,22 L12,21 13,20 14,17 14,13 13,11 12,10",
                "o": 11
            },
            {
                "d": "M6,1 L4,7 M19,1 L18,4 16,7 12,12 10,15 9,18 8,22 M10,14 L8,18 7,22 M16,7 L10,13 8,16 7,18 6,22 8,22 M5,4 L8,1 10,1 15,4 M7,2 L10,2 15,4 M5,4 L7,3 10,3 15,4 17,4 18,3 19,1",
                "o": 11
            },
            {
                "d": "M11,1 L8,2 7,3 6,5 6,8 7,10 9,11 12,11 15,10 17,9 18,7 18,4 17,2 15,1 11,1 M13,1 L8,2 M8,3 L7,5 7,9 8,10 M7,10 L10,11 M11,11 L15,10 M16,9 L17,7 17,4 16,2 M17,2 L13,1 M11,1 L9,3 8,5 8,9 9,11 M12,11 L14,10 15,9 16,7 16,3 15,1 M9,11 L5,12 3,14 2,16 2,19 3,21 6,22 10,22 14,21 15,20 16,18 16,15 15,13 14,12 12,11 M10,11 L5,12 M6,12 L4,14 3,16 3,19 4,21 M3,21 L8,22 14,21 M14,20 L15,18 15,15 14,13 M14,12 L11,11 M9,11 L7,12 5,14 4,16 4,19 5,21 6,22 M10,22 L12,21 13,20 14,18 14,14 13,12 12,11",
                "o": 11
            },
            {
                "d": "M16,9 L15,11 14,12 12,13 9,13 7,12 6,11 5,9 5,6 6,4 8,2 11,1 14,1 16,2 17,3 18,6 18,9 17,13 16,16 14,19 12,21 9,22 6,22 4,21 3,19 3,17 5,17 5,19 4,19 4,18 M7,11 L6,9 6,6 7,4 M16,3 L17,5 17,9 16,13 15,16 13,19 M9,13 L8,12 7,10 7,6 8,3 9,2 11,1 M14,1 L15,2 16,4 16,9 15,13 14,16 13,18 11,21 9,22",
                "o": 11
            },
            {
                "d": "M6,8 L5,9 5,10 6,11 7,11 8,10 8,9 7,8 6,8 M6,9 L6,10 7,10 7,9 6,9 M3,19 L2,20 2,21 3,22 4,22 5,21 5,20 4,19 3,19 M3,20 L3,21 4,21 4,20 3,20",
                "o": 6
            },
            {
                "d": "M6,8 L5,9 5,10 6,11 7,11 8,10 8,9 7,8 6,8 M6,9 L6,10 7,10 7,9 6,9 M4,22 L3,22 2,21 2,20 3,19 4,19 5,20 5,22 4,24 3,25 1,26 M3,20 L3,21 4,21 4,20 3,20 M4,22 L4,23 3,25",
                "o": 6
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,8 L21,8 21,9 M4,8 L4,9 21,9 M4,16 L21,16 21,17 M4,16 L4,17 21,17",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M7,6 L7,5 8,5 8,7 6,7 6,5 7,3 8,2 11,1 15,1 18,2 19,4 19,6 18,8 17,9 15,10 11,11 9,12 9,14 11,15 12,15 M13,1 L18,2 M17,2 L18,4 18,6 17,8 16,9 14,10 M15,1 L16,2 17,4 17,6 16,8 15,9 11,11 10,12 10,14 11,15 M8,19 L7,20 7,21 8,22 9,22 10,21 10,20 9,19 8,19 M8,20 L8,21 9,21 9,20 8,20",
                "o": 11
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M13,1 L1,21 M11,5 L12,22 M12,3 L13,21 M13,1 L13,3 14,20 14,22 M4,16 L12,16 M-2,22 L4,22 M9,22 L16,22 M1,21 L-1,22 M1,21 L3,22 M12,21 L10,22 M12,20 L11,22 M14,20 L15,22",
                "o": 10
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M11,1 L5,22 M6,1 L17,1 20,2 21,4 21,6 20,9 19,10 16,11 M19,2 L20,4 20,6 19,9 18,10 M17,1 L18,2 19,4 19,6 18,9 16,11 M8,11 L16,11 18,12 19,14 19,16 18,19 16,21 12,22 0,22 M17,12 L18,14 18,16 17,19 15,21 M16,11 L17,13 17,16 16,19 14,21 12,22 M7,1 L10,2 M8,1 L9,3 M12,1 L10,3 M13,1 L10,2 M4,21 L1,22 M4,20 L2,22 M5,20 L6,22 M4,21 L7,22",
                "o": 12
            },
            {
                "d": "M18,3 L19,3 20,1 19,7 19,5 18,3 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 8,22 11,22 13,21 15,19 16,17 M9,3 L7,5 6,7 5,10 4,14 4,18 5,20 M12,1 L10,2 8,5 7,7 6,10 5,14 5,19 6,21 8,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M11,1 L5,22 M6,1 L15,1 18,2 19,3 20,6 20,10 19,14 17,18 15,20 13,21 9,22 0,22 M17,2 L18,3 19,6 19,10 18,14 16,18 14,20 M15,1 L17,3 18,6 18,10 17,14 15,18 12,21 9,22 M7,1 L10,2 M8,1 L9,3 M12,1 L10,3 M13,1 L10,2 M4,21 L1,22 M4,20 L2,22 M5,20 L6,22 M4,21 L7,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M11,1 L5,22 M15,7 L13,15 M6,1 L21,1 20,7 M8,11 L14,11 M0,22 L15,22 17,17 M7,1 L10,2 M8,1 L9,3 M12,1 L10,3 M13,1 L10,2 M17,1 L20,2 M18,1 L20,3 M19,1 L20,4 M20,1 L20,7 M15,7 L13,11 13,15 M14,9 L12,11 13,13 M14,10 L11,11 13,12 M4,21 L1,22 M4,20 L2,22 M5,20 L6,22 M4,21 L7,22 M10,22 L15,21 M12,22 L15,20 M15,20 L17,17",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M11,1 L5,22 M15,7 L13,15 M6,1 L21,1 20,7 M8,11 L14,11 M0,22 L8,22 M7,1 L10,2 M8,1 L9,3 M12,1 L10,3 M13,1 L10,2 M17,1 L20,2 M18,1 L20,3 M19,1 L20,4 M20,1 L20,7 M15,7 L13,11 13,15 M14,9 L12,11 13,13 M14,10 L11,11 13,12 M4,21 L1,22 M4,20 L2,22 M5,20 L6,22 M4,21 L7,22",
                "o": 10
            },
            {
                "d": "M18,3 L19,3 20,1 19,7 19,5 18,3 17,2 15,1 12,1 9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 8,22 10,22 13,21 15,19 17,15 M9,3 L7,5 6,7 5,10 4,14 4,18 5,20 M14,19 L15,18 16,15 M12,1 L10,2 8,5 7,7 6,10 5,14 5,19 6,21 8,22 M10,22 L12,21 14,18 15,15 M12,15 L20,15 M13,15 L15,16 M14,15 L15,18 M18,15 L16,17 M19,15 L16,16",
                "o": 12
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M11,1 L5,22 M21,1 L15,22 M22,1 L16,22 M23,1 L17,22 M6,1 L14,1 M18,1 L26,1 M7,11 L19,11 M0,22 L8,22 M12,22 L20,22 M7,1 L10,2 M8,1 L9,3 M12,1 L10,3 M13,1 L10,2 M19,1 L22,2 M20,1 L21,3 M24,1 L22,3 M25,1 L22,2 M4,21 L1,22 M4,20 L2,22 M5,20 L6,22 M4,21 L7,22 M16,21 L13,22 M16,20 L14,22 M17,20 L18,22 M16,21 L19,22",
                "o": 13
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M11,1 L5,22 M6,1 L14,1 M0,22 L8,22 M7,1 L10,2 M8,1 L9,3 M12,1 L10,3 M13,1 L10,2 M4,21 L1,22 M4,20 L2,22 M5,20 L6,22 M4,21 L7,22",
                "o": 7
            },
            {
                "d": "M14,1 L9,18 8,20 6,22 M15,1 L11,14 10,17 9,19 M16,1 L12,14 10,19 8,21 6,22 4,22 2,21 1,19 1,17 2,16 3,16 4,17 4,18 3,19 2,19 M2,17 L2,18 3,18 3,17 2,17 M11,1 L19,1 M12,1 L15,2 M13,1 L14,3 M17,1 L15,3 M18,1 L15,2",
                "o": 10
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M11,1 L5,22 M22,2 L7,13 M11,10 L15,22 M12,10 L16,22 M13,9 L17,21 M6,1 L14,1 M19,1 L25,1 M0,22 L8,22 M12,22 L19,22 M7,1 L10,2 M8,1 L9,3 M12,1 L10,3 M13,1 L10,2 M20,1 L22,2 M24,1 L22,2 M4,21 L1,22 M4,20 L2,22 M5,20 L6,22 M4,21 L7,22 M15,21 L13,22 M15,20 L14,22 M16,20 L18,22",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M11,1 L5,22 M6,1 L14,1 M0,22 L15,22 17,16 M7,1 L10,2 M8,1 L9,3 M12,1 L10,3 M13,1 L10,2 M4,21 L1,22 M4,20 L2,22 M5,20 L6,22 M4,21 L7,22 M10,22 L15,21 M12,22 L16,19 M14,22 L17,16",
                "o": 10
            },
            {
                "d": "M9,1 L3,21 M9,2 L10,20 10,22 M10,1 L11,20 M11,1 L12,19 M23,1 L12,19 10,22 M23,1 L17,22 M24,1 L18,22 M25,1 L19,22 M6,1 L11,1 M23,1 L28,1 M0,22 L6,22 M14,22 L22,22 M7,1 L9,2 M8,1 L9,3 M26,1 L24,3 M27,1 L24,2 M3,21 L1,22 M3,21 L5,22 M18,21 L15,22 M18,20 L16,22 M19,20 L20,22 M18,21 L21,22",
                "o": 14
            },
            {
                "d": "M9,1 L3,21 M9,1 L16,22 M10,1 L16,19 M11,1 L17,19 M22,2 L17,19 16,22 M6,1 L11,1 M19,1 L25,1 M0,22 L6,22 M7,1 L10,2 M8,1 L10,3 M20,1 L22,2 M24,1 L22,2 M3,21 L1,22 M3,21 L5,22",
                "o": 13
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 10,22 13,21 15,19 17,16 18,13 19,9 19,6 18,3 17,2 15,1 12,1 M8,4 L6,7 5,10 4,14 4,18 5,20 M14,19 L16,16 17,13 18,9 18,5 17,3 M12,1 L10,2 8,5 7,7 6,10 5,14 5,19 6,21 7,22 M10,22 L12,21 14,18 15,16 16,13 17,9 17,4 16,2 15,1",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M11,1 L5,22 M6,1 L18,1 21,2 22,4 22,6 21,9 19,11 15,12 7,12 M20,2 L21,4 21,6 20,9 18,11 M18,1 L19,2 20,4 20,6 19,9 17,11 15,12 M0,22 L8,22 M7,1 L10,2 M8,1 L9,3 M12,1 L10,3 M13,1 L10,2 M4,21 L1,22 M4,20 L2,22 M5,20 L6,22 M4,21 L7,22",
                "o": 11
            },
            {
                "d": "M12,1 L9,2 7,4 5,7 4,10 3,14 3,17 4,20 5,21 7,22 10,22 13,21 15,19 17,16 18,13 19,9 19,6 18,3 17,2 15,1 12,1 M8,4 L6,7 5,10 4,14 4,18 5,20 M14,19 L16,16 17,13 18,9 18,5 17,3 M12,1 L10,2 8,5 7,7 6,10 5,14 5,19 6,21 7,22 M10,22 L12,21 14,18 15,16 16,13 17,9 17,4 16,2 15,1 M5,19 L6,17 8,16 9,16 11,17 12,19 13,24 14,25 15,25 16,24 M13,25 L14,26 15,26 M12,19 L12,26 13,27 15,27 16,24 16,23",
                "o": 11
            },
            {
                "d": "M9,1 L3,22 M10,1 L4,22 M11,1 L5,22 M6,1 L17,1 20,2 21,4 21,6 20,9 19,10 16,11 8,11 M19,2 L20,4 20,6 19,9 18,10 M17,1 L18,2 19,4 19,6 18,9 16,11 M12,11 L14,12 15,13 17,19 18,20 19,20 20,19 M17,20 L18,21 19,21 M15,13 L16,21 17,22 19,22 20,19 20,18 M0,22 L8,22 M7,1 L10,2 M8,1 L9,3 M12,1 L10,3 M13,1 L10,2 M4,21 L1,22 M4,20 L2,22 M5,20 L6,22 M4,21 L7,22",
                "o": 12
            },
            {
                "d": "M19,3 L20,3 21,1 20,7 20,5 19,3 18,2 15,1 11,1 8,2 6,4 6,7 7,9 9,11 15,14 16,16 16,19 15,21 M7,7 L8,9 15,13 16,15 M8,2 L7,4 7,6 8,8 14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,16 2,22 3,20 4,20",
                "o": 12
            },
            {
                "d": "M13,1 L7,22 M14,1 L8,22 M15,1 L9,22 M6,1 L4,7 M22,1 L21,7 M6,1 L22,1 M4,22 L12,22 M7,1 L4,7 M9,1 L5,4 M11,1 L6,2 M18,1 L21,2 M19,1 L21,3 M20,1 L21,4 M21,1 L21,7 M8,21 L5,22 M8,20 L6,22 M9,20 L10,22 M8,21 L11,22",
                "o": 11
            },
            {
                "d": "M8,1 L5,12 4,16 4,19 5,21 8,22 12,22 15,21 17,19 18,16 22,2 M9,1 L6,12 5,16 5,20 6,21 M10,1 L7,12 6,16 6,20 8,22 M5,1 L13,1 M19,1 L25,1 M6,1 L9,2 M7,1 L8,3 M11,1 L9,3 M12,1 L9,2 M20,1 L22,2 M24,1 L22,2",
                "o": 13
            },
            {
                "d": "M6,1 L6,3 7,20 7,22 M7,2 L8,19 M8,1 L9,18 M19,2 L7,22 M4,1 L11,1 M16,1 L22,1 M5,1 L6,3 M9,1 L8,3 M10,1 L7,2 M17,1 L19,2 M21,1 L19,2",
                "o": 10
            },
            {
                "d": "M8,1 L8,3 6,20 6,22 M9,2 L7,19 M10,1 L8,18 M16,1 L8,18 6,22 M16,1 L16,3 14,20 14,22 M17,2 L15,19 M18,1 L16,18 M24,2 L16,18 14,22 M5,1 L13,1 M16,1 L18,1 M21,1 L27,1 M6,1 L9,2 M7,1 L8,3 M11,1 L9,4 M12,1 L9,2 M22,1 L24,2 M26,1 L24,2",
                "o": 13
            },
            {
                "d": "M7,1 L13,22 M8,1 L14,22 M9,1 L15,22 M20,2 L2,21 M5,1 L12,1 M17,1 L23,1 M-1,22 L5,22 M10,22 L17,22 M6,1 L8,3 M10,1 L9,3 M11,1 L9,2 M18,1 L20,2 M22,1 L20,2 M2,21 L0,22 M2,21 L4,22 M13,21 L11,22 M13,20 L12,22 M14,20 L16,22",
                "o": 11
            },
            {
                "d": "M6,1 L10,11 7,22 M7,1 L11,11 8,22 M8,1 L12,11 9,22 M21,2 L12,11 M4,1 L11,1 M18,1 L24,1 M4,22 L12,22 M5,1 L7,2 M9,1 L8,3 M10,1 L7,2 M19,1 L21,2 M23,1 L21,2 M8,21 L5,22 M8,20 L6,22 M9,20 L10,22 M8,21 L11,22",
                "o": 11
            },
            {
                "d": "M19,1 L1,22 M20,1 L2,22 M21,1 L3,22 M21,1 L7,1 5,7 M1,22 L15,22 17,16 M8,1 L5,7 M9,1 L6,4 M11,1 L7,2 M11,22 L15,21 M13,22 L16,19 M14,22 L17,16",
                "o": 11
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M3,15 L11,10 19,15 M3,15 L11,11 19,15",
                "o": 11
            },
            {
                "d": "M0,29 L20,29",
                "o": 10
            },
            {
                "d": "M4,1 L9,7 M4,1 L3,2 9,7",
                "o": 6
            },
            {
                "d": "M16,8 L14,15 14,19 15,21 16,22 18,22 20,20 21,18 M17,8 L15,15 15,21 M16,8 L18,8 16,15 15,19 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 11,21 12,20 13,18 14,15 M7,9 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22",
                "o": 11
            },
            {
                "d": "M7,1 L5,8 4,14 4,18 5,20 6,21 8,22 10,22 13,21 15,18 16,15 16,13 15,10 14,9 12,8 10,8 8,9 7,10 6,12 5,15 M8,1 L6,8 5,12 5,18 6,21 M13,20 L14,18 15,15 15,12 14,10 M4,1 L9,1 7,8 5,15 M10,22 L12,20 13,18 14,15 14,12 13,9 12,8 M5,1 L8,2 M6,1 L7,3",
                "o": 10
            },
            {
                "d": "M14,12 L14,11 13,11 13,13 15,13 15,11 14,9 12,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 12,21 14,18 M6,10 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22",
                "o": 9
            },
            {
                "d": "M18,1 L15,12 14,16 14,19 15,21 16,22 18,22 20,20 21,18 M19,1 L16,12 15,16 15,21 M15,1 L20,1 16,15 15,19 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 11,21 12,20 13,18 14,15 M6,10 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22 M16,1 L19,2 M17,1 L18,3",
                "o": 11
            },
            {
                "d": "M4,17 L8,16 11,15 14,13 15,11 14,9 12,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 12,21 14,19 M6,10 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22",
                "o": 9
            },
            {
                "d": "M16,3 L16,2 15,2 15,4 17,4 17,2 16,1 14,1 12,2 10,4 9,6 8,9 7,13 5,22 4,25 3,27 1,29 M10,5 L9,8 8,13 6,22 5,25 M14,1 L12,3 11,5 10,8 9,13 7,21 6,24 5,26 3,28 1,29 -1,29 -2,28 -2,26 0,26 0,28 -1,28 -1,27 M4,8 L15,8",
                "o": 8
            },
            {
                "d": "M16,8 L12,22 11,25 9,28 7,29 M17,8 L13,22 11,26 M16,8 L18,8 14,22 12,26 10,28 7,29 4,29 2,28 1,27 1,25 3,25 3,27 2,27 2,26 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 11,21 12,20 13,18 14,15 M6,10 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22",
                "o": 11
            },
            {
                "d": "M8,1 L2,22 4,22 M9,1 L3,22 M5,1 L10,1 4,22 M6,15 L8,11 10,9 12,8 14,8 16,9 17,11 17,14 15,19 M16,9 L16,13 15,17 15,21 M16,11 L14,16 14,19 15,21 16,22 18,22 20,20 21,18 M6,1 L9,2 M7,1 L8,3",
                "o": 11
            },
            {
                "d": "M8,1 L8,3 10,3 10,1 8,1 M9,1 L9,3 M8,2 L10,2 M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,19 M7,9 L7,13 6,17 6,21 M7,11 L5,16 5,19 6,21 7,22 9,22 11,20 12,18",
                "o": 6
            },
            {
                "d": "M10,1 L10,3 12,3 12,1 10,1 M11,1 L11,3 M10,2 L12,2 M2,12 L3,10 5,8 7,8 8,9 9,11 9,14 7,21 6,24 5,26 3,28 1,29 -1,29 -2,28 -2,26 0,26 0,28 -1,28 -1,27 M8,9 L8,14 6,21 5,24 4,26 M8,11 L7,15 5,22 4,25 3,27 1,29",
                "o": 6
            },
            {
                "d": "M8,1 L2,22 4,22 M9,1 L3,22 M5,1 L10,1 4,22 M18,10 L18,9 17,9 17,11 19,11 19,9 18,8 16,8 14,9 10,13 8,14 M6,14 L8,14 10,15 11,16 13,20 14,21 16,21 M10,16 L12,20 13,21 M8,14 L9,15 11,21 12,22 14,22 16,21 18,18 M6,1 L9,2 M7,1 L8,3",
                "o": 11
            },
            {
                "d": "M8,1 L5,12 4,16 4,19 5,21 6,22 8,22 10,20 11,18 M9,1 L6,12 5,16 5,21 M5,1 L10,1 6,15 5,19 M6,1 L9,2 M7,1 L8,3",
                "o": 6
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,22 M7,9 L7,14 5,22 M7,11 L6,15 4,22 6,22 M8,14 L10,11 12,9 14,8 16,8 18,9 19,11 19,14 17,22 M18,9 L18,14 16,22 M18,11 L17,15 15,22 17,22 M19,14 L21,11 23,9 25,8 27,8 29,9 30,11 30,14 28,19 M29,9 L29,13 28,17 28,21 M29,11 L27,16 27,19 28,21 29,22 31,22 33,20 34,18",
                "o": 17
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,22 M7,9 L7,14 5,22 M7,11 L6,15 4,22 6,22 M8,14 L10,11 12,9 14,8 16,8 18,9 19,11 19,14 17,19 M18,9 L18,13 17,17 17,21 M18,11 L16,16 16,19 17,21 18,22 20,22 22,20 23,18",
                "o": 12
            },
            {
                "d": "M9,8 L6,9 4,12 3,15 3,17 4,20 5,21 8,22 11,22 14,21 16,18 17,15 17,13 16,10 15,9 12,8 9,8 M6,10 L5,12 4,15 4,18 5,20 M14,20 L15,18 16,15 16,12 15,10 M9,8 L7,10 6,12 5,15 5,18 6,21 8,22 M11,22 L13,20 14,18 15,15 15,12 14,9 12,8",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 7,18 4,29 M7,9 L7,14 6,18 3,29 M7,11 L6,15 2,29 M8,15 L9,12 10,10 11,9 13,8 15,8 17,9 18,10 19,13 19,15 18,18 16,21 13,22 11,22 9,21 8,18 8,15 M17,10 L18,12 18,15 17,18 16,20 M15,8 L16,9 17,12 17,15 16,18 15,20 13,22 M-1,29 L7,29 M3,28 L0,29 M3,27 L1,29 M4,27 L5,29 M3,28 L6,29",
                "o": 11
            },
            {
                "d": "M16,8 L10,29 M17,8 L11,29 M16,8 L18,8 12,29 M14,15 L14,12 13,9 11,8 9,8 6,9 4,12 3,15 3,17 4,20 5,21 7,22 9,22 11,21 12,20 13,18 14,15 M6,10 L5,12 4,15 4,18 5,20 M9,8 L7,10 6,12 5,15 5,18 6,21 7,22 M7,29 L15,29 M11,28 L8,29 M11,27 L9,29 M12,27 L13,29 M11,28 L14,29",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,15 6,22 M7,9 L7,15 5,22 M7,11 L6,15 4,22 6,22 M16,10 L16,9 15,9 15,11 17,11 17,9 16,8 14,8 12,9 10,11 8,15",
                "o": 9
            },
            {
                "d": "M14,11 L14,10 13,10 13,12 15,12 15,10 14,9 11,8 8,8 5,9 4,10 4,12 5,14 7,15 10,16 12,17 13,19 M5,9 L4,12 M5,13 L7,14 10,15 12,16 M13,17 L12,21 M4,10 L5,12 7,13 10,14 12,15 13,17 13,19 12,21 9,22 6,22 3,21 2,20 2,18 4,18 4,20 3,20 3,19",
                "o": 9
            },
            {
                "d": "M9,1 L6,12 5,16 5,19 6,21 7,22 9,22 11,20 12,18 M10,1 L7,12 6,16 6,21 M9,1 L11,1 7,15 6,19 M3,8 L13,8",
                "o": 7
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,19 M7,9 L7,13 6,17 6,21 M7,11 L5,16 5,19 6,21 8,22 10,22 12,21 14,19 16,16 M18,8 L16,16 16,19 17,21 18,22 20,22 22,20 23,18 M19,8 L17,16 17,21 M18,8 L20,8 18,15 17,19",
                "o": 12
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,19 M7,9 L7,13 6,17 6,21 M7,11 L5,16 5,19 6,21 8,22 10,22 12,21 14,19 16,16 17,12 17,8 16,8 16,9 17,11",
                "o": 10
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,19 M7,9 L7,13 6,17 6,21 M7,11 L5,16 5,19 6,21 8,22 10,22 12,21 14,19 15,16 M17,8 L15,16 15,19 16,21 18,22 20,22 22,21 24,19 26,16 27,12 27,8 26,8 26,9 27,11 M18,8 L16,16 16,21 M17,8 L19,8 17,15 16,19",
                "o": 15
            },
            {
                "d": "M3,12 L5,9 7,8 9,8 11,9 12,11 12,13 M9,8 L10,9 10,13 9,17 8,19 6,21 4,22 2,22 1,21 1,19 3,19 3,21 2,21 2,20 M11,10 L11,13 10,17 10,20 M19,10 L19,9 18,9 18,11 20,11 20,9 19,8 17,8 15,9 13,11 12,13 11,17 11,21 12,22 M9,17 L9,19 10,21 12,22 14,22 16,21 18,18",
                "o": 11
            },
            {
                "d": "M1,12 L2,10 4,8 6,8 7,9 8,11 8,14 6,19 M7,9 L7,13 6,17 6,21 M7,11 L5,16 5,19 6,21 8,22 10,22 12,21 14,19 16,15 M18,8 L14,22 13,25 11,28 9,29 M19,8 L15,22 13,26 M18,8 L20,8 16,22 14,26 12,28 9,29 6,29 4,28 3,27 3,25 5,25 5,27 4,27 4,26",
                "o": 11
            },
            {
                "d": "M17,8 L16,10 14,12 6,18 4,20 3,22 M16,10 L7,10 5,11 4,13 M14,10 L10,9 7,9 6,10 M14,10 L10,8 7,8 5,10 4,13 M4,20 L13,20 15,19 16,17 M6,20 L10,21 13,21 14,20 M6,20 L10,22 13,22 15,20 16,17",
                "o": 10
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "timesr": {
        "name": "Serif medium",
        "chars": [
            {
                "d": "M5,1 L4,3 5,15 6,3 5,1 M5,3 L5,9 M5,20 L4,21 5,22 6,21 5,20",
                "o": 5
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8 M14,1 L13,2 13,8 M14,2 L13,8 M14,1 L15,2 13,8",
                "o": 9
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M8,-3 L8,26 M12,-3 L12,26 M16,4 L15,5 16,6 17,5 17,4 15,2 12,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 17,15 M3,6 L5,8 7,9 13,11 15,12 16,13 17,15 17,19 15,21 12,22 8,22 5,21 3,19 3,18 4,17 5,18 4,19",
                "o": 10
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M21,9 L20,10 21,11 22,10 22,9 21,8 20,8 19,9 18,11 16,16 14,19 12,21 10,22 7,22 4,21 3,19 3,16 4,14 10,10 12,8 13,6 13,4 12,2 10,1 8,2 7,4 7,6 8,9 10,12 15,19 17,21 20,22 21,22 22,21 22,20 M7,22 L5,21 4,19 4,16 5,14 7,12 M7,6 L8,8 16,19 18,21 20,22",
                "o": 13
            },
            {
                "d": "M4,1 L3,8 M5,1 L3,8",
                "o": 4
            },
            {
                "d": "M11,-3 L9,-1 7,2 5,6 4,11 4,15 5,20 7,24 9,27 11,29 M9,-1 L7,3 6,6 5,11 5,15 6,20 7,23 9,27",
                "o": 7
            },
            {
                "d": "M3,-3 L5,-1 7,2 9,6 10,11 10,15 9,20 7,24 5,27 3,29 M5,-1 L7,3 8,6 9,11 9,15 8,20 7,23 5,27",
                "o": 7
            },
            {
                "d": "M8,7 L8,19 M3,10 L13,16 M13,10 L3,16",
                "o": 8
            },
            {
                "d": "M13,4 L13,22 M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M20,-3 L2,29",
                "o": 11
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1 M9,1 L7,2 6,3 5,5 4,10 4,13 5,18 6,20 7,21 9,22 M11,22 L13,21 14,20 15,18 16,13 16,10 15,5 14,3 13,2 11,1",
                "o": 10
            },
            {
                "d": "M6,5 L8,4 11,1 11,22 M10,2 L10,22 M6,22 L15,22",
                "o": 10
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 8,1 12,1 15,2 16,3 17,5 17,7 16,9 13,11 8,13 6,14 4,16 3,19 3,22 M12,1 L14,2 15,3 16,5 16,7 15,9 12,11 8,13 M3,20 L4,19 6,19 11,21 14,21 16,20 17,19 M6,19 L11,22 15,22 16,21 17,19 17,17",
                "o": 10
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 8,1 12,1 15,2 16,4 16,7 15,9 12,10 9,10 M12,1 L14,2 15,4 15,7 14,9 12,10 M12,10 L14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M15,12 L16,15 16,18 15,20 14,21 12,22",
                "o": 10
            },
            {
                "d": "M12,3 L12,22 M13,1 L13,22 M13,1 L2,16 18,16 M9,22 L16,22",
                "o": 10
            },
            {
                "d": "M5,1 L3,11 M3,11 L5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18 3,17 4,16 5,17 4,18 M11,8 L13,9 15,11 16,14 16,16 15,19 13,21 11,22 M5,1 L15,1 M5,2 L10,2 15,1",
                "o": 10
            },
            {
                "d": "M15,4 L14,5 15,6 16,5 16,4 15,2 13,1 10,1 7,2 5,4 4,6 3,10 3,16 4,19 6,21 9,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 10,9 7,10 5,12 4,15 M10,1 L8,2 6,4 5,6 4,10 4,16 5,19 7,21 9,22 M11,22 L13,21 15,19 16,16 16,15 15,12 13,10 11,9",
                "o": 10
            },
            {
                "d": "M3,1 L3,7 M3,5 L4,3 6,1 8,1 13,4 15,4 16,3 17,1 M4,3 L6,2 8,2 13,4 M17,1 L17,4 16,7 12,12 11,14 10,17 10,22 M16,7 L11,12 10,14 9,17 9,22",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,7 5,9 8,10 12,10 15,9 16,7 16,4 15,2 12,1 8,1 M8,1 L6,2 5,4 5,7 6,9 8,10 M12,10 L14,9 15,7 15,4 14,2 12,1 M8,10 L5,11 4,12 3,14 3,18 4,20 5,21 8,22 12,22 15,21 16,20 17,18 17,14 16,12 15,11 12,10 M8,10 L6,11 5,12 4,14 4,18 5,20 6,21 8,22 M12,22 L14,21 15,20 16,18 16,14 15,12 14,11 12,10",
                "o": 10
            },
            {
                "d": "M16,8 L15,11 13,13 10,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 11,1 14,2 16,4 17,7 17,13 16,17 15,19 13,21 10,22 7,22 5,21 4,19 4,18 5,17 6,18 5,19 M9,14 L7,13 5,11 4,8 4,7 5,4 7,2 9,1 M11,1 L13,2 15,4 16,7 16,13 15,17 14,19 12,21 10,22",
                "o": 10
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M4,17 L3,18 4,19 5,18 4,17",
                "o": 4
            },
            {
                "d": "M4,10 L3,11 4,12 5,11 4,10 M5,18 L4,19 3,18 4,17 5,18 5,20 3,22",
                "o": 4
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,10 L22,10 M4,16 L22,16",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M4,5 L5,6 4,7 3,6 3,5 4,3 5,2 7,1 10,1 13,2 14,3 15,5 15,7 14,9 13,10 9,12 9,15 M10,1 L12,2 13,3 14,5 14,7 13,9 11,11 M9,20 L8,21 9,22 10,21 9,20",
                "o": 9
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M10,1 L3,22 M10,1 L17,22 M10,4 L16,22 M5,16 L14,16 M1,22 L7,22 M13,22 L19,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L14,1 17,2 18,3 19,5 19,7 18,9 17,10 14,11 M14,1 L16,2 17,3 18,5 18,7 17,9 16,10 14,11 M6,11 L14,11 17,12 18,13 19,15 19,18 18,20 17,21 14,22 2,22 M14,11 L16,12 17,13 18,15 18,18 17,20 16,21 14,22",
                "o": 11
            },
            {
                "d": "M17,4 L18,7 18,1 17,4 15,2 12,1 10,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 M10,1 L8,2 6,4 5,6 4,9 4,14 5,17 6,19 8,21 10,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L12,1 15,2 17,4 18,6 19,9 19,14 18,17 17,19 15,21 12,22 2,22 M12,1 L14,2 16,4 17,6 18,9 18,14 17,17 16,19 14,21 12,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M12,7 L12,15 M2,1 L18,1 18,7 17,1 M6,11 L12,11 M2,22 L18,22 18,16 17,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M12,7 L12,15 M2,1 L18,1 18,7 17,1 M6,11 L12,11 M2,22 L9,22",
                "o": 9
            },
            {
                "d": "M17,4 L18,7 18,1 17,4 15,2 12,1 10,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 10,22 12,22 15,21 17,19 M10,1 L8,2 6,4 5,6 4,9 4,14 5,17 6,19 8,21 10,22 M17,14 L17,22 M18,14 L18,22 M14,14 L21,14",
                "o": 12
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M18,1 L18,22 M19,1 L19,22 M2,1 L9,1 M15,1 L22,1 M6,11 L18,11 M2,22 L9,22 M15,22 L22,22",
                "o": 12
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L9,1 M2,22 L9,22",
                "o": 6
            },
            {
                "d": "M10,1 L10,18 9,21 7,22 5,22 3,21 2,19 2,17 3,16 4,17 3,18 M9,1 L9,18 8,21 7,22 M6,1 L13,1",
                "o": 8
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M19,1 L6,14 M11,10 L19,22 M10,10 L18,22 M2,1 L9,1 M15,1 L21,1 M2,22 L9,22 M15,22 L21,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L9,1 M2,22 L17,22 17,16 16,22",
                "o": 9
            },
            {
                "d": "M5,1 L5,22 M6,1 L12,19 M5,1 L12,22 M19,1 L12,22 M19,1 L19,22 M20,1 L20,22 M2,1 L6,1 M19,1 L23,1 M2,22 L8,22 M16,22 L23,22",
                "o": 13
            },
            {
                "d": "M5,1 L5,22 M6,1 L18,20 M6,3 L18,22 M18,1 L18,22 M2,1 L6,1 M15,1 L21,1 M2,22 L8,22",
                "o": 12
            },
            {
                "d": "M10,1 L7,2 5,4 4,6 3,10 3,13 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 19,13 19,10 18,6 17,4 15,2 12,1 10,1 M10,1 L8,2 6,4 5,6 4,10 4,13 5,17 6,19 8,21 10,22 M12,22 L14,21 16,19 17,17 18,13 18,10 17,6 16,4 14,2 12,1",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L14,1 17,2 18,3 19,5 19,8 18,10 17,11 14,12 6,12 M14,1 L16,2 17,3 18,5 18,8 17,10 16,11 14,12 M2,22 L9,22",
                "o": 11
            },
            {
                "d": "M10,1 L7,2 5,4 4,6 3,10 3,13 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 19,13 19,10 18,6 17,4 15,2 12,1 10,1 M10,1 L8,2 6,4 5,6 4,10 4,13 5,17 6,19 8,21 10,22 M12,22 L14,21 16,19 17,17 18,13 18,10 17,6 16,4 14,2 12,1 M7,20 L7,19 8,17 10,16 11,16 13,17 14,19 15,26 16,27 18,27 19,25 19,24 M14,19 L15,23 16,25 17,26 18,26 19,25",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L14,1 17,2 18,3 19,5 19,7 18,9 17,10 14,11 6,11 M14,1 L16,2 17,3 18,5 18,7 17,9 16,10 14,11 M2,22 L9,22 M11,11 L13,12 14,13 17,20 18,21 19,21 20,20 M13,12 L14,14 16,21 17,22 19,22 20,20 20,19",
                "o": 11
            },
            {
                "d": "M16,4 L17,1 17,7 16,4 14,2 11,1 8,1 5,2 3,4 3,6 4,8 5,9 7,10 13,12 15,13 17,15 M3,6 L5,8 7,9 13,11 15,12 16,13 17,15 17,19 15,21 12,22 9,22 6,21 4,19 3,16 3,22 4,19",
                "o": 10
            },
            {
                "d": "M9,1 L9,22 M10,1 L10,22 M3,1 L2,7 2,1 17,1 17,7 16,1 M6,22 L13,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,16 6,19 8,21 11,22 13,22 16,21 18,19 19,16 19,1 M6,1 L6,16 7,19 9,21 11,22 M2,1 L9,1 M16,1 L22,1",
                "o": 12
            },
            {
                "d": "M3,1 L10,22 M4,1 L10,19 M17,1 L10,22 M1,1 L7,1 M13,1 L19,1",
                "o": 10
            },
            {
                "d": "M4,1 L8,22 M5,1 L8,17 M12,1 L8,22 M12,1 L16,22 M13,1 L16,17 M20,1 L16,22 M1,1 L8,1 M17,1 L23,1",
                "o": 12
            },
            {
                "d": "M3,1 L16,22 M4,1 L17,22 M17,1 L3,22 M1,1 L7,1 M13,1 L19,1 M1,22 L7,22 M13,22 L19,22",
                "o": 10
            },
            {
                "d": "M3,1 L10,12 10,22 M4,1 L11,12 11,22 M18,1 L11,12 M1,1 L7,1 M14,1 L20,1 M7,22 L14,22",
                "o": 11
            },
            {
                "d": "M16,1 L3,22 M17,1 L4,22 M4,1 L3,7 3,1 17,1 M3,22 L17,22 17,16 16,22",
                "o": 10
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M3,15 L11,10 19,15 M3,15 L11,11 19,15",
                "o": 11
            },
            {
                "d": "M0,29 L20,29",
                "o": 10
            },
            {
                "d": "M4,1 L9,7 M4,1 L3,2 9,7",
                "o": 6
            },
            {
                "d": "M5,10 L5,11 4,11 4,10 5,9 7,8 11,8 13,9 14,10 15,12 15,19 16,21 17,22 M14,10 L14,19 15,21 17,22 18,22 M14,12 L13,13 7,14 4,15 3,17 3,19 4,21 7,22 10,22 12,21 14,19 M7,14 L5,15 4,17 4,19 5,21 7,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M6,11 L8,9 10,8 12,8 15,9 17,11 18,14 18,16 17,19 15,21 12,22 10,22 8,21 6,19 M12,8 L14,9 16,11 17,14 17,16 16,19 14,21 12,22 M2,1 L6,1",
                "o": 10
            },
            {
                "d": "M15,11 L14,12 15,13 16,12 16,11 14,9 12,8 9,8 6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 14,21 16,19 M9,8 L7,9 5,11 4,14 4,16 5,19 7,21 9,22",
                "o": 9
            },
            {
                "d": "M15,1 L15,22 M16,1 L16,22 M15,11 L13,9 11,8 9,8 6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 13,21 15,19 M9,8 L7,9 5,11 4,14 4,16 5,19 7,21 9,22 M12,1 L16,1 M15,22 L19,22",
                "o": 11
            },
            {
                "d": "M4,14 L16,14 16,12 15,10 14,9 12,8 9,8 6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 14,21 16,19 M15,14 L15,11 14,9 M9,8 L7,9 5,11 4,14 4,16 5,19 7,21 9,22",
                "o": 9
            },
            {
                "d": "M10,2 L9,3 10,4 11,3 11,2 10,1 8,1 6,2 5,4 5,22 M8,1 L7,2 6,4 6,22 M2,8 L10,8 M2,22 L9,22",
                "o": 6
            },
            {
                "d": "M8,8 L6,9 5,10 4,12 4,14 5,16 6,17 8,18 10,18 12,17 13,16 14,14 14,12 13,10 12,9 10,8 8,8 M6,9 L5,11 5,15 6,17 M12,17 L13,15 13,11 12,9 M13,10 L14,9 16,8 16,9 14,9 M5,16 L4,17 3,19 3,20 4,22 7,23 12,23 15,24 16,25 M3,20 L4,21 7,22 12,22 15,23 16,25 16,26 15,28 12,29 6,29 3,28 2,26 2,25 3,23 6,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M6,11 L8,9 11,8 13,8 16,9 17,11 17,22 M13,8 L15,9 16,11 16,22 M2,1 L6,1 M2,22 L9,22 M13,22 L20,22",
                "o": 11
            },
            {
                "d": "M5,1 L4,2 5,3 6,2 5,1 M5,8 L5,22 M6,8 L6,22 M2,8 L6,8 M2,22 L9,22",
                "o": 6
            },
            {
                "d": "M6,1 L5,2 6,3 7,2 6,1 M7,8 L7,26 6,28 4,29 2,29 1,28 1,27 2,26 3,27 2,28 M6,8 L6,26 5,28 4,29 M3,8 L7,8",
                "o": 6
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M16,8 L6,18 M11,14 L17,22 M10,14 L16,22 M2,1 L6,1 M13,8 L19,8 M2,22 L9,22 M13,22 L19,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,1 L6,22 M2,1 L6,1 M2,22 L9,22",
                "o": 6
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M6,11 L8,9 11,8 13,8 16,9 17,11 17,22 M13,8 L15,9 16,11 16,22 M17,11 L19,9 22,8 24,8 27,9 28,11 28,22 M24,8 L26,9 27,11 27,22 M2,8 L6,8 M2,22 L9,22 M13,22 L20,22 M24,22 L31,22",
                "o": 17
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M6,11 L8,9 11,8 13,8 16,9 17,11 17,22 M13,8 L15,9 16,11 16,22 M2,8 L6,8 M2,22 L9,22 M13,22 L20,22",
                "o": 11
            },
            {
                "d": "M9,8 L6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 14,21 16,19 17,16 17,14 16,11 14,9 11,8 9,8 M9,8 L7,9 5,11 4,14 4,16 5,19 7,21 9,22 M11,22 L13,21 15,19 16,16 16,14 15,11 13,9 11,8",
                "o": 10
            },
            {
                "d": "M5,8 L5,29 M6,8 L6,29 M6,11 L8,9 10,8 12,8 15,9 17,11 18,14 18,16 17,19 15,21 12,22 10,22 8,21 6,19 M12,8 L14,9 16,11 17,14 17,16 16,19 14,21 12,22 M2,8 L6,8 M2,29 L9,29",
                "o": 10
            },
            {
                "d": "M15,8 L15,29 M16,8 L16,29 M15,11 L13,9 11,8 9,8 6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 13,21 15,19 M9,8 L7,9 5,11 4,14 4,16 5,19 7,21 9,22 M12,29 L19,29",
                "o": 10
            },
            {
                "d": "M5,8 L5,22 M6,8 L6,22 M6,14 L7,11 9,9 11,8 14,8 15,9 15,10 14,11 13,10 14,9 M2,8 L6,8 M2,22 L9,22",
                "o": 8
            },
            {
                "d": "M13,10 L14,8 14,12 13,10 12,9 10,8 6,8 4,9 3,10 3,12 4,13 6,14 11,16 13,17 14,18 M3,11 L4,12 6,13 11,15 13,16 14,17 14,20 13,21 11,22 7,22 5,21 4,20 3,18 3,22 4,20",
                "o": 9
            },
            {
                "d": "M5,1 L5,18 6,21 8,22 10,22 12,21 13,19 M6,1 L6,18 7,21 8,22 M2,8 L10,8",
                "o": 8
            },
            {
                "d": "M5,8 L5,19 6,21 9,22 11,22 14,21 16,19 M6,8 L6,19 7,21 9,22 M16,8 L16,22 M17,8 L17,22 M2,8 L6,8 M13,8 L17,8 M16,22 L20,22",
                "o": 11
            },
            {
                "d": "M3,8 L9,22 M4,8 L9,20 M15,8 L9,22 M1,8 L7,8 M11,8 L17,8",
                "o": 9
            },
            {
                "d": "M4,8 L8,22 M5,8 L8,19 M12,8 L8,22 M12,8 L16,22 M13,8 L16,19 M20,8 L16,22 M1,8 L8,8 M17,8 L23,8",
                "o": 12
            },
            {
                "d": "M4,8 L15,22 M5,8 L16,22 M16,8 L4,22 M2,8 L8,8 M12,8 L18,8 M2,22 L8,22 M12,22 L18,22",
                "o": 10
            },
            {
                "d": "M4,8 L10,22 M5,8 L10,20 M16,8 L10,22 8,26 6,28 4,29 3,29 2,28 3,27 4,28 M2,8 L8,8 M12,8 L18,8",
                "o": 9
            },
            {
                "d": "M14,8 L3,22 M15,8 L4,22 M4,8 L3,12 3,8 15,8 M3,22 L15,22 15,18 14,22",
                "o": 9
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    },
    "timesrb": {
        "name": "Serif bold",
        "chars": [
            {
                "d": "M5,1 L4,2 4,4 5,12 M5,1 L5,15 6,15 M5,1 L6,1 6,15 M6,1 L7,2 7,4 6,12 M5,19 L4,20 4,21 5,22 6,22 7,21 7,20 6,19 5,19 M5,20 L5,21 6,21 6,20 5,20",
                "o": 6
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8 M14,1 L13,2 13,8 M14,2 L13,8 M14,1 L15,2 13,8",
                "o": 9
            },
            {
                "d": "M11,-3 L4,29 M17,-3 L10,29 M4,10 L18,10 M3,16 L17,16",
                "o": 11
            },
            {
                "d": "M8,-3 L8,26 M12,-3 L12,26 M16,6 L16,5 15,5 15,7 17,7 17,5 16,3 15,2 12,1 8,1 5,2 3,4 3,7 4,9 7,11 13,13 15,14 16,16 16,19 15,21 M4,7 L5,9 7,10 13,12 15,13 16,15 M5,2 L4,4 4,6 5,8 7,9 13,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,16 5,16 5,18 4,18 4,17",
                "o": 10
            },
            {
                "d": "M21,1 L3,22 M8,1 L10,3 10,5 9,7 7,8 5,8 3,6 3,4 4,2 6,1 8,1 10,2 13,3 16,3 19,2 21,1 M17,15 L15,16 14,18 14,20 16,22 18,22 20,21 21,19 21,17 19,15 17,15",
                "o": 12
            },
            {
                "d": "M22,10 L22,9 21,9 21,11 23,11 23,9 22,8 21,8 20,9 19,11 17,16 15,19 13,21 11,22 7,22 5,21 4,19 4,16 5,14 11,10 13,8 14,6 14,4 13,2 11,1 9,2 8,4 8,7 9,10 11,13 15,18 18,21 20,22 22,22 23,20 23,19 M6,21 L5,19 5,16 6,14 7,13 M13,8 L14,4 M14,6 L13,2 M9,2 L8,6 M9,9 L11,12 15,17 18,20 20,21 M9,22 L7,21 6,19 6,16 7,14 11,10 M8,4 L9,8 12,12 16,17 19,20 21,21 22,21 23,20",
                "o": 13
            },
            {
                "d": "M5,1 L4,2 4,8 M5,2 L4,8 M5,1 L6,2 4,8",
                "o": 5
            },
            {
                "d": "M10,-3 L8,-1 6,2 4,6 3,11 3,15 4,20 6,24 8,27 10,29 M6,3 L5,6 4,10 4,16 5,20 6,23 M8,-1 L7,1 6,4 5,10 5,16 6,22 7,25 8,27",
                "o": 7
            },
            {
                "d": "M4,-3 L6,-1 8,2 10,6 11,11 11,15 10,20 8,24 6,27 4,29 M8,3 L9,6 10,10 10,16 9,20 8,23 M6,-1 L7,1 8,4 9,10 9,16 8,22 7,25 6,27",
                "o": 7
            },
            {
                "d": "M8,1 L7,2 9,12 8,13 M8,1 L8,13 M8,1 L9,2 7,12 8,13 M3,4 L4,4 12,10 13,10 M3,4 L13,10 M3,4 L3,5 13,9 13,10 M13,4 L12,4 4,10 3,10 M13,4 L3,10 M13,4 L13,5 3,9 3,10",
                "o": 8
            },
            {
                "d": "M12,4 L12,21 13,21 M12,4 L13,4 13,21 M4,12 L21,12 21,13 M4,12 L4,13 21,13",
                "o": 13
            },
            {
                "d": "M7,21 L6,22 5,22 4,21 4,20 5,19 6,19 7,20 7,23 6,25 4,26 M5,20 L5,21 6,21 6,20 5,20 M6,22 L7,23 M7,21 L6,25",
                "o": 6
            },
            {
                "d": "M4,13 L22,13",
                "o": 13
            },
            {
                "d": "M5,19 L4,20 4,21 5,22 6,22 7,21 7,20 6,19 5,19 M5,20 L5,21 6,21 6,20 5,20",
                "o": 6
            },
            {
                "d": "M20,-3 L2,29 3,29 M20,-3 L21,-3 3,29",
                "o": 12
            },
            {
                "d": "M9,1 L6,2 4,5 3,10 3,13 4,18 6,21 9,22 11,22 14,21 16,18 17,13 17,10 16,5 14,2 11,1 9,1 M6,3 L5,5 4,9 4,14 5,18 6,20 M14,20 L15,18 16,14 16,9 15,5 14,3 M9,1 L7,2 6,4 5,9 5,14 6,19 7,21 9,22 M11,22 L13,21 14,19 15,14 15,9 14,4 13,2 11,1",
                "o": 10
            },
            {
                "d": "M9,3 L9,22 M10,3 L10,21 M11,1 L11,22 M11,1 L8,4 6,5 M5,22 L15,22 M9,21 L7,22 M9,20 L8,22 M11,20 L12,22 M11,21 L13,22",
                "o": 10
            },
            {
                "d": "M4,5 L4,6 5,6 5,5 4,5 M4,4 L5,4 6,5 6,6 5,7 4,7 3,6 3,5 4,3 5,2 8,1 12,1 15,2 16,3 17,5 17,7 16,9 13,11 8,13 6,14 4,16 3,19 3,22 M15,3 L16,5 16,7 15,9 M12,1 L14,2 15,5 15,7 14,9 12,11 8,13 M3,20 L4,19 6,19 11,20 15,20 17,19 M6,19 L11,21 15,21 16,20 M6,19 L11,22 15,22 16,21 17,19 17,17",
                "o": 10
            },
            {
                "d": "M4,5 L4,6 5,6 5,5 4,5 M4,4 L5,4 6,5 6,6 5,7 4,7 3,6 3,5 4,3 5,2 8,1 12,1 15,2 16,4 16,7 15,9 12,10 M14,2 L15,4 15,7 14,9 M11,1 L13,2 14,4 14,7 13,9 11,10 M9,10 L12,10 14,11 16,13 17,15 17,18 16,20 15,21 12,22 8,22 5,21 4,20 3,18 3,17 4,16 5,16 6,17 6,18 5,19 4,19 M15,13 L16,15 16,18 15,20 M11,10 L13,11 14,12 15,15 15,18 14,21 12,22 M4,17 L4,18 5,18 5,17 4,17",
                "o": 10
            },
            {
                "d": "M11,4 L11,22 M12,3 L12,21 M13,1 L13,22 M13,1 L2,16 18,16 M8,22 L16,22 M11,21 L9,22 M11,20 L10,22 M13,20 L14,22 M13,21 L15,22",
                "o": 10
            },
            {
                "d": "M5,1 L3,11 5,9 8,8 11,8 14,9 16,11 17,14 17,16 16,19 14,21 11,22 8,22 5,21 4,20 3,18 3,17 4,16 5,16 6,17 6,18 5,19 4,19 M15,11 L16,13 16,17 15,19 M11,8 L13,9 14,10 15,13 15,17 14,20 13,21 11,22 M4,17 L4,18 5,18 5,17 4,17 M5,1 L15,1 M5,2 L13,2 M5,3 L9,3 13,2 15,1",
                "o": 10
            },
            {
                "d": "M14,4 L14,5 15,5 15,4 14,4 M15,3 L14,3 13,4 13,5 14,6 15,6 16,5 16,4 15,2 13,1 10,1 7,2 5,4 4,6 3,10 3,16 4,19 6,21 9,22 11,22 14,21 16,19 17,16 17,15 16,12 14,10 11,9 9,9 7,10 6,11 5,13 M6,4 L5,6 4,10 4,16 5,19 6,20 M15,19 L16,17 16,14 15,12 M10,1 L8,2 7,3 6,5 5,9 5,16 6,19 7,21 9,22 M11,22 L13,21 14,20 15,17 15,14 14,11 13,10 11,9",
                "o": 10
            },
            {
                "d": "M3,1 L3,7 M17,1 L17,4 16,7 12,12 11,14 10,18 10,22 M11,13 L10,15 9,18 9,22 M16,7 L11,12 9,15 8,18 8,22 10,22 M3,5 L4,3 6,1 8,1 13,4 15,4 16,3 17,1 M5,3 L6,2 8,2 10,3 M3,5 L4,4 6,3 8,3 13,4",
                "o": 10
            },
            {
                "d": "M8,1 L5,2 4,4 4,7 5,9 8,10 12,10 15,9 16,7 16,4 15,2 12,1 8,1 M6,2 L5,4 5,7 6,9 M14,9 L15,7 15,4 14,2 M8,1 L7,2 6,4 6,7 7,9 8,10 M12,10 L13,9 14,7 14,4 13,2 12,1 M8,10 L5,11 4,12 3,14 3,18 4,20 5,21 8,22 12,22 15,21 16,20 17,18 17,14 16,12 15,11 12,10 M5,12 L4,14 4,18 5,20 M15,20 L16,18 16,14 15,12 M8,10 L6,11 5,14 5,18 6,21 8,22 M12,22 L14,21 15,18 15,14 14,11 12,10",
                "o": 10
            },
            {
                "d": "M5,18 L5,19 6,19 6,18 5,18 M15,10 L14,12 13,13 11,14 9,14 6,13 4,11 3,8 3,7 4,4 6,2 9,1 11,1 14,2 16,4 17,7 17,13 16,17 15,19 13,21 10,22 7,22 5,21 4,19 4,18 5,17 6,17 7,18 7,19 6,20 5,20 M5,11 L4,9 4,6 5,4 M14,3 L15,4 16,7 16,13 15,17 14,19 M9,14 L7,13 6,12 5,9 5,6 6,3 7,2 9,1 M11,1 L13,2 14,4 15,7 15,14 14,18 13,20 12,21 10,22",
                "o": 10
            },
            {
                "d": "M5,8 L4,9 4,10 5,11 6,11 7,10 7,9 6,8 5,8 M5,9 L5,10 6,10 6,9 5,9 M5,19 L4,20 4,21 5,22 6,22 7,21 7,20 6,19 5,19 M5,20 L5,21 6,21 6,20 5,20",
                "o": 6
            },
            {
                "d": "M5,8 L4,9 4,10 5,11 6,11 7,10 7,9 6,8 5,8 M5,9 L5,10 6,10 6,9 5,9 M7,21 L6,22 5,22 4,21 4,20 5,19 6,19 7,20 7,23 6,25 4,26 M5,20 L5,21 6,21 6,20 5,20 M6,22 L7,23 M7,21 L6,25",
                "o": 6
            },
            {
                "d": "M20,4 L4,13 20,22",
                "o": 12
            },
            {
                "d": "M4,8 L21,8 21,9 M4,8 L4,9 21,9 M4,16 L21,16 21,17 M4,16 L4,17 21,17",
                "o": 13
            },
            {
                "d": "M4,4 L20,13 4,22",
                "o": 12
            },
            {
                "d": "M4,6 L4,5 5,5 5,7 3,7 3,5 4,3 5,2 7,1 11,1 14,2 15,3 16,5 16,7 15,9 14,10 10,12 M14,3 L15,4 15,8 14,9 M11,1 L13,2 14,4 14,8 13,10 12,11 M9,12 L9,15 10,15 10,12 9,12 M9,19 L8,20 8,21 9,22 10,22 11,21 11,20 10,19 9,19 M9,20 L9,21 10,21 10,20 9,20",
                "o": 10
            },
            {
                "d": "M18,9 L17,7 15,6 12,6 10,7 9,8 8,11 8,14 9,16 11,17 14,17 16,16 17,14 M12,6 L10,8 9,11 9,14 10,16 11,17 M18,6 L17,14 17,16 19,17 21,17 23,15 24,12 24,10 23,7 22,5 20,3 18,2 15,1 12,1 9,2 7,3 5,5 4,7 3,10 3,13 4,16 5,18 7,20 9,21 12,22 15,22 18,21 20,20 21,19 M19,6 L18,14 18,16 19,17",
                "o": 14
            },
            {
                "d": "M10,1 L3,21 M9,4 L15,22 M10,4 L16,22 M10,1 L17,22 M5,16 L14,16 M1,22 L7,22 M12,22 L19,22 M3,21 L2,22 M3,21 L5,22 M15,21 L13,22 M15,20 L14,22 M16,20 L18,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L14,1 17,2 18,3 19,5 19,7 18,9 17,10 14,11 M17,3 L18,5 18,7 17,9 M14,1 L16,2 17,4 17,8 16,10 14,11 M7,11 L14,11 17,12 18,13 19,15 19,18 18,20 17,21 14,22 2,22 M17,13 L18,15 18,18 17,20 M14,11 L16,12 17,14 17,19 16,21 14,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 11
            },
            {
                "d": "M17,4 L18,1 18,7 17,4 15,2 13,1 10,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 10,22 13,22 15,21 17,19 18,17 M6,4 L5,6 4,9 4,14 5,17 6,19 M10,1 L8,2 6,5 5,9 5,14 6,18 8,21 10,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L12,1 15,2 17,4 18,6 19,9 19,14 18,17 17,19 15,21 12,22 2,22 M16,4 L17,6 18,9 18,14 17,17 16,19 M12,1 L14,2 16,5 17,9 17,14 16,18 14,21 12,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L18,1 18,7 M7,11 L13,11 M13,7 L13,15 M2,22 L18,22 18,16 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M13,1 L18,2 M15,1 L18,3 M16,1 L18,4 M17,1 L18,7 M13,7 L12,11 13,15 M13,9 L11,11 13,13 M13,10 L9,11 13,12 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M13,22 L18,21 M15,22 L18,20 M16,22 L18,19 M17,22 L18,16",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L18,1 18,7 M7,11 L13,11 M13,7 L13,15 M2,22 L10,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M13,1 L18,2 M15,1 L18,3 M16,1 L18,4 M17,1 L18,7 M13,7 L12,11 13,15 M13,9 L11,11 13,13 M13,10 L9,11 13,12 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 9
            },
            {
                "d": "M17,4 L18,1 18,7 17,4 15,2 13,1 10,1 7,2 5,4 4,6 3,9 3,14 4,17 5,19 7,21 10,22 13,22 15,21 17,21 18,22 18,14 M6,4 L5,6 4,9 4,14 5,17 6,19 M10,1 L8,2 6,5 5,9 5,14 6,18 8,21 10,22 M17,15 L17,20 M16,14 L16,20 15,21 M13,14 L21,14 M14,14 L16,15 M15,14 L16,16 M19,14 L18,16 M20,14 L18,15",
                "o": 12
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M17,1 L17,22 M18,2 L18,21 M19,1 L19,22 M2,1 L10,1 M14,1 L22,1 M7,11 L17,11 M2,22 L10,22 M14,22 L22,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M15,1 L17,2 M16,1 L17,3 M20,1 L19,3 M21,1 L19,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M17,21 L15,22 M17,20 L16,22 M19,20 L20,22 M19,21 L21,22",
                "o": 12
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L10,1 M2,22 L10,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 6
            },
            {
                "d": "M9,1 L9,18 8,21 7,22 M10,2 L10,18 9,21 M11,1 L11,18 10,21 7,22 5,22 3,21 2,19 2,17 3,16 4,16 5,17 5,18 4,19 3,19 M3,17 L3,18 4,18 4,17 3,17 M6,1 L14,1 M7,1 L9,2 M8,1 L9,3 M12,1 L11,3 M13,1 L11,2",
                "o": 8
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M18,2 L7,13 M10,11 L17,22 M11,11 L18,22 M11,9 L19,22 M2,1 L10,1 M15,1 L21,1 M2,22 L10,22 M14,22 L21,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M17,1 L18,2 M20,1 L18,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M17,20 L15,22 M17,20 L20,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L10,1 M2,22 L17,22 17,16 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M12,22 L17,21 M14,22 L17,20 M15,22 L17,19 M16,22 L17,16",
                "o": 9
            },
            {
                "d": "M5,1 L5,21 M5,1 L12,22 M6,1 L12,19 M7,1 L13,19 M19,1 L12,22 M19,1 L19,22 M20,2 L20,21 M21,1 L21,22 M2,1 L7,1 M19,1 L24,1 M2,22 L8,22 M16,22 L24,22 M3,1 L5,2 M22,1 L21,3 M23,1 L21,2 M5,21 L3,22 M5,21 L7,22 M19,21 L17,22 M19,20 L18,22 M21,20 L22,22 M21,21 L23,22",
                "o": 13
            },
            {
                "d": "M5,1 L5,21 M5,1 L19,22 M6,1 L18,19 M7,1 L19,19 M19,2 L19,22 M2,1 L7,1 M16,1 L22,1 M2,22 L8,22 M3,1 L5,2 M17,1 L19,2 M21,1 L19,2 M5,21 L3,22 M5,21 L7,22",
                "o": 12
            },
            {
                "d": "M10,1 L7,2 5,4 4,6 3,10 3,13 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 19,13 19,10 18,6 17,4 15,2 12,1 10,1 M6,4 L5,6 4,9 4,14 5,17 6,19 M16,19 L17,17 18,14 18,9 17,6 16,4 M10,1 L8,2 6,5 5,9 5,14 6,18 8,21 10,22 M12,22 L14,21 16,18 17,14 17,9 16,5 14,2 12,1",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L14,1 17,2 18,3 19,5 19,8 18,10 17,11 14,12 7,12 M17,3 L18,5 18,8 17,10 M14,1 L16,2 17,4 17,9 16,11 14,12 M2,22 L10,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 11
            },
            {
                "d": "M10,1 L7,2 5,4 4,6 3,10 3,13 4,17 5,19 7,21 10,22 12,22 15,21 17,19 18,17 19,13 19,10 18,6 17,4 15,2 12,1 10,1 M6,4 L5,6 4,9 4,14 5,17 6,19 M16,19 L17,17 18,14 18,9 17,6 16,4 M10,1 L8,2 6,5 5,9 5,14 6,18 8,21 10,22 M12,22 L14,21 16,18 17,14 17,9 16,5 14,2 12,1 M7,19 L8,17 10,16 11,16 13,17 14,19 15,25 16,27 18,27 19,25 19,23 M15,23 L16,25 17,26 18,26 M14,19 L16,24 17,25 18,25 19,24",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M7,1 L7,22 M2,1 L14,1 17,2 18,3 19,5 19,7 18,9 17,10 14,11 7,11 M17,3 L18,5 18,7 17,9 M14,1 L16,2 17,4 17,8 16,10 14,11 M11,11 L13,12 14,14 16,20 17,22 19,22 20,20 20,18 M16,18 L17,20 18,21 19,21 M13,12 L14,13 17,19 18,20 19,20 20,19 M2,22 L10,22 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 11
            },
            {
                "d": "M16,4 L17,1 17,7 16,4 14,2 11,1 8,1 5,2 3,4 3,7 4,9 7,11 13,13 15,14 16,16 16,19 15,21 M4,7 L5,9 7,10 13,12 15,13 16,15 M5,2 L4,4 4,6 5,8 7,9 13,11 16,13 17,15 17,18 16,20 15,21 12,22 9,22 6,21 4,19 3,16 3,22 4,19",
                "o": 10
            },
            {
                "d": "M2,1 L2,7 M9,1 L9,22 M10,2 L10,21 M11,1 L11,22 M18,1 L18,7 M2,1 L18,1 M6,22 L14,22 M3,1 L2,7 M4,1 L2,4 M5,1 L2,3 M7,1 L2,2 M13,1 L18,2 M15,1 L18,3 M16,1 L18,4 M17,1 L18,7 M9,21 L7,22 M9,20 L8,22 M11,20 L12,22 M11,21 L13,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,16 6,19 8,21 11,22 13,22 16,21 18,19 19,16 19,2 M6,2 L6,17 7,19 M7,1 L7,17 8,20 9,21 11,22 M2,1 L10,1 M16,1 L22,1 M3,1 L5,2 M4,1 L5,3 M8,1 L7,3 M9,1 L7,2 M17,1 L19,2 M21,1 L19,2",
                "o": 12
            },
            {
                "d": "M3,1 L10,22 M4,1 L10,19 10,22 M5,1 L11,19 M17,2 L10,22 M1,1 L8,1 M13,1 L19,1 M2,1 L4,3 M6,1 L5,3 M7,1 L5,2 M15,1 L17,2 M18,1 L17,2",
                "o": 10
            },
            {
                "d": "M4,1 L8,22 M5,1 L8,17 8,22 M6,1 L9,17 M12,1 L9,17 8,22 M12,1 L16,22 M13,1 L16,17 16,22 M14,1 L17,17 M20,2 L17,17 16,22 M1,1 L9,1 M12,1 L14,1 M17,1 L23,1 M2,1 L5,2 M3,1 L5,3 M7,1 L6,3 M8,1 L6,2 M18,1 L20,2 M22,1 L20,2",
                "o": 12
            },
            {
                "d": "M3,1 L15,22 M4,1 L16,22 M5,1 L17,22 M16,2 L4,21 M1,1 L8,1 M13,1 L19,1 M1,22 L7,22 M12,22 L19,22 M2,1 L5,3 M6,1 L5,3 M7,1 L5,2 M14,1 L16,2 M18,1 L16,2 M4,21 L2,22 M4,21 L6,22 M15,21 L13,22 M15,20 L14,22 M15,20 L18,22",
                "o": 10
            },
            {
                "d": "M3,1 L10,12 10,22 M4,1 L11,12 11,21 M5,1 L12,12 12,22 M18,2 L12,12 M1,1 L8,1 M15,1 L21,1 M7,22 L15,22 M2,1 L4,2 M7,1 L5,2 M16,1 L18,2 M20,1 L18,2 M10,21 L8,22 M10,20 L9,22 M12,20 L13,22 M12,21 L14,22",
                "o": 11
            },
            {
                "d": "M17,1 L3,1 3,7 M15,1 L3,22 M16,1 L4,22 M17,1 L5,22 M3,22 L17,22 17,16 M4,1 L3,7 M5,1 L3,4 M6,1 L3,3 M8,1 L3,2 M12,22 L17,21 M14,22 L17,20 M15,22 L17,19 M16,22 L17,16",
                "o": 10
            },
            {
                "d": "M4,-3 L4,29 M5,-3 L5,29 M4,-3 L11,-3 M4,29 L11,29",
                "o": 7
            },
            {
                "d": "M0,1 L14,25",
                "o": 7
            },
            {
                "d": "M9,-3 L9,29 M10,-3 L10,29 M3,-3 L10,-3 M3,29 L10,29",
                "o": 7
            },
            {
                "d": "M3,15 L11,10 19,15 M3,15 L11,11 19,15",
                "o": 11
            },
            {
                "d": "M0,29 L20,29",
                "o": 10
            },
            {
                "d": "M4,1 L9,7 M4,1 L3,2 9,7",
                "o": 6
            },
            {
                "d": "M5,11 L5,10 6,10 6,12 4,12 4,10 5,9 7,8 11,8 13,9 14,10 15,12 15,19 16,21 17,22 M13,10 L14,12 14,19 15,21 M11,8 L12,9 13,11 13,19 14,21 17,22 18,22 M13,13 L12,14 7,15 4,16 3,18 3,19 4,21 7,22 10,22 12,21 13,19 M5,16 L4,18 4,19 5,21 M12,14 L8,15 6,16 5,18 5,19 6,21 7,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 6,21 8,21 M6,2 L6,20 M2,1 L7,1 7,21 M7,11 L8,9 10,8 12,8 15,9 17,11 18,14 18,16 17,19 15,21 12,22 10,22 8,21 7,19 M16,11 L17,13 17,17 16,19 M12,8 L14,9 15,10 16,13 16,17 15,20 14,21 12,22 M3,1 L5,2 M4,1 L5,3",
                "o": 10
            },
            {
                "d": "M15,12 L15,11 14,11 14,13 16,13 16,11 14,9 12,8 9,8 6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 14,21 16,19 M5,11 L4,13 4,17 5,19 M9,8 L7,9 6,10 5,13 5,17 6,20 7,21 9,22",
                "o": 9
            },
            {
                "d": "M14,1 L14,22 19,22 M15,2 L15,21 M11,1 L16,1 16,22 M14,11 L13,9 11,8 9,8 6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 13,21 14,19 M5,11 L4,13 4,17 5,19 M9,8 L7,9 6,10 5,13 5,17 6,20 7,21 9,22 M12,1 L14,2 M13,1 L14,3 M16,20 L17,22 M16,21 L18,22",
                "o": 11
            },
            {
                "d": "M5,14 L16,14 16,12 15,10 14,9 11,8 9,8 6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 14,21 16,19 M15,13 L15,12 14,10 M5,11 L4,13 4,17 5,19 M14,14 L14,11 13,9 11,8 M9,8 L7,9 6,10 5,13 5,17 6,20 7,21 9,22",
                "o": 9
            },
            {
                "d": "M12,3 L12,2 11,2 11,4 13,4 13,2 12,1 9,1 7,2 6,3 5,6 5,22 M7,3 L6,6 6,21 M9,1 L8,2 7,4 7,22 M2,8 L11,8 M2,22 L10,22 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 7
            },
            {
                "d": "M15,9 L16,10 17,9 16,8 15,8 13,9 12,10 M8,8 L6,9 5,10 4,12 4,14 5,16 6,17 8,18 10,18 12,17 13,16 14,14 14,12 13,10 12,9 10,8 8,8 M6,10 L5,12 5,14 6,16 M12,16 L13,14 13,12 12,10 M8,8 L7,9 6,11 6,15 7,17 8,18 M10,18 L11,17 12,15 12,11 11,9 10,8 M5,16 L4,17 3,19 3,20 4,22 5,23 8,24 12,24 15,25 16,26 M5,22 L8,23 12,23 15,24 M3,20 L4,21 7,22 12,22 15,23 16,25 16,26 15,28 12,29 6,29 3,28 2,26 2,25 3,23 6,22 M6,29 L4,28 3,26 3,25 4,23 6,22",
                "o": 10
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M2,1 L7,1 7,22 M7,12 L8,10 9,9 11,8 14,8 16,9 17,10 18,13 18,22 M16,10 L17,13 17,21 M14,8 L15,9 16,12 16,22 M2,22 L10,22 M13,22 L21,22 M3,1 L5,2 M4,1 L5,3 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M16,21 L14,22 M16,20 L15,22 M18,20 L19,22 M18,21 L20,22",
                "o": 12
            },
            {
                "d": "M5,1 L5,3 7,3 7,1 5,1 M6,1 L6,3 M5,2 L7,2 M5,8 L5,22 M6,9 L6,21 M2,8 L7,8 7,22 M2,22 L10,22 M3,8 L5,9 M4,8 L5,10 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 6
            },
            {
                "d": "M7,1 L7,3 9,3 9,1 7,1 M8,1 L8,3 M7,2 L9,2 M7,8 L7,25 6,28 5,29 M8,9 L8,24 7,27 M4,8 L9,8 9,24 8,27 7,28 5,29 2,29 1,28 1,26 3,26 3,28 2,28 2,27 M5,8 L7,9 M6,8 L7,10",
                "o": 6
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M2,1 L7,1 7,22 M16,9 L7,18 M11,14 L18,22 M11,15 L17,22 M10,15 L16,22 M13,8 L20,8 M2,22 L10,22 M13,22 L20,22 M3,1 L5,2 M4,1 L5,3 M14,8 L16,9 M19,8 L16,9 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M16,20 L14,22 M15,20 L19,22",
                "o": 11
            },
            {
                "d": "M5,1 L5,22 M6,2 L6,21 M2,1 L7,1 7,22 M2,22 L10,22 M3,1 L5,2 M4,1 L5,3 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 6
            },
            {
                "d": "M5,8 L5,22 M6,9 L6,21 M2,8 L7,8 7,22 M7,12 L8,10 9,9 11,8 14,8 16,9 17,10 18,13 18,22 M16,10 L17,13 17,21 M14,8 L15,9 16,12 16,22 M18,12 L19,10 20,9 22,8 25,8 27,9 28,10 29,13 29,22 M27,10 L28,13 28,21 M25,8 L26,9 27,12 27,22 M2,22 L10,22 M13,22 L21,22 M24,22 L32,22 M3,8 L5,9 M4,8 L5,10 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M16,21 L14,22 M16,20 L15,22 M18,20 L19,22 M18,21 L20,22 M27,21 L25,22 M27,20 L26,22 M29,20 L30,22 M29,21 L31,22",
                "o": 17
            },
            {
                "d": "M5,8 L5,22 M6,9 L6,21 M2,8 L7,8 7,22 M7,12 L8,10 9,9 11,8 14,8 16,9 17,10 18,13 18,22 M16,10 L17,13 17,21 M14,8 L15,9 16,12 16,22 M2,22 L10,22 M13,22 L21,22 M3,8 L5,9 M4,8 L5,10 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22 M16,21 L14,22 M16,20 L15,22 M18,20 L19,22 M18,21 L20,22",
                "o": 12
            },
            {
                "d": "M9,8 L6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 14,21 16,19 17,16 17,14 16,11 14,9 11,8 9,8 M5,11 L4,13 4,17 5,19 M15,19 L16,17 16,13 15,11 M9,8 L7,9 6,10 5,13 5,17 6,20 7,21 9,22 M11,22 L13,21 14,20 15,17 15,13 14,10 13,9 11,8",
                "o": 10
            },
            {
                "d": "M5,8 L5,29 M6,9 L6,28 M2,8 L7,8 7,29 M7,11 L8,9 10,8 12,8 15,9 17,11 18,14 18,16 17,19 15,21 12,22 10,22 8,21 7,19 M16,11 L17,13 17,17 16,19 M12,8 L14,9 15,10 16,13 16,17 15,20 14,21 12,22 M2,29 L10,29 M3,8 L5,9 M4,8 L5,10 M5,28 L3,29 M5,27 L4,29 M7,27 L8,29 M7,28 L9,29",
                "o": 10
            },
            {
                "d": "M14,9 L14,29 M15,10 L15,28 M13,9 L15,9 16,8 16,29 M14,11 L13,9 11,8 9,8 6,9 4,11 3,14 3,16 4,19 6,21 9,22 11,22 13,21 14,19 M5,11 L4,13 4,17 5,19 M9,8 L7,9 6,10 5,13 5,17 6,20 7,21 9,22 M11,29 L19,29 M14,28 L12,29 M14,27 L13,29 M16,27 L17,29 M16,28 L18,29",
                "o": 10
            },
            {
                "d": "M5,8 L5,22 M6,9 L6,21 M2,8 L7,8 7,22 M14,10 L14,9 13,9 13,11 15,11 15,9 14,8 12,8 10,9 8,11 7,14 M2,22 L10,22 M3,8 L5,9 M4,8 L5,10 M5,21 L3,22 M5,20 L4,22 M7,20 L8,22 M7,21 L9,22",
                "o": 8
            },
            {
                "d": "M13,10 L14,8 14,12 13,10 12,9 10,8 6,8 4,9 3,10 3,12 4,14 6,15 11,16 13,17 14,20 M4,9 L3,12 M4,13 L6,14 11,15 13,16 M14,17 L13,21 M3,10 L4,12 6,13 11,14 13,15 14,17 14,20 13,21 11,22 7,22 5,21 4,20 3,18 3,22 4,20",
                "o": 9
            },
            {
                "d": "M5,3 L5,17 6,20 7,21 9,22 11,22 13,21 14,19 M6,3 L6,18 7,20 M5,3 L7,1 7,18 8,21 9,22 M2,8 L11,8",
                "o": 8
            },
            {
                "d": "M5,8 L5,17 6,20 7,21 9,22 12,22 14,21 15,20 16,18 M6,9 L6,18 7,20 M2,8 L7,8 7,18 8,21 9,22 M16,8 L16,22 21,22 M17,9 L17,21 M13,8 L18,8 18,22 M3,8 L5,9 M4,8 L5,10 M18,20 L19,22 M18,21 L20,22",
                "o": 12
            },
            {
                "d": "M3,8 L9,22 M4,8 L9,20 M5,8 L10,20 M15,9 L10,20 9,22 M1,8 L8,8 M11,8 L17,8 M2,8 L5,10 M7,8 L5,9 M13,8 L15,9 M16,8 L15,9",
                "o": 9
            },
            {
                "d": "M4,8 L8,22 M5,8 L8,19 M6,8 L9,19 M12,8 L9,19 8,22 M12,8 L16,22 M13,8 L16,19 M12,8 L14,8 17,19 M20,9 L17,19 16,22 M1,8 L9,8 M17,8 L23,8 M2,8 L5,9 M8,8 L6,9 M18,8 L20,9 M22,8 L20,9",
                "o": 12
            },
            {
                "d": "M4,8 L14,22 M5,8 L15,22 M6,8 L16,22 M15,9 L5,21 M2,8 L9,8 M12,8 L18,8 M2,22 L8,22 M11,22 L18,22 M3,8 L5,9 M8,8 L6,9 M13,8 L15,9 M17,8 L15,9 M5,21 L3,22 M5,21 L7,22 M14,21 L12,22 M15,21 L17,22",
                "o": 10
            },
            {
                "d": "M4,8 L10,22 M5,8 L10,20 M6,8 L11,20 M16,9 L11,20 8,26 6,28 4,29 2,29 1,28 1,26 3,26 3,28 2,28 2,27 M2,8 L9,8 M12,8 L18,8 M3,8 L6,10 M8,8 L6,9 M14,8 L16,9 M17,8 L16,9",
                "o": 9
            },
            {
                "d": "M13,8 L3,22 M14,8 L4,22 M15,8 L5,22 M15,8 L3,8 3,12 M3,22 L15,22 15,18 M4,8 L3,12 M5,8 L3,11 M6,8 L3,10 M8,8 L3,9 M10,22 L15,21 M12,22 L15,20 M13,22 L15,19 M14,22 L15,18",
                "o": 9
            },
            {
                "d": "M9,-3 L7,-2 6,-1 5,1 5,3 6,5 7,6 8,8 8,10 6,12 M7,-2 L6,0 6,2 7,4 8,5 9,7 9,9 8,11 4,13 8,15 9,17 9,19 8,21 7,22 6,24 6,26 7,28 M6,14 L8,16 8,18 7,20 6,21 5,23 5,25 6,27 7,28 9,29",
                "o": 7
            },
            {
                "d": "M4,-3 L4,29",
                "o": 4
            },
            {
                "d": "M5,-3 L7,-2 8,-1 9,1 9,3 8,5 7,6 6,8 6,10 8,12 M7,-2 L8,0 8,2 7,4 6,5 5,7 5,9 6,11 10,13 6,15 5,17 5,19 6,21 7,22 8,24 8,26 7,28 M8,14 L6,16 6,18 7,20 8,21 9,23 9,25 8,27 7,28 5,29",
                "o": 7
            },
            {
                "d": "M3,16 L3,14 4,11 6,10 8,10 10,11 14,14 16,15 18,15 20,14 21,12 M3,14 L4,12 6,11 8,11 10,12 14,15 16,16 18,16 20,15 21,12 21,10",
                "o": 12
            },
            {
                "d": "M0,1 L0,22 1,22 1,1 2,1 2,22 3,22 3,1 4,1 4,22 5,22 5,1 6,1 6,22 7,22 7,1 8,1 8,22 9,22 9,1 10,1 10,22 11,22 11,1 12,1 12,22 13,22 13,1 14,1 14,22 15,22 15,1 16,1 16,22",
                "o": 8
            }
        ]
    }
}
return fonts;
}export default SvgObj;