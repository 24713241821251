import React from 'react';
import {HersheySVG, HersheyObj} from './components/HersheyFont/HersheySVG';
import './App.css';
import jsPdf from 'jspdf';
import Options from './Options.js'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage:1,
      fonts:Object.keys(HersheyObj()),
      inputs:{},
      shipping:{},
    }
    this.changeInput = this.changeInput.bind(this);
    this.resize = this.resize.bind(this);
    this.draw = this.draw.bind(this);
  }
  changeInput(e) {
    const value = e.target.value;
    const inputs = this.state.inputs;
    const name = e.target.name;
    inputs[name] = value;
    this.setState({inputs:inputs});
  }
  moveForward() {
    const stage = this.state.stage;
    console.log(this.state.inputs);
    let inputs = this.state.inputs;
    const keys = Object.keys(inputs);
    for(let i=0;i<keys.length;i++){
     if(inputs[keys[i]]===''){
      alert('Must fill out all fields');
      return;
     } 
    }
    if(keys.length<8){
      alert('Must fill out all fields');
      return;
    }
    this.setState({stage:0},()=>{
      setTimeout(()=>{
        this.setState({stage:stage+1},()=>{
          this.draw();
        });
      },1)
    })
  }
  moveBack() {
    const stage = this.state.stage;
    this.setState({stage:0},()=>{
      setTimeout(()=>{
        this.setState({stage:stage-1});
      },100)
    })
  }
  isEmailValid() {
    if(this.state.inputs.shipEmail){
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.state.inputs.shipEmail).toLowerCase());
    } else {
      return false;
    }
  }
  finalize() {
    console.log(this.state);
    let emailValid = this.isEmailValid();
    console.log('emailvalid',emailValid);
    if(emailValid){
      this.post();
    } else {
      alert('Please enter a valid email');
    }
  }
  resize() {
    const width = parseInt(window.innerWidth);
    const height = parseInt(window.innerHeight);
    this.setState({width:width,height:height});
  }
  post() {
    let url = Options().url;   
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      url=Options().devurl;
    }
    const inputKeys = Object.keys(this.state.inputs);  
      
    for(let i =0;i<inputKeys.length;i++){
      url = url + inputKeys[i].toString()+'='+encodeURIComponent(this.state.inputs[inputKeys[i]].toString())+'&';
    }

    const canvas = document.getElementById('canvas');
    const dataURL = canvas.toDataURL();
    const params = {
      method: 'post',
      body:dataURL.split(',')[1]
        }; 
    this.setState({loading:true},()=>{
      const res = fetch(url,params).then(res=>res.json()).then((res)=>{
        console.log(res);
        if(res.row==='success') {
          const message = <div className='form' style={{textAlign:'center'}}>
            <div>Thank you for your submission!</div>
            <div>You can expect to recieve your heirloom capsule in the mail in 1-2 weeks.</div>
          </div>
          this.setState({loading:false,stage:3,message:message});
        } else {
          const message = <div className='form' style={{textAlign:'center'}}>
            <div>There was an issue submitting your information.</div>
            <div>Please try again at a later time.</div>
          </div>
          this.setState({loading:false,stage:3,message:message});
        }
      });
      setTimeout(()=>{
        if(this.state.stage===2){
          const message = <div className='form' style={{textAlign:'center'}}>
          <div>There was an issue submitting your information.</div>
          <div>Please try again at a later time.</div>
        </div>
        this.setState({loading:false,stage:3,message:message});
        }
      },40000)
      return res;
    })
  }
  draw() {
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    var cert = document.getElementById('certificate');
    canvas.width = cert.naturalWidth/2;
    canvas.height = cert.naturalHeight/2;
    const scaleUp = (cert.naturalWidth/595)/2;
    const svg = document.getElementById('hershey-svg'); 
    ctx.drawImage(cert, 0, 0,canvas.width,canvas.height);
    const string = svg.outerHTML;
    const encodedString = window.btoa(string);
    const src = 'data:image/svg+xml;base64,'+encodedString;
    let image = new Image();
    image.onload = function() {
      const imgWidth = image.naturalWidth;
      const imgHeight = image.naturalHeight;
      ctx.drawImage(image, 0, 0,imgWidth*scaleUp,imgHeight*scaleUp);
    };
    image.src = src;
  }
  componentDidMount() { 
    this.resize();
    window.addEventListener('resize',()=>{this.resize()});
    const svgs = document.getElementsByClassName('hershey-svg');
    for(let i=0;i<svgs.length;i++){
      const svg = svgs[i];
      svg.addEventListener('load',(e)=>{
        console.log(e)
      })
    }
  }
  render() {
    const stage = this.state.stage;
    const appWidth = this.state.width>1310?1310:this.state.width;
    const getForm = () => {
      if(stage===1){
        const fonts = () => {
          const fonts = this.state.fonts;
          const options= [];
          for(let i=0;i<fonts.length;i++){
            const fontName = fonts[i].slice(0,1).toUpperCase()+fonts[i].slice(1,fonts[i].length);
            const option = <option value={fonts[i]}>{fontName}</option>
            options.push(option);
          }
          return options;
        }
        return <div className='form'>
            <ul>
              <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.name}           name='name'          /><label>Name</label></li>
              <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.address1}       name='address1'      /><label>Address (1)</label></li>
              <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.address2}       name='address2'      /><label>Address (2)</label></li>
              <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.date}           name='date'          /><label>Date</label></li>
              <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.productNameNum} name='productNameNum'/><label>Product Name & No.</label></li>
              <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.serial}         name='serial'        /><label>Serial Number</label></li>
              <li><textarea onChange={this.changeInput.bind(this)} value={this.state.inputs.message}         name='message'        /><label>Legacy Message</label></li>
              <li style={{display:'none'}}><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.shipFlag} name='shipFlag'/><label>Enter Info Here</label></li>
              <li>
                <select name='font' onChange={this.changeInput.bind(this)} defaultValue={this.state.inputs.font} >
                  <option disabled selected>Choose Your Font</option>
                  {fonts()}
                </select>
              </li>
            </ul>
            {appWidth<701?<div style={{textAlign:'center',fontStyle:'italic'}} >See Preview Below</div>:''}
            <div className='button' onClick={this.moveForward.bind(this)} >Enter Shipping Details</div>
          </div>
      } else if(stage===2) {
        return <div className='form'>
        <div style={{display:this.state.loading?'block':'none'}} className='loading-bkg'><div className='loader'></div></div>
        <ul>
          <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.shipName}     name='shipName'          /><label>Name</label></li>
          <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.shipAddress1} name='shipAddress1'      /><label>Address (1)</label></li>
          <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.shipCity}     name='shipCity'      /><label>City</label></li>
          <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.shipState}    name='shipState'          /><label>State</label></li>
          <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.shipZip}      name='shipZip'     /><label>Zip</label></li>
          <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.shipEmail}    name='shipEmail'        /><label>Email Address</label></li>
          <li><input type='text' onChange={this.changeInput.bind(this)} value={this.state.inputs.shipPhone}    name='shipPhone'        /><label>Phone Number</label></li>
        </ul>
        {appWidth<700?<div style={{textAlign:'center',fontStyle:'italic'}}>See Preview Below</div>:''}
        <div className='button' onClick={this.moveBack.bind(this)}>Go Back</div><div className='button' onClick={this.finalize.bind(this)} >Finalize</div>
      </div>
      } else if(stage===3) {
        return this.state.message;
      } else {
        return <div className='form'></div>;
      }
    }
    const scale = appWidth>990?1:appWidth>700?((appWidth-397)/595):appWidth>620?1:(appWidth-20)/595;
    return (
      <div className="App">
        <header className="App-header">
        </header>
        <div id='head'>
          <img src="http://www.howardmiller.com/wcsstore/HowardMillerPublicSite/images/logo.jpg" alt="Howard Miller" border="0" />
        </div>
        <div id='body'>
          {getForm()}
          <div className='pdf' style={{transform:'scale('+scale+')',height:this.state.height-224+'px'}} >
            <div className='record-wrap'>
              <svg version="1.1" style={{position:'absolute'}} xmlns="http://www.w3.org/2000/svg"  id='hershey-svg' height={500} width="500">
                <HersheySVG   scale={0.5} left={122} top={175} key={'1'+appWidth+this.state.inputs.font+this.state.inputs.name}                      font={this.state.inputs.font} input={this.state.inputs.name}/>
                <HersheySVG   scale={0.5} left={126} top={198} key={'2'+appWidth+this.state.inputs.font+this.state.inputs.address1}                  font={this.state.inputs.font} input={this.state.inputs.address1}/>
                <HersheySVG   scale={0.5} left={99}  top={220} key={'3'+appWidth+this.state.inputs.font+this.state.inputs.address2}                  font={this.state.inputs.font} input={this.state.inputs.address2}/>
                <HersheySVG   scale={0.5} left={115} top={243} key={'4'+appWidth+this.state.inputs.font+this.state.inputs.date}                      font={this.state.inputs.font} input={this.state.inputs.date}/>
                <HersheySVG   scale={0.5} left={171} top={265} key={'5'+appWidth+this.state.inputs.font+this.state.inputs.productNameNum}            font={this.state.inputs.font} input={this.state.inputs.productNameNum}/>
                <HersheySVG   scale={0.5} left={130} top={288} key={'6'+appWidth+this.state.inputs.font+this.state.inputs.serial}                    font={this.state.inputs.font} input={this.state.inputs.serial}/>
                <HersheySVG   scale={0.5} left={94}  top={370} multiline={true} key={'7'+appWidth+this.state.inputs.font+this.state.inputs.message}  font={this.state.inputs.font} input={this.state.inputs.message}/>
              </svg>
              <img id='certificate' onLoad={this.draw.bind(this)} width={595} src='/record.png'/>
            </div>
          </div>
        </div>
        <canvas style={{transformOrigin:'top left',transform:'scale('+scale+')'}} id='canvas'></canvas>
        <div id='footer'>
          <span><b>© 2019&nbsp;Howard Miller </b>&nbsp;/ <a target="_blank" rel="noopener noreferrer" href="http://www.howardmiller.com/terms_of_use" className="sitemap_link">Terms of Use</a> &nbsp;/ <a target="_blank" rel="noopener noreferrer" href="http://www.howardmiller.com/privacy_policy" className="sitemap_link">Privacy Policy</a></span>
        </div>

      </div>
    );
  }
} export default App;
